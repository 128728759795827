'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:EmailCampaignsCtrl
 * @description
 * # Email Campaigns Ctrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var EmailCampaignsCtrl = function ($state, $scope, $q, $sce, DataSalesService, CurrentEvent, EmailCampaigns, Templates, Search, DataSalesExpression, Demographics, Locations, AttendeeListDownloads, DownloadDemographics, DownloadLocations, EnvironmentConfig, EXSConfig, ActiveUserProfile) {
    'ngInject'
    var self = this;

    self.campaigns = angular.copy(EmailCampaigns);
    self.showPreview = false;
    self.search = Search.dataSales;
    self.attendeeListDownloads = AttendeeListDownloads;
    self.showCode = CurrentEvent.ShowCode;
    self.dataSalesListId = undefined;
    self.filename = "";
    self.exportType = "";
    self.exportUrl = $sce.trustAsResourceUrl(EnvironmentConfig.SiteRoot + 'dataSalesExport/');
    self.exsConfig = EXSConfig;
    self.currentEvent = CurrentEvent;
    self.activeUserProfile = ActiveUserProfile;

    $scope.$parent.dashboard.hide = false;

    function isComplex(expressions) {
      for (var i = 0; i < expressions.length; i++)
        for (var a = 0; a < expressions[i].nodes.length; a++)
          if (expressions[i].nodes[a].operands.length > 1)
            return true;
          else if (expressions[i].nodes[a].operands[0].operator != 'oneOf')
            return true;
    }

    for (var i = 0; i < self.campaigns.length; i++) {
      if (self.campaigns[i].Strategy.ExecutionDate)
        self.campaigns[i].Date = self.campaigns[i].Strategy.ExecutionDate;
      else
        self.campaigns[i].Date = '';

      self.campaigns[i].ProductName = self.campaigns[i].Strategy.DisplayName;
      self.campaigns[i].TargetShowCode = self.campaigns[i].Strategy.ShowCode;
      self.campaigns[i].StrategyType = self.campaigns[i].Strategy.StrategyType;
      self.campaigns[i].ApprovalStatus = self.campaigns[i].Strategy.ApprovalStatus;

      self.campaigns[i].MessageConfigs = self.campaigns[i].Strategy.MessageConfigs.reduce(
        function (acc, curr) { return (curr.ItemValue && (acc[curr.KeyName] = curr.ItemValue) && acc) || acc; }, {})

      for (var x = 0; x < self.attendeeListDownloads.length; x++) {
        if (self.campaigns[i].Strategy.DataSalesListID == self.attendeeListDownloads[x].Transaction.DataSalesListId) {
          self.campaigns[i].Date = self.attendeeListDownloads[x].Date;
          self.campaigns[i].ProductName = self.attendeeListDownloads[x].ProductName;
        }
      }

      if (self.campaigns[i].ListInfo && self.campaigns[i].ListInfo.ContextRaw) {
        self.campaigns[i].ListInfo.expressions = new DataSalesExpression(angular.fromJson(self.campaigns[i].ListInfo.ContextRaw), angular.extend({}, Locations[self.campaigns[i].Strategy.TargetShowCode].FilterDetails, Demographics[self.campaigns[i].Strategy.TargetShowCode].FilterDetails));
        self.campaigns[i].ListInfo.complex = isComplex(self.campaigns[i].ListInfo.expressions);
      }
    }

    for (var i = 0; i < self.attendeeListDownloads.length; i++) {
      self.attendeeListDownloads[i].StrategyType = '';
      self.attendeeListDownloads[i].ApprovalStatus = 'APPROVED';

      for (var x = 0; x < self.campaigns.length; x++) {
        if (self.attendeeListDownloads[i].Transaction.DataSalesListId == self.campaigns[x].Strategy.DataSalesListID) {
          self.attendeeListDownloads[i].StrategyType = self.campaigns[x].Strategy.StrategyType;
          self.attendeeListDownloads[i].ApprovalStatus = self.campaigns[x].Strategy.ApprovalStatus;
        }
      }

      if (self.attendeeListDownloads[i].ListInfo && self.attendeeListDownloads[i].ListInfo.ContextRaw) {
        self.attendeeListDownloads[i].ListInfo.expressions = new DataSalesExpression(angular.fromJson(self.attendeeListDownloads[i].ListInfo.ContextRaw), angular.extend({}, DownloadLocations[self.attendeeListDownloads[i].TargetShowCode].FilterDetails, DownloadDemographics[self.attendeeListDownloads[i].TargetShowCode].FilterDetails));
        self.attendeeListDownloads[i].ListInfo.complex = isComplex(self.attendeeListDownloads[i].ListInfo.expressions);
      }
    }

    self.more = function (pageSize) {
      pageSize.current = '';
    };

    self.getPreview = function (campaign) {
      var template = Templates.filter(function (x) { return x.TemplateID == campaign.Strategy.TemplateID; })[0];
      var templateHtml = angular.copy(template.BodyHtml);
      var iframe = document.getElementById('TemplatePreview');

      for (var i = 0; i < campaign.Strategy.MessageConfigs.length; i++) {
        if (template.BodyFields[campaign.Strategy.MessageConfigs[i].KeyName])
          templateHtml = templateHtml.replace(new RegExp(template.BodyFields[campaign.Strategy.MessageConfigs[i].KeyName].Tag, 'g'), campaign.Strategy.MessageConfigs[i].ItemValue);
      }

      if (iframe) {
        iframe.contentWindow.document.body.innerHTML = '';
        iframe.contentWindow.document.write(templateHtml);
        self.showPreview = true;
      }
    };

    self.collapse = function (pageSize, value) {
      pageSize.current = value;
    };

    self.getFilterLimit = function (pageSize) {
      return pageSize.current;
    };

    self.edit = function (strategy) {
      //TODO: timecheck
      $state.go('event.dashboard.emailCampaigns.edit', { id: strategy.PrimaryID });
    };

    self.refreshStrategy = function (strategy) {
      return function () {
        return $q.all([
          DataSalesService.getStrategy({
            showCode: CurrentEvent.ShowCode,
            id: strategy.PrimaryID
          }, true),
          DataSalesService.getFeedbackSummary({
            strategyId: strategy.PrimaryID
          }, true)
        ]).then(function (data) {
          for (var i = 0; i < EmailCampaigns.length; i++) {
            if (EmailCampaigns[i].Strategy.PrimaryID == strategy.PrimaryID) {
              EmailCampaigns[i].Strategy = DataSalesService.data.strategies[CurrentEvent.ShowCode][strategy.PrimaryID];
              EmailCampaigns[i].Strategy.FeedbackSummary = data[1].data;
              self.campaigns[i].Strategy = angular.copy(EmailCampaigns[i].Strategy);
              return;
            }
          }
        });
      };
    };

    self.download = function (dataSalesListId, type) {
      self.filename = "attendee_list_" + dataSalesListId;
      self.exportType = type;
      self.dataSalesListId = dataSalesListId;
      setTimeout(function () { angular.element('#ExportForm').submit(); }, 0);
    }
  };

  angular.module('exhibitorPortalApp')
    .controller('EmailCampaignsCtrl', EmailCampaignsCtrl);
})(angular, window);
