'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalLoginApp.controller:LoginCtrl
 * @description
 * # LoginCtrl
 * Controller of the exhibitorPortalLoginApp
 */

(function (angular, window, undefined) {
  var LoginCtrl = function ($state, $rootScope, $window, EnvironmentConfig, LoginService) {
    'ngInject'
    var self = this;

    self.root = $rootScope;

    self.panels = ['ShowComp', 'SignIn'];
    self.ShowComp = false;
    self.SignIn = true;

    LoginService.clearChoices();

    self.swapManualLogin = EnvironmentConfig.SWAPManualLogin;
    if (self.swapManualLogin) {
      self['ShowComp'] = false;
      self['SignIn'] = true;
    }

    self.clickPanel = function (panel) {
      if (!self[panel]) {
        self[panel] = true;

        var hiddenPanels = _.filter(self.panels, function (p) { return p !== panel; });
        for (var i = 0; i < hiddenPanels.length; i++)
          self[hiddenPanels[i]] = false;

        LoginService.data.messages = null;
      }
    };

    self.accountSearch = function () {
      $state.go('login.accountSearch');
    };

    self.forgotPassword = function () {
      $state.go('login.accountSearch', { forgotPassword: true });
    };
  };

  angular.module('exhibitorPortalLoginApp')
    .controller('LoginCtrl', LoginCtrl);
})(angular, window);
