'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalLoginApp.controller:ChangePwdCtrl
 * @description
 * # ChangePwdCtrl
 * Controller of the exhibitorPortalLoginApp
 */

(function (angular, window, undefined) {
  var ChangePwdCtrl = function ($state, $rootScope, $window, EnvironmentConfig, LoginService, $stateParams) {
    'ngInject'
    var self = this;

    self.focusPassword = true;
    self.inputType = 'password';

    self.pwdUser = LoginService.getPwdUser();
    if (!self.pwdUser || !self.pwdUser.ContactID)
      $state.go('login.landing');

    self.changePassword = function () {
      if (!self.password) {
        self.errors = ['You must enter a new password to continue.'];
        return;
      }

      LoginService.changePassword({
        params: {
          ContactID: self.pwdUser.ContactID,
          password: self.password
        }
      }).then(function (data) {
        if (data) {
          if (data.Success && data.Data) {
            var loc = (EnvironmentConfig.SiteRoot[EnvironmentConfig.SiteRoot.length - 1] === '/' && data.Data[0] === '/')
              ? EnvironmentConfig.SiteRoot + data.Data.substring(1, data.Data.length)
              : EnvironmentConfig.SiteRoot + data.Data;

            window.location = loc;
          } else if (data.Errors && data.Errors.length > 0) {
            self.errors = data.Errors;
          }
        }
      }).finally(function () {
          LoginService.clearPwdUser();
      });
    };

    self.changeType = function () {
      if (self.inputType === 'password')
        self.inputType = 'text';
      else
        self.inputType = 'password';

      self.focusPassword = true;
    };
  };

  angular.module('exhibitorPortalLoginApp')
    .controller('ChangePwdCtrl', ChangePwdCtrl);
})(angular, window);
