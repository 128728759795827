'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:filterGroupStatus
 * @description
 * # Filter Group Status
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epFilterGroupStatus() {
    'ngInject'
		return {
			restrict: 'A',
			require: '^epFilter',
      link: function (scope, element, attrs, FilterCtrl) {
				var anchor = element.children('a');
				var defaultText = attrs.heading;

				scope.$watch('activeFilters["' + attrs.epFilterGroup + '"]', function () {
					var count = FilterCtrl.getCount(scope.activeFilters[attrs.epFilterGroup]);
					anchor.text(defaultText + (count ? (' (' + count + ')') : ''));
				}, true);
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epFilterGroupStatus', epFilterGroupStatus);
})(angular, window);
