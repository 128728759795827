'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:characterCounterTarget
 * @description
 * # Character Counter Target
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epCharacterCounterTarget() {
    'ngInject'
		return {
			restrict: 'AE',
			// scope: {
			// 	model: '=epCharacterCounterTarget'
			// },
			require: '^epCharacterCounter',
			link: function (scope, elem, attrs, characterCounterCtrl) {
				if(typeof attrs.epCharacterCounterTarget !== 'string')
					throw new Error('Unsupported character counter target model!');

				scope.$watch(attrs.epCharacterCounterTarget, function(newValue) {
					newValue = newValue || '';
					characterCounterCtrl.count = newValue.length;
				});

				elem.on('focus', function() {
					scope.$apply(function() {
						characterCounterCtrl.visible = true;
					});
				});
				elem.on('blur', function() {
					scope.$apply(function() {
						characterCounterCtrl.visible = false;
					});
				});
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epCharacterCounterTarget', epCharacterCounterTarget);
})(angular, window);
