'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:eventBitCtrl
 * @description
 * # eventBitCtrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var EventBitCtrl = function ($sce, $state, $rootScope, $scope, SSO, EventBitOrders) {
    'ngInject'
    var self = this;

    if (!EventBitOrders) {
      $state.go('event.dashboard.landing');
    }

    self.SSO = $sce.trustAsResourceUrl(SSO);

    //keep session alive when on this page
    var reAuth = window.setInterval($rootScope.reAuth, 10000);

    $scope.$on('$stateChangeStart', function () {
      clearInterval(reAuth);
    });
  };

  angular.module('exhibitorPortalApp')
    .controller('EventBitCtrl', EventBitCtrl);
})(angular, window);
