'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:Scroll Bottom
 * @description
 * # Detect when an element's scroll bar has reached the bottom y-axis.
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epScrollBottom($timeout) {
    'ngInject'
        return {
            restrict: 'A',
            scope: {
                isBottom: '=epScrollBottom',
            },
            link: function (scope, element) {
                $timeout(function() {
                    scope.isBottom = element.scrollTop() + element.innerHeight() >= element[0].scrollHeight;
                });

                element.on('scroll', function () {
                    if (element.scrollTop() + element.innerHeight() >= element[0].scrollHeight) {
                        if (!scope.isBottom)
                            scope.$apply(function () {
                                scope.isBottom = true;
                            });
                    }
                    else if (scope.isBottom)
                        scope.$apply(function () {
                            scope.isBottom = false;
                        });
                });
            }
        };
    }

    angular.module('exhibitorPortalApp')
		.directive('epScrollBottom', epScrollBottom);
})(angular, window);
