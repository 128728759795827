'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.Demographic
 * @description
 * # Demographic
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var DemographicService = function (ApiService) {
    'ngInject'
        var self = this;

        self.data = {};

        self.getDemographics = ApiService.endpoint.get({
          url: 'api/demographic',
          memoization: false,
          success: function(data, params) {
              self.data.demographics = self.data.demographics || {};
              self.data.demographics[params.showCode] = data;
          }
        });
    };

    angular.module('exhibitorPortalApp')
      .service('DemographicService', DemographicService);
})(angular, window);
