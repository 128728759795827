'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.Address
 * @description
 * # Address
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var AddressService = function (ApiService) {
    'ngInject'
        var self = this;

        self.data = {};

        self.getBillingAddresses = ApiService.endpoint.get({
            url: 'api/address/getBillingAddresses',
            success: function (data) {
                var deduped = [];

                if(data)
                    for(var i = 0; i < data.length; i++){
                        if(data[i].AddressTypeCode == 'BILLING')
                            deduped.push(data[i]);
                        //search for duplicates if address type is not billing
                        else {
                            var isDupe = false;
                            var a1 = angular.copy(data[i]);

                            //delete things we know are different and then compare the rest
                            delete a1.Id;
                            delete a1.AddressTypeCode;

                            for(var a = 0; a < data.length; a++){
                                //only check against existing BILLING address since that is what we ultimately
                                //want to pass on to PCI
                                if(data[i].Id != data[a].Id && data[a].AddressTypeCode == 'BILLING'){
                                    var a2 = angular.copy(data[a])
                                    delete a2.Id;
                                    delete a2.AddressTypeCode;

                                    if(angular.equals(a1, a2)){
                                        isDupe = true;
                                        break;
                                    }
                                }
                            }

                            if(!isDupe)
                                deduped.push(data[i]);
                        }
                    }

                self.data.billingAddresses = deduped;
            }
        });

        self.getCountries = ApiService.endpoint.get({
            url: 'api/address/getCountries',
            success: function (data) {
                self.data.countries = data;
            }
        });

        self.getStates = ApiService.endpoint.get({
            url: 'api/address/getStates',
            success: function (data) {
                self.data.states = data;
            }
        });

        self.save = ApiService.endpoint.post({
            url: 'api/address/save'
        });
    };

    angular.module('exhibitorPortalApp')
      .service('AddressService', AddressService);
})(angular, window);
