'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:DataSalesTemplateCtrl
 * @description
 * # Data Sales Template Ctrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var DataSalesTemplateCtrl = function ($q, $anchorScroll, $window, $timeout, $state, $stateParams, $scope, Strategy, Templates, Email, CurrentEvent, DataSalesProduct, Cart, DataSalesService, DataSalesCartItem, UtilityFactory) {
    'ngInject'
    var self = this;
    var SCHEDULE_BUFFER_HOURS = 8; //TODO: replace with injected config setting
    var now = new Date();

    self.editMode = !Cart;
    self.email = self.editMode ? angular.copy(Email) : Email;
    self.dataSalesProduct = DataSalesProduct;
    self.templates = Templates;
    self.debounce = 1000;
    $anchorScroll();

    if (self.email.TemplateId)
      self.selectedTemplate = self.templates.filter(function (e) { return e.TemplateID == self.email.TemplateId; })[0];
    else {
      self.selectedTemplate = self.templates[0];
      self.email.TemplateId = self.templates[0].TemplateID;
    }

    self.dateOptions = {
      min: now
    };

    self.datePicker = {};
    self.timePicker = {};

    if ($stateParams.setSubmitted)
      $timeout(function () { self.emailForm.$setSubmitted(); });

    if (!self.editMode)
      $scope.$watch('dataSalesTemplate.email', function (newValue, oldValue) {
        if (!angular.equals(newValue, oldValue))
          Cart.$save();
      }, true);
    else {
      $scope.$parent.$parent.dashboard.hide = true;
      var d = new Date(self.email.ScheduledExecutionDate);

      $timeout(function () {
        self.datePicker.set('select', [d.getFullYear(), d.getMonth(), d.getDate()]);
        self.timePicker.set('select', [d.getHours(), d.getMinutes()]);
        self.onDateChange.init = true;
      });
    }


    self.onDateChange = function () {
      if (!self.onDateChange.init) {
        self.timePicker.clear();

        now = new Date();
        var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        var scheduledExecutionDate = new Date(self.email.ScheduledExecutionDate);

        if (scheduledExecutionDate.getTime() === today.getTime())
          self.timePicker.set('min', [now.getHours(), now.getMinutes() > 30 ? 60 : 30]);
        else
          self.timePicker.set('min', [0, 0]);
      }
      else
        self.onDateChange.init = false;
    };

    self.onTemplateChange = function (template) {
      self.email.TemplateId = template.TemplateID;
    };

    self.getPreview = function (template) {
      var templateHtml = angular.copy(template.BodyHtml);
      var iframe = document.getElementById('TemplatePreview');

      for (var key in self.selectedTemplate.BodyFields)
        templateHtml = templateHtml.replace(new RegExp(self.selectedTemplate.BodyFields[key].Tag, 'g'), self.email[key]);

      if (iframe) {
        iframe.contentWindow.document.body.innerHTML = '';
        iframe.contentWindow.document.write(templateHtml);
        self.showPreview = true;
      }
    };

    self.showEmailSpecification = function () {
      $window.open('http://md.swap.mge360.com/content/exhibitor/HTML_Best_Practices_Exb_Portal.pdf', '_blank');
    };

    self.onFileSelect = function (files) {
      var allowedFileTypes = ['image/png', 'image/jpeg', 'image/gif'];

      for (var i = 0; i < files.length; i++) {
        var file = files[i];

        if (allowedFileTypes.indexOf(file.type) === -1) {
          window.alert('Invalid file type!');
          return;
        }

        if (typeof FileReader !== 'undefined' && file.type.indexOf('image') > -1) {
          var fileReader = new FileReader();
          fileReader.readAsDataURL(files[i]);

          fileReader.onload = function (e) {
            $timeout(function () {
              if (e.target.result.length > 2000000) {
                files.splice(i, 1);
                window.alert('Image is greater than 2MB!');
                self.email.DataSalesExhibitorImage = null;
                $scope.$apply();
              }
              else {
                var tempUrl = window.URL || window.webkitURL;
                var img = new Image();
                var obj = tempUrl.createObjectURL(file);
                var width = 0, height = 0;
                img.onload = function () {
                  width = this.width;
                  height = this.height;
                  if (width > 600) {
                    height = (height / width) * 600;
                    width = 600;
                  }
                  var canvas = document.createElement("canvas");
                  canvas.width = width;
                  canvas.height = height;
                  var ctx = canvas.getContext("2d");
                  ctx.mozImageSmoothingEnabled = false;
                  ctx.webkitImageSmoothingEnabled = false;
                  ctx.msImageSmoothingEnabled = false;
                  ctx.imageSmoothingEnabled = false;
                  ctx.drawImage(this, 0, 0, width, height);
                  var dataurl = canvas.toDataURL(file.type);
                  self.email.DataSalesExhibitorImage = dataurl;
                  $scope.$apply();
                }
                img.src = obj;
              }
            });
          };
        }
      }
    };

    self.next = function () {
      var d = $q.defer();

      $timeout(function () {
        if (!self.emailForm.$valid) {
          self.emailForm.$setSubmitted();
          d.reject();
        }
        else {
          $state.go('event.shop.dataSales.preview');
          d.resolve();
        }
      });

      return d.promise;
    };

    self.save = function () {
      var d = $q.defer();

      if (!self.emailForm.$valid) {
        self.emailForm.$setSubmitted();
        d.reject();
      }
      else {
        DataSalesService.saveStrategy({
          data: {
            showCode: CurrentEvent.ShowCode,
            strategy: {
              activityFocus: 'DSE',
              strategyType: 'BLAST',
              subjectLine: self.email.SubjectLine,
              primaryID: $stateParams.id,
              templateId: self.email.TemplateId,
              messageConfigs: (function (templates, templateId) {
                var messageConfigs = [];
                var template = templates.filter(function (e) { return e.TemplateID == templateId; });

                if (template.length && (template = template[0])) {
                  for (var a = 0; a < template.BodyFields.length; a++) {
                    if (template.BodyFields[a].KeyName === 'DataSalesExhibitorBody') {
                      messageConfigs.push({
                        keyName: template.BodyFields[a].KeyName,
                        itemValue: UtilityFactory.HTMLEncode(self.email[template.BodyFields[a].KeyName])
                      });
                    } else {
                      messageConfigs.push({
                        keyName: template.BodyFields[a].KeyName,
                        itemValue: self.email[template.BodyFields[a].KeyName]
                      });
                    }
                  }
                }

                return messageConfigs;
              })(DataSalesService.data.templates[CurrentEvent.ShowCode], self.email.TemplateId),
              scheduledExecutionDate: new Date(self.email.ScheduledExecutionDate + ' ' + self.email.ScheduledExecutionTime).toUTCString()
            }
          }
        }).then(function () {
          DataSalesService.getEmailCampaigns({ showCode: CurrentEvent.ShowCode }, true)
            .then(function () {
              $state.go('event.dashboard.emailCampaigns', {}, { reload: true });
              d.resolve();
            });
        });
      }

      return d.promise;
    };

    self.sendingTest = false;
    self.sendTestEmail = function () {

      if (!self.testEmails) {
        alert('You must provide an email address');
        return;
      }

      if (!self.email.SubjectLine) {
        alert('You must provide a subject.');
        return;
      }

      self.sendingTest = true;
      //Call Data Sales Service to Email Template.
      DataSalesService.sendTestEmail({
        data: {
          showCode: CurrentEvent.ShowCode,
          strategy: {
            strategyType: 'BLAST',
            subjectLine: self.email.SubjectLine,
            primaryID: $stateParams.id,
            templateId: self.email.TemplateId,
            dataSalesListId: DataSalesCartItem ? DataSalesCartItem.getData().dataSalesListId : Strategy.DataSalesListID,
            messageConfigs: (function (templates, templateId) {
              var messageConfigs = [];
              var template = templates.filter(function (e) { return e.TemplateID == templateId; });

              if (template.length && (template = template[0])) {
                for (var a = 0; a < template.BodyFields.length; a++) {
                  if (template.BodyFields[a].KeyName === 'DataSalesExhibitorBody') {
                    messageConfigs.push({
                      keyName: template.BodyFields[a].KeyName,
                      itemValue: UtilityFactory.HTMLEncode(self.email[template.BodyFields[a].KeyName])
                    });
                  } else {
                    messageConfigs.push({
                      keyName: template.BodyFields[a].KeyName,
                      itemValue: self.email[template.BodyFields[a].KeyName]
                    });
                  }
                }
              }

              return messageConfigs;
            })(DataSalesService.data.templates[CurrentEvent.ShowCode], self.email.TemplateId),
            scheduledExecutionDate: new Date(self.email.ScheduledExecutionDate + ' ' + self.email.ScheduledExecutionTime).toUTCString()
          },
          emails: self.testEmails
        }
      }).then(function (status) {
        //
        self.showTestModal = false;
        self.sendingTest = false;

        if (status)
          window.alert('Test Email Sent Successfully!');
        else
          window.alert('Test Email Send Failed!');
      });

    };
  };

  angular.module('exhibitorPortalApp')
    .controller('DataSalesTemplateCtrl', DataSalesTemplateCtrl);
})(angular, window);
