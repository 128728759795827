'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:filterGroup
 * @description
 * # Filter Group
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
	function epFilterGroup() {
	'ngInject'
		return {
			restrict: 'A',
			require: '^epFilter',
      controller: function ($scope) {
        'ngInject'
				var self = this;
				$scope.currentFilter = {};

				(function(activeFilters) {
          self.changeFilter = function (option, optionKey, optionValue, responseKey, responseValue, predicate, defaultValue) {

						if(!optionKey){
							if(predicate && defaultValue !== undefined){
								activeFilters[option] = [{
									responseValue: responseValue,
									predicate: predicate,
									defaultValue: defaultValue
								}];
							}
							else {
                if (!responseValue || responseValue === 0) {
                  delete activeFilters[option];
                } else if (predicate) {
                  activeFilters[option] = [{
                    responseValue: responseValue,
                    predicate: predicate,
                    defaultValue: defaultValue
                  }];
                } else {
                  activeFilters[option] = responseValue;
                }
							}
						}
						else {
							if(!activeFilters[option])
								activeFilters[option] = [];

							var found;

							for(var i = 0; i < activeFilters[option].length; i++){
								if(activeFilters[option][i].optionValue === optionValue){
									if(!responseValue || responseValue === 0){
										activeFilters[option].splice(i, 1);

										if(!activeFilters[option].length)
											delete activeFilters[option];
									}
									else
										activeFilters[option][i].responseValue = responseValue;

									found = true;
									break;
								}
							}

							if(!found && responseValue)
								activeFilters[option].push({
									optionKey: optionKey,
									optionValue: optionValue,
									responseKey: responseKey,
									responseValue: responseValue,
									defaultValue: defaultValue
								});
            }
					};

					self.getResponse = function(option, optionValue) {
            if (activeFilters[option]) {
              if (Object.prototype.toString.apply(activeFilters[option]) === '[object Array]') {
                for (var i = 0; i < activeFilters[option].length; i++) {
                  if (activeFilters[option][i].optionValue === optionValue) {
                    return activeFilters[option][i].responseValue;
                  }
                }
              }
            }
            else {
              return activeFilters[option];
            }
					};
				})($scope.currentFilter);
			},
			link: function(scope, element, attrs, filterCtrl) {
				if(filterCtrl.activeFilters[attrs.epFilterGroup])
					angular.extend(scope.currentFilter, filterCtrl.activeFilters[attrs.epFilterGroup]);

				filterCtrl.activeFilters[attrs.epFilterGroup] = scope.currentFilter;

				scope.activeFilters = scope.activeFilters || {};
				scope.activeFilters[attrs.epFilterGroup] = scope.currentFilter;
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epFilterGroup', epFilterGroup);
})(angular, window);
