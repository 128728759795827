'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.Survey
 * @description
 * # Survey
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var CollateralService = function (ApiService) {
    'ngInject'
        var self = this;

        self.data = {};

        self.getCollateralConfig = ApiService.endpoint.get({
            url: 'api/collateral/getCollateralConfig',
            success: function (data, params) {
                self.data.collateralConfig = self.data.collateralConfig || {};
                self.data.collateralConfig[params.showCode] = data;

                self.data.collaterals = self.data.collaterals || {};
                self.data.collaterals[params.showCode] = data.Collaterals || [];
            }
        });

        self.saveCollateralConfig = ApiService.endpoint.post({
            url: 'api/collateral/saveCollateralConfig',
            success: function (data, params) {
                self.data.collateralConfig = self.data.collateralConfig || {};
                angular.extend(self.data.collateralConfig[params.showCode], data);

                self.data.collaterals = self.data.collaterals || {};
                angular.extend(self.data.collaterals[params.showCode], data.Collaterals);
            }
        });

        self.getLeadCollateral = ApiService.endpoint.get({
            url: 'api/collateral/getLeadCollateral',
            success: function (data, params) {
                self.data.leadCollateral = self.data.leadCollateral || {};
                self.data.leadCollateral[params.showCode] = data;
            }
        });

        self.getCollateral = ApiService.endpoint.get({
            url: 'api/collateral/getCollateral',
            success: function (data, params) {
                self.data.collaterals = self.data.collaterals || {};
                self.data.collaterals[params.showCode] = self.data.collaterals[params.showCode] || [];

                //Check for exisiting item.
                var cIndex = self.data.collaterals[params.showCode].map(function(x) { return x.CollateralID; }).indexOf(data.CollateralID);

                if (cIndex > -1)
                    self.data.collaterals[params.showCode].splice(cIndex, 1)

                self.data.collaterals[params.showCode].push(data);
            }
        });

        self.saveCollateral = ApiService.endpoint.post({
            url: 'api/collateral/saveCollateral',
            success: function (data, params) {
                self.data.collaterals = self.data.collaterals || {};
                self.data.collaterals[params.showCode] = self.data.collaterals[params.showCode] || [];

                //Check for exisiting item.
                var cIndex = self.data.collaterals[params.showCode].map(function(x) { return x.CollateralID; }).indexOf(data.CollateralID);

                if (cIndex > -1)
                    self.data.collaterals[params.showCode].splice(cIndex, 1)

                self.data.collaterals[params.showCode].push(data);
            }
        });

        self.deleteCollateral = ApiService.endpoint.get({
            url: 'api/collateral/deleteCollateral',
            success: function (data, params) {
                self.data.collaterals = self.data.collaterals || {};
                self.data.collaterals[params.showCode] = self.data.collaterals[params.showCode] || [];

                var cIndex = self.data.collaterals[params.showCode].map(function(x) { return x.CollateralID; }).indexOf(params.collateralId);

                if (cIndex > -1)
                    self.data.collaterals[params.showCode].splice(cIndex, 1)
            }
        });



    };

    angular.module('exhibitorPortalApp')
      .service('CollateralService', CollateralService);
})(angular, window);
