'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalLoginApp.controller:AccountCtrl
 * @description
 * # AccountCtrl
 * Controller of the exhibitorPortalLoginApp
 */

(function (angular, window, undefined) {
  var AccountCtrl = function ($state, $rootScope, $window, EnvironmentConfig, LoginService, $stateParams) {
    'ngInject'
    var self = this;

    self.forgotPassword = $stateParams.forgotPassword;

    if (!self.forgotPassword && (!LoginService.data.show.showCode || !LoginService.data.exhibitor.exhibitorId))
      $state.go('login.landing');

    self.header = self.forgotPassword ? "What's your email address?" : "Let's see if you have an account already.";

    self.search = function () {
      if (!self.email || self.email.indexOf('@') === -1 || self.email.indexOf('.') === -1) {
        self.errors = ['PLEASE NOTE: Please enter a valid email address to continue.'];
      } else {
        self.errors = null;
      }

      if (self.forgotPassword)
        self.sendForgotPasswordEmail();
      else
        self.searchAccount();
    };

    self.sendForgotPasswordEmail = function () {
      LoginService.sendChangePasswordEmail({
        params: {
          email: self.email,
          showCode: LoginService.data.show ? LoginService.data.show.showCode || '' : '',
          exhibitorID: LoginService.data.exhibitor ? LoginService.data.exhibitor.exhibitorId || 0 : 0
        }
      }).then(function (data) {
        if (data) {
          if (data.Success) {
            if (data.Data === 'Multiple') {
              self.NeedMoreInfo = true;
              self.EmailSent = false;
              self.found = false;
              self.errors = null;
            } else if (data.Data === 'Sent') {
              self.NeedMoreInfo = false;
              self.EmailSent = true;
              self.found = false;
              self.errors = null;
            } else {
              self.errors = data.Errors;
            }
          } else {
            self.errors = data.Errors;
          }
        }
      });
    };

    self.searchAccount = function () {
      LoginService.getContactByEmailShowCodeExhibitorID({
        email: self.email,
        showCode: LoginService.data.show.showCode,
        exhibitorId: LoginService.data.exhibitor.exhibitorId
      }).then(function (data) {
        if (data) {
          var result = data.data;

          if (result.Success) {
            LoginService.data.user = result.Data;
            self.found = true;
            self.user = result.Data;
          } else {
            self.errors = result.Errors;
          }
        }
      });
    };

    self.back = function () {
      $state.go('login.landing');
    };
  };

  angular.module('exhibitorPortalLoginApp')
    .controller('AccountCtrl', AccountCtrl);
})(angular, window);
