'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:CollateralCtrl
 * @description
 * # CollateralCtrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var CollateralCtrl = function ($state, $q, ActiveUserProfile, CurrentEvent, CollateralService, Collaterals, CollateralConfig, ScrollToBottom) {
    'ngInject'
    var self = this;

    self.collateralConfig = {};

    self.collaterals = Collaterals;
    self.collateralConfig = CollateralConfig;
    self.collateralEmailBody = replaceBreaks(self.collateralConfig.EmailBody);

    if (ScrollToBottom.value) {
      setTimeout(function () { window.scrollTo(0, document.body.scrollHeight) }, 0);
      ScrollToBottom.value = false;
    }

    self.saveCollateralConfig = function (form) {
      if (!form.$valid) {
        form.$setSubmitted();
        return false;
      }

      self.collateralConfig.ContactID = ActiveUserProfile.Id;
      self.collateralConfig.EmailBody = replaceNewLines(self.collateralEmailBody);

      CollateralService.saveCollateralConfig({
        params: { showCode: CurrentEvent.ShowCode },
        data: {
          showCode: CurrentEvent.ShowCode,
          collateralConfig: self.collateralConfig
        }
      }).then(function (data) {
        self.collateralConfig = data;
        self.collateralEmailBody = replaceBreaks(self.collateralConfig.EmailBody);
      });
    };

    self.editCollateral = function (collateral) {
      $state.go('event.collateral.collateral', { id: collateral.CollateralID });
    };

    function replaceNewLines(body) {
      return body ? body.replace(/(?:\r\n|\r|\n)/g, '<br/>') : body;
    };

    function replaceBreaks(body) {
      return body ? body.replace(new RegExp('<br/>', 'g'), '\n') : body;
    };
  };

  angular.module('exhibitorPortalApp')
    .controller('CollateralCtrl', CollateralCtrl);
})(angular, window);
