'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:characterCounter
 * @description
 * # Character Counter
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epCharacterCounter() {
    'ngInject'
		return {
			restrict: 'A',
			scope: {
				limit: '=epCharacterCounter'
			},
      controller: function () {
        'ngInject'
				var self = this;

				self.visible = false;
				self.count = 0;
			},
			link: function(scope, elem, attrs, ctrl) {
				ctrl.limit = scope.limit;
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epCharacterCounter', epCharacterCounter);
})(angular, window);
