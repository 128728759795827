'use strict';


angular.module('ngCart', ['ngCart.directives'])

    .run(['$rootScope', '$location', '$timeout', 'ngCart', 'ngCartItem', 'store', function ($rootScope, $location, $timeout, ngCart, ngCartItem, store) {

        $rootScope.$on('ngCart:change', function () {
            ngCart.$save(window.getEventCode());
        });


        if (angular.isObject(JSON.parse(store.get(window.getEventCode())))) {
            ngCart.$restore(JSON.parse(store.get(window.getEventCode())));
        } else {
            ngCart.init();
        }
    }])

    .service('ngCart', ['$rootScope', '$timeout', '$q', 'ngCartItem', 'store', 'ProductsService', 'Checkout', function ($rootScope, $timeout, $q, ngCartItem, store, ProductsService, Checkout) {

        this.init = function () {
          this.$cart = {
            couponCode: '',
            items: [],
          };
        };

      this.addItem = function (id, name, price, quantity, discount, data, maxTransactionQuantity) {
            //GTM - Custom Trigger
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({ 'event': 'addToCart' });

            var inCart = this.itemInCart(id);

            maxTransactionQuantity = maxTransactionQuantity || 0;

            if (inCart !== false && (!maxTransactionQuantity || (maxTransactionQuantity && (inCart.getQuantity() + Number(quantity)) < maxTransactionQuantity ))) {
                inCart.setQuantity(quantity, true);
                return inCart;
            } else {
                var newItem = new ngCartItem(id, name, price, quantity, discount, data);
                this.$cart.items.push(newItem);
                $rootScope.$broadcast('ngCart:change', {});
                $rootScope.$broadcast('ngCart:itemAdded', newItem);
                return newItem;
            }
        };

        this.setItem = function (id, name, price, quantity, discount, data) {

            var inCart = this.itemInCart(id);

            if (inCart !== false) {
                inCart.setQuantity(quantity, false);
            } else {
                var newItem = new ngCartItem(id, name, price, quantity, discount, data)
                this.$cart.items.push(newItem);
                $rootScope.$broadcast('ngCart:change', {});
            }
            $rootScope.$broadcast('ngCart:itemAdded', newItem);
        };

        this.itemInCart = function (itemId) {

            var a = _.find(this.getCart().items, { _id: itemId }); // This should really call .getId() - not read the private property
            if (a === undefined) return false
            else return a;
        }

        this.setCouponCode = function (code) {
          this.$cart.couponCode = code;
        }
        this.getCouponCode = function () {
          if (!this.getCart().items.length)
            return '';
          return this.getCart().couponCode || '';
        }

        // PDJ 2018.05.16: Holding onto this for now
        // Not needed now that server is doing work in GetCartSummary()
        this.getDiscountTotal = function () {
          var d = $q.defer();

          $q.all(
            this.getCart().items.map(function (item) {
              return item.getDiscountedPrice();
            })
          )
            .then(function (results) {
              d.resolve(results.reduce(function (accum, val) {
                return accum + val;
              }, 0));
            });

          return d.promise;
        }

        this.setCart = function (cart) {
            this.$cart = cart;
        }
        this.getCart = function () {
            return this.$cart;
        }

        this.setItems = function (pred) {
          this.$cart.items = this.$cart.items.filter(function (x, i) { return pred(x, i); });
          $rootScope.$broadcast('ngCart:itemRemoved', {});
          $rootScope.$broadcast('ngCart:change', {});
        }
        this.getItems = function () {
            return this.getCart().items;
        }

        this.getSubTotal = function () {
            var d = $q.defer();

            $q.all(this.getCart().items.map(function(item) {
                return item.getTotal();
            }))
            .then(function(results){
                if(results.length)
                    d.resolve(results.reduce(function(accum, value) {
                        return accum + value;
                    }));
                else
                    d.resolve(0);
            });

            return d.promise;
        }

        this.getCartSummary = function (validateCoupon) {
          var d = $q.defer();
          var self = this;

          if (!self.$cart.couponCode || !validateCoupon) {
            validateCoupon = false;
          }

          if (self.totalItems() > 0) {
            ProductsService.cartSummary({
              data: {
                showCode: window.getEventCode(),
                addressId: Checkout.address ? Checkout.address.Id : null,
                boothId: Checkout.boothId,
                couponCode: self.$cart.couponCode,
                validateCoupon: validateCoupon,
                cart: (function () {
                  return self.getItems().map(function (x) {
                    return {
                      productPriceId: x.getId(),
                      quantity: x.getQuantity(),
                      note: x.getData().note,
                      dataSalesListId: x.getData().dataSalesListId,
                      dataSalesTargetShowCode: x.getData().targetShowCode,
                      promoCode: x.getData().promoCode
                    };
                  });
                })()
              },
              ignoreLoadingBar: true
            }, true)
              .then(function (data) {
                d.resolve(data);
              });
          }
          else
            $timeout(function () {
              d.resolve(0);
            });

          return d.promise;
        }

        this.totalCost = function () {
            var self = this;
            var d = $q.defer();

            $q.all([
              self.getSubTotal(),
              self.getCartSummary()
            ])
              .then(function (results) {
                  d.resolve(results.reduce(function (accum, summary) {
                    return summary.TotalBalance;
                  }));
              });

            return d.promise;
        }

        this.totalItems = function () {
          return this.getCart().items
            .map(function (x) { return x._quantity; })
            .reduce(function (accum, value) {
              return accum + value;
            }, 0);
        }

        this.removeItem = function (index, force) {
            if(force || window.confirm("Are you sure you want to remove this item?")) {
                this.$cart.items.splice(index, 1);
                $rootScope.$broadcast('ngCart:itemRemoved', {});
                $rootScope.$broadcast('ngCart:change', {});
            };
        }

        this.empty = function () {
            this.$cart.couponCode = '';
            this.$cart.items = [];
            localStorage.removeItem(window.getEventCode());
            $rootScope.$broadcast('ngCart:change', {});
        }

        this.$restore = function (storedCart) {
            var _self = this;
            _self.init();
            _self.$cart.couponCode = storedCart.couponCode;

            angular.forEach(storedCart.items, function (item) {
                _self.$cart.items.push(new ngCartItem(item._id, item._name, item._price, item._quantity, item._discount, item._data));
            });
            this.$save(window.getEventCode());
        }

        this.$save = function () {
          return store.set(window.getEventCode(), JSON.stringify(this.getCart()));
        }

        this.load = function(eventCode) {
            var storedCart = JSON.parse(store.get(eventCode));
            var _self = this;
            _self.init();
            _self.$cart.couponCode = storedCart.couponCode;

            angular.forEach(storedCart.items, function (item) {
                _self.$cart.items.push(new ngCartItem(item._id, item._name, item._price, item._quantity, item._discount, item._data));
            });
        };
    }])

    .factory('ngCartItem', ['$rootScope', '$injector', '$q', '$timeout', function ($rootScope, $injector, $q, $timeout) {

        var item = function (id, name, price, quantity, discount, data) {
            this.setId(id);
            this.setName(name);
            this.setPrice(price);
            this.setQuantity(quantity);
            this.setDiscount(discount);
          this.setData(data);
         this.qty = this._quantity;
        };

        item.prototype.setId = function (id) {
            if (id) this._id = id;
            else {
                console.error('An ID must be provided');
            }
        }

        item.prototype.getId = function () {
            return this._id;
        }

        item.prototype.setName = function (name) {
            if (name) this._name = name;
            else {
                console.error('A name must be provided');
            }
        }
        item.prototype.getName = function () {
            return this._name;
        }

        item.prototype.setPrice = function (price) {
            var price = parseFloat(price);
            if (price) {
                if (price <= 0) {
                    console.error('A price must be over 0');
                }
                this._price = (price);
            } else {
                console.error('A price must be provided');
            }
        }

        item.prototype.getPrice = function () {
            return this._price;
        }

        item.prototype.setQuantity = function (quantity, relative) {
            var self = this;

            if(self._quantity != undefined) {
                if(self.setQuantity.debounce)
                    window.clearTimeout(self.setQuantity.debounce);

                self.setQuantity.debounce = window.setTimeout(function() {
                    $rootScope.$broadcast('ngCart:change', {});
                }, 500);
            }

            var quantity = parseInt(quantity);
            if (quantity % 1 === 0) {
                if (relative === true) {
                    this._quantity += quantity;
                } else {
                    this._quantity = quantity;
                }
              if (this._quantity < 1) this._quantity = 1;
              this.qty = this._quantity;

            } else {
                this._quantity = 1;
                console.info('Quantity must be an integer and was defaulted to 1');
            }
        }

        item.prototype.getQuantity = function () {
            return this._quantity;
        }

        item.prototype.setData = function (data) {
            if (data) this._data = data;
        }

        item.prototype.getData = function () {
            if (this._data) return this._data;
            else {
                console.info('This item has no data');
                return {};
            }
        }

        item.prototype.getTotal = function () {
            var d = $q.defer();
            var self = this;
            var total;

            //if this item has a discount then defer to the discount promise
            if(this.getDiscount() && this.getDiscount().id > 0){
                this.getDiscountedPrice().then(function(adjustedAmount){
                    if(self.getData().cachedCount)
                        total = self.getData().cachedCount * self.getPrice() + adjustedAmount;
                    else
                        total = self.getQuantity() * self.getPrice() + adjustedAmount;

                    d.resolve(total > (self.getData().minAmount || 0) ? total : self.getData().minAmount);
                });
            }
            else{
                $timeout(function(){
                    if(self.getData().cachedCount)
                        total = self.getData().cachedCount * self.getPrice();
                    else
                        total = self.getQuantity() * self.getPrice();

                    d.resolve(total > (self.getData().minAmount || 0) ? total : self.getData().minAmount);
                });
            }

            return d.promise;
        }

        item.prototype.setDiscount = function(discount) {
            if(discount)
                this._discount = discount;
        }

        item.prototype.getDiscount = function() {
            if(this._discount)
                return this._discount;
        }

        item.prototype.discountDelegate = $injector.get('DiscountService');

        item.prototype.getDiscountedPrice = function(){
            if(this.discountDelegate)
                return this.discountDelegate(this);
        }

        return item;

    }])

    .service('store', ['$window', function ($window) {

        return {

        	get: function (key) {
        		try {
       				if ($window.localStorage[key]) {
       					return angular.fromJson($window.localStorage[key]);
        			}
        			return false;
				}
        		catch(e)
        		{
        			return false;
        		}
            },

            set: function (key, val) {

                if (val === undefined) {
                    $window.localStorage.removeItem(key);
                } else {
                    $window.localStorage[key] = angular.toJson(val);
                }
                return $window.localStorage[key];
            }
        }
    }])

    .controller('CartController', ['$scope', 'ngCart', function ($scope, ngCart) {
        $scope.ngCart = ngCart;
    }])

    .value('version', '0.0.1-rc.2');
