'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:Interact
 * @description
 * # Sort
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epInteract() {
    'ngInject'
		return {
			restrict: 'A',
			scope: {
                callback: '=epInteract'
			},
      link: function (scope, element) {
        'ngInject'
			    element.on('mousemove', scope.callback);
			    element.on('click', scope.callback);
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epInteract', epInteract);
})(angular, window);
