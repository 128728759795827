'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:Unique
 * @description
 * # Sort
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epUnique() {
    'ngInject'
		return {
			scope: {
				existing: '=epUnique',
				caseInsensitive: '=?'
			},
			require: 'ngModel',
			link: function (scope, element, attrs, ctrl) {
			    ctrl.$validators.unique = function (modelValue, viewValue) {
			        if (ctrl.$isEmpty(modelValue))
			            return true;

			        if(scope.caseInsensitive){
			        	modelValue = modelValue.toLowerCase();
			        	return !scope.existing.filter(function(x) { return x.toLowerCase() === modelValue; }).length;
			        }
			        else
			        	return !scope.existing.filter(function(x) { return x === modelValue; }).length;
			    };
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epUnique', epUnique);
})(angular, window);
