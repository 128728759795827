'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:MoreInfoCtrl
 * @description
 * # MoreInfoCtrl
 * Controller of the exhibitorPortalApp
 */

(function(angular, window, undefined) {
  var MoreInfoCtrl = function ($state, $stateParams, $rootScope, $anchorScroll, Product, Cart) {
    'ngInject'
		var self = this;

		self.cartItem = Cart.getItems().filter(function(x) { return x.getId() == $stateParams.id; })[0];
		self.product = Product;

		$anchorScroll();

		if(self.cartItem.getData().note)
			self.note = angular.copy(self.cartItem.getData().note);
		else
			self.note = '';

		self.save = function() {
			self.cartItem.getData().note = self.note;
			Cart.$save();
			self.back();
		};

		self.assist = function() {
			var confirm = window.confirm('Upon completion of your order, our Customer Service Department will be informed to contact you to help configure your custom printout.');

			if(confirm) {
				self.note = 'NEEDASSISTANCE';
				self.save();
			}
		};

		self.back = function() {
			if($rootScope.previousState)
				$state.go($rootScope.previousState);
			else
				$state.go('event.shop.products');
		};
	};

	angular.module('exhibitorPortalApp')
	  .controller('MoreInfoCtrl', MoreInfoCtrl);
})(angular, window);
