'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalLoginApp.directive:showOrder
 * @description
 * # Show Order Filter
 * Directive of the exhibitorPortalLoginApp
 */
(function (angular, window, undefined) {
  function showOrder() {
    'ngInject'
    return function (shows) {
      var array = [];
      Object.keys(shows).forEach(function (key) {
        shows[key].name = key;
        array.push(shows[key]);
      });

      array.sort(function (s1, s2) {
        var s1Date = new Date(s1.ShowOpenDate);
        var s2Date = new Date(s2.ShowOpenDate);

        if (s1Date.getFullYear() > s2Date.getFullYear())
          return -1;
        else if (s1Date.getFullYear() < s2Date.getFullYear())
          return 1;

        if (s1Date.getMonth() > s2Date.getMonth())
          return 1;
        else if (s1Date.getMonth() < s2Date.getMonth())
          return -1;

        if (s1.TradeShowName > s2.TradeShowName)
          return 1;
        else if (s1.TradeShowName < s2.TradeShowName)
          return -1;
        return 0;
      });

      return array;
    };
  }

  angular.module('exhibitorPortalLoginApp')
    .filter('showOrder', showOrder);
})(angular, window);
