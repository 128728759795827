'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalLoginApp.Login
 * @description
 * # Login
 * Service in the exhibitorPortalLoginApp.
 */

(function (angular, window, undefined) {
  var LoginService = function (ApiService, $window) {
    'ngInject'
    var self = this;

    self.data = {
      show: {},
      exhibitor: {}
    };

    self.data.messages = window.Messages;

    if (window.PwdUser && window.PwdUser.ContactID) {
      $window.sessionStorage['SWAP|PwdUser'] = angular.toJson(window.PwdUser);
    }

    if (window.CompanyLogin) {
      $window.sessionStorage['SWAP|CompanyLogin'] = angular.toJson(window.CompanyLogin);
    }

    self.getPwdUser = function () {
      return angular.fromJson($window.sessionStorage['SWAP|PwdUser']);
    };

    self.clearPwdUser = function () {
      $window.sessionStorage.removeItem('SWAP|PwdUser');
    };

    self.getCompanyLogin = function () {
      return angular.fromJson($window.sessionStorage['SWAP|CompanyLogin']);
    };

    self.clearCompanyLogin = function () {
      $window.sessionStorage.removeItem('SWAP|CompanyLogin');
    };

    self.clearChoices = function () {
      self.data.show = {};
      self.data.exhibitor = {};
    };

    self.getShowsByName = ApiService.endpoint.get({
      url: 'api/loginApi/GetShowsByName',
      success: function (data, params) {
        self.data.shows = data || [];
      }
    });

    self.getExhibitorsByShowCode = ApiService.endpoint.get({
      url: 'api/loginApi/GetExhibitorsByShowCode',
      memoization: false,
      success: function (data, params) {
        self.data.exhibitors = data || [];
      }
    });

    self.getContactByEmailShowCodeExhibitorID = ApiService.endpoint.get({
      url: 'api/loginApi/GetContactByEmailShowCodeExhibitorID',
      memoization: false
    });

    self.userNameLogin = ApiService.endpoint.post({
      url: 'api/loginApi/Login'
    });

    self.activationLogin = ApiService.endpoint.post({
      url: 'api/loginApi/SWAPManualLogin'
    });

    self.sendChangePasswordEmail = ApiService.endpoint.post({
      url: 'api/loginApi/SendChangePasswordEmail'
    });

    self.changePassword = ApiService.endpoint.post({
      url: 'api/loginApi/ChangePassword'
    });

    self.sendGetHelp = ApiService.endpoint.post({
      url: 'api/loginApi/SendGetHelp'
    });
  };

  angular.module('exhibitorPortalLoginApp')
    .service('LoginService', LoginService);
})(angular, window);
