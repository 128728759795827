'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.controller:LeadsCtrl
 * @description
 * # LeadsCtrl
 * Controller of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  var LeadsCtrl = function ($state, $sce, $http, $q, CurrentEvent, Demographics, Surveys, Search, Countries, States,
    LeadsCapturedBy, HasPurchasedDevKit, HasPurchasedEventBit, LeadService, LeadExportService, EnvironmentConfig,
    LeadCollateral, Collateral, isSPOT, LeadsEmailInformation, BusinessCardLeadService) {
    'ngInject'

    var self = this;

    self.demographics = Demographics;
    self.leadSearch = Search.leads;
    self.leadsCapturedBy = LeadsCapturedBy;
    self.countries = Countries;
    self.states = States;
    self.surveys = Surveys;
    self.hasPurchasedDevKit = HasPurchasedDevKit;
    self.hasPurchasedEventBit = HasPurchasedEventBit;
    self.filterOnly = false;

    self.exportUrl = $sce.trustAsResourceUrl(EnvironmentConfig.SiteRoot + 'export/');
    self.filename = 'leads-' + CurrentEvent.ShowCode + '-' + new Date().toJSON().slice(0, 10);
    self.filedata = '';
    self.filetype = '';

    self.leadCollateral = LeadCollateral;
    self.collateral = Collateral || [];
    self.leadsMsg = "Loading leads...";
    self.leadsEmailInformation = LeadsEmailInformation;
    self.showSalesforceModal = false;
    self.showSalesforceUnderConstructionModal = false;

    setTimeout(function () {
      if (!self.leads)
        self.leadsMsg += " WOW, you must have a lot of leads, good job!";
    }, 20000)

    LeadService.getSwapActivations({ showCode: CurrentEvent.ShowCode })
      .then(function () {
        self.swapActivations = LeadService.data.swapActivations[CurrentEvent.ShowCode];
      });

    $q.all([
      LeadService.getLeadDates(CurrentEvent.ShowCode),
      BusinessCardLeadService.getBusinessCardLeadDates(CurrentEvent.ShowCode)
    ]).then(function () {
      self.leadDates = LeadService.data.leadDates[CurrentEvent.ShowCode];

      BusinessCardLeadService.data.leadDates[CurrentEvent.ShowCode].forEach(function (ld) {
        if (ld && !_.any(self.leadDates, function (sld) { return sld == ld; }))
          self.leadDates.push(ld);
      });

      self.leadDates = self.leadDates.sort();

      self.businessCardLeads = BusinessCardLeadService.data.businessCardLeads[CurrentEvent.ShowCode];
      self.leads = LeadService.data.leads[CurrentEvent.ShowCode].map(function (l) {
        return angular.extend({}, l, {
          Collateral: LeadCollateral[l.Id] || [],
          CollateralFormatted: (LeadCollateral[l.Id] || []).map(function (x) {
            return (Collateral.filter(function (c) { return c.CollateralID == x.CollateralID; }).shift() || {}).DisplayName;
          }).join(' | '),
          Spotted: !!(l.LeadOwnerSpotted || l.NonLeadOwnerSpotted) ? 1 : 0,
          MoreCaptureTimes: l.MoreCaptureTimes ? JSON.parse(l.MoreCaptureTimes).map(function (x) {
            var d = new Date(0);
            d.setUTCMilliseconds(x.mc);

            return d.toISOString();
          }) : '',
          Rating: l.Rating ? l.Rating : 0
        });
      });

      for (var i = 0; i < self.businessCardLeads.length; i++) {
        self.leads.push(self.businessCardLeads[i]);
      }

      LeadExportService.getHeaders(CurrentEvent.ShowCode, self.leads, HasPurchasedEventBit)
        .then(function () {
          self.exportHeaders = LeadExportService.data[CurrentEvent.ShowCode].headers;
        });

      self.showSPOT = (function (hasData, isSPOT) {
        return hasData || (!hasData && isSPOT);
      })(!!self.leads.filter(function (x) { return x.LeadOwnerSpotted || x.NonLeadOwnerSpotted; }).length, isSPOT);
    });

    self.pendingPredicate = function (contactType, checked) {
      if (checked)
        return true;
      else
        return contactType != 'UNV';
    };

    self.collateralPredicate = function (collateral, checked) {
      if (checked)
        return true;
    };

    self.leadDatePredicate = function (leadDate, filterDate) {
      return leadDate === filterDate;
    };

    self.getDate = function (dateString) {
      return new Date(dateString);
    };

    self.changeCheckbox = function (e) {
      e.target.value = e.target.checked ? 'true' : '';
    };

    self.changeCountry = function (e, oldValue, newValue) {
      if (newValue === 'UNITED STATES' || newValue === 'US') {
        e.target.value = '';

        if (oldValue)
          getStates('');
      }
      else
        getStates(newValue);
    };

    self.postLeadsForExport = function (filetype) {
      if (typeof dataLayer !== 'undefined') {
        if (filetype.toLowerCase() === 'csv') {
          dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Leads', 'eventAction': 'Download CSV', 'eventLabel': CurrentEvent.ShowCode });
        } else {
          dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Leads', 'eventAction': 'Download Excel', 'eventLabel': CurrentEvent.ShowCode });
        }
      }

      var data = LeadExportService.getExportCSV(CurrentEvent.ShowCode, self.exportHeaders, self.leadSearch.data, filetype);
      self.filedata = btoa(RawDeflate.deflate(encodeURIComponent(data))).replace(/\//g, '-').replace(/\+/g, '.').replace(/=/g, '_');
      self.filetype = filetype;

      setTimeout(function () { angular.element('#ExportForm').submit(); }, 0);
    };

    // Track a button click event
    self.handleSalesforceIntegrationClick = function () {
      // Track the button click event with Google Tag Manager
      dataLayer.push({
        'event': 'salesforce_integration_click',
        'eventCategory': 'ExhibitorPortal',
        'eventAction': 'Salesforce Integration Clicked',
        'eventLabel': 'Salesforce Integration Button'
      });
    };

    self.toggleFilterOnly = function (e) {
      if (!e || (e && !angular.element(e.target).parents('[ep-filter-clear]').length))
        self.filterOnly = !self.filterOnly;
      else if (e && angular.element(e.target).parents('[ep-filter-clear]').length)
        setTimeout(function () { angular.element(e.target).parents('[ep-filter-clear]').click(); }, 0);
    };

    self.toggleButtonBar = function () {
      self.showButtonBar = !self.showButtonBar;
    };

    self.detail = function (e, lead) {
      if (!angular.element(e.target).is('a')) {
        if (lead.LeadType == 'BUSINESS CARD')
          $state.go('event.businessCardDetail', { key: lead.ImageKey });
        else
          $state.go('event.leadDetail', { id: lead.Id });
      }
    };

    self.clickSort = function (option) {
      var opt = angular.copy(option);
      if (opt && opt.indexOf('.') > -1 && opt.split('.').length > 1)
        opt = option.split('.')[1];

      if (typeof dataLayer != 'undefined') {
        dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Click', 'eventAction': 'Sorting', 'eventLabel': 'By ' + opt });
      }
    };

    function getStates(countryCode) {
      LeadService.getStates({ showCode: CurrentEvent.ShowCode, countryCode: countryCode })
        .then(function () {
          self.states = LeadService.data.states[CurrentEvent.ShowCode][countryCode];

          if (Search.leads.filters.ContactInfo['Contact.Address.StateCode'])
            delete Search.leads.filters.ContactInfo['Contact.Address.StateCode'];
        });
    }
  };

  angular.module('exhibitorPortalApp')
    .controller('LeadsCtrl', LeadsCtrl);
})(angular, window);
