'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:SurveysCtrl
 * @description
 * # Surveys Ctrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var SurveysCtrl = function ($scope, $q, $state, $filter, CurrentEvent, Surveys, Search, SurveyService) {
    'ngInject'
    var self = this;

    self.search = Search.surveys;
    self.search.edit = false;
    self.search.editDefault = false;
    self.currentEvent = CurrentEvent;

    var ordered = $filter('orderBy')(Surveys, 'DisplayOrder');

    for (var i = 0; i < ordered.length; i++)
      ordered[i].DisplayOrder = i;

    self.baseSurveys = angular.copy(ordered);
    self.paginated = self.surveys = angular.copy(ordered);

    $scope.$on('$stateChangeStart', function (event) {
      if (!angular.equals(self.surveys, self.baseSurveys))
        if (!window.confirm('You have unsaved changes that will be lost, are you sure you want to proceed?'))
          event.preventDefault();
    });

    self.save = function () {
      return $q.all((function (promises) {
        //only save surveys that have changed
        for (var i = 0; i < self.surveys.length; i++)
          for (var a = 0; a < self.baseSurveys.length; a++)
            if (self.surveys[i].Id === self.baseSurveys[a].Id && !angular.equals(self.surveys[i], self.baseSurveys[a]))
              promises.push(SurveyService
                .saveSurvey({
                  params: { showCode: CurrentEvent.ShowCode },
                  data: {
                    showCode: CurrentEvent.ShowCode,
                    survey: self.surveys[i]
                  }
                }));

        return promises;
      })([]))
        .then(function () {
          self.baseSurveys = angular.copy(SurveyService.data.surveys[CurrentEvent.ShowCode]);
          self.paginated = self.surveys = angular.copy(self.baseSurveys);
        });
    };

    self.upClick = function (index) {
      if (index === 0)
        self.paginated[index].DisplayOrder = self.paginated[self.paginated.length - 1].DisplayOrder + 1;
      else {
        var dest, destDisplayOrder;

        dest = self.paginated[index - 1];
        destDisplayOrder = dest.DisplayOrder;

        dest.DisplayOrder = self.paginated[index].DisplayOrder;
        self.paginated[index].DisplayOrder = destDisplayOrder;
      }
    };

    self.downClick = function (index) {
      if (index === self.paginated.length - 1) {
        self.paginated[index].DisplayOrder = 0;

        for (var i = 0; i < self.paginated.length; i++)
          if (self.paginated[i] !== self.paginated[index])
            self.paginated[i].DisplayOrder++;
      }
      else {
        var dest, destDisplayOrder;

        dest = self.paginated[index + 1];
        destDisplayOrder = dest.DisplayOrder;

        dest.DisplayOrder = self.paginated[index].DisplayOrder;
        self.paginated[index].DisplayOrder = destDisplayOrder;
      }
    };

    self.surveyClick = function (survey) {
      if (!self.search.edit && !self.search.editDefault) {
        //If the survey does not have a ContactId then it must be an event level or enterprise level survey.
        //Before we edit the survey, we must make a copy of it (this is part of the business logic of the getSurvey endpoint)
        if (!survey.ContactId)
          SurveyService
            .getSurvey({ showCode: CurrentEvent.ShowCode, surveyId: survey.Id })
            .then(function (data) {
              SurveyService.getSurveys({ showCode: CurrentEvent.ShowCode }, true)
                .then(function () {
                  $state.go('event.surveys.survey', { id: data.data.Id });
                });
            });
        else
          $state.go('event.surveys.survey', { id: survey.Id });
      }
    };

    self.edit = function (e) {
      self.search.edit = !self.search.edit
      self.search.editDefault = false;
      e.target.value = self.search.edit;
      angular.element(e.target).triggerHandler('change', true);
    };

    self.activePredicate = function (active, edit) {
      if (edit === 'true')
        return true;
      else
        return active;
    };

    self.filterQuestions = function (surveyQuestions, type) {
      return surveyQuestions.filter(function (s) { return s.SurveyQuestionTypeCode === type; });
    };

    self.editDefault = function (e) {
      self.search.edit = false;
      self.search.editDefault = !self.search.editDefault;
      e.target.value = self.search.editDefault;
      angular.element(e.target).triggerHandler('change', true);
    };

    self.clickDefault = function (survey, isDefault) {
      survey.IsDefault = isDefault;
      if (survey.IsDefault) {
        for (var i = 0; i < self.surveys.length; i++) {
          if (self.surveys[i].Id !== survey.Id)
            self.surveys[i].IsDefault = false;
        }
      }
    };

    self.copySurveys = function () {
      $state.go('event.surveys.copy');
    };
  };

  angular.module('exhibitorPortalApp')
    .controller('SurveysCtrl', SurveysCtrl);
})(angular, window);
