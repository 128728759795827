'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:characterCounterLabel
 * @description
 * # Character Counter Label
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epCharacterCounterLabel() {
    'ngInject'
		return {
			restrict: 'AE',
			scope: {},
			require: '^epCharacterCounter',
			template: '<strong ng-show="visible()">(<span ng-class="{ alertColor: count() > limit() }">{{ count() }}</span> / {{ limit() }})',
			link: function (scope, elem, attrs, characterCounterCtrl) {
				scope.count = function() {
					return characterCounterCtrl.count;
				};

				scope.limit = function() {
					return characterCounterCtrl.limit;
				};

				scope.visible = function() {
					return characterCounterCtrl.visible;
				};
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epCharacterCounterLabel', epCharacterCounterLabel);
})(angular, window);
