'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:ShopCtrl
 * @description
 * # ShopCtrl
 * Controller of the exhibitorPortalApp
 */

(function(angular, window, undefined) {
  var ShopCtrl = function ($state, $q, ActiveUserProfile, CurrentEvent, AdditionalInfo, ngCart, Checkout, AllAvailableProducts, OrderService, DataSalesService, UtilityFactory) {
    'ngInject'
		var self = this;

		self.cart = ngCart;
		self.currentEvent = CurrentEvent;
		self.additionalInfo = AdditionalInfo;
    self.checkout = Checkout;
    self.showTermsOfUseModal = false;

		if(self.currentEvent.HasActiveSalesTier){
			var deadline = AdditionalInfo.PreRegEndDate,
				discountDeadline = AdditionalInfo.DiscountDeadline,
				postShowDataSalesBegin = AdditionalInfo.FullSetPostBeginDate,
				postShowDataSalesEnd = AdditionalInfo.FullSetPostEndDate;

			self.deadline = deadline || 'N/A';
			self.discountDeadline = discountDeadline || 'N/A';
			self.postShowDataSalesDates = (postShowDataSalesBegin && postShowDataSalesEnd) ?
				(postShowDataSalesBegin + ' - ' + postShowDataSalesEnd) : 'N/A';
		}

		//clean up cart
		var invalidItems = ngCart.getCart().items.reduce(function(previousValue, currentValue, currentIndex) {
			if(AllAvailableProducts.map(function(x) { return Number(x.ProductPriceID); }).indexOf(Number(currentValue._id)) > -1)
				return previousValue;
			else {
				previousValue.push(currentIndex);
				return previousValue;
			}
		}, []);

		if(invalidItems.length)
			ngCart.setItems(function(x,i) {
				return invalidItems.indexOf(i) === -1;
			});

		self.processOrder = function(){
      var d = $q.defer();

      if (!self.agreeToTerms) {
        window.alert('You must agree to the Terms and Conditions before proceeding.');
        return $q.when(undefined);
      }
      if (Checkout.doesOrderHaveEventBit && !self.ebitAcknowledgement) {
        window.alert('You must acknowledge eventBit requirements.')
        return $q.when(undefined);
      }
      if (self.processingOrder != true) {
        self.processingOrder = true;
        $q.all((function() {
          var promises = [];
          var cartItems = ngCart.getItems();

          if(cartItems.filter(function(x){ return x.getData().email || x.getData().mailHouse; }).length){
            for(var i = 0; i < cartItems.length; i++){
              var cartItemData = cartItems[i].getData();

              if (cartItemData.email) {
                promises.push(
                  DataSalesService.saveStrategy({
                    data: {
                      showCode: CurrentEvent.ShowCode,
                      strategy: {
                        activityFocus: 'DSE',
                        strategyType: 'BLAST',
                        areTermsAgreed: cartItemData.agreeToTerms,
                        //TODO: ContactID???
                        dataSalesListId: cartItemData.dataSalesListId,
                        subjectLine: cartItemData.email.SubjectLine,
                        templateId: cartItemData.email.TemplateId,
                        messageConfigs: (function (templates, templateId) {
                          var messageConfigs = [];
                          var template = templates.filter(function (e) { return e.TemplateID == templateId; });

                          if (template.length && (template = template[0]))
                            for (var a = 0; a < template.BodyFields.length; a++) {
                              if (template.BodyFields[a].KeyName === 'DataSalesExhibitorBody') {
                                messageConfigs.push({
                                  keyName: template.BodyFields[a].KeyName,
                                  itemValue: UtilityFactory.HTMLEncode(cartItemData.email[template.BodyFields[a].KeyName])
                                });
                              } else {
                                messageConfigs.push({
                                  keyName: template.BodyFields[a].KeyName,
                                  itemValue: cartItemData.email[template.BodyFields[a].KeyName]
                                });
                              }
                            }

                          return messageConfigs;
                        })(DataSalesService.data.templates[CurrentEvent.ShowCode], cartItemData.email.TemplateId),
                        scheduledExecutionDate: new Date(cartItemData.email.ScheduledExecutionDate + ' ' + cartItemData.email.ScheduledExecutionTime),
                        exhibitorId: ActiveUserProfile.ExhibitorId,
                        showCode: CurrentEvent.ShowCode,
                        strategyStatus: 'setup'
                      }
                    }
                  })
                );
              }
              else if (cartItemData.mailHouse) {
                promises.push(
                  DataSalesService.saveStrategy({
                    data: {
                      showCode: CurrentEvent.ShowCode,
                      strategy: {
                        activityFocus: 'DSE',
                        strategyType: 'MAILHOUSE',
                        areTermsAgreed: cartItemData.agreeToTerms,
                        dataSalesListId: cartItemData.dataSalesListId,
                        messageConfigs: (function rescurse(messageConfigs, data) {
                          for (var key in data) {
                            if (typeof data[key] === 'string')
                              messageConfigs.push({
                                keyName: key,
                                itemValue: data[key]
                              });
                            else if (Array.isArray(data[key]))
                              messageConfigs.push({
                                keyName: key,
                                itemValue: data[key].join(',')
                              });
                          }

                          return messageConfigs;
                        })([], cartItemData.mailHouse),
                        exhibitorId: ActiveUserProfile.ExhibitorId,
                        showCode: CurrentEvent.ShowCode,
                        strategyStatus: 'setup'
                      }
                    }
                  })
                );
              }
              else {
                promises.push($q.when(undefined));
              }
            }
          }

          return promises;
        })()).then(function(strategies) {
          OrderService.processOrder({
            data: {
              showCode: CurrentEvent.ShowCode,
              boothId: Checkout.boothId,
              addressId: Checkout.address ? Checkout.address.Id : null,
              couponCode: ngCart.getCouponCode(),
              cart: (function() {
                return ngCart.getItems().map(function(x,i) {
                  var strategy = strategies[i];
                  return {
                    productPriceId: x.getId(),
                    quantity: x.getQuantity(),
                    note: x.getData().note,
                    dataSalesListId: x.getData().dataSalesListId,
                    dataSalesTargetShowCode: x.getData().targetShowCode,
                    campaignStrategyId: strategy ? strategy.PrimaryID : null,
                    promoCode: x.getData().promoCode
                  };
                });
              })()
            }
          }).then(function(data) {
            ngCart.totalCost().then(function(cartTotal) {
              if(!cartTotal && !isNaN(data)) {
                $state.go('event.shop.confirmation', { salesOrderId: data });
              }
              else if(data){
                window.location = data;
              }
              else {
              self.processingOrder = false;
              window.alert('There was an error processing your order.');
              }
            });
          }).finally(function() {
            d.resolve();
          });
        }, function() {
          d.resolve();
        });
      }
      return d.promise;
		};
	};

	angular.module('exhibitorPortalApp')
	  .controller('ShopCtrl', ShopCtrl);
})(angular, window);
