'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalLoginApp.directive:loginForm
 * @description
 * # Login Form
 * Directive of the exhibitorPortalLoginApp
 */
(function (angular, window, undefined) {
  function loginForm($window, LoginService, EnvironmentConfig, $timeout, $rootScope) {
    'ngInject'
    return {
      restrict: 'E',
      scope: {
        userName: '=?',
        forgotPassword: '=?',
        allowChangePassword: '=?',
        changePasswordFunc: '=?',
        defaultToSwap: '=?',
        hideActivationLogin: '=?',
        hideUsername: '=?'
      },
      link: function (scope, element, attrs) {
        var self = scope.formLogin;

        self.nextDisabled = true;

        if (self.hideUsername) {
          self.showUsernameLogin = true;
        }
        else {
          self.showUsernameActivationLogin = true;
        }

        self.errors = LoginService.data.messages;

        if (angular.isFunction(self.forgotPassword) && !self.allowChangePassword)
          self.showForgotPassword = true;


        self.userNameActivationValidation = function () {
          self.errors = null;
          LoginService.data.messages = null;

          if (!self.usernameOrActivationCode) {
            self.errors = ['PLEASE NOTE: You must provide your username or activation code.'];
            return;
          }

          self.isActivationCode = /^(\d{4})+(?:(-|\s|)+(\d{4})){3}$/.test(self.usernameOrActivationCode);
          if (self.isActivationCode) {
            self.switchForms('Activation')
            self.activationCode = self.usernameOrActivationCode;
          }
          else {
            self.switchForms('Username')
            self.userName = self.usernameOrActivationCode;
          }
        };

        self.userNameLogin = function () {
          self.errors = null;
          LoginService.data.messages = null;

          if (!self.userName || !self.password) {
            self.errors = ['PLEASE NOTE: You must provide your username and password.'];
            return;
          }

          LoginService.userNameLogin({
            params: {
              username: self.userName,
              password: self.password,
              showCode: LoginService.data.show ? LoginService.data.show.showCode || '' : '',
              exhibitorID: LoginService.data.exhibitor ? LoginService.data.exhibitor.exhibitorId || 0 : 0
            }
          }).then(function (data) {
            if (data) {
              if (data.Success && data.RedirectUrl) {
                var loc = (EnvironmentConfig.SiteRoot[EnvironmentConfig.SiteRoot.length - 1] === '/' && data.RedirectUrl[0] === '/')
                  ? EnvironmentConfig.SiteRoot + data.RedirectUrl.substring(1)
                  : EnvironmentConfig.SiteRoot + data.RedirectUrl;

                window.location = loc;
              } else if (data.Errors && data.Errors.length > 0) {
                self.errors = data.Errors;
              }
            }
          });
        };

        self.activationLogin = function () {
          LoginService.data.messages = null;
          if (!self.activationCode || !self.accessKey) {
            self.errors = ['PLEASE NOTE: You must provide an activation code and access key or badge ID'];
            return;
          }

          LoginService.activationLogin({
            params: {
              activationCode: self.activationCode,
              badgeKey: self.accessKey
            }
          }).then(function (data) {
            if (data) {
              if (data.Success && data.RedirectUrl) {
                var loc = (EnvironmentConfig.SiteRoot[EnvironmentConfig.SiteRoot.length - 1] === '/' && data.RedirectUrl[0] === '/')
                  ? EnvironmentConfig.SiteRoot + data.RedirectUrl.substring(1)
                  : EnvironmentConfig.SiteRoot + data.RedirectUrl;

                window.location = loc;
              } else if (data.Errors && data.Errors.length > 0) {
                self.errors = data.Errors;
              }
            }
          });
        };

        self.switchForms = function (form, keepErrors) {
          switch (form) {
            case 'UsernameActivation':
              self.showUsernameActivationLogin = true;
              self.showUsernameLogin = false;
              self.showActivationLogin = false;

              if (!keepErrors)
                self.errors = null;

              break;
            case 'Activation':
              self.showActivationLogin = true;
              self.showUsernameLogin = false;
              self.showUsernameActivationLogin = false;

              self.userName = null;
              self.password = null;
              if (!keepErrors)
                self.errors = null;

              self.focusUsername = false;
              self.focusPassword = false;
              self.focusActivationCode = true;
              break;
            case 'Username':
              self.showActivationLogin = false;
              self.showUsernameLogin = true;
              self.showUsernameActivationLogin = false;

              self.activationCode = null;
              self.accessKey = null;
              if (!keepErrors)
                self.errors = null;

              self.focusActivationCode = false;

              self.focusPassword = !(self.focusUsername = !self.hideUsername);
              break;
            default:
              break;
          }
        };

        self.defaultToSwap = 'UsernameActivation';
      },
      templateUrl: (window.location.hostname === 'localhost' ? '/app/' : '') + 'views/Login/loginForm.html',
      controllerAs: 'formLogin',
      controller: function () {

      },
      bindToController: true
    };
  }

  angular.module('exhibitorPortalLoginApp')
    .directive('loginForm', loginForm);
})(angular, window);
