'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.User
 * @description
 * # User
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var UserService = function (ApiService) {
    'ngInject'
        var self = this;

        self.data = {};

        self.changePassword = ApiService.endpoint.post({
            url: 'api/user/changePassword'
        });

        self.save = ApiService.endpoint.post({
            url: 'api/user/save'
        });

        self.reAuth = ApiService.endpoint.post({
            url: 'api/user/reAuth'
        });

        self.getEmail = ApiService.endpoint.get({
            url: 'api/contact/email',
            success: function (data) {                
                self.data.email = data;
            }
        });

        self.getSWAPExhibitor = ApiService.endpoint.get({
            url: 'api/contact/getswapexhibitor',
            success: function (data, params) {
                self.data[params.showCode] = self.data[params.showCode] || {};
                self.data[params.showCode].SWAPExhibitor = data;
            }
        })
    };

    angular.module('exhibitorPortalApp')
      .service('UserService', UserService);
})(angular, window);
