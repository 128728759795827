'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:Sticky
 * @description
 * # Sticky
 * "Stick" an element to the top of the viewport after the browser scrolls past it.
 */
(function (angular, window, undefined) {
  function epSticky($window) {
    'ngInject'
		return {
			restrict: 'AE',
			template: '<div class="row"><div class="columns" ng-transclude></div></div>',
			transclude: true,
			link: function (scope, element) {
				var elementPosition = element[0].getBoundingClientRect().top;
				var placeHolder = angular.element('<div class="invisible"></div>').hide();
				var body = angular.element('body');
				scope.window = window;

				element.after(placeHolder);

				//grab the height of the element after angular has a chance to render
				setTimeout(function() {
					placeHolder.height(element.height());
				}, 0);

				angular.element($window).on('scroll', function() {
					if(this.pageYOffset >= elementPosition){
						placeHolder.show();
						body.prepend(element);
						element.addClass('sticky');
					}
					else if (element.is('.sticky') && this.pageYOffset < elementPosition){
						placeHolder.hide();
						placeHolder.before(element);
						element.removeClass('sticky');
					}
				});

				scope.$on('$destroy', function() {
					placeHolder.hide();
					placeHolder.before(element);
					element.removeClass('sticky');
					angular.element($window).off('scroll');
				});
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epSticky', epSticky);
})(angular, window);
