'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:SurveyCtrl
 * @description
 * # Survey Ctrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var SurveyCtrl = function ($scope, $filter, $state, $q, $timeout, CurrentEvent, Surveys, Survey, Search, SurveyService) {
    'ngInject'
    var self = this;

    Survey.SurveyQuestions = $filter('orderBy')(Survey.SurveyQuestions, 'DisplayOrder');

    for (var i = 0; i < Survey.SurveyQuestions.length; i++)
      Survey.SurveyQuestions[i].DisplayOrder = i;

    self.baseSurvey = Survey;
    self.survey = angular.copy(Survey);
    self.search = Search.survey;
    self.search.edit = false;

    self.isUsed = _.any(self.survey.SurveyQuestions, function (sq) { return sq.IsUsed; });

    self.existingSurveyNames = Surveys.filter(function (s) { return s.Id !== Survey.Id; })
      .map(function (s) { return s.Name; });

    $scope.$on('$stateChangeStart', function (event) {
      var pageChangeOk = true;
      if (!angular.equals(self.survey, self.baseSurvey)) {
        if (!window.confirm('You have unsaved changes that will be lost, are you sure you want to proceed?')) {
          pageChangeOk = false;
          event.preventDefault();
        }
      }

      //PDJ 2016.10.12: Remove this survey, leaving it is causing a mess in some places do not list it when name is blank then saving goes wonky
      if (pageChangeOk == true && self.survey.Id == 0) {
        for (var i = 0; i < SurveyService.data.surveys[CurrentEvent.ShowCode].length; i++) {
          if (SurveyService.data.surveys[CurrentEvent.ShowCode][i].Id == self.survey.Id) {
            SurveyService.data.surveys[CurrentEvent.ShowCode].splice(i, 1);
            break;
          }
        }
      }
    });

    self.markQuestionInactive = function (question) {
      if (question) {
        self.deleteModalLinkedQuestions = self.survey.SurveyQuestions.filter(function (sq) {
          return _.any(sq.SurveyAnswers, function (sa) {
            return sa.BranchAction == 'OVERRIDE' && sa.NextQuestionId == question.Id
          });
        });

        if (self.deleteModalLinkedQuestions && self.deleteModalLinkedQuestions.length) {
          self.showSurveyFixerUpper = true;
        } else {
          question.IsActive = false;
        }
      }
    };

    self.save = function (redirect) {
      //check to see if we actually need to save
      if (!angular.equals(self.survey, self.baseSurvey)) {
        return SurveyService
          .saveSurvey({
            params: { showCode: CurrentEvent.ShowCode },
            data: {
              showCode: CurrentEvent.ShowCode,
              survey: self.survey
            }
          })
          .then(function (survey) {
            for (var i = 0; i < SurveyService.data.surveys[CurrentEvent.ShowCode].length; i++)
              if (SurveyService.data.surveys[CurrentEvent.ShowCode][i].Id == survey.Id) {
                self.baseSurvey = SurveyService.data.surveys[CurrentEvent.ShowCode][i];
                self.survey = angular.copy(self.baseSurvey);
                break;
              }

            if (redirect !== false && self.survey.SurveyQuestions.length)
              $state.go('event.surveys.surveys');
          });
      }
      //nope, but code still expects this to return a promise so return one and resolve it immediately
      else {
        var d = $q.defer();

        $timeout(function () {
          d.resolve();

          if (redirect !== false && self.survey.SurveyQuestions.length)
            $state.go('event.surveys.surveys');
        });

        return d.promise;
      }
    };

    self.upClick = function (index) {
      if (index === 0)
        self.paginated[index].DisplayOrder = self.paginated[self.paginated.length - 1].DisplayOrder + 1;
      else {
        var dest, destDisplayOrder;

        dest = self.paginated[index - 1];
        destDisplayOrder = dest.DisplayOrder;

        dest.DisplayOrder = self.paginated[index].DisplayOrder;
        self.paginated[index].DisplayOrder = destDisplayOrder;
      }
    };

    self.downClick = function (index) {
      if (index === self.paginated.length - 1) {
        self.paginated[index].DisplayOrder = 0;

        for (var i = 0; i < self.paginated.length; i++)
          if (self.paginated[i] != self.paginated[index])
            self.paginated[i].DisplayOrder++;
      }
      else {
        var dest, destDisplayOrder;

        dest = self.paginated[index + 1];
        destDisplayOrder = dest.DisplayOrder;

        dest.DisplayOrder = self.paginated[index].DisplayOrder;
        self.paginated[index].DisplayOrder = destDisplayOrder;
      }
    };

    self.questionClick = function (surveyId, questionId) {
      if (!self.search.edit)
        $state.go('event.surveys.surveyQuestion', { id: surveyId, surveyQuestionId: questionId });

      return false;
    };

    self.edit = function (e) {
      self.search.edit = !self.search.edit
      e.target.value = self.search.edit;
      angular.element(e.target).triggerHandler('change', true);
    };

    self.activePredicate = function (active, edit) {
      if (edit === 'true')
        return true;
      else
        return active;
    };

    self.preview = function () {
      return self.save(false).then(function () {
        SurveyService.getPreviewURL({
          showCode: CurrentEvent.ShowCode,
          surveyId: self.survey.Id,
          questionId: 0
        })
          .then(function () {
            window.open(SurveyService.data.previewURL[CurrentEvent.ShowCode][self.survey.Id][0]);
          });
      });
    };

    self.email = function () {
      return self.save(false).then(function () {
        $state.go('event.surveys.emailSurvey', { id: self.survey.Id });
      });
    };

  };

  angular.module('exhibitorPortalApp')
    .controller('SurveyCtrl', SurveyCtrl);
})(angular, window);
