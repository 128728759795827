'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.Products
 * @description
 * # Products
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var ProductsService = function (ApiService) {
    'ngInject'
        var self = this;

        self.data = {};

        self.getProducts = ApiService.endpoint.get({
            url: 'api/products/getProducts',
            success: function(data, params) {
                self.data.products = self.data.products || {};
                self.data.products[params.showCode] = data;
            }
        });

        self.getPurchasedProducts = ApiService.endpoint.get({
          url: 'api/products/getPurchasedProducts',
          success: function (data, params) {
            self.data.purchasedProducts = self.data.purchasedProducts || {};
            self.data.purchasedProducts[params.showCode] = self.data.purchasedProducts[params.showCode] || {};
            self.data.purchasedProducts[params.showCode][params.exhibitorId] = data;
          }
        });

        self.getShowExhibitorBoothRecommendation = ApiService.endpoint.get({
          url: 'api/products/getShowExhibitorBoothRecommendation',
          success: function (data, params) {
            self.data.showExhibitorBoothRecommendation = self.data.showExhibitorBoothRecommendation || {};
            self.data.showExhibitorBoothRecommendation[params.exhibitorId] = self.data.showExhibitorBoothRecommendation[params.exhibitorId] || {};
            self.data.showExhibitorBoothRecommendation[params.exhibitorId][params.showExhibitorBoothID] = self.data.showExhibitorBoothRecommendation[params.exhibitorId][params.showExhibitorBoothID] || {};
            self.data.showExhibitorBoothRecommendation[params.exhibitorId][params.showExhibitorBoothID][params.boothWorkers] = self.data.showExhibitorBoothRecommendation[params.exhibitorId][params.showExhibitorBoothID][params.boothWorkers] || {};
            self.data.showExhibitorBoothRecommendation[params.exhibitorId][params.showExhibitorBoothID][params.boothWorkers][params.surveyBoothsize] = self.data.showExhibitorBoothRecommendation[params.exhibitorId][params.showExhibitorBoothID][params.boothWorkers][params.surveyBoothsize] || {};
            self.data.showExhibitorBoothRecommendation[params.exhibitorId][params.showExhibitorBoothID][params.boothWorkers][params.surveyBoothsize][params.showCode] = data;
          }
        });

        self.getProductImages = ApiService.endpoint.get({
          url: 'api/products/getProductImages',
          success: function (data, params) {
            self.data.productImages = self.data.productImages || {};
            self.data.productImages[params.productID] = data;
          }
        });

        self.getDataSalesProducts = ApiService.endpoint.get({
            url: 'api/products/getDataSalesProducts',
            success: function(data, params) {
                self.data.dataSalesProducts = self.data.dataSalesProducts || {};
                self.data.dataSalesProducts[params.showCode] = data;
            }
        });

        self.getUpsell = ApiService.endpoint.get({
            url: 'api/products/getUpsell',
            success: function(data, params) {
                self.data.upsell = self.data.upsell || {};
                self.data.upsell[params.showCode] = self.data.upsell[params.showCode] || {};
                self.data.upsell[params.showCode][params.productIds] = data;
            }
        });

        self.cartSummary = ApiService.endpoint.post({
          url: 'api/products/cartSummary'
        });

        self.validateCart = ApiService.endpoint.post({
            url: 'api/products/validateCart'
        });
    };

    angular.module('exhibitorPortalApp')
      .service('ProductsService', ProductsService);
})(angular, window);
