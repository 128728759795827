'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalLoginApp.directive:lookupShowCompany
 * @description
 * # Lookup Show Company
 * Directive of the exhibitorPortalLoginApp
 */
(function (angular, window, undefined) {
  function lookupShowCompany($window, $rootScope, LoginService, SHOW) {
    'ngInject';
    /** @type {angular.IDirective} */
    const directive = {
      restrict: 'E',
      scope: {
        callback: '='
      },
      link: function (scope, element, attrs) {
        var self = scope.scLookup;

        self.selectShow = true;
        self.validShow = false;

        self.focusShow = true;

        self.errors = LoginService.data.messages;

        LoginService.getShowsByName({
          showName: self.showName || ''
        }).then(function () {
          if (LoginService.data.shows) {
            self.showList = LoginService.data.shows;

            var dupeShows = _.filter(self.showList, function (s) {
              return _.any(self.showList, function (s1) { return s1.ShowCode !== s.ShowCode && s1.TradeShowName.trim() === s.TradeShowName.trim(); });
            });

            self.showList = _.map(self.showList, function (s) {
              var newShow = angular.copy(s);

              if (_.filter(dupeShows, function (d) { return d.ShowCode === newShow.ShowCode; })) {
                try {
                  var openDate = new Date(newShow.ShowOpenDate);
                  var month = openDate.getMonth() + 1;
                  if (openDate.getFullYear() === 1)
                    newShow.TradeShowName = s.TradeShowName;
                  else
                    newShow.TradeShowName = s.TradeShowName + ' (' + month + '/' + openDate.getFullYear() + ')';
                } catch (e) {
                  newShow.TradeShowName = s.TradeShowName;
                }
              }

              return newShow;
            });

            if (SHOW && SHOW.ShowCode && _.any(self.showList, function (s) { return s.ShowCode === SHOW.ShowCode; })) {
              self.showName = SHOW.TradeShowName || null;
              self.showCode = SHOW.ShowCode;
            }
          }
          else
            self.showList = [];
        });

        scope.$watch('scLookup.showName', function (newval, oldval) {
          var valid = _.uniq(_.filter(self.showList, function (s) {
            return s.TradeShowName === newval;
          }), function (show) {
            return show.ShowCode;
          });
          self.validShow = valid && valid.length === 1;

          if (self.validShow) {
            self.showCode = valid[0].ShowCode;

            self.loadingCompanies = true;
            LoginService.getExhibitorsByShowCode({
              showCode: self.showCode
            }).then(function () {
              if (LoginService.data.exhibitors) {
                self.exhibitorList = LoginService.data.exhibitors;

                self.exhibitorList = _.map(self.exhibitorList, function (s) {
                  var newExhibitor = angular.copy(s);

                  if (newExhibitor.BoothNumbers && newExhibitor.BoothNumbers.trim() !== '') {
                    newExhibitor.ExhibitorName = newExhibitor.ExhibitorName + ' (Booth Number(s): ' + newExhibitor.BoothNumbers + ')';
                  }

                  return newExhibitor;
                });

                self.focusExhibitor = true;
              }
            }).finally(function () {
              self.loadingCompanies = false;

              if (!self.exhibitorList || !_.any(self.exhibitorList, function (e) { return e.ExhibitorName === self.exhibitorName; }))
                self.exhibitorName = null;

              if (self.validShow && (!self.exhibitorList || self.exhibitorList.length === 0))
                self.errors = ['PLEASE NOTE: The list of exhibiting companies is not yet available for this show but we\'d be happy to help you. Please contact our Customer Service Department at exhibitorservices@maritz.com.'];
              else
                self.errors = [];
            });
          } else {
            self.showCode = null;
            self.exhibitorName = null;
            self.exhibitorList = null;
            self.errors = [];
          }
        });

        scope.$watch('scLookup.exhibitorName', function (newval, oldval) {
          var valid = _.filter(self.exhibitorList, function (e) { return e.ExhibitorName === newval; });
          self.validExhibitor = valid && valid.length === 1;

          self.moreThanOne = valid && valid.length > 1;

          self.exhibitorId = self.validExhibitor ? valid[0].Id : null;
        });

        self.next = function () {
          self.errors = null;
          LoginService.data.messages = null;

          if (self.moreThanOne) {
            self.errors = ["PLEASE NOTE: We found more than one company that matches the name selected. Please contact our Customer Service Department at exhibitorservices@maritz.com and we'll work this out together."];
            return;
          }
          else if (!self.showCode || !self.exhibitorId) {
            self.errors = ["PLEASE NOTE: We couldn't find your company in our database. Please contact our Customer Service Department at exhibitorservices@maritz.com and we'll work this out together."];
            return;
          }

          LoginService.data.show = {
            showName: self.showName,
            showCode: self.showCode
          };

          LoginService.data.exhibitor = {
            exhibitorName: self.exhibitorName,
            exhibitorId: self.exhibitorId
          };

          if (angular.isFunction(self.callback)) {
            self.callback();
          }
        };
      },
      templateUrl: (window.location.hostname === 'localhost' ? '/app/' : '') + 'views/Login/lookupShowCompany.html',
      controllerAs: 'scLookup',
      controller: function () {

      },
      bindToController: true
    };
    return directive;
  }

  angular.module('exhibitorPortalLoginApp')
    .directive('lookupShowCompany', lookupShowCompany);
})(angular, window);
