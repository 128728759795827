'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.controller:BadgeSelectionCtrl
 * @description
 * # BadgeSelectionCtrl
 * Controller of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  var BadgeSelectionCtrl = function ($q, $timeout, $state, LeadService, CurrentEvent) {
    'ngInject'
        var self = this;

        self.ID = '';

       	self.next = function() {
       		if(self.badgeForm.$valid) {
       			return LeadService.activateDevKit({
       				params: {
       					connectKey: self.ID,
       					showCode: CurrentEvent.ShowCode
       				}
       			}).then(function(data){
       				if(data)
       					$state.go('event.leadUpload', { contactId: data });
       			});
       		}
       		else {
       			var d = $q.defer();

       			$timeout(function() {
       				self.badgeForm.$setSubmitted();
       				d.reject();
       			});

       			return d.promise;
       		}
       	};
	};

    angular.module('exhibitorPortalApp')
		.controller('BadgeSelectionCtrl', BadgeSelectionCtrl);
})(angular, window);
