'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:BusinessCardDetailCtrl
 * @description
 * # Business Card Detail
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var BusinessCardDetailCtrl = function ($state, $anchorScroll, $location, $filter, $sce, BusinessCardLeadService, LeadExportService, EnvironmentConfig, CurrentEvent, BusinessCard, ExportHeaders, ActiveUserProfile, HasPurchasedEventBit) {
    'ngInject'
    var self = this;

    self.businesscard = BusinessCard;
    self.editMode = false;
    self.exportHeaders = ExportHeaders;
    self.hasPurchasedEventBit = HasPurchasedEventBit;

    self.exportUrl = $sce.trustAsResourceUrl(EnvironmentConfig.SiteRoot + 'export/');
    self.filename = 'lead-' + CurrentEvent.ShowCode + '-' + new Date().toJSON().slice(0, 10);
    self.filedata = '';
    self.filetype = '';

    self.localRating = self.businesscard.Rating ? self.businesscard.Rating : 0;

    self.search = {
      collateral: {
        sort: 'SentDate',
        sortDirection: true
      }
    };

    $anchorScroll();

    self.ratingLeave = function () {
      self.localRating = self.businesscard.Rating ? self.businesscard.Rating : 0;
    };

    self.setRating = function (rating) {
      self.businesscard.Rating = rating;
      self.localRating = self.businesscard.Rating ? self.businesscard.Rating : 0;
    };

    self.save = function () {
      return BusinessCardLeadService
        .saveBusinessCardLeads({
          params: { showCode: CurrentEvent.ShowCode },
          data: {
            showCode: CurrentEvent.ShowCode,
            businessCards: [self.businesscard]
          }
        })
        .then(function () {
          for (var i = 0; i < BusinessCardLeadService.data.businessCardLeads[CurrentEvent.ShowCode].length; i++)
            if (BusinessCardLeadService.data.businessCardLeads[CurrentEvent.ShowCode][i].ImageKey == self.businesscard.ImageKey) {
              self.businesscard = angular.copy(BusinessCardLeadService.data.businessCardLeads[CurrentEvent.ShowCode][i]);
              break;
            }
        });
    };

    self.edit = function () {
      self.editMode = !self.editMode;

      if (self.editMode) {
        $location.hash('Edit');
      }
      else {
        $location.hash('');
      }
    };

    self.postLeadsForExport = function (filetype) {
      if (typeof dataLayer !== 'undefined') {
        if (filetype.toLowerCase() === 'csv') {
          dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Lead Detail', 'eventAction': 'Download CSV', 'eventLabel': CurrentEvent.ShowCode });
        } else {
          dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Lead Detail', 'eventAction': 'Download Excel', 'eventLabel': CurrentEvent.ShowCode });
        }
      }

      var data = LeadExportService.getExportCSV(CurrentEvent.ShowCode, self.exportHeaders, [self.businesscard], filetype);
      self.filedata = btoa(RawDeflate.deflate(encodeURIComponent(data))).replace(/\//g, '-').replace(/\+/g, '.').replace(/=/g, '_');
      self.filetype = filetype;

      setTimeout(function () { angular.element('#ExportForm').submit(); }, 0);
    };


    self.share = function () {
      //build mailto link
      var subject = 'Lead info from ' + CurrentEvent.TradeShowName;
      var body = '';

      //contact info
      body += 'First Name: ' + self.businesscard.FirstName + '\r\n';
      body += 'Last Name: ' + self.businesscard.LastName + '\r\n';
      body += 'Title: ' + self.businesscard.Title + '\r\n';
      body += 'Company: ' + self.businesscard.Company + '\r\n';

      body += 'Address: ' + self.businesscard.Address + '\r\n';

      body += 'Phone: ' + self.businesscard.Phone + '\r\n';
      body += 'Email: ' + self.businesscard.Email + '\r\n';

      //notes
      body += '\r\nNotes: \r\n' + self.businesscard.Notes + '\r\n \r\n';

      // other
      body += 'Capture Date: ' + $filter('date')(self.businesscard.LeadDate, 'short', self.businesscard.TimeZoneOffset);

      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Business Card Detail', 'eventAction': 'Share', 'eventLabel': CurrentEvent.ShowCode });
      }

      window.location.href = 'mailto:?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
    };

    self.delete = function () {
      if (window.confirm('Are you sure you want to delete this lead?')) {

        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Business Card Detail', 'eventAction': 'DeleteBusinessCardLead', 'eventLabel': CurrentEvent.ShowCode });
        }

        return BusinessCardLeadService
          .deleteBusinessCardLead({
            params: { showCode: CurrentEvent.ShowCode },
            data: {
              showCode: CurrentEvent.ShowCode,
              key: self.businesscard.ImageKey
            }
          })
          .then(function () {
            $state.go('event.leads');
          });
      }
    }
  };


  angular.module('exhibitorPortalApp')
    .controller('BusinessCardDetailCtrl', BusinessCardDetailCtrl);
})(angular, window);
