'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.controller:LandingCtrl
 * @description
 * # LandingCtrl
 * Controller of the exhibitorPortalApp
 */

function LandingCtrl($q, $state, $filter, $sce, CurrentEvent, LeadActivityService, OrderService, Search, ActiveUserProfile,
  LeadSourcesChartData, LeadDaysChartData, LeadSurveyChartData, LeadDemographicChartData, LeadSummaryData,
  isSPOT, DemographicQuestions, CurrentDemographic, Surveys, CurrentSurvey, CurrentSurveyQuestion, EventBitOrders,
  LeadService, LeadExportService, LeadCollateral, Collateral, HasPurchasedEventBit, BusinessCardLeadService, CollateralConfig, FollowUpConfig, AdditionalInfo, ShowService) {
  'ngInject'

  var self = this;
  self.currentEvent = CurrentEvent;
  self.leadSearch = Search.leads;
  self.activeUserProfile = ActiveUserProfile;
  self.showSalesforceModal = false;
  self.showSalesforceUnderConstructionModal = false;
  self.additionalInfo = AdditionalInfo;

  self.isDemographicLoaded = true;
  self.demographics = DemographicQuestions;
  self.currentDemographic = CurrentDemographic;

  self.isSurveyLoaded = true;
  self.surveys = Surveys;
  self.currentSurvey = CurrentSurvey;
  self.currentSurveyQuestion = CurrentSurveyQuestion;

  self.exportUrl = $sce.trustAsResourceUrl(EnvironmentConfig.SiteRoot + 'export/');
  self.filename = 'leads-' + CurrentEvent.ShowCode + '-' + new Date().toJSON().slice(0, 10);
  self.filedata = '';
  self.filetype = '';

  self.SPOTSummaryOptions = {
    chart: {
      bottomPinch: 1,
      bottomWidth: 1 / 2,
      height: 300
    },
    label: {
      format: '{f}'
    },
    block: {
      dynamicHeight: true,
      minHeight: 20,
      fill: {
        scale: [
          '#FF8E46',
          '#008E8E',
          '#8BBA00',
        ]
      }
    },
  }

  var now = new Date();
  var beginDate = new Date(self.currentEvent.BeginDate);
  var endDate = new Date(self.currentEvent.EndDate);

  self.preShowActions = {
    visible: now <= beginDate,
    surveyCreated: Array.isArray(self.surveys) && self.surveys.length > 1,
    collateralUploaded: CollateralConfig && CollateralConfig.Collaterals && Array.isArray(CollateralConfig.Collaterals) && CollateralConfig.Collaterals.length > 0,
    leadEmailScheduled: FollowUpConfig && FollowUpConfig.FollowUpType !== 'None',
  };

  self.onsiteShowActions = {
    visible: now >= beginDate && now <= endDate
  };

  self.postShowActions = {
    visible: now > endDate
  };

  var SPOTSummaryChartData = [];
  if (EventBitOrders) {
    LandingCtrl.process.SPOTSummaryChartData(CurrentEvent, LeadActivityService).then(function (data) {
      SPOTSummaryChartData = data;
      updateLeadData();

      self.showSPOTChart = (function (hasData, isSPOT) {
        return hasData || (!hasData && isSPOT);
      })(!!SPOTSummaryChartData.filter(function (x) { return !!x.value; }).length, isSPOT);
    });
  }

  LeadService.getSwapActivations({ showCode: CurrentEvent.ShowCode })
    .then(function () {
      self.swapActivations = LeadService.data.swapActivations[CurrentEvent.ShowCode];
    });

  $q.all([
    LeadService.getLeads({ showCode: CurrentEvent.ShowCode }),
    BusinessCardLeadService.getBusinessCardLeads({ showCode: CurrentEvent.ShowCode })
  ]).then(function () {
    self.businessCardLeads = BusinessCardLeadService.data.businessCardLeads[CurrentEvent.ShowCode];
    self.leads = LeadService.data.leads[CurrentEvent.ShowCode].map(function (l) {
      return angular.extend({}, l, {
        Collateral: LeadCollateral[l.Id] || [],
        CollateralFormatted: (LeadCollateral[l.Id] || []).map(function (x) {
          return (Collateral.filter(function (c) { return c.CollateralID == x.CollateralID; }).shift() || {}).DisplayName;
        }).join(' | '),
        Spotted: !!(l.LeadOwnerSpotted || l.NonLeadOwnerSpotted) ? 1 : 0,
        MoreCaptureTimes: l.MoreCaptureTimes ? JSON.parse(l.MoreCaptureTimes).map(function (x) {
          var d = new Date(0);
          d.setUTCMilliseconds(x.mc);
          return d.toISOString();
        }) : '',
        Rating: l.Rating ? l.Rating : 0
      });
    });

    if (self.businessCardLeads) {
      for (var i = 0; i < self.businessCardLeads.length; i++) {
        self.leads.push(self.businessCardLeads[i]);
      }
    }

    self.leadSearch.data = $filter('orderBy')(self.leads, self.leadSearch.sort, self.leadSearch.sortDirection);

    LeadService.getLeadDates(CurrentEvent.ShowCode)
      .then(function () {
        self.leadDates = LeadService.data.leadDates[CurrentEvent.ShowCode];
      })

    LeadExportService.getHeaders(CurrentEvent.ShowCode, self.leads, HasPurchasedEventBit)
      .then(function () {
        self.exportHeaders = LeadExportService.data[CurrentEvent.ShowCode].headers;
      });

    self.showSPOT = (function (hasData, isSPOT) {
      return hasData || (!hasData && isSPOT);
    })(!!self.leads.filter(function (x) { return x.LeadOwnerSpotted || x.NonLeadOwnerSpotted; }).length, isSPOT);
  });

  self.postLeadsForExport = function (filetype) {
    if (typeof dataLayer !== 'undefined') {
      if (filetype.toLowerCase() === 'csv') {
        dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Leads', 'eventAction': 'Download CSV', 'eventLabel': CurrentEvent.ShowCode });
      } else {
        dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Leads', 'eventAction': 'Download Excel', 'eventLabel': CurrentEvent.ShowCode });
      }
    }

    var data = LeadExportService.getExportCSV(CurrentEvent.ShowCode, self.exportHeaders, self.leadSearch.data, filetype);
    self.filedata = btoa(RawDeflate.deflate(encodeURIComponent(data))).replace(/\//g, '-').replace(/\+/g, '.').replace(/=/g, '_');
    self.filetype = filetype;

    setTimeout(function () { angular.element('#ExportForm').submit(); }, 0);
  };

  self.downloadQR = function (filetype) {
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'QR Download', 'eventAction': 'Download ' + filetype.toUpperCase(), 'eventLabel': CurrentEvent.ShowCode });
    }

    var exhibitorId = AdditionalInfo.Id;
    var url = 'https://030zczz9je.execute-api.us-east-1.amazonaws.com/dev/api/qrcode?c=HTTPS://SR-DEV.L4E.US?'
      + 'SC=' + CurrentEvent.ShowCode.toUpperCase() + '%26'
      + 'EID=' + exhibitorId + '&'
      + 's=20' + '&' 
      + 'format=' + filetype.toLowerCase();
    window.open(url, '_blank');
  };

  self.showQrDownload = function () {
    if (ShowService.data.currentShow &&
      ShowService.data.currentShow.ShowCode &&
      OrderService.data.orders &&
      OrderService.data.orders[ShowService.data.currentShow.ShowCode]) {

      var orders = OrderService.data.orders[ShowService.data.currentShow.ShowCode];
      var QrProductCode = '270';
      var SWAPCodes = new Set(['311', '312', '313', '782', '325']);
      var hasQrProductCode = false;
      var hasSWAPCode = false;

      for (var i = 0; i < orders.length; i++) {
        for (var a = 0; a < orders[i].Transactions.length; a++) {
          var transaction = orders[i].Transactions[a];

          if (transaction.TransTypeCode === 'PUR' && transaction.TransStatus !== 'VOIDED') {
            if (transaction.ProductCode === QrProductCode) {
              hasQrProductCode = true;
            }
            if (SWAPCodes.has(transaction.ProductCode)) {
              hasSWAPCode = true;
            }
            if (hasQrProductCode && hasSWAPCode) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };
  self.selectDemographic = function (demo) {
    self.currentDemographic = demo;
    self.asyncDemo = true;
    self.isDemographicLoaded = false;

    LandingCtrl.process.LeadDemographicChartData(CurrentEvent, self.currentDemographic, LeadActivityService)
      .then(function (data) {
        LeadDemographicChartData = data;
        self.chartData.demographic = {
          series: data.series
        };
        self.chartLabels.demographic = data.labels;
        self.asyncDemo = false;
      }).finally(function () {
        self.isDemographicLoaded = true;
      });
  };

  self.selectSurvey = function (survey) {
    self.currentSurvey = survey;
    var defaultQuestion = null;
    if (self.currentSurvey) {
      for (var x = 0; x < self.currentSurvey.SurveyQuestions.length; x++) {
        if (self.currentSurvey.SurveyQuestions[x].IsActive) {
          defaultQuestion = self.currentSurvey.SurveyQuestions[x];
          break;
        }
      }
    }
    self.selectSurveyQuestion(defaultQuestion);
  }

  self.selectSurveyQuestion = function (question) {
    self.currentSurveyQuestion = question;
    self.asyncSurveyQuestion = true;
    self.isSurveyLoaded = false;

    LandingCtrl.process.LeadSurveyChartData(CurrentEvent, self.currentSurveyQuestion, LeadActivityService)
      .then(function (data) {
        LeadSurveyChartData = data;
        self.chartData.survey = {
          series: data.series
        };
        self.chartLabels.survey = data.labels;
        self.asyncSurveyQuestion = false;
      }).finally(function () {
        self.isSurveyLoaded = true;
      });
  };

  self.refresh = function () {
    return $q.all([
      LeadActivityService.getLeadSources({ showCode: CurrentEvent.ShowCode }, true),
      LeadActivityService.getLeadsByDay({ showCode: CurrentEvent.ShowCode }, true),
      LeadActivityService.getLeadsByDemographicQuestion({
        showCode: CurrentEvent.ShowCode,
        fieldDetailId: self.currentDemographic.Id
      }, true),
      LeadActivityService.getLeadsBySurveyQuestion({
        showCode: CurrentEvent.ShowCode,
        surveyQuestionId: self.currentSurveyQuestion ? self.currentSurveyQuestion.Id : 0
      }, true),
      LeadActivityService.getLeadSummary({ showCode: CurrentEvent.ShowCode }, true),
      EventBitOrders ? LeadActivityService.getSPOTSummary({ showCode: CurrentEvent.ShowCode }, true) : $q.when([]),
    ])
      .then(function () {
        $q.all([
          LandingCtrl.process.LeadSourcesChartData(CurrentEvent, LeadActivityService),
          LandingCtrl.process.LeadDaysChartData(CurrentEvent, LeadActivityService),
          LandingCtrl.process.LeadSurveyChartData(CurrentEvent, self.currentSurveyQuestion, LeadActivityService),
          LandingCtrl.process.LeadDemographicChartData(CurrentEvent, self.currentDemographic, LeadActivityService),
          LandingCtrl.process.LeadSummaryData(CurrentEvent, LeadActivityService),
          EventBitOrders ? LandingCtrl.process.SPOTSummaryChartData(CurrentEvent, LeadActivityService) : $q.when([]),
        ])
          .then(function (data) {
            LeadSourcesChartData = data[0];
            LeadDaysChartData = data[1];
            LeadSurveyChartData = data[2];
            LeadDemographicChartData = data[3];
            LeadSummaryData = data[4];
            SPOTSummaryChartData = data[5];
            self.eBitCompanySummary = data[6];

            updateLeadData();
          });
      });
  };

  self.onLeadSearch = function (e) {
    if (!e || (e && e.which === 13))
      $state.go('event.leads');
  };

  self.onPickPrizeWinner = function () {
    return LeadActivityService.getLeads({ showCode: CurrentEvent.ShowCode })
      .then(function () {
        var leads = [];
        for (var i = 0; i < LeadActivityService.data.leads[CurrentEvent.ShowCode].length; i++) {
          leads.push(angular.copy(LeadActivityService.data.leads[CurrentEvent.ShowCode][i]));
        }

        var randomLead = leads[Math.floor(Math.random() * leads.length)];
        if (randomLead) {
          if (typeof dataLayer !== 'undefined') {
            dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Leads', 'eventAction': 'Select Prize Winner', 'eventLabel': CurrentEvent.ShowCode });
          }
          $state.go('event.leadDetail', { id: randomLead.Id });
        }
      });
  };

  function updateLeadData() {
    self.chartData = {
      source: {
        series: LeadSourcesChartData.series
      },
      day: {
        labels: LeadDaysChartData.labels,
        series: [LeadDaysChartData.series]
      },
      survey: {
        series: LeadSurveyChartData.series || []
      },
      demographic: {
        series: LeadDemographicChartData.series
      },
      summary: LeadSummaryData,
      SPOTSummary: SPOTSummaryChartData
    };

    self.chartLabels = {
      source: LeadSourcesChartData.labels,
      survey: LeadSurveyChartData.labels,
      demographic: LeadDemographicChartData.labels,
      SPOTSummary: SPOTSummaryChartData.map(function (x) { return x.label; })
    };
  }

  updateLeadData();
};

(function () {
  LandingCtrl.process = {
    DemographicQuestions: function (CurrentEvent, DemographicService) {
      return DemographicService.getDemographics({ showCode: CurrentEvent.ShowCode })
        .then(function () {
          var questions = [];

          for (var i = 0; i < DemographicService.data.demographics[CurrentEvent.ShowCode].length; i++)
            if (DemographicService.data.demographics[CurrentEvent.ShowCode][i].TableName == 'RegDemo')
              questions.push(DemographicService.data.demographics[CurrentEvent.ShowCode][i]);

          return questions;
        });
    },
    LeadSummaryData: function (CurrentEvent, LeadActivityService) {
      return LeadActivityService.getLeadSummary({ showCode: CurrentEvent.ShowCode })
        .then(function () {
          return LeadActivityService.data.leadSummary[CurrentEvent.ShowCode];
        });
    },
    LeadSourcesChartData: function (CurrentEvent, LeadActivityService) {
      return LeadActivityService.getLeadSources({ showCode: CurrentEvent.ShowCode })
        .then(function () {
          return LeadActivityService.parseChartData(LeadActivityService.data.leadSources[CurrentEvent.ShowCode]);
        });
    },
    LeadDemographicChartData: function (CurrentEvent, CurrentDemographic, LeadActivityService) {
      if (!CurrentDemographic)
        return {
          labels: [],
          series: []
        };

      return LeadActivityService.getLeadsByDemographicQuestion({
        showCode: CurrentEvent.ShowCode,
        fieldDetailId: CurrentDemographic.Id
      })
        .then(function () {
          return LeadActivityService.parseChartData(LeadActivityService.data.leadDemographics[CurrentEvent.ShowCode][CurrentDemographic.Id], 'Other Demographics');
        });
    },
    LeadSurveyChartData: function (CurrentEvent, CurrentSurveyQuestion, LeadActivityService) {
      if (!CurrentSurveyQuestion)
        return {
          labels: [],
          series: []
        };

      return LeadActivityService.getLeadsBySurveyQuestion({
        showCode: CurrentEvent.ShowCode,
        surveyQuestionId: CurrentSurveyQuestion.Id
      })
        .then(function () {
          return LeadActivityService.parseChartData(LeadActivityService.data.leadSurveys[CurrentEvent.ShowCode][CurrentSurveyQuestion.Id], 'Other Survey Questions');
        });
    },
    LeadDaysChartData: function (CurrentEvent, LeadActivityService) {
      return LeadActivityService.getLeadsByDay({ showCode: CurrentEvent.ShowCode })
        .then(function () {
          var data = LeadActivityService.data.leadDays[CurrentEvent.ShowCode],
            labels = [],
            series = [];

          for (var i = 0; i < data.length; i++) {
            labels.push(data[i].Source);
            series.push(data[i].Count);
          }

          return {
            labels: labels,
            series: series
          };
        });
    },
    SPOTSummaryChartData: function (CurrentEvent, LeadActivityService) {
      return LeadActivityService.getSPOTSummary({ showCode: CurrentEvent.ShowCode })
        .then(function () {
          return LeadActivityService.data.SPOTSummary[CurrentEvent.ShowCode].map(function (x) {
            return {
              label: x.Source,
              value: x.Count || 0
            }
          });
        })
    },
  }

  function parseChartData(data, otherLabel) {
    data = data || [];
    return angular.injector(['ng']).invoke(['$filter', function ($filter) {
      var labels = [],
        series = [],
        other = 0;

      data = $filter('orderBy')(data, 'Count', true);

      for (var i = 0; i < 5 && i < data.length; i++) {
        labels.push(data[i].Source);
        series.push(data[i].Count);
      }

      for (i = 5; i < data.length; i++)
        other += data[i].Count;

      if (data.length > 5) {
        labels.push(otherLabel || 'Other');
        series.push(other);
      }

      return {
        labels: labels,
        series: series
      };
    }]);
  }
})()

angular.module('exhibitorPortalApp')
  .controller('LandingCtrl', LandingCtrl);
