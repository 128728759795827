'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:ConfirmationCtrl
 * @description
 * # ConfirmationCtrl
 * Controller of the exhibitorPortalApp
 */

(function(angular, window, undefined) {
  var ConfirmationCtrl = function ($window, $stateParams, $state, $q, $timeout, Cart, ShowService, ConfirmationHtml, EXSConfig, EnvironmentConfig, ActiveUserProfile, CurrentEvent, OrderService, ECR, ipCookie) {
    'ngInject'
        var self = this;

        self.EXSConfig = EXSConfig;
        self.environmentConfig = EnvironmentConfig;
        self.isPayOnly = $window.SalesOrderId;

        if (!self.environmentConfig.InAppPurchase && ConfirmationHtml)
            setTimeout(function() {
                var iframe = document.getElementById('Confirmation');

                if(iframe)
                    iframe.contentWindow.document.write(ConfirmationHtml);
            }, 0);

        Cart.empty();
        //START GTM CODE
      OrderService.getOrder({ salesOrderId: $stateParams.salesOrderId }, true).then(function success(response) {
          var order = response.data;
          var productsForGTM = [];
        
          for (var i = 0; i < order.OrderPurchases.length; i++) {
            var gtmItem = order.OrderPurchases[i];
            var gtmCategoryCode = '';
            for (var x = 0; x < order.OrderSummary.length; x++) {
              var gtmSummary = order.OrderSummary[x];

              if (gtmSummary.ProductID === gtmItem.ProductID) {
                gtmCategoryCode = gtmSummary.CategoryCode;
                break;
              }
            }
              productsForGTM.push({
                'id': gtmItem.ProductID,
                'name': gtmItem.ProductName,
                'price': (gtmItem.TotalAmount - gtmItem.Taxes)/ gtmItem.Quantity, //Accounts for all adjustments e.g. ((UnitCost - UnitAdjmt) * Quantity) + TransAdjustment [no taxes]
                'category': gtmCategoryCode,
                'quantity': gtmItem.Quantity,
                'coupon': gtmItem.CouponName,
                'dimension1': gtmItem.ProductCode
              });
          }
          window.dataLayer = window.dataLayer || [];

        dataLayer.push({
              'event': 'purchase',
              'ecommerce': {
                'purchase': {
                  'actionField': {
                    'id': order.PrimaryID,
                    'affiliation': order.ShowCode,
                    'revenue': order.TotalPayments === 0 ? order.TotalPayments : order.TotalPayments *= -1,                     // Total transaction value (incl. tax and shipping)
                    'tax': order.TotalSalesTax,
                    'coupon': order.CouponName
                  },
                  'products': productsForGTM
                }
              }
          });
      });
          //END GTM CODE

        //refresh orders
        OrderService.getOrders({ showCode: CurrentEvent.ShowCode }, true);

        //send extra confirmation
        if (ECR.get() !== undefined && ECR.get() != null) {
            if(ECR.get())
                OrderService.sendExtraConfirmation({
                    data: {
                        salesOrderId: $stateParams.salesOrderId,
                        email: ECR.get()
                    }
                });

            ECR.remove();
        }

        self.returnToSWAP = function () {
            var session = ipCookie('EPSession');

            if (typeof injectedObject !== 'undefined') {
                // Android
                injectedObject.done();
            } else if (typeof WP !== 'undefined') {
                // Windows Phone
                new WP().what('done');
            } else if (session.Re !== 'undefined' && session.Re != '') {
                // Web Mobile
                window.location = session.Re;
            } else {
                // iOS
                window.location = "js-call:done";
            }
        };

        self.shareActivation = function () {
            var session = ipCookie('EPSession');

            if (typeof injectedObject !== 'undefined') {
                // Android
                injectedObject.share();
            } else if (typeof WP !== 'undefined') {
                // Windows Phone
                new WP().what('share');
            } else if (session.Re !== 'undefined' && session.Re != '') {
                // Web Mobile
                window.location = session.Re;
            } else {
                // iOS
                window.location = "js-call:share";
            }
        };

        self.print = function () {
            document.getElementById('Confirmation').contentWindow.document.execCommand('print', false, null) || document.getElementById('Confirmation').contentWindow.print();
        };

        self.goToDashboard = function() {
            var d = $q.defer();

            if(ShowService.data.currentShow && ShowService.data.activations && ShowService.data.activations[CurrentEvent.ShowCode]){
                $timeout(function() {
                    $state.go('event.dashboard.landing');
                    d.resolve();
                });
            }
            else
                OrderService.getOrders({ showCode: CurrentEvent.ShowCode })
                    .then(function() {
                        var orders = OrderService.data.orders[CurrentEvent.ShowCode];

                        for(var i = 0; i < orders.length; i++){
                            for(var a = 0; a < orders[i].Transactions.length; a++){
                                if(orders[i].Transactions[a].DataSalesListId && orders[i].Transactions[a].TransTypeCode === 'PUR' && orders[i].Transactions[a].TransStatus !== 'VOIDED'){
                                    $state.go('event.dashboard.emailCampaigns');
                                    d.resolve();
                                    return;
                                }
                            }
                        }

                        $state.go('event.dashboard.landing');
                        d.resolve();
                    });
        };
    };

	angular.module('exhibitorPortalApp')
	  .controller('ConfirmationCtrl', ConfirmationCtrl);
})(angular, window);
