'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:eventBit
 * @description
 * # eventBit iframe
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epEventBit($sce) {
    'ngInject'
		return {
			restrict: 'E',
			scope: {
                url: '=url',
                // origin: '=origin'
            },
			template: '<iframe id ="myIframe" ng-src="{{url}}" style="border:0; width:100%; height:75vh; margin:0;padding:0; overflow:hidden;" scrolling="no"></iframe>',
			link: function (scope, elem, attrs) {
                var iframe = elem.find('iframe')[0];

                window.addEventListener("message", receiveMessage, false);
                window.addEventListener("scroll", onScroll, false);

                //scope.url = $sce.trustAsResourceUrl(scope.url);

                function receiveMessage(event) {
                    // if(event.origin.toLowerCase() !== "@ViewBag.eventBitOrigin")
                    //     return;

                    if(event.data.resizeHeight) {
                        iframe.style.height = event.data.resizeHeight + "px";
                    }

                    if (event.data.scrollTo) {
                        setTimeout(function () {
                            window.scrollTo(event.data.scrollTo.x, event.data.scrollTo.y)
                        }, 200); // leave buffer of time for iframe to resize to contain content we are scrolling to
                    }
                }

                function onScroll() {
                    iframe.contentWindow.postMessage({ hostScrollPosition: { x: window.scrollX, y: window.scrollY } }, "*");
                }
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epEventBit', epEventBit);
})(angular, window);
