'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.filter:dataSalesFile
 * @description
 * # Data Sales File
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function dataSalesFile() {
    'ngInject'
        return function (input, EXSConfig, CurrentEvent, ActiveUserProfile) {            
            if(arguments.length === 1)
                return typeof input === 'string' ? /.*(-.*)\..*/.exec(input.split('/').pop())
                    .reduce(function(acc, curr) { return acc && (acc = acc.replace(curr,'')) || (acc = curr); }, '') 
                : null;
            else {
                var rootURL = EXSConfig.AWS3RootURL.replace('{0}', CurrentEvent.ShowCode.toLowerCase());
                rootURL = rootURL.replace('{1}', ActiveUserProfile.ExhibitorId)

                return rootURL + '/' + input;
            }
        };
    }

    angular.module('exhibitorPortalApp')
		.filter('dataSalesFile', dataSalesFile);
})(angular, window);
