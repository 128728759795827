'use strict';

/**
 * @ngdoc factory
 * @name exhibitorPortalApp.factory:DataSalesExecuteContext
 * @description
 * Wrapper around a complete DataSales plan tree, including global parameters.
 */

(function (angular, window, undefined) {
  var DataSalesExecuteContext = function () {
    'ngInject'

        //@param {Array} filterExpression the expression to build an execute context from
        //@param {String} mediaTypeCode how it's going to be used (download file types, email blast, etc.)
        return function ExecuteContext(filterExpression, mediaTypeCode) {
            this.MediaTypeCode = mediaTypeCode;
            this.PlanRoot = {}; //PlanPolymorph

            this.PlanRoot.PlanCombine = {};
            this.PlanRoot.PlanCombine.Min = 1;
            this.PlanRoot.PlanCombine.Max = filterExpression.length;
            this.PlanRoot.PlanCombine.Sources = [];

            for(var i = 0; i < filterExpression.length; i++) {
                var x = {}; //PlanCombine

                x.Min = x.Max = filterExpression[i].nodes.length;
                x.Sources = [];

                for(var a = 0; a < filterExpression[i].nodes.length; a++){
                    var y = {}; //PlanCombine

                    y.Min = 1;
                    y.Max = filterExpression[i].nodes[a].operands.length;
                    y.Sources = [];

                    for(var b = 0; b < filterExpression[i].nodes[a].operands.length; b++) {
                        var z = {}; //PlanCombine

                        if(filterExpression[i].nodes[a].operands[b].detail && filterExpression[i].nodes[a].operands[b].values.length) {
                            z.Field = filterExpression[i].nodes[a].operands[b].detail.filterDetail.Id;
                            z.Values = angular.copy(filterExpression[i].nodes[a].operands[b].values);

                            switch(filterExpression[i].nodes[a].operands[b].operator) {
                                case 'oneOf':
                                case 'notOneOf':
                                    z.Min = 1;
                                    z.Max = filterExpression[i].nodes[a].operands[b].values.length;
                                break;
                                case 'allOf':
                                case 'notAllOf':
                                    z.Min = z.Max = filterExpression[i].nodes[a].operands[b].values.length;
                                break;
                            }

                            if(['notOneOf', 'notAllOf'].indexOf(filterExpression[i].nodes[a].operands[b].operator) > -1)
                                y.Sources.push({
                                    PlanExclude: {
                                        Exclude: {
                                            PlanFilterField: z
                                        }
                                    }
                                });
                            else
                                y.Sources.push({
                                    PlanFilterField: z
                                });
                        }
                    }

                    if(y.Sources.length)
                        x.Sources.push({
                            PlanCombine: y
                        });
                }

                if(x.Sources.length){
                    if(filterExpression[i].include === 'true'){
                        this.PlanRoot.PlanCombine.Sources.push({
                            PlanCombine: x
                        });
                    }
                    else {
                        this.PlanRoot.PlanCombine.Sources.push({
                            PlanExclude: {
                                Exclude: {
                                    PlanCombine: x
                                }
                            }
                        });
                    }
                }
            }
        };
    };

    angular.module('exhibitorPortalApp')
      .factory('DataSalesExecuteContext', DataSalesExecuteContext);
})(angular, window);
