'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.Show
 * @description
 * # Show
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var ShowService = function ($q, $timeout, ApiService) {
    'ngInject'
        var self = this;

        self.data = {};

        self.addShow = ApiService.endpoint.get({
            url: 'api/show/addShow'
        });

        self.getShows = ApiService.endpoint.get({
            url: 'api/show',
            success: function (data) {
                self.data.shows = data;
            }
        });

        self.getShowAdditionalInfo = ApiService.endpoint.get({
            url: 'api/show/getShowAdditionalInfo',
            success: function(data, params) {
                self.data.showAdditionalInfo = self.data.showAdditionalInfo || {};
                self.data.showAdditionalInfo[params.showCode] = data;
            }
        });

        self.hasActivations = ApiService.endpoint.get({
            url: 'api/show/hasActivations',
            success: function(data, params) {
                self.data.activations = self.data.activations || {};
                self.data.activations[params.showCode] = data;
            },
            error: function(promise, data, status, headers, config){
                self.data.activations = self.data.activations || {};
                promise.resolve(self.data.activations[config.params.showCode] = 0);
            }
        });

        self.getShow = function (showCode) {
            var d = $q.defer();

            if (self.data.currentShow && self.data.currentShow.ShowCode.toUpperCase() === showCode.toUpperCase())
                $timeout(function () {
                    d.resolve();
                });
            else if (showCode) {
                if (self.data.shows && self.data.shows.length) {
                    var show;
                    showCode = showCode.toUpperCase();

                    for(var i = 0; i < self.data.shows.length; i++)
                        if (self.data.shows[i].ShowCode.toUpperCase() === showCode) {
                            show = self.data.shows[i];
                            break;
                        }

                    if (show) {
                        self.data.currentShow = show;
                        $timeout(function () {
                            d.resolve();
                        });
                    }
                    else
                        throw 'Event was not found!';
                }
                else
                    self
                    .getShows()
                    .then(function () {
                        self
                            .getShow(showCode)
                            .then(function () {
                                d.resolve();
                            });
                    });
            }

            return d.promise;
        };
    };

    angular.module('exhibitorPortalApp')
      .service('ShowService', ShowService);
})(angular, window);
