'use strict';

/**
 * @ngdoc factory
 * @name exhibitorPortalApp.factory:DataSalesExpression
 * @description
 * The model for data sales filtering views.
 */

(function (angular, window, undefined) {
  var DataSalesExpression = function () {
    'ngInject'

        //@param {Object} context The plan (from SWAP) to build an expression from.
        //@param {Object} filters The full metadata for all available filters.
        return function DataSalesExpression(context, filters) {
        	var expressions = [];

        	if (context.PlanRoot) {
        		for (var i = 0; i < context.PlanRoot.PlanCombine.Sources.length; i++) {
        			var include = !!context.PlanRoot.PlanCombine.Sources[i].PlanCombine;

        			var expression = {
        				include: include.toString(),
        				nodes: []
        			};

        			var planCombine = include ? context.PlanRoot.PlanCombine.Sources[i].PlanCombine : context.PlanRoot.PlanCombine.Sources[i].PlanExclude.Exclude.PlanCombine;

        			for (var a = 0; a < planCombine.Sources.length; a++) {
        				var nodePlanCombine = planCombine.Sources[a].PlanCombine;
        				var node = {
        					operands: []
        				};

        				for (var b = 0; b < nodePlanCombine.Sources.length; b++) {
        					var operatorExclude = !!nodePlanCombine.Sources[b].PlanExclude;
        					var operandPlanFilterField = operatorExclude ? nodePlanCombine.Sources[b].PlanExclude.Exclude.PlanFilterField : nodePlanCombine.Sources[b].PlanFilterField;

        					var operand = {
        						values: operandPlanFilterField.Values,
        						detail: {
        							filterDetail: filters[operandPlanFilterField.Field]
        						},
        						operator: (function () {
        							if ((operandPlanFilterField.Min < operandPlanFilterField.Max || (operandPlanFilterField.Min === operandPlanFilterField.Max === 1)))
        								return operatorExclude ? 'notOneOf' : 'oneOf';
        							else
        								return operatorExclude ? 'notAllOf' : 'allOf';
        						})()
        					};

        					node.operands.push(operand);
        				}

        				expression.nodes.push(node);
        			}

        			expressions.push(expression);
        		}
        	}

        	return expressions;
        };
    };

    angular.module('exhibitorPortalApp')
      .factory('DataSalesExpression', DataSalesExpression);
})(angular, window);
