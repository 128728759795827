'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.Colors
 * @description
 * # Colors
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
    angular.module('exhibitorPortalApp')
      .constant('Colors', [
        '#8ede43',
        '#F6BD0F',
        '#AFD8F8',
        '#8BBA00',
        '#008E8E',
        '#FF8E46',
        '#D64646',
        '#8E468E',
        '#588526',
        '#B3AA00',
        '#008ED6',
        '#9D080D',
        '#A186BE',
        '#9A652F',
        '#A8CB3E',
        '#F9CE4B',
        '#C5E1F6',
        '#F8AE55',
        '#407FBC',
        '#FBEF4A',
        '#F5919E',
        '#C1B93F',
        '#FAD1A3',
        '#B8A4CE',
        '#B5474A',
        '#3FA9E0',
        '#CECF9A',
        '#82A35B',
        '#AA74AA',
        '#E07474',
        '#40AAAA',
        '#D0499A',
        '#76C9B6',
        '#6566AE',
        '#CE6728',
        '#CDDC29',
        '#9A652F',
        '#cbb72f',
        '#e31c00',
        '#93ddcf',
        '#a26500',
        '#007c2e',
        '#ea305c',
        '#c4338e',
        '#533881',
        '#7a6a1a',
        '#9c3100',
        '#00ba82',
        '#90005f',
        '#7179a8',
        '#8d212e',
        '#ba842c',
        '#e54a35',
        '#a8d9cf',
        '#e33f4e',
        '#2fac9a',
        '#e76934',
        '#dc77c4',
        '#b1562e',
        '#dc77c4',
        '#b1562e',
        '#e08891',
        '#8c93b6',
        '#a5367e',
        '#2cce9f',
        '#9e37be',
        '#61b46c',
        '#9e37be',
        '#61b46c',
        '#9e37be',
        '#61b46c',
        '#53829c',
        '#be1841',
        '#cc6a18',
        '#8d212e',
        '#caefa7',
        '#fbe08e',
        '#afd8f8',
        '#c8df87',
        '#87caca',
        '#ffcaa8',
        '#eca8a8',
        '#caa8ca',
        '#b0c699',
        '#dbd787',
        '#87caec',
        '#d18b8d',
        '#d3c6e0',
        '#cfb79d',
        '#d6e7a4',
        '#fce8aa',
        '#e4f1fb',
        '#fcd9af',
        '#a5c3df',
        '#fdf7aa',
        '#facbd1',
        '#e2dea5',
        '#fde9d4',
        '#ded4e8',
        '#dca8aa',
        '#a5d7f0',
        '#e8e8cf',
        '#c4d4b2',
        '#d7bed7',
        '#f0bebe',
        '#a5d7d7',
        '#e8e8cf'
      ]);
})(angular, window);