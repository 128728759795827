'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.LeadActivity
 * @description
 * # LeadActivity
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var LeadActivityService = function (ApiService, $filter) {
    'ngInject'
    var self = this;

    self.data = {};

    self.getLeadSources = ApiService.endpoint.get({
      url: 'api/leadActivity/getLeadsBySource',
      success: function (data, params) {
        self.data.leadSources = self.data.leadSources || {};
        self.data.leadSources[params.showCode] = data;
      }
    });

    self.getLeadsByDemographicQuestion = ApiService.endpoint.get({
      url: 'api/leadActivity/getLeadsByDemographicQuestion',
      success: function (data, params) {
        self.data.leadDemographics = self.data.leadDemographics || {};
        self.data.leadDemographics[params.showCode] = self.data.leadDemographics[params.showCode] || {};
        self.data.leadDemographics[params.showCode][params.fieldDetailId] = data;
      }
    });

    self.getLeadsBySurveyQuestion = ApiService.endpoint.get({
      url: 'api/leadActivity/getLeadsBySurveyQuestion',
      success: function (data, params) {
        self.data.leadSurveys = self.data.leadSurveys || {};
        self.data.leadSurveys[params.showCode] = self.data.leadSurveys[params.showCode] || {};
        self.data.leadSurveys[params.showCode][params.surveyQuestionId] = data;
      }
    });

    self.getLeadsByDay = ApiService.endpoint.get({
      url: 'api/leadActivity/getLeadsByDay',
      success: function (data, params) {
        self.data.leadDays = self.data.leadDays || {};
        self.data.leadDays[params.showCode] = data;
      }
    });

    self.getLeadSummary = ApiService.endpoint.get({
      url: 'api/leadActivity/getSummary',
      success: function (data, params) {
        self.data.leadSummary = self.data.leadSummary || {};
        self.data.leadSummary[params.showCode] = data;
      }
    });

    self.getSPOTSummary = ApiService.endpoint.get({
      url: 'api/leadActivity/getSPOTSummary',
      success: function (data, params) {
        self.data.SPOTSummary = self.data.SPOTSummary || {};
        self.data.SPOTSummary[params.showCode] = data;
      }
    });

    self.getEventBitCompanySummary = ApiService.endpoint.get({
      url: 'api/leadActivity/getEventBitCompanySummary',
      success: function (data, params) {
        self.data.eBitCompanySummary = self.data.eBitCompanySummary || {};
        self.data.eBitCompanySummary[params.showCode] = data;
      }
    });

    self.getLeads = ApiService.endpoint.get({
      url: 'api/lead/getLeads',
      success: function (data, params) {
        self.data.leads = self.data.leads || {};
        self.data.leads[params.showCode] = data;
      }
    });

    self.parseChartData = function (data, otherLabel) {
      data = data || [];
      return angular.injector(['ng']).invoke(['$filter', function ($filter) {
        var labels = [],
          series = [],
          other = 0;

        data = $filter('orderBy')(data, 'Count', true);

        for (var i = 0; i < 5 && i < data.length; i++) {
          labels.push(data[i].Source);
          series.push(data[i].Count);
        }

        for (i = 5; i < data.length; i++)
          other += data[i].Count;

        if (data.length > 5) {
          labels.push(otherLabel || 'Other');
          series.push(other);
        }

        return {
          labels: labels,
          series: series
        };
      }]);
    }
  };

  angular.module('exhibitorPortalApp')
    .service('LeadActivityService', LeadActivityService);
})(angular, window);
