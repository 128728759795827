'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:LeadFollowUpCtrl
 * @description
 * # Survey Ctrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var LeadFollowUpCtrl = function ($q, HTMLTemplate, FollowUpConfig, CurrentEvent, LeadFollowUpService) {
    'ngInject'
    var self = this;

    self.baseConfig = angular.copy(FollowUpConfig);
    self.config = angular.copy(FollowUpConfig);
    self.baseTemplate = angular.copy(HTMLTemplate);


    self.saveFollowUpConfig = function () {
      var d = $q.defer();


      if (!self.form.$valid) {
        self.form.$setSubmitted();
        d.reject();
      }

      LeadFollowUpService
        .saveFollowUpConfig({
          params: { showCode: CurrentEvent.ShowCode },
          data: self.config
        }).then(function (data) {
          self.baseConfig = angular.copy(data);
          d.resolve();
        });

      return d.promise;
    };

    self.getPreview = function () {
      if (!self.form.$valid) {
        self.form.$setSubmitted();
        return false;
      }

      var templateHtml = angular.copy(self.baseTemplate.BodyHtml);
      var iframe = document.getElementById('TemplatePreview');

      console.log(templateHtml);

      templateHtml = templateHtml.replace(new RegExp('%%DataSalesExhibitorBody%%', 'g'), self.config.MessageText);
      templateHtml = templateHtml.replace(new RegExp('%%SignatureCompany%%', 'g'), self.config.FromCompany);
      templateHtml = templateHtml.replace(new RegExp('%%SignatureAddress%%', 'g'), self.config.FromAddress);
      templateHtml = templateHtml.replace(new RegExp('%%SignatureAddrCSZ%%', 'g'), self.config.FromCSZ);
      templateHtml = templateHtml.replace(new RegExp('%%SignatureTitle%%', 'g'), self.config.FromTitle);
      templateHtml = templateHtml.replace(new RegExp('%%SignaturePhone%%', 'g'), self.config.FromPhone);
      templateHtml = templateHtml.replace(new RegExp('%%SignatureEmail%%', 'g'), self.config.FromEmail);
      templateHtml = templateHtml.replace(new RegExp('%%SignatureWebSite%%', 'g'), self.config.FromWebsite);

      if (iframe) {
        iframe.contentWindow.document.body.innerHTML = '';
        iframe.contentWindow.document.write(templateHtml);
        self.showPreview = true;
      }
    };
  };

  angular.module('exhibitorPortalApp')
    .controller('LeadFollowUpCtrl', LeadFollowUpCtrl);
})(angular, window);
