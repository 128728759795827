'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:AccountCtrl
 * @description
 * # AccountCtrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var AccountCtrl = function ($q, $state, ActiveUserProfile, UserService, ShowService, OrderService) {
    'ngInject'
    var self = this;

    self.stateName = $state.$current.name;
    self.userProfile = angular.copy(ActiveUserProfile);
    self.verifyEmail = self.userProfile.Email;
    self.oldPassword = '';
    self.newPassword = '';
    self.confirmNewPassword = '';
    self.waiting = false;
    self.hideOrders = ActiveUserProfile.SWAPOnly
    self.orders = null;
    self.orderHistory = {
      data: [],
      chapterSize: 5,
      pageSize: 5,
      currentChapter: 0,
      currentPage: 0
    };
    ShowService.getShows()
      .then(function () {
        self.shows = ShowService.data.shows;
      });

    self.selectedShow = null;

    self.changePassword = function () {
      self.waiting = true;

      return UserService.changePassword({
        data: {
          oldPassword: self.oldPassword,
          newPassword: self.newPassword,
          confirmNewPassword: self.confirmNewPassword
        }
      })
        .then(function () {
          self.oldPassword = '';
          self.newPassword = '';
          self.confirmNewPassword = '';
          self.waiting = false;
        }, function (error) {
          self.waiting = false;
          return $q.reject(error);
        });
    };

    self.validatePassword = function (password) {
      if (password.includes('/') || password.includes('\\'))
        return false;
      else
        return true;
    };

    self.save = function () {
      return UserService.save({
        data: {
          user: self.userProfile
        }
      })
        .then(function () {
          angular.extend(ActiveUserProfile, self.userProfile);
        });
    };

    self.getOrderHistoryForShow = function () {
      if (!self.hideOrders) {
        if (self.selectedShow && self.selectedShow.ShowCode) {
          self.orders = [];
          self.retrievingOrders = true;
          
          OrderService.getOrders({ showCode: self.selectedShow.ShowCode }).then(function () {
            self.orders = OrderService.data.orders[self.selectedShow.ShowCode];
            self.retrievingOrders = false;
          });

          self.getConfirmationHtmlForOrder = function (salesOrderId) {
            OrderService.getConfirmationHtml({
              showCode: self.selectedShow.ShowCode,
              salesOrderId: salesOrderId
            }, true)
              .then(function () {
                var confirmationHtml = OrderService.data.confirmations[self.selectedShow.ShowCode][salesOrderId];

                if (confirmationHtml)
                  angular.element(window.open().document.body).html(confirmationHtml);
              });
          };
        } else {
          self.orders = [];
        }
      }
    }

    
    

    
    
  };

  angular.module('exhibitorPortalApp')
    .controller('AccountCtrl', AccountCtrl);
})(angular, window);
