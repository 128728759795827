'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:filterOption
 * @description
 * # Filter Option
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epFilterOption($timeout) {
    'ngInject'
		return {
			restrict: 'A',
			scope: {
				option: '=epFilterOption',
				optionKey: '=?epFilterOptionKey',
				optionValue: '=?epFilterOptionValue',
				responseKey: '=?epFilterOptionResponseKey',
				predicate: '=?epFilterOptionPredicate',
				change: '=?epFilterOptionChange',
        defaultValue: '=?epFilterOptionDefaultValue',
        changeEventObject: '=?epFilterOptionChangeEventObject'
			},
			require: '^epFilterGroup',
      link: function (scope, element, attrs, filterGroupCtrl) {
        scope.ignoreGTM = false;

				function init(reset) {
					var response = filterGroupCtrl.getResponse(scope.option, scope.optionValue);

					if((reset && scope.defaultValue === undefined) || response) {
						element[0].value = reset ? '' : response;

						//special case for when element is a checkbox, we need to also set its checked state
						if('checked' in element[0])
							element[0].checked = !!response;

						if(reset)
							element.triggerHandler('change', true);
					}
					else if(scope.defaultValue !== undefined) {
						element[0].value = scope.defaultValue;

						if('checked' in element[0])
              element[0].checked = scope.defaultValue;

            scope.ignoreGTM = true;
            element.triggerHandler('change', true);
            scope.ignoreGTM = false;
					}
				}

        function change(e) {

          if (scope.change) {
            scope.change(e, change.oldValue, e.target.value, scope.clearing);
          }

					filterGroupCtrl.changeFilter(
						scope.option,
						scope.optionKey,
						scope.optionValue,
						scope.responseKey,
						e.target.value,
						scope.predicate,
						scope.defaultValue
					);

          if (!scope.ignoreGTM && scope.changeEventObject && change.oldValue !== e.target.value) {
            if (typeof dataLayer !== 'undefined')
              dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Click', 'eventAction': 'Filtering By' + scope.changeEventObject.Group, 'eventLabel': scope.changeEventObject.Label });
          }

          change.oldValue = e.target.value;
				}

				//timeout so that any angular templating can finish rendering before setting the elements value
				//ex. a select's options are generated by ng-repeat
				$timeout(init);

        scope.$on('clear', function () {
          scope.ignoreGTM = true;
          init(true);
          scope.ignoreGTM = false;
				});

				element[0].onchange = function (e, internal){
					if(!internal)
						scope.$apply(function () {
							change(e);
						});
					else
						change(e);
				};
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epFilterOption', epFilterOption);
})(angular, window);
