'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:pagerStatus
 * @description
 * # Pager Status
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epPagerStatus() {
    'ngInject'
		return {
			restrict: 'A',
			scope: {
			    config: '=epPagerStatus',
			},
			template: '{{ getResultString() }}',
			link: function (scope) {
				scope.getResultString = function() {
	                return !scope.config.data.length ?
	                    'No results found!' :
	                     scope.config.data.length ?
	                        ((scope.config.data.length > scope.config.pageSize ?
	                            ((scope.config.currentPage * scope.config.pageSize + 1) + ' - ' + (((1 + scope.config.currentPage) * scope.config.pageSize) -
	                                (((1 + scope.config.currentPage) * scope.config.pageSize) % scope.config.data.length) > scope.config.pageSize ?
	                                (((1 + scope.config.currentPage) * scope.config.pageSize) - ((1 + scope.config.currentPage) * scope.config.pageSize) % scope.config.data.length) :
	                                ((1 + scope.config.currentPage) * scope.config.pageSize)) + ' of ') : '') +
									scope.config.data.length + ' result' + (scope.config.data.length === 1 ? '' : 's') + '.')
							: 'No results found!';
	            };
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epPagerStatus', epPagerStatus);
})(angular, window);
