'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:FunnelChart
 * @description
 * # Funnel Chart
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epFunnelChart() {
    'ngInject'
        return {
            restrict: 'A',
            scope: {
                data: '=epFunnelChart',
                options: '=epFunnelChartOptions',
            },
            link: function (scope, element) {
                var options = scope.options;

                scope.$watch('data', function(newValue) {
					update(newValue);
				});

                function update(data) {
                    if(data !== undefined && data.length) {
                        var chart = new D3Funnel(element[0]);
                        chart.draw(data, options);
                    }
                }
            }
        };
    }

    angular.module('exhibitorPortalApp')
		.directive('epFunnelChart', epFunnelChart);
})(angular, window);
