'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:CheckoutOnlyCtrl
 * @description
 * # CheckoutOnlyCtrl
 * Controller of the exhibitorPortalApp
 */

(function(angular, window, undefined) {
  var CheckoutOnlyCtrl = function ($q, $state, $anchorScroll, $rootScope, OrderService, pendingOrder, Checkout, AllAvailableProducts) {
    'ngInject'
    var self = this;

    if (!pendingOrder) {
      $state.go('event.shop.cart');
    }
    else if (pendingOrder && pendingOrder.OrderStatus !== 'BalanceDue') {
      $state.go('event.shop.confirmation', { salesOrderId: pendingOrder.PrimaryID, isPayOnly: 1 });
    }
    else {
      $rootScope.showActionWidget = true;
    }

    self.doesOrderHaveEventBit = false;

    //add the product image to the transaction
    for (var i = 0; i < pendingOrder.OrderPurchases.length; i++) {
      var product = _.find(AllAvailableProducts, function (p) { return p.Id === pendingOrder.OrderPurchases[i].ProductID; });
      if (product) {
        pendingOrder.OrderPurchases[i]["ProductImage"] = product.ProductImage;
        if (product.ProductCode == 1510) {
          self.doesOrderHaveEventBit = true;
        } else if (product.PackageItemIDList) {
          const productIDList = product.PackageItemIDList.split(',').map(x => parseInt(x));
          const products = AllAvailableProducts.filter(p => _.some(productIDList, id => id == p.Id));
          self.doesOrderHaveEventBit = self.doesOrderHaveEventBit || _.some(products, p => p.ProductCode == 1510);
        };
      }
    }
    Checkout.doesOrderHaveEventBit = self.doesOrderHaveEventBit;

    $anchorScroll();

    self.pendingOrder = pendingOrder;

    self.processOrder = function () {
      var d = $q.defer();

      if (!self.agreeToTerms) {
        window.alert('You must agree to the Terms and Conditions before proceeding.');
        return $q.when(undefined);
      }

      if (Checkout.doesOrderHaveEventBit && !self.ebitAcknowledgement) {
        window.alert('You must acknowledge eventBit requirements.')
        return $q.when(undefined);
      }
      if (self.processingOrder != true) {
        self.processingOrder = true;
        OrderService.processPendingOrder({
          data: pendingOrder.PrimaryID
        })
          .then(function (data) {
            if (data.toLowerCase().indexOf('http') > -1) {
              window.location = data;
            }
            else if (angular.isNumber(data)) {
              $state.go('event.shop.confirmation', { salesOrderId: data });
            }
            else {
              self.processingOrder = false;
              window.alert('There was an error processing your order.');
            }
          })
          .finally(function () {
            d.resolve();
          });
      }
      return d.promise;
    };

	};

	angular.module('exhibitorPortalApp')
	  .controller('CheckoutOnlyCtrl', CheckoutOnlyCtrl);
})(angular, window);
