'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalLoginApp.directive:loginErrors
 * @description
 * # Login Errors
 * Directive of the exhibitorPortalLoginApp
 */
(function (angular, window, undefined) {
  function loginErrors($window, $rootScope, $location, LoginService, SHOW) {
    'ngInject'
    return {
      restrict: 'E',
      scope: {
        errors: '='
      },
      link: function (scope, element, attrs) {
        var self = scope.logErrors;

        scope.$watch('logErrors.errors', function (newval, oldval) {
          if (newval && newval.length > 0 && window.dataLayer) {
              window.dataLayer.push({
                event: 'loginError',
                attributes: {
                  route: $location.url(),
                  pageTitle: $rootScope.pageTitle,
                  errorMessage: newval[0]
                }
              });
          }
        });
      },
      templateUrl: (window.location.hostname === 'localhost' ? '/app/' : '') + 'views/Login/loginErrors.html',
      controllerAs: 'logErrors',
      controller: function () {

      },
      bindToController: true
    };
  }

  angular.module('exhibitorPortalLoginApp')
    .directive('loginErrors', loginErrors);
})(angular, window);
