'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.Search
 * @description
 * # Search
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  angular.module('exhibitorPortalApp')
    .constant('SearchBase', {
      events: {
        data: [],
        filters: {},
        filtering: 0,
        chapterSize: 5,
        pageSize: 10,
        currentChapter: 0,
        currentPage: 0
      },
      leads: {
        data: [],
        filters: {},
        filtering: 0,
        chapterSize: 5,
        pageSize: 40,
        currentChapter: 0,
        currentPage: 0,
        sort: 'LeadDate',
        sortDirection: true,
        sortInverse: true,
        displayMode: 'block'
      },
      surveys: {
        data: [],
        filters: {},
        filtering: false,
        searchText: '',
        chapterSize: 5,
        pageSize: 10,
        currentChapter: 0,
        currentPage: 0,
        sort: 'DisplayOrder',
        edit: false,
        editDefault: false
      },
      survey: {
        data: [],
        filters: {},
        filtering: false,
        searchText: '',
        chapterSize: 5,
        pageSize: 10,
        currentChapter: 0,
        currentPage: 0,
        sort: 'DisplayOrder',
        edit: false
      },
      surveyQuestion: {
        data: [],
        filters: {},
        filtering: false,
        searchText: '',
        chapterSize: 5,
        pageSize: 10,
        currentChapter: 0,
        currentPage: 0,
        sort: 'DisplayOrder',
        edit: false
      },
      resources: {
        data: [],
        filters: {},
        filtering: false,
        searchText: '',
        chapterSize: 5,
        pageSize: 10,
        currentChapter: 0,
        currentPage: 0,
        sort: 'DisplayOrder'
      },
      dataSales: {
        filters: {

        },
        expressions: [
          {
            include: 'true',
            nodes: [
              {
                operands: [
                  {
                    operator: 'oneOf',
                    values: []
                  }
                ]
              }
            ]
          }
        ],
        email: {
          subject: '',
          from: ''
        },
        pageSize: 10
      }
    });
})(angular, window);
