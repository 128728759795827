'use strict';

  angular
    .module('exhibitorPortalApp')
    .directive('exlTemplate', exlTemplate);

  exlTemplate.$inject = ['$compile'];

  function exlTemplate($compile) {
    // Usage:
    //     <exl-template></exl-template>
    // Creates:
    // 
    var directive = {
      link: link,
      restrict: 'E',
      scope: {
        content: '@'
      }
    };
    return directive;

    function link(scope, element, attrs) {
      $compile(element.html(scope.content).contents())(scope.$parent);
    }
  }

