'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:SurveyCopyCtrl
 * @description
 * # Survey Copy Ctrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var SurveyCopyCtrl = function ($state, CurrentEvent, HistoricalSurveys, Search, SurveyService) {
    'ngInject'
    var self = this;

    self.currentEvent = CurrentEvent;
    self.surveys = HistoricalSurveys; //.filter(function (survey) { return survey.ShowCode !== CurrentEvent.ShowCode; });

    self.select = function (survey) {
      if (survey.selected)
        survey.selected = false;
      else
        survey.selected = true;
    };

    self.copySurvey = function (showcode) {

      var selectedSurveys = self.surveys.filter(function (survey) { return survey.selected; });

      var surveyData = selectedSurveys.reduce(function (acc, obj) {
        var showCode = obj.ShowCode;
        (acc[showCode] = acc[showCode] || []).push(obj.SurveyID);
        return acc;
      }, {});

      SurveyService.copySurvey({
        data: {
          copySurveys: surveyData,
          sourceShowCode: showcode,
          destinationShowCode: CurrentEvent.ShowCode
        }
      }).then(function (data) {
        $state.go('event.surveys.surveys');
      });
    };
  };

  angular.module('exhibitorPortalApp')
    .controller('SurveyCopyCtrl', SurveyCopyCtrl);
})(angular, window);
