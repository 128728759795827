'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.BusinessCardLead
 * @description
 * # Business Card Lead
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var BusinessCardLeadService = function ($q, $timeout, $filter, ApiService) {
    'ngInject'
    var self = this;

    self.data = {};

    self.getBusinessCardLead = ApiService.endpoint.get({
      url: 'api/businesscardlead/',
      memoization: false,
      success: function (data, params) {
        self.data.businessCardLeads = self.data.businessCardLeads || {};
        self.data.businessCardLeads[params.showCode] = self.data.businessCardLeads[params.showCode] || [];

        var businessCard = extendBusinessCardLeads([data])[0];
        if (self.data.businessCardLeads[params.showCode].length == 0) {
          self.data.businessCardLeads[params.showCode].push(businessCard);
          return businessCard;
        }

        for (var i = 0; i < self.data.businessCardLeads[params.showCode].length; i++) {
          if (self.data.businessCardLeads[params.showCode][i].ImageKey == businessCard.ImageKey) {
            self.data.businessCardLeads[params.showCode][i] = businessCard;
            return businessCard;
          }
        }
      }
    });

    self.getBusinessCardLeads = ApiService.endpoint.get({
      url: 'api/businesscardlead/getList',
      memoization: false,
      success: function (data, params) {
        self.data.businessCardLeads = self.data.businessCardLeads || {};
        self.data.businessCardLeads[params.showCode] = extendBusinessCardLeads(data.People);
      }
    });

    self.saveBusinessCardLeads = ApiService.endpoint.post({
      url: 'api/businesscardlead/',
      success: function (data, params) {
        self.data.businessCardLeads = self.data.businessCardLeads || {};
        self.data.businessCardLeads[params.showCode] = extendBusinessCardLeads(self.data.businessCardLeads[params.showCode]) || [];

        for (var i = 0; i < self.data.businessCardLeads[params.showCode].length; i++) {
          for (var a = 0; a < data.UpdatedPeople.length; a++) {
            if (self.data.businessCardLeads[params.showCode][i].ImageKey == data.UpdatedPeople[a].ImageKey) {
              self.data.businessCardLeads[params.showCode][i] = extendBusinessCardLeads([data.UpdatedPeople[a]])[0];
            }
          }
        }
      }
    });

    self.deleteBusinessCardLead = ApiService.endpoint.post({
      url: 'api/businesscardlead/deletebusinesscardlead',
      success: function (data, params) {
        self.data.businessCardLeads = self.data.businessCardLeads || {};
        self.data.businessCardLeads[params.showCode] = extendBusinessCardLeads(self.data.businessCardLeads[params.showCode]) || [];

        if (data) {
          self.data.businessCardLeads[params.showCode] = self.data.businessCardLeads[params.showCode].filter(function (bcl) {
            return bcl.ImageKey != data;
          });
        }
      }
    });

    self.getBusinessCardLeadDates = function (showcode) {
      var d = $q.defer();

      self.data.leadDates = self.data.leadDates || {};

      if (self.data.leadDates[showcode])
        $timeout(function () {
          d.resolve();
        });
      else {
        var leadDates = {},
          toReturn = [];

        self.getBusinessCardLeads({ showCode: showcode }).then(function () {
          for (var i = 0; i < self.data.businessCardLeads[showcode].length; i++) {
            var shortDate = $filter('date')(self.data.businessCardLeads[showcode][i].LeadDate, 'shortDate', self.data.businessCardLeads[showcode][i].TimeZoneOffset);

            //save the shortDate on the lead object so that we don't have to recalc it when filtering
            self.data.businessCardLeads[showcode][i].shortLeadDate = shortDate;

            if (!leadDates[shortDate]) {
              leadDates[shortDate] = 1;

              toReturn.push(shortDate);
            }
          }


          self.data.leadDates[showcode] = toReturn;
          d.resolve();
        });
      }

      return d.promise;
    };

    function extendBusinessCardLeads(cards) {
      if (!cards)
        return cards;

      return cards.map(function (c) {
        return angular.extend({}, c, {
          Id: null,
          Contact: contactFromBusinessCard(c),
          SurveyAnswers: [],
          LeadDate: convertDate(c.CaptureDate),
          Collateral: [],
          CollateralFormatted: '',
          Spotted: 0,
          MoreCaptureTimes: '',
          LeadType: 'BUSINESS CARD'
        });
      });
    }

    function contactFromBusinessCard(bcl) {
      return {
        Id: null,
        Company: bcl.Company,
        FirstName: bcl.FirstName,
        LastName: bcl.LastName,
        Address: contactAddress(bcl),
        Email: bcl.Email,
        Phone: bcl.Phone,
        Title: bcl.Title,
        RegistrantId: null,
        ContactTypeCode: 'UNK',
        DemographicAnswers: []
      };
    }

    function contactAddress(bcl) {
      return {
        Address: bcl.Address,
        Address2: '',
        Address3: '',
        City: '',
        CountryCode: '',
        Id: 0,
        StateCode: '',
        ZipCode: ''
      };
     }

    function convertDate(timestamp) {
      if (!timestamp)
        return '';
      var d = new Date(0);
      d.setUTCMilliseconds(timestamp);
      return d.toISOString();
    }
  };

  angular.module('exhibitorPortalApp')
    .service('BusinessCardLeadService', BusinessCardLeadService);
})(angular, window);
