'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:pager
 * @description
 * # Pager
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epPager() {
    'ngInject'
		return {
			restrict: 'A',
			scope: {
				config: '=epPager',
				scrollTop: '=?epPagerScrollTop'
			},
			templateUrl: (window.location.hostname === 'localhost' ? '/app/' : '') + 'views/shared/pager.html',
			link: function (scope) {
				scope.$watch('config.data.length', function(newValue, oldValue) {
					if(scope.config.currentPage > 0 && newValue != oldValue)
						scope.config.currentPage = scope.config.currentChapter = 0;
				});

				scope.$watch('config.searchText', function(newValue, oldValue) {
					if(scope.config.currentPage > 0 && newValue != oldValue){
						scope.config.currentPage = 0;
						scope.config.currentChapter = 0;
					}
				});

				scope.setCurrentPage = function(page) {
					if(page >= 0 && page < scope.numberOfPages()){
						if(page > scope.config.currentPage && page === ((scope.config.currentChapter * scope.config.chapterSize) + scope.config.chapterSize))
							scope.config.currentChapter++;
						else if(page < scope.config.currentPage && page === ((scope.config.currentChapter * scope.config.chapterSize) - 1))
							scope.config.currentChapter--;

						scope.config.currentPage = page;
					}

					if(scope.scrollTop)
						angular.element(document).scrollTop(0);
				};

				scope.setCurrentChapter = function(chapter) {
					if(chapter >= 0 && chapter < scope.numberOfChapters()){
						if(scope.config.currentChapter < chapter)
							if((scope.config.currentPage + scope.config.chapterSize) > scope.numberOfPages())
								scope.config.currentPage = scope.numberOfPages() - 1;
							else
								scope.config.currentPage = scope.config.currentPage + scope.config.chapterSize;
						else
							scope.config.currentPage = scope.config.currentPage - scope.config.chapterSize;

						scope.config.currentChapter = chapter;

						if(scope.scrollTop)
							angular.element(document).scrollTop(0);
					}
				};

				scope.numberOfPages = function() {
					var n = Math.ceil(scope.config.data.length / scope.config.pageSize);
					return n;
				};

				scope.numberOfChapters = function () {
					var n = Math.ceil(scope.config.data.length / scope.config.pageSize / scope.config.chapterSize);
					return n;
				};

				scope.numberOfChapterPages = function (chapter) {
					var n = scope.numberOfPages() - ((chapter || scope.config.currentChapter) * scope.config.chapterSize);
					return n >= scope.config.chapterSize ? scope.config.chapterSize : n;
				};

				scope.getNumberAsArray = function(num) {
					return new Array(num);
				};
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epPager', epPager);
})(angular, window);
