'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:filterClear
 * @description
 * # Filter Clear
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epFilterClear() {
    'ngInject'
		return {
			restrict: 'AE',
			require: '^epFilter',
      link: function (scope, element, attrs, FilterCtrl) {
				element[0].onclick = function (e) {
					scope.$apply(function () {
						for(var f in FilterCtrl.activeFilters)
							for(var o in FilterCtrl.activeFilters[f])
								delete FilterCtrl.activeFilters[f][o];

						scope.$broadcast('clear');
					});

					e.stopPropagation();
				};
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epFilterClear', epFilterClear);
})(angular, window);
