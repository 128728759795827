'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.filter:excludeShow
 * @description
 * # ExcludeShow
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function excludeShow() {
    'ngInject'
    return function (input, showcode) {
      var results = [];
      if (input) {

        for (var i = 0; i < input.length; i++) {
          if (input[i].ShowCode !== showcode)
            results.push(input[i]);
        }
      }
      return results;
    };
  }

  angular.module('exhibitorPortalApp')
    .filter('excludeShow', excludeShow);
})(angular, window);
