'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:LeadDetailEditSurveyCtrl
 * @description
 * # Lead Detail Edit Survey
 * Controller of the exhibitorPortalApp
 */

(function(angular, window, undefined) {
  var LeadDetailEditSurveyCtrl = function ($q, $timeout, $rootScope, CurrentEvent, Lead, Survey, LeadService, SurveyService) {
    'ngInject'
		var self = this;

		self.lead = Lead;
      self.survey = Survey;

		self.answers = {};

      $rootScope.$on('leadSurvey:change', function (e, l) {
        if (l && self.lead.Id == l.Id) {
          self.lead = l;

          buildAnswers();
        }
      });

      function buildAnswers() {
        for (var i = 0; i < Survey.SurveyQuestions.length; i++) {
          var exists = false;
          self.answers[Survey.SurveyQuestions[i].Id] = {};

          for (var a = 0; a < self.lead.SurveyAnswers.length; a++) {
            if (self.lead.SurveyAnswers[a].ParentId == Survey.SurveyQuestions[i].Id) {
              self.answers[Survey.SurveyQuestions[i].Id][self.lead.SurveyAnswers[a].Id] = self.lead.SurveyAnswers[a].Response;
              exists = true;
            }
          }

          if (!exists && Survey.SurveyQuestions[i].SurveyQuestionTypeCode == 'OPN')
            self.answers[Survey.SurveyQuestions[i].Id][Survey.SurveyQuestions[i].SurveyAnswers[0].Id] = '';
        }

        // grab the first question based on display order. This will be used to find the list of questions that
        // the lead was actually presented with
        var firstQuestion = SurveyService.getFirstQuestion(self.survey.SurveyQuestions);
        self.firstQuestionId = firstQuestion ? firstQuestion.Id : null;

        var answered = Object.keys(self.answers).filter(function (a) { return Object.keys(self.answers[a]).length > 0; }).map(function (k) {
          return Number(k);
        });

        // build a list of questions based off of the next questionid that is configured
        var seenQuestions = SurveyService.leadSeenQuestions(self.answers, self.survey.SurveyQuestions);
        // which questions have responses of this list
        if (_.any(seenQuestions, function (q) {
          return _.any(answered, function (answer) {
            return q.Id === answer;
          });
        })) {
          self.questionsToShow = seenQuestions;
        } else {
          self.questionsToShow = [];
        }
      }

      buildAnswers();

      // build a composite response for questions with multple responses
      self.getQuestionAnswerComposite = function (question) {
        if (!question)
          return 'No Response';

        var answers = self.answers[question.Id];
        if (!answers)
          return 'No Response';

        var allAnswers = Object.keys(answers).map(function (a) { return self.answers[question.Id][a]; });
        if (!allAnswers || allAnswers.length == 0)
          return 'No Response';

        return allAnswers.join(',');
      };
	};

	angular.module('exhibitorPortalApp')
	  .controller('LeadDetailEditSurveyCtrl', LeadDetailEditSurveyCtrl);
})(angular, window);
