'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.Api
 * @description
 * # Api
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  /**
   * @param {angular.IHttpService} $http
   * @param {angular.IQService} $q
   * @param {angular.ITimeoutService} $timeout
   * @param {angular.IWindowService} $window
   * @param {angular.ILogService} $log
   * @param {Record<string, string>} EnvironmentConfig
   */
  var ApiService = function ($http, $q, $timeout, $window, $log, EnvironmentConfig) {
    'ngInject'
    this.endpoint = {
      //the main workhorse for GET requests; this wrapper provides concurrency management,
      //memoization, cancelation and error handling
      get: function (config) {
        //config defaults
        config = angular.extend({
          memoization: true
        }, config);

        return function get(params, force, options) {
          var d = $q.defer();
          var c = $q.defer();

          params = params || {};
          options = options || {};
          get.defer = get.defer || {};
          get.memo = get.memo || [];

          function memoized(params) {
            for (var i = 0; i < get.memo.length; i++)
              if (angular.equals(get.memo[i], params))
                return true;

            return false;
          }

          if (!force && config.memoization && memoized(params))
            $timeout(function () {
              d.resolve();
            });
          //prevent redundant requests by checking if a request with the same params is currently in progress
          else if (!get.defer || force || (get.defer && !angular.equals(get.defer.params, params))) {
            //if this request is forced, and there is a concurrent defered request with the same
            //params then cancel it and give this request priority; note that even a forced defered
            //request can be overridden in this way
            if (force && get.defer && angular.equals(get.defer.params, params))
              get.defer.cancel();

            get.defer = {
              params: params,
              force: force,
              cancel: function () {
                c.resolve('This request was canceled by another request that was forced!');
              },
              request: $http(angular.extend({
                method: 'GET',
                url: EnvironmentConfig.SiteRoot + config.url,
                params: params,
                timeout: c.promise
              }, options))
                .then(
                  (response) => {
                    if (config.memoization && !memoized(params))
                      get.memo.push(params);

                    if (config.success) {
                      try {
                        config.success(response.data, params);
                      }
                      catch (e) {
                        $timeout(function () { $window.alert(e.message); });
                        d.reject(e);
                        throw e;
                      }
                    }

                    d.resolve({
                      data: response.data,
                      status: response.status,
                      headers: response.headers,
                      config: response.config
                    });
                  },
                  /**
                   * @param {angular.IHttpPromiseCallbackArg<any>} response Error response
                   */
                  (response) => {
                    //if an error handler has been provided then use that
                    //note: the erorr handler will be responsible for rejecting if that is desired
                    if (config.error)
                      config.error(d, response.data, response.status, response.headers, response.config);
                    //default handling of internal exceptions is to alert the user, api exception messages
                    //should be well formatted
                    else if (response.status === 500 && response.data.ExceptionMessage) {
                      $log.log(response.data, response.status, response.headers, response.config);
                      $timeout(function () { $window.alert(response.data.ExceptionMessage); });
                    }

                    if (!config.error)
                      d.reject({
                        data: response.data,
                        status: response.status,
                        headers: response.headers,
                        config: response.config
                      });
                  }
                ).finally(function () {
                  get.defer = null;
                })
            };
          }
          //if a request with the same params is already in progress then piggy back off of it
          else if (get.defer)
            get.defer.request.then(function () {
              d.resolve();
            });

          return d.promise;
        };
      },
      post: function (endpointConfig) {
        return function post(config) {
          var d = $q.defer();
          var c = $q.defer();

          config = angular.extend({
            method: 'POST',
            timeout: c.promise
          }, endpointConfig, config);

          config.url = EnvironmentConfig.SiteRoot + config.url;

          post.defer = {
            cancel: function (msg) {
              c.resolve(msg);
              post.defer = null;
            },
            request: $http(config)
              .then(
                (response) => {
                  if (config.success) {
                    try {
                      config.success(response.data, config.data);
                    }
                    catch (e) {
                      $timeout(function () { $window.alert(e.message); });
                      d.reject(e);
                      throw e;
                    }
                  }

                  d.resolve(response.data);
                },
                /**
                 * @param {angular.IHttpPromiseCallbackArg<any>} response Error response
                 */
                (response) => {
                  //if an error handler has been provided then use that
                  //note: the erorr handler will be responsible for rejecting if that is desired
                  if (config.error)
                    config.error(d, response.data, response.status, response.headers, response.config);
                  //default handling of internal exceptions is to alert the user, api exception messages
                  //should be well formatted
                  else if (response.status === 500 && response.data.ExceptionMessage) {
                    $log.log(response.data, response.status, response.headers, response.config);
                    $timeout(function () { $window.alert(response.data.ExceptionMessage); });
                  }

                  if (!config.error)
                    d.reject({
                      data: response.data,
                      status: response.status,
                      headers: response.headers,
                      config: response.config
                    });
                }
              ).finally(function () {
                post.defer = null;
              })
          };

          return d.promise;
        };
      },
      del: function (config) {
        return function del(params, options) {
          var d = $q.defer();
          var c = $q.defer();

          params = params || {};
          options = options || {};

          del.defer = {
            params: params,
            cancel: function () {
              c.resolve('This request was canceled by another request that was forced!');
            },
            request: $http.delete(EnvironmentConfig.SiteRoot + config.url, angular.extend({}, params, options))
              .then(
                (response) => {
                  if (config.success) {
                    try {
                      config.success(response.data, params);
                    }
                    catch (e) {
                      $timeout(function () { $window.alert(e.message); });
                      d.reject(e);
                      throw e;
                    }
                  }

                  d.resolve({
                    data: response.data,
                    status: response.status,
                    headers: response.headers,
                    config: response.config
                  });
                },
                /**
                 * @param {angular.IHttpPromiseCallbackArg<any>} response Error response
                 */
                (response) => {
                  //if an error handler has been provided then use that
                  //note: the erorr handler will be responsible for rejecting if that is desired
                  if (config.error)
                    config.error(d, response.data, response.status, response.headers, response.config);
                  //default handling of internal exceptions is to alert the user, api exception messages
                  //should be well formatted
                  else if (response.status === 500 && response.data.ExceptionMessage) {
                    $log.log(response.data, response.status, response.headers, response.config);
                    $timeout(function () { $window.alert(response.data.ExceptionMessage); });
                  }

                  if (!config.error)
                    d.reject({
                      data: response.data,
                      status: response.status,
                      headers: response.headers,
                      config: response.config
                    });
                }
              ).finally(function () {
                del.defer = null;
              })
          };

          return d.promise;
        };
      },
    };
  };

  angular.module('sharedModule')
    .service('ApiService', ApiService);
})(angular, window);
