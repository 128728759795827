'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:ProductsCtrl
 * @description
 * # ProductsCtrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var ProductsCtrl = function ($state, $timeout, $q, $sce, $anchorScroll, CurrentEvent, Products, PurchasedProducts, RecommendedProducts, UpsellProducts, DataSalesProducts, AllAvailableProducts, Cart, ProductsService, DataSalesService, ngCartItem, Search, ActiveUserProfile, AdditionalInfo, modalFactory) {
    'ngInject'
    var self = this;

    self.isRecommendationEvent = window.RecommendationEvents.toString().split(',').includes(CurrentEvent.ShowCode);
    self.products = Products;
    self.upsellProducts = UpsellProducts;
    self.dataSalesProducts = DataSalesProducts;
    self.additionalInfo = AdditionalInfo;
    self.featuredProducts = Products.filter(p => p.IsFeatured);
    //push featured products down in the display order
    self.featuredProducts.forEach(p => p.DisplayOrder += 1000);
    self.currentEvent = CurrentEvent;
    self.pastProducts = PurchasedProducts;
    self.currentRecProductIds = RecommendedProducts;
    self.boothSizeLimit = 1000;
    self.boothList = AdditionalInfo.BoothList || [];
    self.totalRecPrice = 0;

    const numInputs = document.querySelectorAll('input[type=number]')

    numInputs.forEach(function (input) {
      input.addEventListener('change', function (e) {
        if (e.target.value == '') {
          e.target.value = 0
        }
      })
    })

    self.displayErrors = false;
    self.isRecommendationValid = false;//based on what is returned by ERE
    self.isProductHistoryEmpty = true;
    self.isRecommendationEbitRep = false;//if booth size is over a specific value (error will be returned by ERE in future)
    self.isBoothListEmpty = true;
    self.isBoothList = false;

    if (AdditionalInfo.BoothList.length > 1) {
      self.isBoothList = true;
    }
    if (self.pastProducts.length > 0) {
      self.isProductHistoryEmpty = false;
    }

    self.exhibitorId = ActiveUserProfile.ExhibitorId;
    if (AdditionalInfo.BoothList.length > 0) {
      self.boothId = AdditionalInfo.BoothList[0].showExhibitorBoothId;
      self.boothSize = AdditionalInfo.BoothList[0].boothSize;
      self.boothWorkers = Math.ceil(self.boothSize / 300);
      self.isBoothListEmpty = false;
    }
    else {
      self.boothId = 0;
      self.boothSize = 0;
      self.boothWorkers = 0;
    }

    if (self.currentRecProductIds.length > 0 && self.boothSize < self.boothSizeLimit) {
      self.isRecommendationValid = true;
    }

    self.quantities = {};
    self.added = {};
    self.items = {};
    self.prices = {};

    $anchorScroll();

    self.getThumbnail = function (image) {
      return { thumbnail: image };
    };

    const createGroups = () => {
      const packages = Products.filter(p => p.ProductTypeCode == 'PKG');
      packages.forEach(p => {
        //sort the ids so item order is consistent and we can group on it
        p.sortedPackageItemIDList = p.PackageItemIDList.split(',').map(x => x.trim()).sort().join(',');
      });
      //packages that have the same items can be bundled
      const groups = _.groupBy(packages, 'sortedPackageItemIDList');
      return groups;
    };
    const groups = createGroups();
    self.bundles = {};

    //any groups with length > 1 are bundles
    for (const prop in groups) {
      if (groups[prop].length > 1) {
        const lowestPricePkg = groups[prop].reduce((prev, curr) => {
          return prev.PackageTotalPrice < curr.PackageTotalPrice ? prev : curr;
        })
        //all packages in a bundle are like packages, so use the first package for image and desc
        const pkg = groups[prop][0];
        self.bundles[prop] = {
          startingPrice: lowestPricePkg.PackageTotalPrice,
          imageUrl: pkg.ProductImage,
          title: pkg.ProductDescription,
          description: pkg.HTMLDescription,
          items: groups[prop]
        };
      }
    }
    console.log(self.bundles);

    self.bundleOnChange = (e) => {
      if (e.selectedItemId) {
        e.selectedItem = e.items.find(p => p.Id == e.selectedItemId);
      } else {
        e.selectedItem = undefined;
      }
      console.log(e);
    };

    self.onBoothIdChange = (selectedBoothId) => {
      if (selectedBoothId) {
        var booth = self.boothList.find(p => p.showExhibitorBoothId == selectedBoothId);
        self.boothSize = booth.boothSize;
        self.boothWorkers = Math.ceil(self.boothSize / 300);
      } else {
        self.boothSize = 0;
        self.boothWorkers = 0;
      }
    };

    self.isBundleEmpty = () => {
      return _.isEmpty(self.bundles);
    };

    self.onClickShowProductDetail = function (product, showPrice = true) {
      //self.modalProductDetailID = product.Id;
      //self.modalProductDetailShow = true;

      var inputs = {
        product: product,
        quantities: self.quantities,
        added: self.added,
        prices: self.prices,
        onAdd: self.onAdd,
        setQuantity: self.setQuantity,
        title: product.ProductName,
      };

      var bodyTpl = '<product-detail product="modalCtrl.product" quantities="modalCtrl.quantities" added="modalCtrl.added" prices="modalCtrl.prices" on-add="modalCtrl.onAdd" set-quantity="modalCtrl.setQuantity"></product-detail>';
      if (!showPrice) {
        bodyTpl = '<product-detail product="modalCtrl.product" quantities="modalCtrl.quantities" added="modalCtrl.added" on-add="modalCtrl.onAdd" set-quantity="modalCtrl.setQuantity"></product-detail>';
      }
      var footerTpl = '<button class="button secondary" ng-click="modalCtrl.close(\'Cancel\')">Cancel</button> <button class="button dark" ng-click="modalCtrl.close(\'OK\')">Save</button>';
      modalFactory.showModal({
        bodyTemplate: bodyTpl,
        //footerTemplate: footerTpl,
        controller: 'ModalController',
        //modalcss: vm.modalcss,
        inputs: inputs
      })
        .then(function (result) {
          if (result.status == 'OK')
            angular.extend(vm.profile, profileCopy);
        });
    };

    self.onAdd = function (cartItem, element) {
      var product = AllAvailableProducts.filter(function (p) { return p.ProductPriceID == cartItem.getId(); })[0];
      var swapProduct = AllAvailableProducts.filter(function (p) { return p.ProductCode === 'SWAP'; });

      if ((product.MoreInfoTypeCode == 'CUSTOMPRINT' && !cartItem.length) ||
        (product.MoreInfoTypeCode == 'CUSTOMPRINT' && !cartItem[0].getData().note))
        $state.go('event.shop.moreInfo', { id: id });
      else if (product.ProductCode == '203') { //203 is the email blast product code

        // PDJ 2016.05.23: City State Zip was displaying as "undefined, undefined, undefined"
        // May be a cleaner way than this
        var AddrCSZ = "";
        if (ActiveUserProfile.CompanyAddress.City !== undefined) {
          AddrCSZ = ActiveUserProfile.CompanyAddress.City;
        }
        if (ActiveUserProfile.CompanyAddress.State !== undefined) {
          if (AddrCSZ != '') {
            AddrCSZ = AddrCSZ + ', ';
          }
          AddrCSZ = AddrCSZ + ActiveUserProfile.CompanyAddress.State;
        }
        if (ActiveUserProfile.CompanyAddress.PostalCode !== undefined) {
          if (AddrCSZ != '') {
            AddrCSZ = AddrCSZ + ' ';
          }
          AddrCSZ = AddrCSZ + ActiveUserProfile.CompanyAddress.PostalCode;
        }

        var data = {
          timestamp: new Date().getTime(),
          thumbnail: product.ProductImage,
          productCode: product.ProductCode,
          //moreInfoTypeCode: product.MoreInfoTypeCode,
          dataSalesTierTypeCode: product.DataSalesTierTypeCode,
          productPriceId: product.ProductPriceID,
          expressions: angular.copy(Search.dataSales.expressions),
          email: {
            SubjectLine: '',
            ScheduledExecutionDate: '',
            ScheduleExecutionTime: '',
            ShowCode: CurrentEvent.ShowCode,
            ShowName: CurrentEvent.TradeShowName,
            SignatureFullName: ActiveUserProfile.FirstName + ' ' + ActiveUserProfile.LastName,
            SignatureTitle: ActiveUserProfile.Title,
            SignatureCompany: ActiveUserProfile.Company,
            SignatureAddress: ActiveUserProfile.CompanyAddress.Address,
            SignatureAddress2: ActiveUserProfile.CompanyAddress.Address2,
            SignatureAddrCSZ: AddrCSZ,
            SignatureEmail: ActiveUserProfile.Email,
            SignaturePhone: ActiveUserProfile.Phone,
            SignatureFax: ActiveUserProfile.Fax,
            SignatureWebSite: '',
            DataSalesExhibitorImage: '',
            DataSalesExhibitorBody: ''
          },
          agreeToTerms: false,
          minAmount: product.DataSalesMinOrderAmount || 0,
          targetShowCode: product.DataSalesTargetShowCode
        };

        cartItem.setData(data);

        if (product.DataSalesTierTypeCode.startsWith('SET'))
          DataSalesService.finalize({
            data: {
              showCode: product.DataSalesTargetShowCode,
              dataSalesExecuteContext: { MediaTypeCode: 'EMAIL' }
            }
          }).then(function (results) {
            if (product.DataSalesTierTypeCode == 'SETPERNAME') {
              data.cachedCount = results.FinalCount;
            }
            data.dataSalesListId = results.DataSalesListID;
            Cart.$save();
            $state.go('event.shop.dataSales.emailBuilder', { timestamp: data.timestamp });
          });
        else {
          Cart.$save();
          $state.go('event.shop.dataSales.filterBuilder', { timestamp: data.timestamp });
        }
      }
      else if (['204', '205'].indexOf(product.ProductCode) > -1) {
        var data = {
          timestamp: new Date().getTime(),
          thumbnail: product.ProductImage,
          productCode: product.ProductCode,
          dataSalesTierTypeCode: product.DataSalesTierTypeCode,
          productPriceId: product.ProductPriceID,
          expressions: angular.copy(Search.dataSales.expressions),
          agreeToTerms: false,
          minAmount: product.DataSalesMinOrderAmount || 0,
          targetShowCode: product.DataSalesTargetShowCode
        };

        if (product.ProductCode == '205') {
          data.mailHouse = {
            SignatureCompany: '',
            SignatureAddress: '',
            SignatureAddress2: '',
            SignatureAddrCSZ: '',
            SignatureEmail: '',
            SignaturePhone: '',
            DataSalesAttachments: []
          };
        }

        cartItem.setData(data);

        if (product.DataSalesTierTypeCode.startsWith('SET'))
          DataSalesService.finalize({
            data: {
              showCode: product.DataSalesTargetShowCode,
              dataSalesExecuteContext: { MediaTypeCode: 'EMAIL' }
            }
          }).then(function (results) {
            if (product.DataSalesTierTypeCode == 'SETPERNAME') {
              data.cachedCount = results.FinalCount;
            }
            data.dataSalesListId = results.DataSalesListID;
            Cart.$save();

            if (product.ProductCode == '205')
              $state.go('event.shop.dataSales.mailHouse', { timestamp: data.timestamp });
            else
              $state.go('event.shop.dataSales.terms', { timestamp: data.timestamp });
          });
        else {
          Cart.$save();
          $state.go('event.shop.dataSales.filterBuilder', { timestamp: data.timestamp });
        }
      }
      else if (product.ProductCode == '205') {

      }
      else if (!window.localStorage.getItem(CurrentEvent.ShowCode + ':UPSELL:SWAP') && swapProduct.length && ['782', '325', '326'].indexOf(product.ProductCode) > -1) {
        if (window.confirm('That\'s a great choice, but did you know you could save money and have 3 points of lead collection by using SWAP, a mobile lead retrieval app? Would you like to change your order to SWAP?')) {
          Cart.removeItem(Cart.getCart().items.indexOf(cartItem), true);
          swapProduct = swapProduct[0];
          cartItem = Cart.addItem(swapProduct.ProductPriceID.toString(), swapProduct.ProductName, swapProduct.PackageTotalPrice, 1, swapProduct.discount, {
            thumbnail: swapProduct.ProductImage,
            productCode: swapProduct.ProductCode,
            promoCode: 'SWAPOUT'
          });
        }

        window.localStorage.setItem(CurrentEvent.ShowCode + ':UPSELL:SWAP', true);
      }

      self.added[cartItem.getId()] = true;
      angular.element(element).prop('disabled', true);

      $timeout(function () {
        self.setQuantity(cartItem.getId(), 1);
        self.added[cartItem.getId()] = false;
        self.setTotalRecPrice;
        angular.element(element).prop('disabled', false);
      }, 2000);
    };

    self.setTotalRecPrice = () => {
      var total = 0;
      self.currentRecProductIds.forEach(r => {
        var product = AllAvailableProducts.find(p => p.ProductPriceID == r.ProductPriceID);
        total = total + (product.quantity * product.PackageTotalPrice);
      });
      self.totalRecPrice = total;
    }

    self.getPrice = function (p, quantity) {
      if (p.ProductDiscountID > 0) {
        var existing = Cart.getItems().filter(function (x) { return x.getId() == p.ProductPriceID; });

        if (existing.length)
          self.items[p.ProductPriceID].setQuantity(quantity + existing[0].getQuantity());
        else
          self.items[p.ProductPriceID].setQuantity(quantity);

        self.items[p.ProductPriceID].getTotal().then(function (result) {
          if (existing.length)
            existing[0].getTotal().then(function (existingTotal) {
              self.prices[p.ProductPriceID] = result - existingTotal;
            });
          else
            self.prices[p.ProductPriceID] = result;
        });
      }
      else {
        self.prices[p.ProductPriceID] = p.PackageTotalPrice * quantity;
      }
    };

    self.setQuantity = function (id, quantity, relative) {
      quantity = parseInt(quantity);

      if (quantity % 1 === 0) {
        if (relative === true) {
          self.quantities[id] += quantity;
        } else {
          self.quantities[id] = quantity;
        }
        if (self.quantities[id] < 1) self.quantities[id] = 1;

        const product = AllAvailableProducts.find(p => p.ProductPriceID == id);
        product.quantity = self.quantities[id];
        const prod = self.currentRecProductIds.find(p => p.ProductPriceID == id);
        if (prod) {
          prod.quantity = self.quantities[id];
        }
      } else {
        self.quantities[id] = 1;
        console.info('Quantity must be an integer and was defaulted to 1');
      }


      self.updatePrice(id);
      self.setTotalRecPrice();
    };

    self.updatePrice = function (id) {
      self.getPrice(AllAvailableProducts.filter(function (p) {
        return p.ProductPriceID == id;
      })[0], self.quantities[id]);
    };

    self.newRecommendation = function () {
      var d = $q.defer();
      self.displayErrors = false;
      self.isRecommendationEbitRep = false;
      var tempExhibitorId = self.exhibitorId;
      var tempBoothId = self.boothId;
      if (CurrentEvent.ShowCode != 'CON231') {
        tempExhibitorId = 1001894;
        tempBoothId = 1520143;
      }
      //temporary work around
      if (self.boothSize >= self.boothSizeLimit) {
        self.isRecommendationEbitRep = true;
        self.displayErrors = true;
      }
      else if (self.boothSize == 0 || self.boothSize == undefined || self.boothWorkers == 0 || self.boothWorkers == undefined) {
        self.isRecommendationValid = false;
        self.displayErrors = true;
      }
      else {
        ProductsService
          .getShowExhibitorBoothRecommendation({ exhibitorId: tempExhibitorId, showExhibitorBoothID: tempBoothId, boothWorkers: self.boothWorkers, surveyBoothsize: self.boothSize, showCode: CurrentEvent.ShowCode })
          .then(function () {
            if (ProductsService.data.showExhibitorBoothRecommendation[tempExhibitorId][tempBoothId][self.boothWorkers][self.boothSize][CurrentEvent.ShowCode].length) {
              self.currentRecProductIds = ProductsService.data.showExhibitorBoothRecommendation[tempExhibitorId][tempBoothId][self.boothWorkers][self.boothSize][CurrentEvent.ShowCode];
              self.resetRec();
              self.isRecommendationValid = true;
            }
            else {
              self.currentRecProductIds = [];
              self.isRecommendationValid = false;
              self.displayErrors = true;
            }
            d.resolve();
          });
      }
    }


    self.next = function () {
      var d = $q.defer();

      if (Cart.totalItems()) {
        var productIds = Cart.getItems().map(function (e) {
          var product = AllAvailableProducts.filter(function (p) {
            return p.ProductPriceID == e._id;
          });

          if (product.length)
            return product[0].Id;
          else
            return 0;
        }).join(',');

        ProductsService
          .getUpsell({ showCode: CurrentEvent.ShowCode, productIds: productIds })
          .then(function () {
            if (ProductsService.data.upsell[CurrentEvent.ShowCode][productIds].length)
              $state.go('event.shop.upsell');
            else
              $state.go('event.shop.cart');

            d.resolve();
          });
      }
      else {
        $timeout(function () {
          window.alert('Please add at least one item to your cart before attempting to continue.');
          d.resolve();
        });
      }

      return d.promise;
    };

    self.trustAsHtml = function (html) {
      return $sce.trustAsHtml(html);
    };

    self.resetRec = function () {
      AllAvailableProducts.forEach(function (p) {
        let defaultQty = p.ProductMinQuantity ? p.ProductMinQuantity : 1;
        self.quantities[p.ProductPriceID] = defaultQty
        p.quantity = defaultQty;

        p.discount = {
          id: p.ProductDiscountID,
          limit: p.MaxAvailableQty,
          max: p.MaxDiscountsPerShow,
          min: p.MinQualifyingQty,
          price: p.DiscountedUnitPrice
        };

        self.items[p.ProductPriceID] = new ngCartItem(p.ProductPriceID, p.ProductName, p.PackageTotalPrice, defaultQty, p.discount, { thumbnail: p.ProductImage });
        self.getPrice(p, defaultQty);
      });
      
      if (self.currentRecProductIds.length != 0) {
        self.currentRecProductIds.forEach(r => {
          var product = AllAvailableProducts.find(p => p.ProductPriceID == r.ProductPriceID);
          let defaultQty = r.ProductRecQuantity ? r.ProductRecQuantity : 1;
          self.quantities[product.ProductPriceID] = defaultQty
          product.quantity = defaultQty;
          r.quantity = defaultQty;

          self.items[product.ProductPriceID] = new ngCartItem(product.ProductPriceID, product.ProductName, product.PackageTotalPrice, defaultQty, product.discount, { thumbnail: product.ProductImage });
          self.getPrice(product, defaultQty);
        })
      }
      self.setTotalRecPrice();
    }

    self.onAddAll = function (element) {
      self.currentRecProductIds.forEach(r => {
        var product = AllAvailableProducts.find(p => p.ProductPriceID == r.ProductPriceID);
        var cartItem = Cart.addItem(product.ProductPriceID, product.ProductName, product.PackageTotalPrice, product.quantity, product.discount, {
          thumbnail: product.ProductImage,
          productCode: product.ProductCode,
        });
        self.onAdd(cartItem, element);
      });
      $state.go('event.shop.products');
    }

    self.resetRec();
    self.setTotalRecPrice();
  };

  angular.module('exhibitorPortalApp')
    .controller('ProductsCtrl', ProductsCtrl);
})(angular, window);
