'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:LeadDetailEditSurveyQuestionCtrl
 * @description
 * # Lead Detail Edit Survey Question
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var LeadDetailEditSurveyQuestionCtrl = function ($q, $timeout, CurrentEvent, Lead, Survey, LeadService, SurveyQuestion, $state, SurveyService, $rootScope) {
    'ngInject'
    var self = this;

    self.lead = Lead;
    self.survey = Survey;
    self.surveyQuestion = SurveyQuestion;
    self.questionsAnswered = {};
    self.nextAction = {
      BranchAction: 'NATURAL',
      NextQuestionId: null
    };
    self.prevQuestion = null;
    self.errorModalInfo = {
      errorModalText: '',
      showSaveError: false,
      showErrorModalButtons: false,
      errorModalConfirmButtonText: '',
      onContinue: goToNextQuestion
    };

    self.branchedQuestionAlert = "Branching questions must have an answer to continue.";
    self.requiredQuestionAlert = "This required question must be answered before continuing.";
    self.requiredQuestionAlertDownstreamQuestions = "Downstream required questions are unanswered. They must be answered to leave the survey.";
    self.changeBranchedQuestionAlert = "This is a branched question. If you change your answer, the other answers in this branch will be removed. Are you sure you want to change it?";
    self.importantQuestionText = "This is an important question. It can be skipped, but is not advised.";
    self.importantQuestionAlert = "This is an important question. Are you sure you want to skip it?";

    self.answers = {};

    self.isRequired = self.surveyQuestion.Priority == 'REQUIRED';
    self.isImportant = self.surveyQuestion.Priority == 'IMPORTANT';

    self.uniqueBranchAnswers = self.surveyQuestion.SurveyQuestionTypeCode == 'SGL' && self.surveyQuestion.SurveyAnswers
      ? self.surveyQuestion.SurveyAnswers.map(function (sa) { return sa.NextQuestionId; }).filter(function (value, index, self) { return self.indexOf(value) === index; })
      : [];

    // deteremine if there is branching in this question
    self.hasBranching = self.uniqueBranchAnswers && self.uniqueBranchAnswers.length > 1;

    // if not single select, the next question id will always be the same as we only branch from single select questions
    if ((self.surveyQuestion.SurveyQuestionTypeCode != 'SGL' || !self.hasBranching) && self.surveyQuestion.SurveyAnswers.length) {
      self.nextAction.NextQuestionId = self.surveyQuestion.SurveyAnswers[0].NextQuestionId;
    }

    for (var i = 0; i < Survey.SurveyQuestions.length; i++) {
      var exists = false;
      self.answers[Survey.SurveyQuestions[i].Id] = {};
      self.questionsAnswered[Survey.SurveyQuestions[i].Id] = {};

      for (var a = 0; a < Lead.SurveyAnswers.length; a++) {
        if (Lead.SurveyAnswers[a].ParentId == Survey.SurveyQuestions[i].Id) {
          setAnswerValues(Survey.SurveyQuestions[i], Survey.SurveyQuestions[i].SurveyAnswers.filter(function (sa) { return sa.Id == Number(Lead.SurveyAnswers[a].Id); })[0], Lead.SurveyAnswers[a].Response, !!Lead.SurveyAnswers[a].Response);
          exists = true;
        }
      }

      if (!exists && Survey.SurveyQuestions[i].SurveyQuestionTypeCode == 'OPN') {
        setAnswerValues(Survey.SurveyQuestions[i], Survey.SurveyQuestions[i].SurveyAnswers[0], '', false);
      }
    }

    self.prevQuestion = SurveyService.getPreviousQuestion(self.answers, self.survey.SurveyQuestions, self.surveyQuestion.Id);

    // mimic a check box/radio button setting answers and the next question/branching action based off of the answer
    self.setAnswer = function (answer) {
      if (self.hasAnswer(answer.Id)) {
        deleteAnswers(null, answer.Id);

        if (self.hasBranching) {
          self.nextAction.NextQuestionId = null;
        }

        setNextButtonText();
      } else {
        if (self.surveyQuestion.SurveyQuestionTypeCode == 'SGL') {
          deleteAnswers(self.surveyQuestion.SurveyAnswers.filter(function (sa) { return sa.Id == answer.Id; }));
        }

        setAnswerValues(self.surveyQuestion, answer, answer.SurveyAnswerTypeCode == 'OPN' ? '' : answer.Text, true);
        setNextButtonText(answer.Id);
      }

      setModel();
      self.errorModalInfo.showSaveError = false;
      self.branchChanging = false;
      self.noLongerValidQuestions = null;
    };

    // get the current answers as a list of ids
    function getCurrentAnswerIds() {
      var answers = self.answers[self.surveyQuestion.Id];

      if (answers) {
        return Object.keys(answers).map(function (aid) { return Number(aid); });
      }

      return [];
    }

    // collect the list of starting answerIds in order to detect changes in the branching strategy
    self.startingAnswerIds = getCurrentAnswerIds();

    // do we have an answer already for this question
    self.hasAnswer = function (answerId) {
      var answers = self.answers[self.surveyQuestion.Id];

      if (answers) {
        return _.any(Object.keys(answers).filter(function (a) { return !answerId || Number(a) === answerId; }), function (ai) { return answers[ai] && answers[ai].length; });
      }
      return false;
    };

    function setNextButtonText(answerId) {
      if (self.surveyQuestion.SurveyQuestionTypeCode == 'SGL' && self.hasAnswer() && !answerId) {
        var currentAnswers = Object.keys(self.answers[self.surveyQuestion.Id]).map(function (a) { return Number(a); });
        if (currentAnswers && currentAnswers.length) {
          answerId = currentAnswers[0];
        }
      }

      var answers = self.surveyQuestion.SurveyAnswers.filter(function (sa) { return !answerId || sa.Id == answerId; });
      if (answers && answers.length && _.any(answers, function (a) { return a.NextQuestionId; }))
        self.NextButtonText = 'Next';
      else
        self.NextButtonText = 'Done';
    }

    setNextButtonText();

    // open answers are a bit different, the answer should be selected if the user types in the box
    self.changeOpenAnswer = function (answerId, newval, oldval) {
      if (oldval === '' && newval !== '' && newval.length > 0) {
        if (self.surveyQuestion.SurveyQuestionTypeCode == 'SGL') {
          deleteAnswers([answerId]);
        }
        setAnswerValues(self.surveyQuestion, self.surveyQuestion.SurveyAnswers.filter(function (sa) { return sa.Id == answerId; })[0], newval, true);
        setModel();
        self.branchChanging = false;
      }
    };

    self.goBack = function () {
      if (self.prevQuestion) {
        $state.go('event.leadDetail.editSurvey.editSurveyQuestion', { questionId: self.prevQuestion.Id });
      } else {
        $state.go('event.leadDetail.editSurvey', { surveyId: self.survey.Id });
      }
    };

    self.save = function (removeOldBranchQuestions) {
      setModel();

      if (!validateSurveyQuestion(removeOldBranchQuestions)) {
        return returnPromise();
      }

      if (!self.form.$valid) {
        return returnPromise();
      }

      //find all survey answer IDs valid for the current survey
      var validSurveyAnswerIds = self.survey.SurveyQuestions.reduce(function (accumulator, currentValue) {
        currentValue.SurveyAnswers.map(function (answer) { return answer.Id; }).forEach(function (answerId) {
          accumulator.push(answerId);
        });
        return accumulator;
      }, []);

      //Find all answers that are on the lead that do not belong to the current survey
      var otherSurveyAnswers = self.lead.SurveyAnswers.filter(function (answer) {
        return validSurveyAnswerIds.filter(function (validAnswer) { return answer.Id === validAnswer; }).length === 0;
      });

      self.lead.SurveyAnswers = otherSurveyAnswers;

      for (var questionId in self.answers)
        for (var answerId in self.answers[questionId])
          self.lead.SurveyAnswers.push({
            Id: Number(answerId),
            ParentId: Number(questionId),
            Response: self.answers[questionId][answerId],
            SurveyAnswerTypeCode: getAnswerType(questionId, answerId)
          });

      if (removeOldBranchQuestions && self.noLongerValidQuestions && self.noLongerValidQuestions.length) {
        self.lead.SurveyAnswers = self.lead.SurveyAnswers.filter(function (sa) { return !_.any(self.noLongerValidQuestions, function (nv) { return sa.ParentId == nv; }); });
      }

      return LeadService
        .saveLeads({
          params: { showCode: CurrentEvent.ShowCode },
          data: {
            showCode: CurrentEvent.ShowCode,
            leads: [self.lead]
          }
        })
        .then(function () {
          var lead = LeadService.data.leads[CurrentEvent.ShowCode].filter(function (l) { return l.Id == self.lead.Id; });

          if (lead && lead.length) {
            $rootScope.$broadcast('leadSurvey:change', lead[0]);
          }

          goToNextQuestion();
        });
    };

    function returnPromise() {
      var d = $q.defer();

      $timeout(function () {
        self.form.$setSubmitted();

        d.reject();
      });

      return d.promise;
    }

    function validateSurveyQuestion(skipBranchingChange) {
      if (!self.hasAnswer()) {
        if (self.isRequired || self.hasBranching) {
          self.errorModalInfo.showErrorModalButtons = false;
          self.errorModalInfo.errorModalText = self.isRequired ? self.requiredQuestionAlert : self.branchedQuestionAlert;
          self.errorModalInfo.showSaveError = true;
          return false;
        } else if (self.isImportant) {
          self.errorModalInfo.showErrorModalButtons = true;
          self.errorModalInfo.errorModalText = self.importantQuestionAlert;
          self.errorModalInfo.errorModalConfirmButtonText = 'Skip';
          self.errorModalInfo.onContinue = function () {
            goToNextQuestion();
          };
          self.errorModalInfo.showSaveError = true;
          return false;
        }
      } else {
        var currentAnswerIds = getCurrentAnswerIds();
        if (!skipBranchingChange && self.surveyQuestion.SurveyQuestionTypeCode == 'SGL' && currentAnswerIds.length && self.startingAnswerIds.length) {
          self.noLongerValidQuestions = SurveyService.getNoLongerValidQuestions(self.startingAnswerIds[0], self.survey.SurveyQuestions, self.surveyQuestion, currentAnswerIds[0]);
          if (self.noLongerValidQuestions && self.noLongerValidQuestions.length) {
            self.branchChanging = true;
            self.errorModalInfo.showErrorModalButtons = true;
            self.errorModalInfo.errorModalText = self.changeBranchedQuestionAlert;
            self.errorModalInfo.errorModalConfirmButtonText = 'Yes, continue';
            self.errorModalInfo.onContinue = function () {
              self.save(true);
            };
            self.errorModalInfo.showSaveError = true;
            return false;
          }
        }
      }

      return true;
    }

    function goToNextQuestion() {
      if (self.nextAction.BranchAction == 'ENDSURVEY' || (self.nextAction.BranchAction == 'NATURAL' && !self.nextAction.NextQuestionId)) {
        $state.go('event.leadDetail.editSurvey', { surveyId: self.survey.Id }, { reload: 'event.leadDetail.editSurvey' });
      } else {
        $state.go('event.leadDetail.editSurvey.editSurveyQuestion', { questionId: self.nextAction.NextQuestionId }, { reload: 'event.leadDetail.editSurvey.editSurveyQuestion' });
      }
    }

    function getAnswerType(questionId, answerId) {
      for (var i = 0; i < self.survey.SurveyQuestions.length; i++)
        if (self.survey.SurveyQuestions[i].Id == questionId)
          for (var a = 0; a < self.survey.SurveyQuestions[i].SurveyAnswers.length; a++)
            if (self.survey.SurveyQuestions[i].SurveyAnswers[a].Id == answerId)
              return self.survey.SurveyQuestions[i].SurveyAnswers[a].SurveyAnswerTypeCode;
    }

    function setAnswerValues(question, answer, value, answered) {
      self.answers[question.Id][answer.Id] = value;
      self.questionsAnswered[question.Id][answer.Id] = answered !== undefined ? answered : !!value;

      if (answer && question.Id == self.surveyQuestion.Id) {
        self.nextAction.BranchAction = answer.BranchAction == 'NATURAL' && !answer.NextQuestionId ? 'ENDSURVEY' : answer.BranchAction;
        self.nextAction.NextQuestionId = answer.NextQuestionId;
      }

      if (self.nextAction.BranchAction == 'ENDSURVEY') {
        self.nextAction.NextQuestionId = null;
      }
    }

    function deleteAnswers(excluded, onlyId) {
      for (var i = 0; i < self.surveyQuestion.SurveyAnswers.length; i++) {
        if ((!excluded || !_.any(excluded, function (a) { return self.surveyQuestion.SurveyAnswers[i].Id == a; }))
          && (!onlyId || onlyId == self.surveyQuestion.SurveyAnswers[i].Id)) {
          delete self.answers[self.surveyQuestion.Id][self.surveyQuestion.SurveyAnswers[i].Id];
          delete self.questionsAnswered[self.surveyQuestion.Id][self.surveyQuestion.SurveyAnswers[i].Id];
        }
      }
    }

    function setModel() {
      self.theModel = self.hasAnswer() ? 'answered' : '';
    }

    setModel();
  };

  angular.module('exhibitorPortalApp')
    .controller('LeadDetailEditSurveyQuestionCtrl', LeadDetailEditSurveyQuestionCtrl);
})(angular, window);
