'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.eventBit
 * @description
 * # eventBit
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var EventBitService = function (ApiService) {
    'ngInject'
        var self = this;

        self.data = {};

        self.getSSO = ApiService.endpoint.get({
            url: 'api/eventbit/sso',
            success: function (data, params) {
                self.data.sso = self.data.sso || {};
                self.data.sso[params.showCode] = data;
            }
        });

        self.isSPOT = ApiService.endpoint.get({
            url: 'api/eventbit/isSPOT',
            success: function (data, params) {
                self.data.isSPOT = self.data.isSPOT || {};
                self.data.isSPOT[params.showCode] = data;
            }
        });
    };

    angular.module('exhibitorPortalApp')
      .service('EventBitService', EventBitService);
})(angular, window);
