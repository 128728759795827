'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:DataSalesCtrl
 * @description
 * # Data Sales Ctrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var DataSalesCtrl = function ($window, $document, $scope, $state, $timeout, $anchorScroll, $rootScope, $q, $tour, DataSalesService, DataSalesExecuteContext, DataSalesProduct, Search, Filters, DataSalesConfig, DataSalesCartItem, Cart, TourHistory, CurrentEvent, ActiveUserProfile) {
    'ngInject'
		var self = this;

		//shouldn't be here
		if(DataSalesProduct.DataSalesTierTypeCode == 'SET'){
			$state.go('event.shop.dataSales.emailBuilder');
			return;
		}

		self.expressions = DataSalesCartItem.getData().expressions;
		self.dataSalesProduct = DataSalesProduct;
		self.filters = Filters;
		self.filterOptions = [];
		self.search = Search.dataSales;
		self.config = DataSalesConfig;
		self.tour = $tour;
		self.isDownload = DataSalesProduct.ProductCode == '204';
		self.isMailHouse = DataSalesProduct.ProductCode == '205';
		$anchorScroll();

        self.simple = !(function (expressions) {
            for(var i = 0; i < expressions.length; i++)
                for(var a = 0; a < expressions[i].nodes.length; a++)
                    if(expressions[i].nodes[a].operands.length > 1)
                        return true;
                    else if(expressions[i].nodes[a].operands[0].operator != 'oneOf')
                        return true;
        })(self.expressions);

		self.startTour = function() {
			if($window.confirm('Taking the tour will reset the current filter.  Do you still want to proceed?')) {
				self.expressions = [];
				self.expressions.push({
	                include: 'true',
	                nodes: [
	                    {
	                        operands: [
	                            {
	                            	operator: 'oneOf',
	                                values: []
	                            }
	                        ]
	                    }
	                ]
	            });
	            self.applied = false;
	            execCtx();
	            $tour.end();
	            $tour.start();
	            $anchorScroll();

              if (window.dataLayer) {
                window.dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Data Sales', 'eventAction': 'Tour Restart', 'eventLabel': CurrentEvent.ShowCode });
              }
			}
			// else {
			// 	$tour.end();
			// 	$tour.start();
			// 	$anchorScroll();
			// }
		};

    if (window.dataLayer) {
      $scope.$on('$stateChangeStart', function (fromState, toState) {
        if (!DataSalesCartItem.getData().dataSalesListId && toState.name != 'event.shop.dataSales.emailBuilder')
          window.dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Data Sales', 'eventAction': 'List Build Bounce', 'eventLabel': CurrentEvent.ShowCode });
      });
    }

		var tourNext = $tour.next;
		var tourEnd = $tour.end;

		$tour.end = function() {
      if (window.dataLayer) {
        if ($tour.current() < 11)
           window.dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Data Sales', 'eventAction': 'Tour Close', 'eventLabel': CurrentEvent.ShowCode });
         else
           window.dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Data Sales', 'eventAction': 'Tour Complete', 'eventLabel': CurrentEvent.ShowCode });
			}

			tourEnd();
		};

		$tour.next = function() {
			if(self.simple && $tour.current() === 2){
				$tour.current(4);
			}

			tourNext();
		};

		if(!TourHistory.dataSales){
			$tour.start();
			TourHistory.dataSales = true;
			TourHistory.save();
		}
		else
			$tour.end();

		for(var filterGroupKey in Filters)
			for(var filterDetailKey in Filters[filterGroupKey].FilterDetails)
				self.filterOptions.push({
					filterGroupName: Filters[filterGroupKey].Name,
					filterDetail: Filters[filterGroupKey].FilterDetails[filterDetailKey]
				});

		if(DataSalesCartItem.getData().cachedCount !== undefined){
			self.count = DataSalesCartItem.getData().cachedCount;
			self.showCount = true;
		}

		self.applied = !!self.expressions[0].nodes[0].operands[0].values.length;

		self.hasSelections = function() {
			return !!self.expressions[0].nodes[0].operands[0].values.length;
		};

		self.getValues = function (obj) {
		    var arr = [];

		    for (var key in obj) 
		        arr.push(obj[key]);		    

		    return arr;
		}

		function execCtx() {
			if(execCtx.debounce)
				clearTimeout(execCtx.debounce);

			if(DataSalesService.execute.defer)
				DataSalesService.execute.defer.cancel();

			self.showCount = false;

			execCtx.debounce = setTimeout(function() {
				DataSalesService.execute({
					data: {
						showCode: DataSalesProduct.DataSalesTargetShowCode,
						dataSalesExecuteContext: new DataSalesExecuteContext(self.expressions, 'EMAIL')
					},
					ignoreLoadingBar: true
				}).then(function(data) {
					DataSalesCartItem.getData().cachedCount = self.count = getCachedCount(data);
					Cart.$save();
					$rootScope.$broadcast('ngCart:change', {});
					self.showCount = true;
				});
			}, 500);
		}

		function getCachedCount(obj) {
			for(var prop in obj) {
				if(Object.prototype.toString.call(obj[prop]) === '[object Object]' && obj[prop].Summary !== undefined)
					return obj[prop].Summary.CachedCount;
				else if(Object.prototype.toString.call(obj[prop]) === '[object Object]')
					return getCachedCount(obj[prop]);
			}
		}

		//finalize the list and proceed to the next step
		self.next = function() {
			var d = $q.defer();

			if(!self.applied){				
				setTimeout(function() { 
					d.reject();															
					window.alert('Please hit the \'Apply\' button to activate this filter.');	
				}, 0);

				return d.promise;
			}
			else if(self.count === 0) {
				setTimeout(function() { 
					d.reject();									
					window.alert('Your count is 0, cannot proceed!');					
				}, 0);

				return d.promise;					
			}
			else {
				return DataSalesService.finalize({
					data: {
						showCode: DataSalesProduct.DataSalesTargetShowCode,
						dataSalesExecuteContext: new DataSalesExecuteContext(self.expressions, 'EMAIL')
					},
					ignoreLoadingBar: true
				}).then(function(data) {
					//in the rare case that the user's cached count has become stale before finalization, alert them to the update
					if(DataSalesCartItem.getData().cachedCount != data.FinalCount)
						window.alert('Please note that your count has changed since the last time the query was run.  Your new count and price has been updated accordingly.');

					DataSalesCartItem.getData().cachedCount = self.count = data.FinalCount;
					DataSalesCartItem.getData().dataSalesListId = data.DataSalesListID;
					DataSalesCartItem.getData().finalExpressions = angular.copy(self.expressions);
					DataSalesCartItem.getData().execDate = Date.now();
					Cart.$save();
					$rootScope.$broadcast('ngCart:change', {});

					if (!self.isDownload && !self.isMailHouse)
						$state.go('event.shop.dataSales.emailBuilder');
					else if(self.isMailHouse)
						$state.go('event.shop.dataSales.mailHouse');
					else
					    $state.go('event.shop.dataSales.terms');
				});				
			}
		};

		self.listSelect = function(operand, filterDetail, filter) {
			var filterOption = self.filterOptions.filter(function(e) {
				return e.filterGroupName === filter.Name && e.filterDetail.Id == filterDetail.Id;
			});

			if(filterOption.length)
				operand.detail = filterOption[0];

			if($tour.isActive())
				$tour.next();
		};

		self.changeInclude = function() {
			execCtx();
		};

		self.onFilterChange = function(operand) {
			if(operand.values.length)
				execCtx();

			operand.values = [];
			operand.operator = 'oneOf';
		};

		self.changeOperator = function(operand) {
			if(operand.detail)
				execCtx();
		};

		self.applyFilter = function(editing) {
			editing.enabled = false;
			self.applied = true;

			if($tour.isActive())
				$tour.next();
		};

		self.toggleFilterPick = function(e, operand, id) {
			if(e.target.checked)
				operand.values.push(id);
			else
				operand.values.splice(operand.values.indexOf(id), 1);

      //Set countryCode filters to include United States along with
      //an empty string when United States filter is selected
      if (operand.detail.filterDetail.Id == 'CountryCode' && id == '')
      {
        if (e.target.checked)
          operand.values.push('United States');
        else if (operand.values.includes('United States'))
          operand.values.splice(operand.values.indexOf('United States'), 1);
      }

			execCtx();

			if($tour.isActive() && $tour.current() === 5)
				$tour.next();
		};

		self.getRandomNum = function (min, max) {
			return Math.floor(Math.random() * (max - min)) + min;
		};

		self.all = function(operand) {
			operand.values = [];

			for(var id in operand.detail.filterDetail.FilterPicks)
				operand.values.push(id);
      if (operand.detail.filterDetail.Id == 'CountryCode' && operand.values.includes(''))
        operand.values.push('United States');

			execCtx();
		};

		self.none = function(operand) {
			operand.values = [];

			execCtx();
		};

		self.removeOperand = function(nodes, nodeIndex, operandIndex) {
			if(!nodes[nodeIndex].operands[operandIndex].detail || window.confirm('Are you sure you want to remove this filter?')){
				nodes[nodeIndex].operands.splice(operandIndex, 1);

				if(!nodes[nodeIndex].operands.length && nodes.length > 1)
					nodes.splice(nodeIndex, 1);
				else if(!nodes[nodeIndex].operands.length)
					nodes[nodeIndex].operands.push({
						operator: 'oneOf',
						values: []
					});

				execCtx();
			}
		};

		self.removeExpression = function(expressions, index) {
			if(window.confirm('Are you sure you want to remove this filter group?')){
				expressions.splice(index, 1);
				execCtx();
			}
		};

		self.more = function(pageSize) {
			pageSize.current = '';
		};

		self.collapse = function(pageSize, value) {
			pageSize.current = value;
		};

		self.getFilterLimit = function(pageSize) {
			return pageSize.current;
		};

		self.or = function(operands, index) {
			operands.splice(index + 1, 0, {
				operator: 'oneOf',
				values: []
			});
		};

		self.and = function(nodes, index) {
			nodes.splice(index + 1, 0, { operands: [ {
				operator: 'oneOf',
				values: []
			} ] });
		};

		self.addFilter = function(expressions) {
			expressions.push({
                include: 'true',
                nodes: [
                    {
                        operands: [
                            {
                            	operator: 'oneOf',
                                values: []
                            }
                        ]
                    }
                ]
            });
		};

		self.import = function (e) {
			setTimeout(function() { 
				angular.element(e.target).parent().find('input').click();
			}, 0);
		};
		
		self.onFileSelect = function (file) {
			var fileReader = new FileReader();
			file = file[0];
			
			fileReader.onload = function(e) {
				//console.log(e.target.result);
				$scope.$apply(function() { 
					self.expressions = JSON.parse(e.target.result);
					execCtx();
					self.applied = true;
				});
			}
			
			fileReader.readAsText(file);			
		}

		self.export = function () {
			var charset = "utf-8";
			var filename = "List-Export-" + Date.now() + ".json";			 
			var blob = new Blob([angular.toJson(self.expressions)], {
				type: "application/json;charset=" + charset + ";"
			});
			
			if (window.navigator.msSaveOrOpenBlob) {
            	navigator.msSaveBlob(blob, filename);
			} else {
				var downloadLink = angular.element('<a></a>');
				downloadLink.attr('href', window.URL.createObjectURL(blob));
				downloadLink.attr('download', filename);
				downloadLink.attr('target', '_blank');

				$document.find('body').append(downloadLink);
				$timeout(function () {
				downloadLink[0].click();
				downloadLink.remove();
				}, null);
			}
		};
	};

    angular.module('exhibitorPortalApp')
		.controller('DataSalesCtrl', DataSalesCtrl);
})(angular, window);
