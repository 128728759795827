'use strict';

window.taTools = {};

angular
  .module('exhibitorPortalLoginApp', [
    'mm.foundation',
    'ngSanitize',
    'ui.router',
    'angular-chartist',
    'ngCsv',
    'angular-loading-bar',
    'angularFileUpload',
    'ngMessages',
    'ngAria',
    'ngTouch',
    'ipCookie',
    'swapTemplates',
    'ngModal',
    require('textangularjs'),
    'sharedModule'
  ])
  .config(function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject'
    $urlRouterProvider
      .when('', '/')
      .otherwise('/oops');

    // AngularJS 1.6 changed the default hash prefix from empty string to '!'... reverting here so as not to screw up routes.
    $locationProvider.hashPrefix('');

    $stateProvider
      .state('login', {
        abstract: true,
        template: '<ui-view></ui-view>',
        controller: function () {
          'ngInject'

        }
      })
      .state('login.landing', {
        url: '/',
        templateUrl: 'views/Login/landing.html',
        controller: 'LoginCtrl',
        controllerAs: 'Login',
        data: {
          pageTitle: 'Login Landing',
          feedbackName: 'LoginLanding'
        }
      })
      .state('login.accountSearch', {
        url: '/accountSearch',
        templateUrl: 'views/Login/accountSearch.html',
        controller: 'AccountCtrl',
        controllerAs: 'Account',
        params: {
          forgotPassword: false
        },
        data: {
          pageTitle: 'Account Search',
          feedbackName: 'LoginAccountSearch'
        }
      })
      .state('login.changePassword', {
        url: '/changePassword',
        templateUrl: 'views/Login/changePassword.html',
        controller: 'ChangePwdCtrl',
        controllerAs: 'Pwd',
        data: {
          pageTitle: 'Change Password',
          feebackName: 'ChangePassword'
        }
      })
      .state('login.companyLogin', {
        url: '/companyLogin',
        templateUrl: 'views/Login/companyLogin.html',
        controller: 'CompanyLoginCtrl',
        controllerAs: 'CompLogin',
        data: {
          pageTitle: 'Company Login',
          feedbackName: 'CompanyLogin'
        }
      });
  })
  .config(function ($provide) {
    'ngInject'
    $provide.value('EXSConfig', angular.copy(window.EXSConfig));
    $provide.value('EnvironmentConfig', angular.copy(window.EnvironmentConfig));
    $provide.value('SHOW', angular.copy(window.SHOW));
  })
  .config(function (cfpLoadingBarProvider) {
    'ngInject'
    // set loading bar preferences
    cfpLoadingBarProvider.includeSpinner = false;
    // only show the loading bar if the resolve takes more than 1 second. this prevents the user
    // from seeing the loading bar flash on the screen when the resolve completes quickly.
    // cfpLoadingBarProvider.latencyThreshold = 1000;
    // if(EnvironmentConfig.InAppPurchase)
    // 	cfpLoadingBarProvider.includeBar = false;
  })
  .run(function ($rootScope, $location, $interval, $timeout, ipCookie, EnvironmentConfig, $state, $window) {
    'ngInject'
    var utm = ipCookie('EPUTM');

    if (window.dataLayer && utm) {
      dataLayer.push({ event: 'utmChange', utm: utm });
    }

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams, error) {
      $rootScope.previousState = fromState.name;
      $rootScope.currentState = toState.name;

      $timeout(function () {
        $rootScope.pageTitle = (toState.data && toState.data.pageTitle) ? toState.data.pageTitle + ' | Exhibitor Portal' : '';

        //google analytics tracking
        if (window.dataLayer && $rootScope.pageTitle) {
          const pageViewUrl = () => {
            // Do not include the specific ID(s) in the URL sent. Construct the URL from the current location
            // as well as the state, if applicable.
            let pageUrl = $window.location.pathname;

            // Trim trailing slash at the end
            if (pageUrl.slice(-1) === '/')
              pageUrl = pageUrl.slice(0, -1);

            if ($state && $state.$current && $state.$current.url && $state.$current.url.source) {
              // Including the '#' character that is normally in the URL seems to chop off the rest of
              // the URL for the data collection.
              pageUrl += $state.$current.url.source;
            }

            if (['', '/'].includes(pageUrl))
              pageUrl = '/home/login'

            return pageUrl;
          };

          window.dataLayer.push({
            event: 'ngRouteChange',
            attributes: {
              route: pageViewUrl(),
              pageTitle: $rootScope.pageTitle
            }
          });
        }

        // Experient feedback widget
        if (ExperientFeedback) {
          // Going to set the location of the widget to the state name unless a specific name is given for it
          // in the state's data object
          var location = toState.name;
          var data = toState.data;
          if (data && data.feedbackName) {
            location = data.feedbackName;
          } else {
            location = location.replace('login.', '');
            var statePath = location.split('.');
            if (statePath && statePath.length > 1) {
              location = '';
              for (var i = 0; i < statePath.length; i++) {
                location = location + (statePath[i].charAt(0).toUpperCase() + statePath[i].slice(1));
              }
            } else {
              location = location.charAt(0).toUpperCase() + location.slice(1);
            }
          }

          window.exfb = new ExperientFeedback({
            Application: "ExhibitorPortal",
            Location: location,
            Event: '',
            UserId: '',
            UserType: "EXSEnterprise.Contact",
            QuestionText: "Is this page easy to use?"
          }, "exfb-content");
        }
      });
    });
  });
