'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:Time
 * @description
 * # Time
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epTime() {
    'ngInject'
		return {
			restrict: 'A',
			scope: {
				options: '=?options',
				picker: '=picker'
			},
			link: function (scope, element) {
			    element.pickatime(scope.options);
			    scope.picker = element.pickatime('picker');
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epTime', epTime);
})(angular, window);
