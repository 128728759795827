'use strict';

window.getEventCode = function () {
  var results = new RegExp("^#/event/([_a-zA-Z0-9]*)/.*").exec(window.location.hash);
  return (results && results.length) ? results[1] : '';
};

window.taTools = {};

angular
  .module('exhibitorPortalApp', [
    'mm.foundation',
    'ngSanitize',
    'ui.router',
    'angular-chartist',
    'ngCsv',
    'angular-loading-bar',
    'angularFileUpload',
    'ngCart',
    'ngMessages',
    'ngAria',
    'ngTouch',
    'ipCookie',
    'swapTemplates',
    'ngModal',
    require('textangularjs'),
    'sharedModule',
    'ngClickCopy'
  ])
  .config(function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject'
    $urlRouterProvider
      .when('', '/')
      .otherwise('/oops');

    //removes hash from the url but royally screws things up with virtual directory so not using for now
    //$locationProvider.html5Mode(true);

    // AngularJS 1.6 changed the default hash prefix from empty string to '!'... reverting here so as not to screw up routes.
    $locationProvider.hashPrefix('');

    $stateProvider
      .state('root', {
        abstract: true,
        template: '<ui-view></ui-view>',
        controller: function (ShowService, Search, SearchBase, Checkout, CheckoutBase) {
          'ngInject'
          //clear the current show
          ShowService.data.currentShow = null;

          //reset these values to their initial config
          Search.leads = angular.copy(SearchBase.leads);
          Search.surveys = angular.copy(SearchBase.surveys);
          Search.survey = angular.copy(SearchBase.survey);
          Search.resources = angular.copy(SearchBase.resources);
          Search.surveyQuestion = angular.copy(SearchBase.surveyQuestion);
          Checkout = angular.copy(CheckoutBase);
        }
      })
      .state('root.myShows', {
        url: '/',
        templateUrl: 'views/myShows.html',
        controller: 'MyShowsCtrl',
        controllerAs: 'MyShows',
        resolve: {
          MyEvents: function (ShowService) {
            'ngInject'
            return ShowService
              .getShows()
              .then(function () {
                var now = new Date();
                var shows = angular.copy(ShowService.data.shows);

                function addDays(date, days) {
                  var result = new Date(date);
                  result.setDate(result.getDate() + days);
                  return result;
                }

                for (var i = 0; i < shows.length; i++) {
                  shows[i].moveInDateMS = new Date(shows[i].MoveInDate);

                  if (shows[i].BeginDate) {
                    var beginDate = new Date(shows[i].BeginDate);
                    shows[i].current = beginDate >= addDays(now, -60) && beginDate <= addDays(now, 60);
                    shows[i].past = beginDate < addDays(now, -60);
                    shows[i].future = beginDate > addDays(now, 60);
                  }
                }

                return shows;
              });
          }
        },
        data: {
          pageTitle: 'Events',
          feedbackName: 'ShowList'
        }
      })
      .state('root.account', {
        url: '/account',
        templateUrl: 'views/account.html',
        controller: 'AccountCtrl',
        controllerAs: 'account',
        data: {
          pageTitle: 'Account'
        }
      })
      .state('event', {
        abstract: true,
        url: '/event/:showcode',
        templateUrl: 'views/event.html',
        controller: 'SwitchShowCtrl',
        controllerAs: 'SwitchShow',
        resolve: {
          AdditionalInfo: function (CurrentEvent, ShowService) {
            'ngInject'
            if (CurrentEvent.HasActiveSalesTier)
              return ShowService
                .getShowAdditionalInfo({ showCode: CurrentEvent.ShowCode })
                .then(function () {
                  return ShowService.data.showAdditionalInfo[CurrentEvent.ShowCode];
                });
          },
          CurrentEvent: function ($stateParams, ShowService) {
            'ngInject'
            return ShowService
              .getShow($stateParams.showcode)
              .then(function () {
                return ShowService.data.currentShow;
              });
          },
          MyEvents: function (ShowService) {
            'ngInject'
            return ShowService
              .getShows()
              .then(function () {
                return ShowService.data.shows;
              });
          },
          HasActivations: function (CurrentEvent, ShowService) {
            'ngInject'
            return ShowService
              .hasActivations({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return ShowService.data.activations[CurrentEvent.ShowCode];
              });
          }
        }
      })
      //Check with Rob about the child router.
      .state('event.collateral', {
        abstract: true,
        template: '<ui-view></ui-view>',
        resolve: {
          CollateralConfig: function (CollateralService, CurrentEvent) {
            'ngInject'
            return CollateralService.getCollateralConfig({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return CollateralService.data.collateralConfig[CurrentEvent.ShowCode];
              });
          },
          Collaterals: function (CollateralConfig, CollateralService, CurrentEvent) {
            'ngInject'
            return CollateralService.data.collaterals[CurrentEvent.ShowCode];
          },
          ScrollToBottom: function () {
            'ngInject'
            return { value: false };
          }
        }
      })
      .state('event.collateral.collaterals', {
        url: '/collateral',
        templateUrl: 'views/collateral.html',
        controller: 'CollateralCtrl',
        controllerAs: 'collateral',
        data: {
          feedbackName: 'Collateral'
        }
      })
      .state('event.collateral.collateral', {
        url: '/collateral/edit/:id',
        templateUrl: 'views/collateralAdd.html',
        controller: 'CollateralAddCtrl',
        controllerAs: 'collateralAdd',
        resolve: {
          Collateral: function ($q, $stateParams, CollateralService, CurrentEvent) {
            'ngInject'
            if (!$stateParams.id)
              return $q.when();

            var collateralId = $stateParams.id;

            return CollateralService.getCollateral({ showCode: CurrentEvent.ShowCode, collateralId: collateralId })
              .then(function () {
                var collaterals = CollateralService.data.collaterals[CurrentEvent.ShowCode];
                return collaterals.filter(function (c) {
                  return c.CollateralID == collateralId;
                })
              });
          },
          SWAPExhibitor: function (UserService, CurrentEvent) {
            'ngInject'
            return UserService.getSWAPExhibitor({ showCode: CurrentEvent.ShowCode })
              .then(function () { return UserService.data[CurrentEvent.ShowCode].SWAPExhibitor; });
          }
        },
        data: {
          feedbackName: 'CollateralAdd'
        }
      })
      .state('event.followUp', {
        url: '/followUp',
        templateUrl: 'views/leadFollowUp.html',
        controller: 'LeadFollowUpCtrl',
        controllerAs: 'leadFollowUp',
        resolve: {
          HTMLTemplate: function (LeadFollowUpService, CurrentEvent) {
            'ngInject'
            return LeadFollowUpService.getTemplate({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return LeadFollowUpService.data.templateHtml[CurrentEvent.ShowCode];
              });
          },
          FollowUpConfig: function (LeadFollowUpService, CurrentEvent) {
            'ngInject'
            return LeadFollowUpService.getFollowUpConfig({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return LeadFollowUpService.data.followUpConfigs[CurrentEvent.ShowCode];
              });
          }
        },
        data: {
          feedbackName: 'CollateralAdd'
        }
      })
      .state('event.account', {
        url: '/account',
        templateUrl: 'views/account.html',
        controller: 'AccountCtrl',
        controllerAs: 'account',
        data: {
          pageTitle: 'Account',
          feedbackName: 'EventAccount'
        }
      })
      .state('event.dashboard', {
        abstract: true,
        templateUrl: 'views/dashboard.html',
        controllerAs: 'dashboard',
        resolve: {
          Orders: function (CurrentEvent, OrderService) {
            'ngInject'
            return OrderService
              .getOrders({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return OrderService.data.orders[CurrentEvent.ShowCode] || [];
              });
          },
          EmailCampaignOrders: function (Orders) {
            'ngInject'
            for (var i = 0; i < Orders.length; i++) {
              for (var a = 0; a < Orders[i].Transactions.length; a++) {
                if (Orders[i].Transactions[a].DataSalesListId && Orders[i].Transactions[a].TransTypeCode === 'PUR' && Orders[i].Transactions[a].TransStatus !== 'VOIDED')
                  return true;
              }
            }
            return false;
          },
          EventBitOrders: function (Orders) {
            'ngInject'
            for (var i = 0; i < Orders.length; i++) {
              for (var a = 0; a < Orders[i].Transactions.length; a++) {
                if (Orders[i].Transactions[a].ProductCode === '1500' &&
                  Orders[i].Transactions[a].TransTypeCode === 'PUR' &&
                  Orders[i].Transactions[a].TransStatus !== 'VOIDED' &&
                  !Orders[i].Transactions[a].IsVoided)
                  return true;
              }
            }
            return false;
          },
          isSPOT: function (CurrentEvent, EventBitService) {
            'ngInject'
            return EventBitService
              .isSPOT({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return !!EventBitService.data.isSPOT[CurrentEvent.ShowCode];
              });
          }
        },
        controller: function ($state, $scope, EmailCampaignOrders, EventBitOrders, ActiveUserProfile) {
          'ngInject'
          var self = this;

          self.showEmailCampaignsTab = EmailCampaignOrders;
          self.showEventBitTab = EventBitOrders;
          self.showLeadPrizeTab = true;

          if (!self.showEventBitTab && !self.showEmailCampaignsTab || (ActiveUserProfile.SWAPOnly && !self.showEventBitTab))
            self.hide = true;
          else {
            self.currentState = $state.$current;
            self.stateName = self.currentState.name;

            $scope.$watch('currentState', function (newValue, oldValue) {
              if (newValue !== oldValue)
                self.stateName = newValue;
            });
          }
        }
      })
      .state('event.landing', {
        url: '/',
        templateUrl: 'views/landing.html',
        redirectTo: 'event.dashboard.landing'
        //controller: 'LandingCtrl',
        //controllerAs: 'Landing',
        //resolve: LandingCtrl.resolve,
        //data: {
        //	pageTitle: 'Dashboard'
        //}
      })
      .state('event.dashboard.landing', {
        url: '/dashboard/swap',
        templateUrl: 'views/landing.html',
        controller: 'LandingCtrl',
        controllerAs: 'Landing',
        resolve: {
          AdditionalInfo: function (CurrentEvent, ShowService) {
            'ngInject'
            if (CurrentEvent.HasActiveSalesTier)
              return ShowService
                .getShowAdditionalInfo({ showCode: CurrentEvent.ShowCode })
                .then(function () {
                  return ShowService.data.showAdditionalInfo[CurrentEvent.ShowCode];
                });
          },
          Surveys: function (CurrentEvent, SurveyService) {
            'ngInject'
            return SurveyService.getSurveys({ showCode: CurrentEvent.ShowCode })
              .then(function () {

                var surveys = [];

                // Get surveys active only
                for (var i = 0; i < SurveyService.data.surveys[CurrentEvent.ShowCode].length; i++) {
                  if (SurveyService.data.surveys[CurrentEvent.ShowCode][i].IsActive) {
                    var questions = [];
                    for (var x = 0; x < SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions.length; x++) {
                      if (SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions[x].IsActive) {
                        questions.push(SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions[x]);
                      }
                    }
                    SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions = questions.sort(function (i1, i2) {
                      return i1.DisplayOrder - i2.DisplayOrder;
                    });
                    surveys.push(SurveyService.data.surveys[CurrentEvent.ShowCode][i]);
                  }
                }

                // Sort Surveys
                surveys = surveys.sort(function (i1, i2) {
                  return i1.DisplayOrder - i2.DisplayOrder;
                });

                return surveys;
              });
          },
          CurrentSurvey: function (Surveys) {
            'ngInject'
            if (Surveys) {
              if (Surveys.length > 1) {
                return Surveys[1];
              } else if (Surveys.length > 0) {
                return Surveys[0];
              }
            }
            return null;
          },
          CurrentSurveyQuestion: function (CurrentSurvey) {
            'ngInject'
            if (CurrentSurvey) {
              for (var x = 0; x < CurrentSurvey.SurveyQuestions.length; x++) {
                if (CurrentSurvey.SurveyQuestions[x].IsActive) {
                  return CurrentSurvey.SurveyQuestions[x];
                }
              }
            }
            return null;
          },
          DemographicQuestions: function (CurrentEvent, DemographicService) {
            'ngInject'
            return DemographicService.getDemographics({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                var questions = [];

                for (var i = 0; i < DemographicService.data.demographics[CurrentEvent.ShowCode].length; i++)
                  if (DemographicService.data.demographics[CurrentEvent.ShowCode][i].TableName == 'RegDemo')
                    questions.push(DemographicService.data.demographics[CurrentEvent.ShowCode][i]);

                return questions;
              });
          },
          CurrentDemographic: function (DemographicQuestions) {
            'ngInject'
            return DemographicQuestions[0];
          },
          LeadSummaryData: function (CurrentEvent, LeadActivityService) {
            'ngInject'
            return LeadActivityService.getLeadSummary({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return LeadActivityService.data.leadSummary[CurrentEvent.ShowCode];
              });
          },
          LeadSourcesChartData: function (CurrentEvent, LeadActivityService) {
            'ngInject'
            return LeadActivityService.getLeadSources({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return LeadActivityService.parseChartData(LeadActivityService.data.leadSources[CurrentEvent.ShowCode]);
              });
          },
          LeadDemographicChartData: function (CurrentEvent, CurrentDemographic, LeadActivityService) {
            'ngInject'
            if (!CurrentDemographic)
              return {
                labels: [],
                series: []
              };

            return LeadActivityService.getLeadsByDemographicQuestion({
              showCode: CurrentEvent.ShowCode,
              fieldDetailId: CurrentDemographic.Id
            })
              .then(function () {
                return LeadActivityService.parseChartData(LeadActivityService.data.leadDemographics[CurrentEvent.ShowCode][CurrentDemographic.Id], 'Other Demographics');
              });
          },
          LeadSurveyChartData: function (CurrentEvent, CurrentSurveyQuestion, LeadActivityService) {
            'ngInject'
            if (!CurrentSurveyQuestion)
              return {
                labels: [],
                series: []
              };

            return LeadActivityService.getLeadsBySurveyQuestion({
              showCode: CurrentEvent.ShowCode,
              surveyQuestionId: CurrentSurveyQuestion.Id
            })
              .then(function () {
                return LeadActivityService.parseChartData(LeadActivityService.data.leadSurveys[CurrentEvent.ShowCode][CurrentSurveyQuestion.Id], 'Other Survey Questions');
              });
          },
          LeadDaysChartData: function (CurrentEvent, LeadActivityService) {
            'ngInject'
            return LeadActivityService.getLeadsByDay({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                var data = LeadActivityService.data.leadDays[CurrentEvent.ShowCode],
                  labels = [],
                  series = [];

                for (var i = 0; i < data.length; i++) {
                  labels.push(data[i].Source);
                  series.push(data[i].Count);
                }

                return {
                  labels: labels,
                  series: series
                };
              });
          },
          HasActivations: function (CurrentEvent, ShowService) {
            'ngInject'
            return ShowService
              .hasActivations({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return ShowService.data.activations[CurrentEvent.ShowCode];
              });
          },
          LeadCollateral: function (CollateralService, CurrentEvent, HasActivations, $q) {
            'ngInject'
            if (HasActivations) {
              return CollateralService.getLeadCollateral({ showCode: CurrentEvent.ShowCode })
                .then(function () {
                  return CollateralService.data.leadCollateral[CurrentEvent.ShowCode].reduce(
                    function (acc, curr) {
                      acc[curr.LeadID] = acc[curr.LeadID] || [];
                      acc[curr.LeadID].push(curr);
                      return acc;
                    }, {});
                });
            } else {
              return $q.when({});
            }
          },
          HasPurchasedEventBit: function (Orders) {
            'ngInject'
            var EVENT_BIT_PRODUCT_CODE = '1500';

            for (var i = 0; i < Orders.length; i++) {
              for (var a = 0; a < Orders[i].Transactions.length; a++) {
                if (Orders[i].Transactions[a].ProductCode === EVENT_BIT_PRODUCT_CODE && Orders[i].Transactions[a].TransTypeCode === 'PUR' && Orders[i].Transactions[a].TransStatus !== 'VOIDED')
                  return true;
              }
            }

            return false;
          },
          CollateralConfig: function (CollateralService, CurrentEvent) {
            'ngInject'
            return CollateralService.getCollateralConfig({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return CollateralService.data.collateralConfig[CurrentEvent.ShowCode];
              });
          },
          Collateral: function (CollateralConfig, CollateralService, CurrentEvent) {
            'ngInject'
            return CollateralService.data.collaterals[CurrentEvent.ShowCode];
          },
          FollowUpConfig: function (LeadFollowUpService, CurrentEvent) {
            'ngInject'
            return LeadFollowUpService.getFollowUpConfig({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return LeadFollowUpService.data.followUpConfigs[CurrentEvent.ShowCode];
              });
          }
        },
        data: {
          pageTitle: 'SWAP Dashboard'
        }
      })
      .state('event.dashboard.eventBit', {
        url: '/dashboard/eventBit',
        templateUrl: 'views/eventBit.html',
        controller: 'EventBitCtrl',
        controllerAs: 'eventBit',
        resolve: {
          SSO: function (CurrentEvent, EventBitService) {
            'ngInject'
            return EventBitService.getSSO({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return EventBitService.data.sso[CurrentEvent.ShowCode];
              });
          }
        },
        data: {
          pageTitle: 'eventBit Dashboard'
        }
      })
      .state('event.dashboard.emailCampaigns', {
        url: '/dashboard/attendeeData',
        templateUrl: 'views/emailCampaigns.html',
        controller: 'EmailCampaignsCtrl',
        controllerAs: 'emailCampaigns',
        resolve: {
          EmailCampaigns: function (CurrentEvent, DataSalesService) {
            'ngInject'
            return DataSalesService.getEmailCampaigns({ showCode: CurrentEvent.ShowCode }).then(function () {
              return DataSalesService.data.emailCampaigns[CurrentEvent.ShowCode];
            });
          },
          AttendeeListDownloads: function (CurrentEvent, DataSalesService) {
            'ngInject'
            return DataSalesService.getAttendeeListDownloads({
              showCode: CurrentEvent.ShowCode
            }).then(function (data) {
              return DataSalesService.data.attendeeListDownloads[CurrentEvent.ShowCode];
            });
          },
          FilterGroup: function () {
            'ngInject'
            return function (name) {
              this.Name = name;
              this.FilterDetails = {};
            };
          },
          FilterDetail: function () {
            'ngInject'
            return function (id, description) {
              this.Id = String(id);
              this.Description = description;
              this.FilterPicks = {};
            };
          },
          FilterPick: function () {
            'ngInject'
            return function (id, description) {
              this.Id = String(id);
              this.Description = description;
            };
          },
          Demographics: function ($q, EmailCampaigns, CurrentEvent, DemographicService, FilterGroup, FilterDetail, FilterPick) {
            'ngInject'
            return $q.all((function () {
              var promises = [];

              for (var i = 0; i < EmailCampaigns.length; i++)
                promises.push(DemographicService.getDemographics({ showCode: EmailCampaigns[i].Strategy.TargetShowCode }));

              return promises;
            })()).then(function (data) {
              var allDemographics = {};

              for (var z = 0; z < data.length; z++) {
                if (!allDemographics[EmailCampaigns[z].Strategy.TargetShowCode]) {
                  var demographics = DemographicService.data.demographics[EmailCampaigns[z].Strategy.TargetShowCode].filter(function (e) {
                    return e.DataSalesAllowUserFilters;
                  });

                  var filterGroup = new FilterGroup('Demographics');

                  for (var i = 0; i < demographics.length; i++) {
                    var filterDetail = new FilterDetail(demographics[i].FieldName, demographics[i].Description);

                    for (var a = 0; a < demographics[i].FieldPicks.length; a++) {
                      var pickDesc = '';
                      if (demographics[i].IsPickCodeDesc) {
                        pickDesc = demographics[i].FieldPicks[a].PickCode + ' - ' + demographics[i].FieldPicks[a].Description;
                      } else {
                        pickDesc = demographics[i].FieldPicks[a].Description;
                      }
                      filterDetail.FilterPicks[demographics[i].FieldPicks[a].PickCode] = new FilterPick(demographics[i].FieldPicks[a].PickCode, pickDesc);
                    }

                    filterGroup.FilterDetails[demographics[i].FieldName] = filterDetail;
                  }

                  allDemographics[EmailCampaigns[z].Strategy.TargetShowCode] = filterGroup;
                }
              }

              return allDemographics;
            });
          },
          Locations: function ($q, FilterGroup, EmailCampaigns, LeadService, FilterDetail, FilterPick) {
            'ngInject'
            return $q.all((function () {
              var promises = [];

              for (var i = 0; i < EmailCampaigns.length; i++)
                promises.push(LeadService.getCountries({ showCode: EmailCampaigns[i].Strategy.TargetShowCode }));

              for (i = 0; i < EmailCampaigns.length; i++)
                promises.push(LeadService.getAllStates({ showCode: EmailCampaigns[i].Strategy.TargetShowCode }));

              return promises;
            })()).then(function () {
              var allLocations = {};

              for (var i = 0; i < EmailCampaigns.length; i++)
                allLocations[EmailCampaigns[i].Strategy.TargetShowCode] = allLocations[EmailCampaigns[i].Strategy.TargetShowCode] || {};

              for (var showCode in allLocations)
                allLocations[showCode] = {
                  FilterDetails: {
                    CountryCode: (function () {
                      var countries = LeadService.data.countries[showCode];
                      var filterDetail = new FilterDetail('CountryCode', 'Country');

                      for (var i = 0; i < countries.length; i++) {
                        //4-5-2016 - RJZ - hack to replace us value with blank
                        filterDetail.FilterPicks[countries[i].Value === 'UNITED STATES' ? '' : countries[i].Value] = new FilterPick(countries[i].Value === 'UNITED STATES' ? '' : countries[i].Value, countries[i].Key);
                      }

                      return filterDetail;
                    })(showCode),
                    StateCode: (function () {
                      var states = LeadService.data.allStates[showCode];
                      var filterDetail = new FilterDetail('StateCode', 'State');

                      for (var i = 0; i < states.length; i++) {
                        filterDetail.FilterPicks[states[i].Value] = new FilterPick(states[i].Value, states[i].Key);
                      }

                      return filterDetail;
                    })(showCode)
                  }
                };

              return allLocations;
            });
          },
          DownloadDemographics: function ($q, CurrentEvent, DemographicService, FilterGroup, FilterDetail, FilterPick, AttendeeListDownloads) {
            'ngInject'
            return $q.all((function () {
              var promises = [];

              for (var i = 0; i < AttendeeListDownloads.length; i++)
                promises.push(DemographicService.getDemographics({ showCode: AttendeeListDownloads[i].TargetShowCode }));

              return promises;
            })()).then(function (data) {
              var allDemographics = {};

              for (var z = 0; z < data.length; z++) {
                if (!allDemographics[AttendeeListDownloads[z].TargetShowCode]) {
                  var demographics = DemographicService.data.demographics[AttendeeListDownloads[z].TargetShowCode].filter(function (e) {
                    return e.DataSalesAllowUserFilters;
                  });

                  var filterGroup = new FilterGroup('Demographics');

                  for (var i = 0; i < demographics.length; i++) {
                    var filterDetail = new FilterDetail(demographics[i].FieldName, demographics[i].Description);

                    for (var a = 0; a < demographics[i].FieldPicks.length; a++) {
                      var pickDesc = '';
                      if (demographics[i].IsPickCodeDesc) {
                        pickDesc = demographics[i].FieldPicks[a].PickCode + ' - ' + demographics[i].FieldPicks[a].Description;
                      } else {
                        pickDesc = demographics[i].FieldPicks[a].Description;
                      }
                      filterDetail.FilterPicks[demographics[i].FieldPicks[a].PickCode] = new FilterPick(demographics[i].FieldPicks[a].PickCode, pickDesc);
                    }

                    filterGroup.FilterDetails[demographics[i].FieldName] = filterDetail;
                  }

                  allDemographics[AttendeeListDownloads[z].TargetShowCode] = filterGroup;
                }
              }

              return allDemographics;
            });
          },
          DownloadLocations: function ($q, FilterGroup, AttendeeListDownloads, LeadService, FilterDetail, FilterPick) {
            'ngInject'
            return $q.all((function () {
              var promises = [];

              for (var i = 0; i < AttendeeListDownloads.length; i++)
                promises.push(LeadService.getCountries({ showCode: AttendeeListDownloads[i].TargetShowCode }));

              for (i = 0; i < AttendeeListDownloads.length; i++)
                promises.push(LeadService.getAllStates({ showCode: AttendeeListDownloads[i].TargetShowCode }));

              return promises;
            })()).then(function () {
              var allLocations = {};

              for (var i = 0; i < AttendeeListDownloads.length; i++)
                allLocations[AttendeeListDownloads[i].TargetShowCode] = allLocations[AttendeeListDownloads[i].TargetShowCode] || {};

              for (var showCode in allLocations)
                allLocations[showCode] = {
                  FilterDetails: {
                    CountryCode: (function () {
                      var countries = LeadService.data.countries[showCode];
                      var filterDetail = new FilterDetail('CountryCode', 'Country');

                      for (var i = 0; i < countries.length; i++) {
                        //4-5-2016 - RJZ - hack to replace us value with blank
                        filterDetail.FilterPicks[countries[i].Value === 'UNITED STATES' ? '' : countries[i].Value] = new FilterPick(countries[i].Value === 'UNITED STATES' ? '' : countries[i].Value, countries[i].Key);
                      }

                      // for(var i = 0; i < countries.length; i++){
                      // 	filterDetail.FilterPicks[countries[i].Value] = new FilterPick(countries[i].Value, countries[i].Key);
                      // }

                      return filterDetail;
                    })(showCode),
                    StateCode: (function () {
                      var states = LeadService.data.allStates[showCode];
                      var filterDetail = new FilterDetail('StateCode', 'State');

                      for (var i = 0; i < states.length; i++) {
                        filterDetail.FilterPicks[states[i].Value] = new FilterPick(states[i].Value, states[i].Key);
                      }

                      return filterDetail;
                    })(showCode)
                  }
                };

              return allLocations;
            });
          },
          Templates: function ($q, EmailCampaigns, DataSalesService) {
            'ngInject'
            var showCodes = [];

            for (var i = 0; i < EmailCampaigns.length; i++)
              if (showCodes.indexOf(EmailCampaigns[i].Strategy.TargetShowCode) == -1)
                showCodes.push(EmailCampaigns[i].Strategy.TargetShowCode);

            return $q.all(showCodes.map(function (x) {
              return DataSalesService.getTemplates({
                showCode: x
              });
            })).then(function () {
              var templates = [];

              for (var y = 0; y < showCodes.length; y++)
                for (var i = 0; i < DataSalesService.data.templates[showCodes[y]].length; i++) {
                  var template = angular.copy(DataSalesService.data.templates[showCodes[y]][i]);
                  var bodyFields = {};

                  for (var a = 0; a < template.BodyFields.length; a++)
                    bodyFields[template.BodyFields[a].KeyName] = template.BodyFields[a];

                  template.BodyFields = bodyFields;
                  templates.push(template);
                }

              return templates;
            });
          }
        },
        data: {
          pageTitle: 'Email Campaigns'
        }
      })
      .state('event.dashboard.emailCampaigns.edit', {
        url: '/edit/:id',
        templateUrl: 'views/dataSalesTemplate.html',
        controller: 'DataSalesTemplateCtrl',
        controllerAs: 'dataSalesTemplate',
        resolve: {
          Strategy: function ($stateParams, EmailCampaigns) {
            'ngInject'
            return EmailCampaigns.filter(function (x) {
              return x.Strategy.PrimaryID == $stateParams.id;
            })[0].Strategy;
          },
          Email: function (Strategy) {
            'ngInject'
            var email = {};

            for (var key in Strategy)
              email[key] = Strategy[key];

            for (var i = 0; i < Strategy.MessageConfigs.length; i++)
              email[Strategy.MessageConfigs[i].KeyName] = Strategy.MessageConfigs[i].ItemValue;

            email.TemplateId = email.TemplateID;

            delete email.MessageConfigs;
            delete email.FeedbackSummary;

            return email;
          },
          Cart: function () {
            'ngInject'
            return undefined;
          },
          DataSalesProduct: function () {
            'ngInject'
            return undefined;
          },
          DataSalesCartItem: function () {
            'ngInject'
            return undefined;
          }
        }
      })
      .state('event.leads', {
        url: '/leads',
        templateUrl: 'views/leads.html',
        controller: 'LeadsCtrl',
        controllerAs: 'leads',
        resolve: {
          Demographics: function (CurrentEvent, DemographicService) {
            'ngInject'
            return DemographicService.getDemographics({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return DemographicService.data.demographics[CurrentEvent.ShowCode].filter(function (x) {
                  return x.FieldTypeCode !== 'OPN';
                });
              });
          },
          CollateralConfig: function (CollateralService, CurrentEvent) {
            'ngInject'
            return CollateralService.getCollateralConfig({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return CollateralService.data.collateralConfig[CurrentEvent.ShowCode];
              });
          },
          Collateral: function (CollateralConfig, CollateralService, CurrentEvent) {
            'ngInject'
            return CollateralService.data.collaterals[CurrentEvent.ShowCode];
          },
          HasActivations: function (CurrentEvent, ShowService) {
            'ngInject'
            return ShowService
              .hasActivations({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return ShowService.data.activations[CurrentEvent.ShowCode];
              });
          },
          LeadCollateral: function (CollateralService, CurrentEvent, HasActivations, $q) {
            'ngInject'
            if (HasActivations) {
              return CollateralService.getLeadCollateral({ showCode: CurrentEvent.ShowCode })
                .then(function () {
                  return CollateralService.data.leadCollateral[CurrentEvent.ShowCode].reduce(
                    function (acc, curr) {
                      acc[curr.LeadID] = acc[curr.LeadID] || [];
                      acc[curr.LeadID].push(curr);
                      return acc;
                    }, {});
                });
            } else {
              return $q.when({});
            }
          },
          LeadsCapturedBy: function (CurrentEvent, LeadService) {
            'ngInject'
            return LeadService.getLeadsCapturedBy({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return LeadService.data.leadsCapturedBy[CurrentEvent.ShowCode];
              });
          },
          Countries: function (CurrentEvent, LeadService) {
            'ngInject'
            return LeadService.getCountries({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return LeadService.data.countries[CurrentEvent.ShowCode];
              });
          },
          States: function (CurrentEvent, LeadService, Search) {
            'ngInject'
            var countryCode =
              (Search.leads.filters.ContactInfo && Search.leads.filters.ContactInfo['Contact.Address.CountryCode']) ?
                Search.leads.filters.ContactInfo['Contact.Address.CountryCode'] :
                '';

            return LeadService.getStates({ showCode: CurrentEvent.ShowCode, countryCode: countryCode })
              .then(function () {
                return LeadService.data.states[CurrentEvent.ShowCode][countryCode];
              });
          },
          Surveys: function ($q, CurrentEvent, SurveyService) {
            'ngInject'
            return SurveyService.getSurveys({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                var surveys = angular.copy(SurveyService.data.surveys[CurrentEvent.ShowCode]).filter(function (x) {
                  return x.IsActive;
                });

                for (var i = 0; i < surveys.length; i++) {
                  surveys[i].SurveyQuestions = surveys[i].SurveyQuestions.filter(function (x) {
                    return x.IsActive;
                  });

                  for (var a = 0; a < surveys[i].SurveyQuestions.length; a++)
                    surveys[i].SurveyQuestions[a].SurveyAnswers = surveys[i].SurveyQuestions[a].SurveyAnswers.filter(function (x) {
                      return x.IsActive;
                    });
                }

                return surveys;
              });
          },
          Orders: function (CurrentEvent, OrderService) {
            'ngInject'
            return OrderService
              .getOrders({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return OrderService.data.orders[CurrentEvent.ShowCode];
              });
          },
          HasPurchasedDevKit: function (CurrentEvent, Orders, OrderService) {
            'ngInject'
            var DEV_KIT_PRODUCT_CODE = '300';

            for (var i = 0; i < Orders.length; i++)
              for (var a = 0; a < Orders[i].Transactions.length; a++) {
                var transaction = Orders[i].Transactions[a];

                if (transaction.ProductCode === DEV_KIT_PRODUCT_CODE && !transaction.IsVoided && transaction.TransTypeCode === 'PUR')
                  return true;
              }

            return false;
          },
          HasPurchasedEventBit: function (Orders) {
            'ngInject'
            var EVENT_BIT_PRODUCT_CODE = '1500';

            for (var i = 0; i < Orders.length; i++) {
              for (var a = 0; a < Orders[i].Transactions.length; a++) {
                if (Orders[i].Transactions[a].ProductCode === EVENT_BIT_PRODUCT_CODE && Orders[i].Transactions[a].TransTypeCode === 'PUR' && Orders[i].Transactions[a].TransStatus !== 'VOIDED')
                  return true;
              }
            }

            return false;
          },
          isSPOT: function (CurrentEvent, EventBitService) {
            'ngInject'
            return EventBitService
              .isSPOT({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return !!EventBitService.data.isSPOT[CurrentEvent.ShowCode];
              });
          },
          LeadsEmailInformation: function (CurrentEvent, LeadService) {
            'ngInject'
            return LeadService.getLeadsEmailInformation({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return LeadService.data.leadsEmailInformation[CurrentEvent.ShowCode];
              });
          }
        },
        data: {
          pageTitle: 'Leads'
        }
      })
      .state('event.businessCardDetail', {
        url: '/businesscard/detail/:key',
        templateUrl: 'views/businessCardDetail.html',
        controller: 'BusinessCardDetailCtrl',
        controllerAs: 'businessCardDetail',
        resolve: {
          BusinessCard: function ($stateParams, CurrentEvent, BusinessCardLeadService) {
            'ngInject'
            return BusinessCardLeadService.getBusinessCardLead({ showCode: CurrentEvent.ShowCode, key: $stateParams.key })
              .then(function () {
                for (var i = 0; i < BusinessCardLeadService.data.businessCardLeads[CurrentEvent.ShowCode].length; i++)
                  if (BusinessCardLeadService.data.businessCardLeads[CurrentEvent.ShowCode][i].ImageKey == $stateParams.key) {
                    return angular.copy(BusinessCardLeadService.data.businessCardLeads[CurrentEvent.ShowCode][i]);
                  }

                return undefined;
              });
          },
          Orders: function (CurrentEvent, OrderService) {
            'ngInject'
            return OrderService
              .getOrders({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return OrderService.data.orders[CurrentEvent.ShowCode];
              });
          },
          HasPurchasedEventBit: function (Orders) {
            'ngInject'
            var EVENT_BIT_PRODUCT_CODE = '1500';

            for (var i = 0; i < Orders.length; i++) {
              for (var a = 0; a < Orders[i].Transactions.length; a++) {
                if (Orders[i].Transactions[a].ProductCode === EVENT_BIT_PRODUCT_CODE && Orders[i].Transactions[a].TransTypeCode === 'PUR' && Orders[i].Transactions[a].TransStatus !== 'VOIDED')
                  return true;
              }
            }

            return false;
          },
          ExportHeaders: function (CurrentEvent, LeadExportService, BusinessCard, HasPurchasedEventBit) {
            'ngInject'
            return LeadExportService.getHeaders(CurrentEvent.ShowCode, [BusinessCard], HasPurchasedEventBit)
              .then(function () {
                return LeadExportService.data[CurrentEvent.ShowCode].headers;
              });
          }
        },
        data: {
          pageTitle: 'Business Card Detail'
        }
      })
      .state('event.leadDetail', {
        url: '/leads/detail/:id',
        templateUrl: 'views/leadDetail.html',
        controller: 'LeadDetailCtrl',
        controllerAs: 'leadDetail',
        resolve: {
          Lead: function ($stateParams, CurrentEvent, LeadService) {
            'ngInject'
            return LeadService.getLeads({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                for (var i = 0; i < LeadService.data.leads[CurrentEvent.ShowCode].length; i++)
                  if (LeadService.data.leads[CurrentEvent.ShowCode][i].Id == $stateParams.id) {
                    return angular.copy(LeadService.data.leads[CurrentEvent.ShowCode][i]);
                  }

                return undefined;
              });
          },
          LeadsCapturedBy: function (CurrentEvent, LeadService) {
            'ngInject'
            return LeadService.getLeadsCapturedBy({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return LeadService.data.leadsCapturedBy[CurrentEvent.ShowCode];
              });
          },
          Demographics: function (CurrentEvent, DemographicService) {
            'ngInject'
            return DemographicService.getDemographics({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return DemographicService.data.demographics[CurrentEvent.ShowCode];
              });
          },
          Surveys: function ($q, CurrentEvent, SurveyService) {
            'ngInject'
            return SurveyService.getSurveys({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                var surveys = angular.copy(SurveyService.data.surveys[CurrentEvent.ShowCode]).filter(function (x) {
                  return x.IsActive;
                });

                for (var i = 0; i < surveys.length; i++) {
                  surveys[i].SurveyQuestions = surveys[i].SurveyQuestions.filter(function (x) {
                    return x.IsActive;
                  });

                  for (var a = 0; a < surveys[i].SurveyQuestions.length; a++)
                    surveys[i].SurveyQuestions[a].SurveyAnswers = surveys[i].SurveyQuestions[a].SurveyAnswers.filter(function (x) {
                      return x.IsActive;
                    });
                }

                return surveys;
              });
          },
          Orders: function (CurrentEvent, OrderService) {
            'ngInject'
            return OrderService
              .getOrders({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return OrderService.data.orders[CurrentEvent.ShowCode];
              });
          },
          HasPurchasedEventBit: function (Orders) {
            'ngInject'
            var EVENT_BIT_PRODUCT_CODE = '1500';

            for (var i = 0; i < Orders.length; i++) {
              for (var a = 0; a < Orders[i].Transactions.length; a++) {
                if (Orders[i].Transactions[a].ProductCode === EVENT_BIT_PRODUCT_CODE && Orders[i].Transactions[a].TransTypeCode === 'PUR' && Orders[i].Transactions[a].TransStatus !== 'VOIDED')
                  return true;
              }
            }

            return false;
          },
          ExportHeaders: function (CurrentEvent, LeadExportService, Lead, HasPurchasedEventBit) {
            'ngInject'
            return LeadExportService.getHeaders(CurrentEvent.ShowCode, [Lead], HasPurchasedEventBit)
              .then(function () {
                return LeadExportService.data[CurrentEvent.ShowCode].headers;
              });
          },
          CollateralConfig: function (CollateralService, CurrentEvent) {
            'ngInject'
            return CollateralService.getCollateralConfig({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return CollateralService.data.collateralConfig[CurrentEvent.ShowCode];
              });
          },
          Collateral: function (CollateralConfig, CollateralService, CurrentEvent) {
            'ngInject'
            return CollateralService.data.collaterals[CurrentEvent.ShowCode];
          },
          LeadCollateral: function (CollateralService, CurrentEvent, $stateParams, LeadsCapturedBy, Collateral) {
            'ngInject'
            return CollateralService.getLeadCollateral({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return CollateralService.data.leadCollateral[CurrentEvent.ShowCode].filter(
                  function (x) {
                    return x.LeadID == $stateParams.id;
                  }).map(function (x) {
                    return {
                      CollateralName: (Collateral.filter(function (c) { return c.CollateralID == x.CollateralID; }).shift() || {}).DisplayName,
                      SentBy: (LeadsCapturedBy.filter(function (l) { return l.Value == x.ContactID; }).shift() || {}).Key,
                      SentDate: x.SendDate
                    }
                  });
              })
          },
        },
        data: {
          pageTitle: 'Lead Detail'
        }
      })
      .state('event.leadDetail.editSurvey', {
        url: '/editSurvey/:surveyId',
        templateUrl: 'views/leadDetailEditSurvey.html',
        controller: 'LeadDetailEditSurveyCtrl',
        controllerAs: 'surveyEdit',
        resolve: {
          Survey: function ($stateParams, CurrentEvent, SurveyService) {
            'ngInject'
            return SurveyService.getSurvey({ showCode: CurrentEvent.ShowCode, surveyId: $stateParams.surveyId })
              .then(function () {
                for (var i = 0; i < SurveyService.data.surveys[CurrentEvent.ShowCode].length; i++)
                  if (SurveyService.data.surveys[CurrentEvent.ShowCode][i].Id == $stateParams.surveyId) {
                    var survey = angular.copy(SurveyService.data.surveys[CurrentEvent.ShowCode][i]);

                    survey.SurveyQuestions = survey.SurveyQuestions.filter(function (x) {
                      return x.IsActive;
                    });

                    for (var a = 0; a < survey.SurveyQuestions.length; a++)
                      survey.SurveyQuestions[a].SurveyAnswers = survey.SurveyQuestions[a].SurveyAnswers.filter(function (x) {
                        return x.IsActive;
                      });

                    return survey;
                  }

                return undefined;
              });
          }
        },
        data: {
          pageTitle: 'Edit Lead Survey'
        }
      })
      .state('event.leadDetail.editSurvey.editSurveyQuestion', {
        url: '/question/:questionId',
        templateUrl: 'views/leadDetailEditSurveyQuestion.html',
        controller: 'LeadDetailEditSurveyQuestionCtrl',
        controllerAs: 'surveyQuestionEdit',
        resolve: {
          SurveyQuestion: function ($stateParams, CurrentEvent, SurveyService, Survey) {
            'ngInject'
            return SurveyService
              .getSurveyQuestion({
                showCode: CurrentEvent.ShowCode,
                surveyId: Survey.Id,
                surveyQuestionId: $stateParams.questionId
              })
              .then(function (data) {
                if (!data.data.Id)
                  return data.data;
                else
                  for (var i = 0; i < SurveyService.data.surveys[CurrentEvent.ShowCode].length; i++) {
                    if (SurveyService.data.surveys[CurrentEvent.ShowCode][i].Id == Survey.Id) {
                      for (var a = 0; a < SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions.length; a++) {
                        if (SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions[a].Id == $stateParams.questionId) {
                          var surveyQuestion = angular.copy(SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions[a]);
                          surveyQuestion.SurveyAnswers = surveyQuestion.SurveyAnswers.filter(function (x) {
                            return x.IsActive;
                          });
                          return surveyQuestion;
                        }
                      }
                    }
                  }
              });
          }
        },
        data: {
          pageTitle: 'Edit Lead Survey'
        }
      })
      .state('event.badgeSelection', {
        url: '/leads/upload/badgeSelection',
        templateUrl: 'views/badgeSelection.html',
        controller: 'BadgeSelectionCtrl',
        controllerAs: 'badgeSelection',
        data: {
          pageTitle: 'Badge Selection'
        }
      })
      .state('event.leadUpload', {
        url: '/leads/upload/:contactId',
        templateUrl: 'views/leadUpload.html',
        controller: 'LeadUploadCtrl',
        controllerAs: 'leadUpload',
        data: {
          pageTitle: 'Lead Upload'
        }
      })
      .state('event.surveys', {
        abstract: true,
        template: '<ui-view></ui-view>'
      })
      .state('event.surveys.surveys', {
        url: '/surveys',
        templateUrl: 'views/surveys.html',
        controller: 'SurveysCtrl',
        controllerAs: 'surveys',
        resolve: {
          Surveys: function (CurrentEvent, SurveyService) {
            'ngInject'
            return SurveyService.getSurveys({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return SurveyService.data.surveys[CurrentEvent.ShowCode].filter(function (x) {
                  return x.Id;
                });
              });
          }
        },
        data: {
          pageTitle: 'Surveys',
          feedbackName: 'SurveyList'
        }
      })
      .state('event.surveys.copy', {
        url: '/surveys/copy',
        templateUrl: 'views/surveyCopy.html',
        controller: 'SurveyCopyCtrl',
        controllerAs: 'surveyCopy',
        resolve: {
          HistoricalSurveys: function (CurrentEvent, SurveyService) {
            'ngInject'
            return SurveyService.getHistoricalSurveys()
              .then(function () {
                return SurveyService.data.historicalSurveys;
              });
          }
        },
        data: {
          pageTitle: 'Survey Copy',
          feedbackName: 'SurveyCopy'
        }
      })
      .state('event.surveys.survey', {
        url: '/survey/:id',
        templateUrl: 'views/survey.html',
        controller: 'SurveyCtrl',
        controllerAs: 'survey',
        resolve: {
          Surveys: function (CurrentEvent, SurveyService) {
            'ngInject'
            return SurveyService.getSurveys({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return SurveyService.data.surveys[CurrentEvent.ShowCode].filter(function (x) {
                  return x.Id;
                });
              });
          },
          Survey: function ($stateParams, CurrentEvent, Surveys, SurveyService) {
            'ngInject'
            return SurveyService
              .getSurvey({ showCode: CurrentEvent.ShowCode, surveyId: $stateParams.id })
              .then(function () {
                for (var i = 0; i < SurveyService.data.surveys[CurrentEvent.ShowCode].length; i++)
                  if (SurveyService.data.surveys[CurrentEvent.ShowCode][i].Id == $stateParams.id)
                    return SurveyService.data.surveys[CurrentEvent.ShowCode][i];
              });
          }
        },
        data: {
          pageTitle: 'Survey',
          feedbackName: 'SurveyAddEdit'
        }
      })
      .state('event.surveys.emailSurvey', {
        url: '/survey/:id/email',
        templateUrl: 'views/emailSurvey.html',
        controller: 'EmailSurveyCtrl',
        controllerAs: 'emailSurvey',
        resolve: {
          Surveys: function (CurrentEvent, SurveyService) {
            'ngInject'
            return SurveyService.getSurveys({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return SurveyService.data.surveys[CurrentEvent.ShowCode].filter(function (x) {
                  return x.Id;
                });
              });
          },
          Survey: function ($stateParams, CurrentEvent, Surveys, SurveyService) {
            'ngInject'
            return SurveyService
              .getSurvey({ showCode: CurrentEvent.ShowCode, surveyId: $stateParams.id })
              .then(function () {
                for (var i = 0; i < SurveyService.data.surveys[CurrentEvent.ShowCode].length; i++)
                  if (SurveyService.data.surveys[CurrentEvent.ShowCode][i].Id == $stateParams.id)
                    return SurveyService.data.surveys[CurrentEvent.ShowCode][i];
              });
          }
        },
        data: {
          pageTitle: 'Email Survey'
        }
      })
      .state('event.surveys.surveyQuestion', {
        url: '/survey/:id/surveyQuestion/:surveyQuestionId',
        templateUrl: 'views/surveyQuestion.html',
        controller: 'SurveyQuestionCtrl',
        controllerAs: 'surveyQuestion',
        resolve: {
          Surveys: function (CurrentEvent, SurveyService) {
            'ngInject'
            return SurveyService.getSurveys({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return SurveyService.data.surveys[CurrentEvent.ShowCode].filter(function (x) {
                  return x.Id;
                });
              });
          },
          Survey: function ($stateParams, CurrentEvent, Surveys, SurveyService) {
            'ngInject'
            return SurveyService
              .getSurvey({ showCode: CurrentEvent.ShowCode, surveyId: $stateParams.id })
              .then(function () {
                for (var i = 0; i < SurveyService.data.surveys[CurrentEvent.ShowCode].length; i++)
                  if (SurveyService.data.surveys[CurrentEvent.ShowCode][i].Id == $stateParams.id)
                    return SurveyService.data.surveys[CurrentEvent.ShowCode][i];
              });
          },
          SurveyQuestions: function ($stateParams, CurrentEvent, SurveyService) {
            'ngInject'
            return SurveyService.getSurvey({ showCode: CurrentEvent.ShowCode, surveyId: $stateParams.id })
              .then(function () {
                var survey = undefined;
                for (var i = 0; i < SurveyService.data.surveys[CurrentEvent.ShowCode].length; i++) {
                  if (SurveyService.data.surveys[CurrentEvent.ShowCode][i].Id == $stateParams.id) {
                    survey = SurveyService.data.surveys[CurrentEvent.ShowCode][i];
                    break;
                  }
                }

                if (survey) {
                  return survey.SurveyQuestions;
                } else {
                  return [];
                }
              });
          },
          SurveyQuestion: function ($stateParams, CurrentEvent, Surveys, SurveyService) {
            'ngInject'
            return SurveyService
              .getSurveyQuestion({
                showCode: CurrentEvent.ShowCode,
                surveyId: $stateParams.id,
                surveyQuestionId: $stateParams.surveyQuestionId
              })
              .then(function (data) {
                if (!data.data.Id)
                  return data.data;
                else
                  for (var i = 0; i < SurveyService.data.surveys[CurrentEvent.ShowCode].length; i++)
                    if (SurveyService.data.surveys[CurrentEvent.ShowCode][i].Id == $stateParams.id)
                      for (var a = 0; a < SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions.length; a++)
                        if (SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions[a].Id == $stateParams.surveyQuestionId)
                          return SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions[a];
              });
          }
        },
        data: {
          pageTitle: 'Survey Question'
        }
      })
      .state('event.shop', {
        abstract: true,
        url: '/shop',
        templateUrl: 'views/shop.html',
        controller: 'ShopCtrl',
        controllerAs: 'shop',
        resolve: {
          AdditionalInfo: function (CurrentEvent, ShowService) {
            'ngInject'
            if (CurrentEvent.HasActiveSalesTier)
              return ShowService
                .getShowAdditionalInfo({ showCode: CurrentEvent.ShowCode })
                .then(function () {
                  return ShowService.data.showAdditionalInfo[CurrentEvent.ShowCode];
                });
          },
          Cart: function (ngCart) {
            'ngInject'
            return ngCart;
          },
          Products: function (CurrentEvent, ProductsService) {
            'ngInject'
            return ProductsService
              .getProducts({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return ProductsService.data.products[CurrentEvent.ShowCode];
              });
          },
          PurchasedProductIds: function (CurrentEvent, OrderService) {
            'ngInject'
            return OrderService
              .getOrders({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                var orders = OrderService.data.orders[CurrentEvent.ShowCode] || [];
                var productIds = [];

                for (var i = 0; i < orders.length; i++)
                  for (var a = 0; a < orders[i].Transactions.length; a++)
                    if (!productIds.filter(function (x) { return x == orders[i].Transactions[a].ProductId; }).length)
                      productIds.push(orders[i].Transactions[a].ProductId);

                return productIds.join(',');
              });
          },
          DataSalesProducts: function (CurrentEvent, ProductsService) {
            'ngInject'
            return ProductsService.getDataSalesProducts({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return ProductsService.data.dataSalesProducts[CurrentEvent.ShowCode];
              });
          },
          AllAvailableProducts: function (CurrentEvent, Products, ProductsService, DataSalesProducts, PurchasedProductIds) {
            'ngInject'
            //use all possible productIds so this is truly AllAvailableProducts
            var productIds = Products.concat(DataSalesProducts).map(p => {
              return p.Id;
            }).concat(PurchasedProductIds).join(',');

            return ProductsService.getUpsell({ showCode: CurrentEvent.ShowCode, productIds: productIds })
              .then(function () {
                var upSellProducts = ProductsService.data.upsell[CurrentEvent.ShowCode][productIds] || [];
                //console.log('shop - AllAvailableProducts', Products.concat(DataSalesProducts).concat(upSellProducts));
                return Products.concat(DataSalesProducts).concat(upSellProducts);
              });
          },
          /*
           * WI 54461 - Reordered this and injected "AllAvailableProducts." We then use this property to construct the
           * array of UpsellProducts. This is a workaround to get the quantities to properly display on the shopping
           * page(s) for certain products. The issue is that we set the quantity on the AllAvailableProducts array
           * objects, so other arrays need to share memory with those array items in order for the quantities to display
           * properly. This is noted on the products controller. This was introduced when we refactored AllAvailableProducts.
           * TODO: Find a better approach.
           * */
          UpsellProducts: function (CurrentEvent, PurchasedProductIds, ProductsService, Products, DataSalesProducts, Cart, AllAvailableProducts) {
            'ngInject'
            var AllProducts = Products.concat(DataSalesProducts);

            var cartProductIds = Cart.getItems().map(function (e) {
              var product = AllProducts.filter(function (p) {
                return p.ProductPriceID == e._id;
              });

              if (product.length)
                return product[0].Id;
              else
                return 0;
            }).join(',');

            var productIds = [cartProductIds, PurchasedProductIds].filter(function (x) { return !!x; }).join(',');

            return ProductsService.getUpsell({ showCode: CurrentEvent.ShowCode, productIds: productIds })
              .then(function () {
                var upSellProducts = ProductsService.data.upsell[CurrentEvent.ShowCode][productIds] || [];
                var toRet = [];
                if (!upSellProducts) {
                  return toRet;
                }

                // Use "AllAvailableProducts" to construct array. Should be the same items
                // we otherwise would have had, but in memory will be the same objects.
                for (var i = 0; i < upSellProducts.length; i++) {
                  for (var j = 0; j < AllAvailableProducts.length; j++) {
                    if (upSellProducts[i].Id == AllAvailableProducts[j].Id) {
                      toRet.push(AllAvailableProducts[j]);
                    }
                  }
                }

                return toRet;
              });
          },
          Templates: function (CurrentEvent, DataSalesService) {
            'ngInject'
            return DataSalesService.getTemplates({
              showCode: CurrentEvent.ShowCode
            });
          }
        }
      })
      .state('event.shop.detail', {
        url: '/detail',
        templateUrl: 'views/shopDetail.html',
        controllerAs: 'detail',
        controller: function ($anchorScroll, $rootScope, CurrentEvent, EXSConfig) {
          'ngInject'
          var self = this;

          self.isRecommendationEvent = window.RecommendationEvents.toString().split(',').includes(CurrentEvent.ShowCode);
          self.hasActiveSalesTier = CurrentEvent.HasActiveSalesTier;
          self.EXSConfig = EXSConfig;

          $rootScope.showActionWidget = true;

          $anchorScroll();
          //Google Optimize - Custom Trigger
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({ 'event': 'optimize.activate' });
        },
        data: {
          pageTitle: 'Event Detail'
        }
      })
      .state('event.shop.recommendation', {
        url: '/recommendation',
        templateUrl: 'views/recommendation.html',
        controller: 'ProductsCtrl',
        controllerAs: 'recommendation',
        resolve: {
          AdditionalInfo: function (CurrentEvent, ShowService) {
            'ngInject'
            if (CurrentEvent.HasActiveSalesTier)
              return ShowService
                .getShowAdditionalInfo({ showCode: CurrentEvent.ShowCode })
                .then(function () {
                  return ShowService.data.showAdditionalInfo[CurrentEvent.ShowCode];
                });
          },
          PurchasedProducts: function (CurrentEvent, ProductsService, ActiveUserProfile) {
            'ngInject'
            if (CurrentEvent.PriorShowCode != "") {
              return ProductsService.getPurchasedProducts({ showCode: CurrentEvent.PriorShowCode, exhibitorId: ActiveUserProfile.ExhibitorId })
                .then(function () {
                  return ProductsService.data.purchasedProducts[CurrentEvent.PriorShowCode][ActiveUserProfile.ExhibitorId];
                });
            }
            else {
              return [];
            }
          },
          RecommendedProducts: function (AllAvailableProducts, CurrentEvent, ProductsService, ActiveUserProfile, AdditionalInfo) {
            'ngInject'

            var exhibitorId = ActiveUserProfile.ExhibitorId;
            var boothId = 0;
            var boothSize = 0;
            var regCnt = 0;

            if (AdditionalInfo.BoothList.length > 0) {
              boothId = AdditionalInfo.BoothList[0].showExhibitorBoothId;
              boothSize = AdditionalInfo.BoothList[0].boothSize;
              regCnt = Math.ceil(boothSize / 300);
            }
            if (CurrentEvent.ShowCode != 'CON231') {
              exhibitorId = 1001894;
              boothId = 1520143;
            }

            if (AdditionalInfo.BoothList.length > 0 && boothSize > 0)
              return ProductsService.getShowExhibitorBoothRecommendation({ exhibitorId: exhibitorId, showExhibitorBoothID: boothId, boothWorkers: regCnt, surveyBoothsize: boothSize, showCode: CurrentEvent.ShowCode })
                .then(function () {
                  return ProductsService.data.showExhibitorBoothRecommendation[exhibitorId][boothId][regCnt][boothSize][CurrentEvent.ShowCode];
                });
            else {
              return [];
            }
          },
        },
        data: {
          pageTitle: 'Recommendation'
        }
      })
      .state('event.shop.products', {
        url: '/products',
        templateUrl: 'views/products.html',
        controller: 'ProductsCtrl',
        controllerAs: 'products',
        resolve: {
          AdditionalInfo: function (CurrentEvent, ShowService) {
            'ngInject'
            if (CurrentEvent.HasActiveSalesTier)
              return ShowService
                .getShowAdditionalInfo({ showCode: CurrentEvent.ShowCode })
                .then(function () {
                  return ShowService.data.showAdditionalInfo[CurrentEvent.ShowCode];
                });
          },
          PurchasedProducts: function (CurrentEvent, ProductsService, ActiveUserProfile) {
            'ngInject'
            if (CurrentEvent.PriorShowCode != "") {
              return ProductsService.getPurchasedProducts({ showCode: CurrentEvent.PriorShowCode, exhibitorId: ActiveUserProfile.ExhibitorId })
                .then(function () {
                  return ProductsService.data.purchasedProducts[CurrentEvent.PriorShowCode][ActiveUserProfile.ExhibitorId];
                });
            }
            else {
              return [];
            }
          },
          RecommendedProducts: function () {
            'ngInject'
            return [];
          },
        },
        data: {
          pageTitle: 'Products',
          optimizeTag: 'Products_OptimizeTag'
        }
      })
      .state('event.shop.moreInfo', {
        url: '/products/:id/moreInfo',
        templateUrl: 'views/moreInfo.html',
        controller: 'MoreInfoCtrl',
        controllerAs: 'moreInfo',
        resolve: {
          Product: function ($stateParams, CurrentEvent, ProductsService) {
            'ngInject'
            return ProductsService
              .getProducts({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return ProductsService.data.products[CurrentEvent.ShowCode].filter(function (x) {
                  return x.Id == $stateParams.id;
                })[0];
              });
          }
        },
        data: {
          pageTitle: 'More Info'
        }
      })
      .state('event.shop.upsell', {
        url: '/recommended',
        templateUrl: 'views/upsell.html',
        controller: 'ProductsCtrl',
        controllerAs: 'upsell',
        resolve: {
          AllProducts: function ($q, CurrentEvent, ProductsService) {
            'ngInject'
            return $q.all([
              ProductsService.getProducts({ showCode: CurrentEvent.ShowCode }),
              ProductsService.getDataSalesProducts({ showCode: CurrentEvent.ShowCode })              
            ]).then(function () {
              return ProductsService.data.products[CurrentEvent.ShowCode]
                .concat(ProductsService.data.dataSalesProducts[CurrentEvent.ShowCode]);
            });
          },
          Products: function (CurrentEvent, Cart, ProductsService, AllProducts) {
            'ngInject'
            var productIds = Cart.getItems().map(function (e) {
              var product = AllProducts.filter(function (p) {
                return p.ProductPriceID == e._id;
              });

              if (product.length)
                return product[0].Id;
              else
                return 0;
            }).join(',');

            return ProductsService
              .getUpsell({ showCode: CurrentEvent.ShowCode, productIds: productIds })
              .then(function () {
                return ProductsService.data.upsell[CurrentEvent.ShowCode][productIds];
              });
          },
          PurchasedProducts: function (CurrentEvent, ProductsService, ActiveUserProfile) {
            'ngInject'
            if (CurrentEvent.PriorShowCode != "") {
              return ProductsService.getPurchasedProducts({ showCode: CurrentEvent.PriorShowCode, exhibitorId: ActiveUserProfile.ExhibitorId })
                .then(function () {
                  return ProductsService.data.purchasedProducts[CurrentEvent.PriorShowCode][ActiveUserProfile.ExhibitorId];
                });
            }
            else {
              return [];
            }
          },
          RecommendedProducts: function () {
            'ngInject'
            return [];
          },
          UpsellProducts: function () {
            'ngInject'
            return [];
          },
          // WI 54461 - Redefine just for this upsell path. Otherwise the quantities
          // in the inputs were not displaying by default.
          // TODO: Find a better approach.
          AllAvailableProducts: function (Products, UpsellProducts) {
            'ngInject'
            return Products;
          },
          DataSalesProducts: function () {
            'ngInject'
            return [];
          }
        },
        data: {
          pageTitle: 'Recommended Products'
        }
      })
      .state('event.shop.cart', {
        url: '/cart/:productsHash',
        templateUrl: 'views/cart.html',
        params: {
          productsHash: ''
        },
        controller: 'CartCtrl',
        controllerAs: 'cart',
        resolve: {
          Addresses: function (AddressService) {
            'ngInject'
            return AddressService.getBillingAddresses()
              .then(function () {
                return AddressService.data.billingAddresses;
              });
          },
          Countries: function (AddressService) {
            'ngInject'
            return AddressService.getCountries()
              .then(function () {
                return AddressService.data.countries;
              });
          },
          States: function (AddressService) {
            'ngInject'
            return AddressService.getStates()
              .then(function () {
                return AddressService.data.states;
              });
          },
          CartTotal: function (Cart) {
            'ngInject'
            return Cart.totalCost();
          },
          UserEmail: function (ActiveUserProfile, UserService, CurrentEvent) {
            'ngInject'
            return !ActiveUserProfile.BadgeId ? '' : UserService
              .getEmail({ showCode: CurrentEvent.ShowCode, connectKey: ActiveUserProfile.BadgeId })
              .then(function () {
                return UserService.data.email;
              });
          },
          Errors: function ($q, ProductsService, DataSalesService, CurrentEvent, Cart) {
            'ngInject'
            var dataSalesValidation = [];
            var errors = {};

            return $q.all((function () {
              var promises = [];

              // PDJ: This same code is in cart.js, changes here needed there
              var cartItems = Cart.getItems();
              for (var i = 0; i < cartItems.length; i++) {
                if (!!cartItems[i].getData().dataSalesTierTypeCode) {
                  dataSalesValidation.push(cartItems[i].getData().timestamp);
                  promises.push(DataSalesService.validate(CurrentEvent.ShowCode, cartItems[i].getData()));
                }
              }

              return promises;
            })())
              .then(function (results) {
                // PDJ: This same code is in cart.js, changes here needed there
                for (var i = 0; i < results.length; i++) {
                  if (results[i].length) {
                    errors.dataSales = errors.dataSales || {};
                    errors.dataSales[dataSalesValidation[i]] = results[i];
                  }
                }

                return errors;
              });
          }
        },
        data: {
          pageTitle: 'Cart',
          optimizeTag: 'Cart_OptimizeTag'
        }
      })
      .state('event.shop.address', {
        url: '/address',
        templateUrl: 'views/address.html',
        controller: 'AddressCtrl',
        controllerAs: 'address',
        resolve: {
          Addresses: function (AddressService) {
            'ngInject'
            return AddressService.getBillingAddresses()
              .then(function () {
                return AddressService.data.billingAddresses;
              });
          },
          Countries: function (AddressService) {
            'ngInject'
            return AddressService.getCountries()
              .then(function () {
                return AddressService.data.countries;
              });
          },
          States: function (AddressService) {
            'ngInject'
            return AddressService.getStates()
              .then(function () {
                return AddressService.data.states;
              });
          },
          CartTotal: function (Cart) {
            'ngInject'
            return Cart.totalCost();
          }
        },
        data: {
          pageTitle: 'Address'
        }
      })
      .state('event.shop.pciauth', {
        url: '/checkout?auth',
        template: '<ui-view></ui-view>',
        resolve: {
          pciResult: function ($stateParams, CurrentEvent, OrderService) {
            'ngInject'
            if ($stateParams.auth) {
              return OrderService.completeOrder({
                data: {
                  auth: $stateParams.auth,
                  showCode: CurrentEvent.ShowCode
                }
              })
                .then(function (data) {
                  return data;
                }, function () {
                  return null;
                });
            }
            else
              return null;
          }
        },
        controller: function ($anchorScroll, pciResult, $state) {
          'ngInject'
          $anchorScroll();
          if (pciResult && pciResult.IsAuthorized)
            $state.go('event.shop.confirmation', { salesOrderId: pciResult.SalesOrderId });
          else
            $state.go('event.shop.cart');
        },
        data: {
          pageTitle: 'Checkout'
        }
      })
      .state('event.shop.checkoutonly', {
        url: '/checkoutonly',
        templateUrl: 'views/checkoutOnly.html',
        controller: 'CheckoutOnlyCtrl',
        controllerAs: 'checkoutonly',
        resolve: {
          pendingOrder: function (OrderService) {
            'ngInject'
            if (window.SalesOrderId) {
              return OrderService.getOrder({ salesOrderId: window.SalesOrderId }, true)
                .then(function (resp) {
                  return resp.data;
                }, function () {
                  return null;
                });
            }
            else return null;
          }
        },
        data: {
          pageTitle: 'CheckoutOnly'
        }
      })
      .state('event.shop.checkout', {
        url: '/checkout',
        templateUrl: 'views/checkout.html',
        controller: 'CheckoutCtrl',
        controllerAs: 'checkout',
        resolve: {
          CartTotal: function (Cart) {
            'ngInject'
            return Cart.totalCost();
          }
        },
        data: {
          pageTitle: 'Checkout'
        }
      })
      .state('event.shop.confirmation', {
        url: '/confirmation/:salesOrderId',
        templateUrl: 'views/confirmation.html',
        resolve: {
          ConfirmationHtml: function ($stateParams, CurrentEvent, OrderService) {
            'ngInject'
            return OrderService.getConfirmationHtml({
              showCode: CurrentEvent.ShowCode,
              salesOrderId: $stateParams.salesOrderId
            }, true)
              .then(function () {
                return OrderService.data.confirmations[CurrentEvent.ShowCode][$stateParams.salesOrderId];
              });
          }
        },
        controller: 'ConfirmationCtrl',
        controllerAs: 'confirmation',
        data: {
          pageTitle: 'Confirmation'
        }
      })
      .state('event.shop.dataSales', {
        url: '/dataSales/:timestamp',
        abstract: true,
        template: '<ui-view class="dataSales"></ui-view>',
        resolve: {
          DataSalesCartItem: function ($stateParams, Cart) {
            'ngInject'
            var cartItems = Cart.getItems();
            var data;

            for (var i = 0; i < cartItems.length; i++) {
              data = cartItems[i].getData();

              if (data.timestamp == $stateParams.timestamp)
                return cartItems[i];
            }

            return null;
          },
          DataSalesProduct: function (DataSalesCartItem, CurrentEvent, ProductsService) {
            'ngInject'
            return ProductsService.getDataSalesProducts({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                return ProductsService.data.dataSalesProducts[CurrentEvent.ShowCode].filter(function (x) {
                  return x.ProductPriceID == DataSalesCartItem.getData().productPriceId;
                })[0];
              });
          },
          TourHistory: function ($window, CurrentEvent) {
            'ngInject'
            var tourRaw = $window.localStorage.getItem('EPTOUR');

            if (tourRaw)
              tourRaw = angular.fromJson(tourRaw);

            var tour = tourRaw || {};
            tour[CurrentEvent.ShowCode] = tour[CurrentEvent.ShowCode] || {};
            tour[CurrentEvent.ShowCode].dataSales = tour[CurrentEvent.ShowCode].dataSales || false;

            tour[CurrentEvent.ShowCode].save = function () {
              $window.localStorage.setItem('EPTOUR', angular.toJson(tour));
            };

            return tour[CurrentEvent.ShowCode];
          }
        }
      })
      .state('event.shop.dataSales.filterBuilder', {
        url: '/listBuilder',
        controller: 'DataSalesCtrl',
        controllerAs: 'dataSales',
        templateUrl: 'views/dataSales.html',
        resolve: {
          FilterGroup: function () {
            'ngInject'
            return function (name) {
              this.Name = name;
              this.FilterDetails = {};
            };
          },
          FilterDetail: function () {
            'ngInject'
            return function (id, description) {
              this.Id = String(id);
              this.Description = description;
              this.FilterPicks = {};
            };
          },
          FilterPick: function () {
            'ngInject'
            return function (id, description) {
              this.Id = String(id);
              this.Description = description;
            };
          },
          ExcludedFieldPicks: function (DataSalesProduct, DataSalesService) {
            'ngInject'
            return DataSalesService.getPreFilterFields({
              showCode: DataSalesProduct.DataSalesTargetShowCode
            }).then(function () {
              return DataSalesService.data.preFilterFields[DataSalesProduct.DataSalesTargetShowCode].filter(function (x) {
                return x.Op === 1; //isnotoneof
              });
            });
          },
          IncludedFieldPicks: function (DataSalesProduct, DataSalesService) {
            'ngInject'
            return DataSalesService.getPreFilterFields({
              showCode: DataSalesProduct.DataSalesTargetShowCode
            }).then(function () {
              return DataSalesService.data.preFilterFields[DataSalesProduct.DataSalesTargetShowCode].filter(function (x) {
                return !x.Op; //isoneof
              });
            });
          },
          Demographics: function (DataSalesProduct, DemographicService, FilterGroup, FilterDetail, FilterPick, ExcludedFieldPicks, IncludedFieldPicks) {
            'ngInject'
            return DemographicService.getDemographics({ showCode: DataSalesProduct.DataSalesTargetShowCode })
              .then(function () {
                var demographics = DemographicService.data.demographics[DataSalesProduct.DataSalesTargetShowCode].filter(function (e) {
                  return e.DataSalesAllowUserFilters;
                });

                var filterGroup = new FilterGroup('Demographics');

                for (var i = 0; i < demographics.length; i++) {
                  var filterDetail = new FilterDetail(demographics[i].FieldName, demographics[i].Description);
                  var excludedPicks = ExcludedFieldPicks.filter(function (x) { return x.FieldName.toLowerCase() === demographics[i].FieldName.toLowerCase(); });
                  var includedPicks = IncludedFieldPicks.filter(function (x) { return x.FieldName.toLowerCase() === demographics[i].FieldName.toLowerCase(); });

                  for (var a = 0; a < demographics[i].FieldPicks.length; a++) {
                    if ((!excludedPicks.length || !excludedPicks.filter(function (x) { return !!x.PickIDs.filter(function (y) { return y == demographics[i].FieldPicks[a].Id; }).length; }).length)
                      && !(includedPicks.length && !includedPicks.filter(function (x) { return !!x.PickIDs.filter(function (y) { return y == demographics[i].FieldPicks[a].Id; }).length; }).length)) {
                      var pickDesc = '';
                      if (demographics[i].IsPickCodeDesc) {
                        pickDesc = demographics[i].FieldPicks[a].PickCode + ' - ' + demographics[i].FieldPicks[a].Description;
                      } else {
                        pickDesc = demographics[i].FieldPicks[a].Description;
                      }
                      filterDetail.FilterPicks[demographics[i].FieldPicks[a].PickCode] = new FilterPick(demographics[i].FieldPicks[a].PickCode, pickDesc);
                    }
                  }

                  filterGroup.FilterDetails[demographics[i].FieldName] = filterDetail;
                }

                return filterGroup;
              });
          },
          Location: function (FilterGroup) {
            'ngInject'
            return new FilterGroup('Location');
          },
          Countries: function (DataSalesProduct, LeadService, Location, FilterDetail, FilterPick, ExcludedFieldPicks, IncludedFieldPicks) {
            'ngInject'
            return LeadService.getCountries({ showCode: DataSalesProduct.DataSalesTargetShowCode })
              .then(function () {
                var countries = LeadService.data.countries[DataSalesProduct.DataSalesTargetShowCode];
                var filterDetail = new FilterDetail('CountryCode', 'Country');
                var excludedPicks = ExcludedFieldPicks.filter(function (x) { return x.FieldName.toLowerCase() === filterDetail.Id.toLowerCase(); });
                var includedPicks = IncludedFieldPicks.filter(function (x) { return x.FieldName.toLowerCase() === filterDetail.Id.toLowerCase(); });

                for (var i = 0; i < countries.length; i++) {
                  if ((!excludedPicks.length || !excludedPicks.filter(function (x) { return !!x.PickCodes.filter(function (y) { return y == countries[i].Key; }).length; }).length)
                    && !(includedPicks.length && !includedPicks.filter(function (x) { return !!x.PickCodes.filter(function (y) { return y == countries[i].Key; }).length; }).length))
                    //4-5-2016 - RJZ - hack to replace us value with blank
                    filterDetail.FilterPicks[countries[i].Value === 'UNITED STATES' ? '' : countries[i].Value] = new FilterPick(countries[i].Value === 'UNITED STATES' ? '' : countries[i].Value, countries[i].Key);
                }

                Location.FilterDetails.Country = filterDetail;
              });
          },
          States: function (DataSalesProduct, LeadService, Location, FilterDetail, FilterPick) {
            'ngInject'
            return LeadService.getAllStates({ showCode: DataSalesProduct.DataSalesTargetShowCode })
              .then(function () {
                var states = LeadService.data.allStates[DataSalesProduct.DataSalesTargetShowCode];
                var filterDetail = new FilterDetail('StateCode', 'State');

                for (var i = 0; i < states.length; i++) {
                  filterDetail.FilterPicks[states[i].Value] = new FilterPick(states[i].Value, states[i].Key);
                }

                Location.FilterDetails.State = filterDetail;
              });
          },
          Filters: function (Demographics, Location) {
            'ngInject'
            var filters = {};

            filters[Location.Name] = Location;
            filters[Demographics.Name] = Demographics;

            return filters;
          },
          DataSalesConfig: function (DataSalesService, DataSalesProduct) {
            'ngInject'
            return DataSalesService.getConfig({
              showCode: DataSalesProduct.DataSalesTargetShowCode
            }).then(function () {
              return DataSalesService.data.config[DataSalesProduct.DataSalesTargetShowCode];
            });
          }
        },
        data: {
          pageTitle: 'Filter Builder'
        }
      })
      .state('event.shop.dataSales.emailBuilder', {
        url: '/emailBuilder',
        controller: 'DataSalesTemplateCtrl',
        controllerAs: 'dataSalesTemplate',
        templateUrl: 'views/dataSalesTemplate.html',
        resolve: {
          Strategy: function () {
            'ngInject'
            return undefined;
          },
          Templates: function (DataSalesProduct, DataSalesService) {
            'ngInject'
            return DataSalesService.getTemplates({
              showCode: DataSalesProduct.DataSalesTargetShowCode
            }).then(function () {
              var templates = [];

              for (var i = 0; i < DataSalesService.data.templates[DataSalesProduct.DataSalesTargetShowCode].length; i++) {
                var template = angular.copy(DataSalesService.data.templates[DataSalesProduct.DataSalesTargetShowCode][i]);
                var bodyFields = {};

                for (var a = 0; a < template.BodyFields.length; a++)
                  bodyFields[template.BodyFields[a].KeyName] = template.BodyFields[a];

                template.BodyFields = bodyFields;
                templates.push(template);
              }

              return templates;
            });
          },
          Email: function (DataSalesCartItem) {
            'ngInject'
            return DataSalesCartItem.getData().email;
          }
        },
        params: {
          setSubmitted: false
        },
        data: {
          pageTitle: 'Email Builder'
        }
      })
      .state('event.shop.dataSales.mailHouse', {
        url: '/mailHouse',
        templateUrl: 'views/dataSalesMailHouse.html',
        controller: 'DataSalesMailHouseCtrl',
        controllerAs: 'dataSalesMailHouse',
        resolve: {
          MailHouse: function (DataSalesCartItem) {
            'ngInject'
            return DataSalesCartItem.getData().mailHouse;
          },
          DataSalesConfig: function (DataSalesService, DataSalesProduct) {
            'ngInject'
            return DataSalesService.getConfig({
              showCode: DataSalesProduct.DataSalesTargetShowCode
            }).then(function () {
              return DataSalesService.data.config[DataSalesProduct.DataSalesTargetShowCode];
            });
          }
        },
        data: {
          pageTitle: 'Mail House Information'
        }
      })
      .state('event.shop.dataSales.preview', {
        url: '/preview',
        templateUrl: 'views/dataSalesPreview.html',
        controllerAs: 'dataSalesPreview',
        resolve: {
          Email: function (DataSalesCartItem) {
            'ngInject'
            return DataSalesCartItem.getData().email;
          },
          Template: function (DataSalesProduct, DataSalesService, Email) {
            'ngInject'
            return DataSalesService.getTemplates({
              showCode: DataSalesProduct.DataSalesTargetShowCode
            }).then(function () {
              var templates = [];

              for (var i = 0; i < DataSalesService.data.templates[DataSalesProduct.DataSalesTargetShowCode].length; i++) {
                var template = angular.copy(DataSalesService.data.templates[DataSalesProduct.DataSalesTargetShowCode][i]);
                var bodyFields = {};

                for (var a = 0; a < template.BodyFields.length; a++)
                  bodyFields[template.BodyFields[a].KeyName] = template.BodyFields[a];

                template.BodyFields = bodyFields;
                templates.push(template);
              }

              templates = templates.filter(function (x) {
                return x.TemplateID == Email.TemplateId;
              });

              return templates.length ? templates[0] : null;
            });
          }
        },
        controller: function ($state, $anchorScroll, Template, Email) {
          'ngInject'
          var self = this;

          self.email = Email;
          self.selectedTemplate = Template;

          var templateHtml = angular.copy(Template.BodyHtml);
          var iframe = document.getElementById('TemplatePreview');

          $anchorScroll();

          for (var key in self.selectedTemplate.BodyFields)
            templateHtml = templateHtml.replace(new RegExp(self.selectedTemplate.BodyFields[key].Tag, 'g'), self.email[key]);

          if (iframe) {
            iframe.contentWindow.document.body.innerHTML = '';
            iframe.contentWindow.document.write(templateHtml);
          }

          self.next = function () {
            $state.go('event.shop.dataSales.terms');
          };
        },
        data: {
          pageTitle: 'Email Review'
        }
      })
      .state('event.shop.dataSales.terms', {
        url: '/terms',
        templateUrl: 'views/dataSalesTerms.html',
        controllerAs: 'dataSalesTerms',
        controller: function ($state, $stateParams, $timeout, $anchorScroll, DataSalesConfig, DataSalesProduct, DataSalesCartItem, Cart) {
          'ngInject'
          var self = this;

          self.dataSalesConfig = DataSalesConfig;
          self.dataSalesCartData = DataSalesCartItem.getData();
          self.dataSalesProduct = DataSalesProduct;
          self.enableTermsCheckbox = false;
          self.isEmail = DataSalesProduct.ProductCode == '203';
          self.isDownload = DataSalesProduct.ProductCode == '204';
          self.isMailHouse = DataSalesProduct.ProductCode == '205';
          self.isSet = self.dataSalesProduct.DataSalesTierTypeCode == "SET";
          $anchorScroll();

          if (self.isMailHouse)
            self.previous = 'event.shop.dataSales.mailHouse';
          else if (self.isEmail)
            self.previous = 'event.shop.dataSales.emailBuilder';
          else if (!self.isSet && self.isDownload)
            self.previous = 'event.shop.dataSales.filterBuilder';
          else
            self.previous = "event.shop.products";

          if ($stateParams.setSubmitted)
            $timeout(function () { self.termsForm.$setSubmitted(); });

          self.saveCart = function () {
            Cart.$save();
          };

          self.next = function () {
            if (!self.termsForm.$valid)
              self.termsForm.$setSubmitted();
            else
              $state.go('event.shop.cart');
          };
        },
        resolve: {
          DataSalesConfig: function (DataSalesService, DataSalesProduct) {
            'ngInject'
            return DataSalesService.getConfig({
              showCode: DataSalesProduct.DataSalesTargetShowCode
            }).then(function () {
              return DataSalesService.data.config[DataSalesProduct.DataSalesTargetShowCode];
            });
          }
        },
        params: {
          setSubmitted: false
        },
        data: {
          pageTitle: 'Terms & Conditions'
        }
      })
      //Resources states
      .state('event.resources', {
        abstract: true,
        template: '<ui-view></ui-view>'
      })
      .state('event.resources.resources', {
        url: '/resources',
        templateUrl: 'views/resources.html',
        controller: 'ResourcesCtrl',
        controllerAs: 'resources',
        resolve: {
          ExhibitorResources: function (CurrentEvent, ResourceService) {
            'ngInject'
            return ResourceService.getExhibitorResources({ showCode: CurrentEvent.ShowCode })
              .then(function () {
                var resources = angular.copy(ResourceService.data.resources[CurrentEvent.ShowCode]);

                return resources;
              });
          },
          AllResources: function (ResourceService) {
            'ngInject'
            var subFolderName = 'ALL';
            return ResourceService.getResources({ subFolder: subFolderName })
              .then(function () {
                var resources = angular.copy(ResourceService.data.resources[subFolderName]);
                return resources;
              });
          }
        },
        data: {
          pageTitle: 'Resources',
          feedbackName: 'ResourcesList'
        }
      })
      .state('404', {
        url: '/oops',
        templateUrl: '404.html',
        data: {
          pageTitle: 'Oops!'
        }
      });
  })
  .config(function ($provide, SearchBase, CheckoutBase) {
    'ngInject'
    $provide.value('ActiveUserProfile', angular.copy(window.ActiveUserProfile));
    $provide.value('EXSConfig', angular.copy(window.EXSConfig));
    $provide.value('EnvironmentConfig', angular.copy(window.EnvironmentConfig));
    $provide.value('Search', angular.copy(SearchBase));
    $provide.value('Checkout', angular.copy(CheckoutBase));
  })
  .config(function (cfpLoadingBarProvider) {
    'ngInject'
    // set loading bar preferences
    cfpLoadingBarProvider.includeSpinner = false;
    // only show the loading bar if the resolve takes more than 1 second. this prevents the user
    // from seeing the loading bar flash on the screen when the resolve completes quickly.
    // cfpLoadingBarProvider.latencyThreshold = 1000;
    // if(EnvironmentConfig.InAppPurchase)
    // 	cfpLoadingBarProvider.includeBar = false;
  })
  .run(function ($rootScope, $location, $interval, $timeout, ipCookie, UserService, ActiveUserProfile, EnvironmentConfig, $state, $window) {
    'ngInject'
    var session = ipCookie('EPSession'),
      returnURL = ipCookie('EPReturnURL'),
      expire = ipCookie('EPExpire'),
      utm = ipCookie('EPUTM'),
      authCheckInProgress = false,
      topBar;

    $rootScope.hideFeedbackWidget = false;

    if (window.dataLayer && utm) {
      dataLayer.push({ event: 'utmChange', utm: utm });
    }

    $timeout(function () {
      topBar = angular.element('.top-bar');
    });

    if (session && session.ShowCode) {
      if (session.Location) {
        if (session.Location == 'shop')
          $location.path('event/' + session.ShowCode + '/shop/products');
        else if (session.Location == 'lead')
          $location.path('event/' + session.ShowCode + '/leads');
        else if (session.Location == 'eventbit')
          $location.path('event/' + session.ShowCode + '/dashboard/eventBit');
        else if (session.Location == 'attendeeData')
          $location.path('event/' + session.ShowCode + '/dashboard/attendeeData');
        else if (session.Location == 'swap' || session.Location == 'landing')
          $location.path('event/' + session.ShowCode + '/dashboard/swap');
        else if (session.Location == 'landing')
          $location.path('event/' + session.ShowCode + '/');
        else if (session.Location == 'products')
          $location.path('event/' + session.ShowCode + '/shop/products');
        else if (session.Location == 'resources')
          $location.path('event/' + session.ShowCode + '/resources');

        session.Location = null;
        ipCookie('EPSession', session, { path: EnvironmentConfig.ReturnURLPath });
      }
    }

    if (returnURL) {
      ipCookie.remove('EPReturnURL', { path: EnvironmentConfig.ReturnURLPath });
      $location.path(returnURL);
    }

    $rootScope.$watch(function () { return ipCookie('EPExpire'); }, function (newValue) {
      expire = newValue;
    });

    var reAuth = window.setInterval(function () {
      if (!authCheckInProgress && (expire - new Date()) <= 60000) {
        authCheckInProgress = true;

        if (window.confirm('Your login is about to expire. Would you like to continue using the site?')) {
          if (expire - new Date() > 0) {
            UserService
              .reAuth({ ignoreLoadingBar: true })
              .finally(function () {
                authCheckInProgress = false;
              });
          }
          else {
            window.clearInterval(reAuth);
            window.location = EnvironmentConfig.SiteRoot + 'home/logout' + (ActiveUserProfile.SWAPOnly ? '/?swap=true' : '');
          }
        }
        else
          window.location = EnvironmentConfig.SiteRoot + 'home/logout' + (ActiveUserProfile.SWAPOnly ? '/?swap=true' : '');
      }
    }, 10000);

    $rootScope.reAuth = function () {
      if (!authCheckInProgress && ((expire - new Date()) <= 300000)) {
        authCheckInProgress = true;
        UserService
          .reAuth({ ignoreLoadingBar: true })
          .finally(function () {
            authCheckInProgress = false;
          });
      }
    };

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, error) {
      $rootScope.currentState = toState.name;

      if (topBar && topBar.hasClass('expanded'))
        topBar.removeClass('expanded');

      if (toState.redirectTo) {
        event.preventDefault();
        $state.go(toState.redirectTo, toParams, { location: 'replace' });
      }
    });

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams, error) {
      $rootScope.previousState = fromState.name;
      $rootScope.currentState = toState.name;

      //shop.detail, checkout, and checkoutonly will turn this on
      $rootScope.showActionWidget = false;

      $timeout(function () {
        var eventCode = getEventCode();
        $rootScope.pageTitle = (toState.data && toState.data.pageTitle) ? ((toState.data.pageTitle + (eventCode ? (' | ' + eventCode) : '')) + ' | Exhibitor Portal') :
          (eventCode ? (eventCode + ' | Exhibitor Portal') : 'Exhibitor Portal');

        $rootScope.optimizeTag = (toState.data && toState.data.optimizeTag) ? toState.data.optimizeTag : null;

        //google analytics tracking
        if (window.dataLayer) {
          const pageViewUrl = () => {
            // Do not include the specific ID(s) in the URL sent. Construct the URL from the current location
            // as well as the state, if applicable.
            let pageUrl = $window.location.pathname;

            // Trim trailing slash at the end
            if (pageUrl.slice(-1) === '/')
              pageUrl = pageUrl.slice(0, -1);

            if ($state && $state.$current && $state.$current.url && $state.$current.url.source) {
              // Including the '#' character that is normally in the URL seems to chop off the rest of
              // the URL for the data collection.
              pageUrl += $state.$current.url.source;

              // Replace show code parameter with the value of the show code
              pageUrl = pageUrl.replace(':showcode', eventCode);
            }

            if (['', '/'].includes(pageUrl))
              pageUrl = '/eventSearch'

            return pageUrl;
          };

          window.dataLayer.push({
            event: 'ngRouteChange',
            attributes: {
              route: pageViewUrl(),
              pageTitle: $rootScope.pageTitle
            }
          });
        }

        // Experient feedback widget
        $rootScope.hideFeedbackWidget = !ExperientFeedback || $rootScope.currentState.indexOf('eventBit') > -1;
        if (ExperientFeedback) {
          // Going to set the location of the widget to the state name unless a
          // specific name is given for it in the state's data object
          var location = toState.name;
          var data = toState.data;
          if (data && data.feedbackName) {
            location = data.feedbackName;
          } else {
            location = location.replace('root.', '').replace('event.', '').replace('surveys.', '').replace('collateral.', '');
            var statePath = location.split('.');
            if (statePath && statePath.length > 1) {
              location = '';
              for (var i = 0; i < statePath.length; i++) {
                location = location + (statePath[i].charAt(0).toUpperCase() + statePath[i].slice(1));
              }
            } else {
              location = location.charAt(0).toUpperCase() + location.slice(1);
            }
          }

          window.exfb = new ExperientFeedback({
            Application: "ExhibitorPortal",
            Location: location,
            Event: window.getEventCode(),
            UserId: window.ActiveUserProfile.Id,
            UserType: "EXSEnterprise.Contact",
            QuestionText: "Is this page easy to use?"
          }, "exfb-content");
        }
      });

    });

    //make sure we are logging errors from ui-router
    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
      $rootScope.currentState = fromState.name;

      console.log({
        event: event,
        toState: toState,
        toParams: toParams,
        fromState: fromState,
        fromParams: fromParams,
        error: error
      });
    });
  })
  .factory('ECR', function ($window, $rootScope) {
    'ngInject'
    return {
      set: function (val) {
        $window.sessionStorage && $window.sessionStorage.setItem('extraConfirmationRecipient', val);
        return this;
      },
      get: function () {
        return $window.sessionStorage && $window.sessionStorage.getItem('extraConfirmationRecipient');
      },
      remove: function () {
        $window.sessionStorage && $window.sessionStorage.removeItem('extraConfirmationRecipient');
      }
    };
  })
  .factory('UtilityFactory', function () {
    'ngInject'
    return {
      HTMLEncode: function (str) {
        var i = str.length,
          aRet = [];

        while (i--) {
          var iC = str[i].charCodeAt();
          if (iC > 160) {
            aRet[i] = '&#' + iC + ';';
          } else {
            aRet[i] = str[i];
          }
        }
        return aRet.join('');
      }
    };
  });
