'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:MyShowsCtrl
 * @description
 * # MyShowsCtrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var MyShowsCtrl = function ($state, $scope, $window, $timeout, ActiveUserProfile, MyEvents, Search, ShowService, LeadActivityService, OrderService, EnvironmentConfig, ngCart, EXSConfig) {
    'ngInject'
		var self = this;

		self.shows = MyEvents;
		self.search = Search.events;
		self.accessCode = '';
		self.profile = ActiveUserProfile;
		self.modalWidth = angular.element($window).width() > 641 ? '50%' : '90%';
		self.EXSConfig = EXSConfig;
		self.filterSelections = {
			current: true,
			past: false,
			future: true
		};

		if(EnvironmentConfig.MarketingSplash && !$window.localStorage.getItem('EPMODAL')){
			self.showModal = true;
			$window.localStorage.setItem('EPMODAL', true);
		}

		self.selectShow = function (show) {
			ShowService.hasActivations({
				showCode: show.ShowCode
			})
			.then(function() {
				$timeout(function() { ngCart.load(show.ShowCode); });

				if(ShowService.data.activations[show.ShowCode])
					LeadActivityService.getLeadSummary({ showCode: show.ShowCode })
					.then(function() {
						if(LeadActivityService.data.leadSummary[show.ShowCode][1].Count)
							$state.go('event.dashboard.landing', { showcode: show.ShowCode });
						else
							$state.go('event.shop.products', { showcode: show.ShowCode });
					});
				else
					OrderService.getOrders({ showCode: show.ShowCode })
						.then(function() {
							var orders = OrderService.data.orders[show.ShowCode] || [];

					    	for(var i = 0; i < orders.length; i++){
								for(var a = 0; a < orders[i].Transactions.length; a++){
									if(orders[i].Transactions[a].DataSalesListId && orders[i].Transactions[a].TransTypeCode === 'PUR' && orders[i].Transactions[a].TransStatus !== 'VOIDED'){
										$state.go('event.dashboard.emailCampaigns', { showcode: show.ShowCode });
										return;
									}
								}
							}

              $state.go('event.shop.products', { showcode: show.ShowCode });
						});
			});
		};

		self.changeFilter = function(e) {
            e.target.value = e.target.checked ? 'true' : '';
        };

		self.showFilter = function(show) {
			if(self.filterSelections.current && show.current)
				return true;
			else if(self.filterSelections.past && show.past)
				return true;
			else if(self.filterSelections.future && show.future)
				return true;
			else if (!(self.filterSelections.current || self.filterSelections.past || self.filterSelections.future))
				return true;
			else
				return false;
		};

		self.addShow = function() {
			return ShowService
				.addShow({accessCode: self.accessCode}, true)
				.then(function() {
					ShowService.getShows({}, true)
						.then(function() {
							self.accessCode = '';
							self.shows = ShowService.data.shows;
						});
				});
		};
	};

	angular.module('exhibitorPortalApp')
	  .controller('MyShowsCtrl', MyShowsCtrl);
})(angular, window);

