'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:AddressCtrl
 * @description
 * # AddressCtrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var AddressCtrl = function ($timeout, $state, $anchorScroll, CartTotal, CurrentEvent, Addresses, Countries, States, AdditionalInfo, Checkout, AddressService, EnvironmentConfig) {
    'ngInject'
        var self = this;

        self.isRecommendationEvent = window.RecommendationEvents.toString().split(',').includes(CurrentEvent.ShowCode);
        self.addresses = Addresses;
        self.countries = Countries;
        self.states = States;
        self.checkout = Checkout;
        self.editMode = !Addresses.length;
        self.editAddress = {};
        self.cartTotal = CartTotal;
        self.environmentConfig = EnvironmentConfig;
        self.boothList = AdditionalInfo.BoothList || [];
        self.currentEvent = CurrentEvent;
        self.addressControl = {};

        $anchorScroll();

        self.USA = Countries.filter(function(x) {
            return x.CountryCode === 'US';
        })[0];

        if(!self.addresses.length)
            self.editAddress = {
                CountryId: self.USA.Id
            };

        if (!self.checkout.address) {
            var preferred = self.addresses.length ? self.addresses.filter(function (x) {
                return x.IsPreferred;
            }) : null;

            self.checkout.address = (preferred && preferred.length) ? preferred[0] :
                ((self.addresses && self.addresses.length) ? self.addresses[0] : null);
        }

        if (!self.checkout.boothId && self.boothList && self.boothList.length) {
          self.checkout.boothId = self.boothList[0].showExhibitorBoothId;
        }

        self.next = function() {
          if (self.addressControl.editMode && !self.addresses.length) {
            if (!self.addressControl.addressForm.$valid) {
              self.addressControl.addressForm.$setSubmitted();
            }
            else {
              self.addressControl.save().then(function () {
                $state.go('event.shop.checkout');
              });
            }
          }
          else if (self.addressControl.editMode) {
            self.addressControl.save().then(function () {
              $state.go('event.shop.checkout');
            });
          }
          else if (!self.addressControl.editMode && !self.checkout.address) {
            window.alert('Please make a selection before proceeding.');
          }
          else if(self.checkout.address.AddressTypeCode !== 'BILLING'){
            self.editAddress = self.checkout.address;

            self.addressControl.save(true).then(function() {
                $state.go('event.shop.checkout');
            });
          }
          else
              $state.go('event.shop.checkout');
        };

        self.save = function(force) {
            if(force || self.addressForm.$valid) {
                if(self.editAddress.AddressTypeCode !== 'BILLING')
                    self.editAddress.Id = 0;

            	angular.extend(self.editAddress,
                {
            		AddressTypeCode: 'BILLING',
            		CountryName: self.editAddress.CountryId ? self.countries.filter(function(x) {
            			return x.Id === self.editAddress.CountryId;
            		})[0].CountryName : undefined
            	});

            	return AddressService.save({
            		data: {
            			showCode: CurrentEvent.ShowCode,
            			addresses: (function(){
            				if(self.editAddress.IsPreferred){
            					var oldPreferred = self.addresses.filter(function(x) {
            						return x.Id !== self.editAddress.Id && x.IsPreferred;
            					});

            					if(oldPreferred.length){
            						var modified = oldPreferred.map(function(x) {
            							x.IsPreferred = false;
            							return x;
            						});

            						modified.push(self.editAddress);

            						return modified;
            					}
            					else
            						return [self.editAddress];
            				}
            				else
            					return [self.editAddress];
            			})()
            		}
            	})
            	.then(function() {
            		AddressService.getBillingAddresses({}, true)
            			.then(function() {
                            //add
                            if(!self.editAddress.Id){
                                //scan the existing address array and find the id that is missing to determine
                                //which address is our new one
                                for(var i = 0; i < AddressService.data.billingAddresses.length; i++)
                                    if(!self.addresses.filter(function(x) { return x.Id === AddressService.data.billingAddresses[i].Id; }).length){
                                        self.checkout.address = AddressService.data.billingAddresses[i];
                                        break;
                                    }
                            }
                            //edit
                            else {
                                self.checkout.address = AddressService.data.billingAddresses.filter(function(x) { return x.Id === self.editAddress.Id; })[0];
                            }

                            self.addresses = AddressService.data.billingAddresses;
            				self.editMode = false;
            			});
            	});
            }
        };

	};

	angular.module('exhibitorPortalApp')
		.controller('AddressCtrl', AddressCtrl);
})(angular, window);
