'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.controller:LeadUploadCtrl
 * @description
 * # LeadUploadCtrl
 * Controller of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  var LeadUploadCtrl = function ($upload, $stateParams, LeadService, CurrentEvent, EnvironmentConfig) {
    'ngInject'
        var self = this;

        self.toggleTechRequirements = false;
        self.barcodes = '';
        self.siteRoot = EnvironmentConfig.SiteRoot;

        self.uploadBarcodes = function() {
			return LeadService.uploadBarcodes({
					data: {
						showCode: CurrentEvent.ShowCode,
						barcodes: self.barcodes,
						contactId: $stateParams.contactId
					}
				})
				.then(function(){
					self.barcodes = '';
					LeadService.getLeads({
						showCode: CurrentEvent.ShowCode
					}, true);
				});
		};

        self.onFileSelect = function($files) {
		//$files: an array of files selected, each file has name, size, and type.
			for (var i = 0; i < $files.length; i++) {
				var file = $files[i];
				self.upload = $upload.upload({
					url: 'api/lead/uploadFile', //upload.php script, node.js route, or servlet url
					//method: 'POST' or 'PUT',
					//headers: {'header-key': 'header-value'},
					//withCredentials: true,
					data: { showCode: CurrentEvent.ShowCode, contactId: $stateParams.contactId },
					file: file, // or list of files ($files) for html5 only
					//fileName: 'doc.jpg' or ['1.jpg', '2.jpg', ...] // to modify the name of the file(s)
					// customize file formData name ('Content-Disposition'), server side file variable name.
					//fileFormDataName: myFile, //or a list of names for multiple files (html5). Default is 'file'
					// customize how data is added to formData. See #40#issuecomment-28612000 for sample code
					//formDataAppender: function(formData, key, val){}
				})
				// .progress(function(evt) {
				//   console.log('percent: ' + parseInt(100.0 * evt.loaded / evt.total));
				// })
				.success(function(data) {
					// file is uploaded successfully
					LeadService.getLeads({ showCode: CurrentEvent.ShowCode }, true);

					self.uploadMessages = data;
				})
				.error(function(data) {
					if(data && data.ExceptionMessage)
						self.errorMessages = [data.ExceptionMessage];
					else
						self.errorMessages = ['There was a problem uploading your file.'];
				});
				//.error(...)
				//.then(success, error, progress);
				// access or attach event listeners to the underlying XMLHttpRequest.
				//.xhr(function(xhr){xhr.upload.addEventListener(...)})
			}
			/* alternative way of uploading, send the file binary with the file's content-type.
			   Could be used to upload files to CouchDB, imgur, etc... html5 FileReader is needed.
			   It could also be used to monitor the progress of a normal http post/put request with large data*/
			// $scope.upload = $upload.http({...})  see 88#issuecomment-31366487 for sample code.
		};
	};

    angular.module('exhibitorPortalApp')
		.controller('LeadUploadCtrl', LeadUploadCtrl);
})(angular, window);
