'use strict';

  angular
    .module('exhibitorPortalApp')
    .controller('ModalController', ModalController);

  ModalController.$inject = ['$scope', '$window', '$modalInstance', 'templates', 'inputs', '$timeout'];

  function ModalController($scope, $window, $modalInstance, templates, inputs, $timeout) {
    /* jshint validthis:true */
    var vm = this;

    vm.templates = templates;

    // bind to controller
    angular.extend(vm, inputs);

    $timeout(() => {
      vm.isInitialized = true;
    }, 100);

    vm.dismiss = $modalInstance.dismiss;
    vm.close = function (type, httpPromise) {
      var result = {
        status: type
      };
      if (httpPromise) {
        // Sometimes we need to defer the closing of a modal until after a successful callback so
        //  that we can display error messages in the modal so the user can resolve and try again.
        httpPromise.promise().then(function () {
          var fn = this;
          if (httpPromise.callback.apply(fn, arguments)) {
            $modalInstance.close(result);
          }
        }, angular.noop);
      }
      else {
        $modalInstance.close(result);
      }
    };

    vm.print = function ($event) {
      // http://stackoverflow.com/questions/32946154/window-open-throwing-apply-error-on-firefox
      // Not sure why this only seems to be a problem in FireFox. The link has a similar problem,
      // we were seeing the "$digest already in progress" rather than "$apply already in progress."
      // Generally, the $timeout is the way to go. The link mentions that this caused popups to be
      // blocked, but I haven't seen this in testing. Maybe the user had the "onClick" method coming
      // from outside angularJS.
      $timeout(function () {
        var win = $window.open('', '_blank', 'top=0,left=0,toolbar=no,titlebar=no,status=no,scrollbars=no,resizable=no,menubar=no,location=no', true);

        var doc = win.document;
        doc.title = document.title;//re-use the main window's title in the new window
        angular.element(doc.head)
          .append('<style>body { display: none; } @media print { body { display: block; } .hide-for-print { display: none; } }</style>');
        //angular.element(doc.body).html(vm.templates.body);
        // comment the following code b/c exception of symbol.iterator not defined was thrown on WIN10/EDGE browser
        //angular.element(doc.body).html(
        //    angular.element($event.target).closest('.reveal-modal').find('.email-wrapper').html()
        //);
        doc.write(angular.element($event.target).closest('.reveal-modal').find('.email-wrapper').html());

        doc.close(); // must close the document so the browser knows we're finished writing to it

        var images = doc.getElementsByTagName('img'),
          countPrintAttempts = 0,
          retryDelayMilliseconds = 250,
          attemptPrint = function () {
            // if it takes longer than 20 seconds, then something is wrong and we should exit this recursive loop...
            if (++countPrintAttempts * retryDelayMilliseconds / 1000 > 20) {
              win.close();
              return;
            }

            var areAllImagesLoaded = true;
            for (var i = 0; i < images.length; i++) {
              if (!images[i].complete) {
                areAllImagesLoaded = false;
                break;
              }
            }

            if (areAllImagesLoaded) {
              win.focus();// focus needed by IE?
              win.print();
              win.close();
            } else {
              /// if at first you don't succeed, then try again...
              $timeout(attemptPrint, retryDelayMilliseconds);
            }
          };
        attemptPrint();
      }, 0);
    };
  }

