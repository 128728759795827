'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:CollateralAddCtrl
 * @description
 * # ShopCtrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var CollateralAddCtrl = function ($state, $q, ActiveUserProfile, CurrentEvent, CollateralService, Collateral, CollateralConfig, SWAPExhibitor, $timeout, EXSConfig, $upload, ScrollToBottom) {
    'ngInject'
    var self = this;

    self.collateral = {
      CollateralConfigID: CollateralConfig.Id
    };
    self.exsConfig = EXSConfig;
    self.currentEvent = CurrentEvent;
    self.activeUserProfile = ActiveUserProfile;
    self.SWAPExhibitor = SWAPExhibitor;
    self.isExternalBrowser = window.isExternalBrowser.toLowerCase() === 'true';
    ScrollToBottom.value = true;
    self.showUnableToUploadMessage = false;
    self.showToolTip = false;

    /*if (self.isExternalBrowser === false) {
      self.uploadRadioButtonStyle = { "opacity": "0.5", "cursor": "not-allowed" };
    } else {
      self.uploadRadioButtonStyle = {};
    }
*/
    if (Collateral && Collateral.length) {
      self.collateral = Collateral[0];
    }

    self.collateral.cType = self.collateral.URL ? 'l' : 'f';
    // If the user has come from the internal web view of the mobile app,
    // the mobile upload section of collateral is disabled - so we must
    // default to l (upload a link)


    if (self.isExternalBrowser === false) {
      self.collateral.cType = 'l'
    }

    self.saveCollateral = function (form) {
      if (form && !form.$valid) {
        form.$setSubmitted();
        return false;
      }

      CollateralService.saveCollateral({
        params: { showCode: CurrentEvent.ShowCode },
        data: {
          showCode: CurrentEvent.ShowCode,
          collateral: angular.extend(self.collateral, self.collateral.cType == 'l' ? { FilePath: undefined } : { URL: undefined })
        }
      }).then(function (c) {
        $state.go('event.collateral.collaterals');
      });
    }

    self.deleteCollateral = function () {
      CollateralService.deleteCollateral({ showCode: CurrentEvent.ShowCode, collateralId: self.collateral.CollateralID })
        .then(function () {
          $state.go('event.collateral.collaterals');
        });
    }

    self.onFileSelect = function ($files) {
      self.errorMessages = [];

      self.upload = $upload.upload({
        url: EnvironmentConfig.SiteRoot + 'api/collateral/uploadFile', //upload.php script, node.js route, or servlet url
        //method: 'POST' or 'PUT',
        //headers: {'header-key': 'header-value'},
        //withCredentials: true,
        data: { showCode: CurrentEvent.ShowCode },
        file: $files, // or list of files ($files) for html5 only
        //fileName: 'doc.jpg' or ['1.jpg', '2.jpg', ...] // to modify the name of the file(s)
        // customize file formData name ('Content-Disposition'), server side file variable name.
        //fileFormDataName: myFile, //or a list of names for multiple files (html5). Default is 'file'
        // customize how data is added to formData. See #40#issuecomment-28612000 for sample code
        //formDataAppender: function(formData, key, val){}
      })
        // .progress(function(evt) {
        //   console.log('percent: ' + parseInt(100.0 * evt.loaded / evt.total));
        // })
        .success(function (data) {
          if (data && data.length > 0)
            self.collateral.FilePath = data[0];
        })
        .error(function (data) {
          if (data && data.ExceptionMessage)
            self.errorMessages = [data.ExceptionMessage];
          else
            self.errorMessages = ['There was a problem uploading your file.'];
        });
    };

    self.linkUrl = function (url) {

      if (url)
        if (url.trim().toLowerCase().indexOf('http') === 0)
          return url;
        else
          return "http://" + url;

      return url;
    }

    self.handleUploadRadioSelect = function () {
      if (!self.isExternalBrowser) {
        self.showUnableToUploadMessage = true;
      } else {
        self.collateral.cType = 'f'
      }
    }

    self.closeModal = function () {
      if (self.showUnableToUploadMessage) {
        self.showUnableToUploadMessage = false;
      }
    }

  };

  angular.module('exhibitorPortalApp')
    .controller('CollateralAddCtrl', CollateralAddCtrl);
})(angular, window);
