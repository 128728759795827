'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.LeadFollowUp
 * @description
 * # LeadFollowUp
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var LeadFollowUpService = function (ApiService) {
    'ngInject'
    var self = this;

    self.data = {};

    self.getFollowUpConfig = ApiService.endpoint.get({
      url: 'api/followUp/getFollowUpConfig',
      success: function (data, params) {
        self.data.followUpConfigs = self.data.followUpConfigs || {};
        self.data.followUpConfigs[params.showCode] = data;
      }
    });

    self.saveFollowUpConfig = ApiService.endpoint.post({
      url: 'api/followUp/saveFollowUpConfig',
      success: function (data, params) {
        self.data.followUpConfigs = self.data.followUpConfigs || {};
        self.data.followUpConfigs[this.params.showCode] = data;
      }
    });

    self.getTemplate = ApiService.endpoint.get({
      url: 'api/followUp/getTemplate',
      success: function (data, params) {
        self.data.templateHtml = self.data.templateHtml || {};
        self.data.templateHtml[params.showCode] = data;
      }
    });
  };

  angular.module('exhibitorPortalApp')
    .service('LeadFollowUpService', LeadFollowUpService);
})(angular, window);
