'use strict';

/**
 * @ngdoc function
 * @name sharedModule.directive:epAutoFocus
 * @description
 * # Filter Group
 * Directive of the shared Module
 */
(function (angular, window, undefined) {
  function epAutoFocus($timeout, $parse) {
    'ngInject'
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var model = $parse(attrs.epAutoFocus);
        scope.$watch(model, function (value) {
          if (value === true) {
            $timeout(function () {
              element[0].focus();
            });
          }
        });
      }
    };
  }

  angular.module('sharedModule')
    .directive('epAutoFocus', epAutoFocus);
})(angular, window);
