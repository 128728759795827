'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.leadExport
 * @description
 * # Lead Export
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var LeadExportService = function ($q, $timeout, $filter, SurveyService, DemographicService) {
    'ngInject'
    var self = this;

    self.data = {};

    var columns = [
      {
        name: 'Lead Type',
        key: 'LeadType'
      },
      {
        name: 'Lead Id',
        key: 'Id'
      },
      {
        name: 'Captured Date',
        key: 'LeadDate'
      },
      {
        name: 'More Capture Times',
        key: 'MoreCaptureTimes'
      },
      {
        name: 'Captured By',
        key: 'SalesPerson'
      },
      {
        name: 'Badge Id',
        key: 'Contact.ConnectKey'
      },
      {
        name: 'FirstName',
        key: 'Contact.FirstName'
      },
      {
        name: 'LastName',
        key: 'Contact.LastName'
      },
      {
        name: 'Title',
        key: 'Contact.Title'
      },
      {
        name: 'Company',
        key: 'Contact.Company'
      },
      {
        name: 'Company2',
        key: 'Contact.Company2'
      },
      {
        name: 'Address',
        key: 'Contact.Address.Address'
      },
      {
        name: 'Address2',
        key: 'Contact.Address.Address2'
      },
      {
        name: 'Address3',
        key: 'Contact.Address.Address3'
      },
      {
        name: 'City',
        key: 'Contact.Address.City'
      },
      {
        name: 'StateCode',
        key: 'Contact.Address.StateCode'
      },
      {
        name: 'ZipCode',
        key: 'Contact.Address.ZipCode'
      },
      {
        name: 'CountryCode',
        key: 'Contact.Address.CountryCode'
      },
      {
        name: 'Email',
        key: 'Contact.Email'
      },
      {
        name: 'Phone',
        key: 'Contact.Phone'
      },
      {
        name: 'PhoneExtension',
        key: 'Contact.PhoneExtension'
      },
      {
        name: 'Fax',
        key: 'Contact.Fax'
      },
      {
        name: 'Notes',
        key: 'Notes'
      },
      {
        name: 'Total Dwell Time (Min.)',
        key: 'TotalDwellMinutes'
      },
      {
        name: 'Targeted Leads Captured',
        key: 'LeadOwnerSpotted'
      },
      {
        name: 'Collateral',
        key: 'CollateralFormatted'
      },
      {
        name: "Rating",
        key: "Rating"
      }
    ];

    self.getHeaders = function (showCode, leads, hasPurchasedEventBit) {
      var d = $q.defer();
      var dwellTimeColumns = getDwellTimeColumns(leads);

      if (!self.data[showCode] || !self.data[showCode].headers)
        $q.all([
          getDemographicColumns(showCode),
          getSurveyColumns(showCode)
        ])
          .then(function (data) {
            self.data[showCode] = self.data[showCode] || {};
            self.data[showCode].columns = angular.copy(columns);

            if (hasPurchasedEventBit) {
              //omit "Total Dwell Time" column if there is no dwell time data or only a single location
              if (!dwellTimeColumns.length || dwellTimeColumns.length === 1)
                self.data[showCode].columns = self.data[showCode].columns.filter(function (x) { return x.key !== 'TotalDwellMinutes'; });
              //omit SPOT columns if there is no data
              if (!leads.filter(function (l) { return l.LeadOwnerSpotted || l.NonLeadOwnerSpotted; }).length)
                self.data[showCode].columns = self.data[showCode].columns.filter(function (x) {
                  return [
                    'LeadOwnerSpotted',
                    'NonLeadOwnerSpotted'
                  ].indexOf(x.key) === -1;
                });

              self.data[showCode].columns = self.data[showCode].columns.concat(dwellTimeColumns);
            }
            else {
              self.data[showCode].columns = self.data[showCode].columns.filter(function (x) {
                return [
                  'TotalDwellMinutes',
                  'LeadOwnerSpotted',
                  'NonLeadOwnerSpotted'
                ].indexOf(x.key) === -1;
              });
            }

            self.data[showCode].columns = self.data[showCode].columns.concat(data[0]);
            self.data[showCode].columns = self.data[showCode].columns.concat(data[1]);

            var names = [];
            var dupes = {};

            for (var i = 0; i < self.data[showCode].columns.length; i++) {
              var name = self.data[showCode].columns[i].name;

              dupes[name] = dupes[name] || 1;

              if (dupes[name] > 1) {
                self.data[showCode].columns[i].name = name + dupes[name];
                names.push(self.data[showCode].columns[i].name);
              }
              else
                names.push(name);

              dupes[name]++;
            }

            self.data[showCode].headers = names;
            d.resolve();
          });
      else
        $timeout(function () {
          d.resolve();
        });

      return d.promise;
    };

    function getDemographicColumns(showCode) {
      return DemographicService
        .getDemographics({ showCode: showCode })
        .then(function () {
          var columns = [];

          for (var i = 0; i < DemographicService.data.demographics[showCode].length; i++) {
            columns.push({
              name: DemographicService.data.demographics[showCode][i].Description,
              key: 'Contact.DemographicAnswers',
              responseKey: 'ParentId',
              responseValue: DemographicService.data.demographics[showCode][i].Id
            });
          }

          return columns;
        });
    }

    function getSurveyColumns(showCode) {
      return SurveyService
        .getSurveys({ showCode: showCode })
        .then(function () {
          var columns = [];

          for (var a = 0; a < SurveyService.data.surveys[showCode].length; a++)
            if (SurveyService.data.surveys[showCode][a].IsActive)
              for (var i = 0; i < SurveyService.data.surveys[showCode][a].SurveyQuestions.length; i++)
                if (SurveyService.data.surveys[showCode][a].SurveyQuestions[i].IsActive)
                  columns.push({
                    //apparantly if the last column header in excel can be coerced into a number it shifts all the columns over....
                    //try to detect if column headers can be coerced, if true than add a space to force it to a string, sigh....
                    name: (function (x) { return isNaN(x) ? x : x + " "; })(SurveyService.data.surveys[showCode][a].SurveyQuestions[i].Text),
                    key: 'SurveyAnswers',
                    responseKey: 'ParentId',
                    responseValue: SurveyService.data.surveys[showCode][a].SurveyQuestions[i].Id
                  });

          return columns;
        });
    }

    function getDwellTimeColumns(leads) {
      var columns = [];

      for (var i = 0; i < leads.length; i++)
        if (leads[i].DwellTimes)
          for (var a = 0; a < leads[i].DwellTimes.length; a++)
            if (!columns.filter(function (x) { return x.responseValue === leads[i].DwellTimes[a].LocationID }).length)
              columns.push({
                name: leads[i].DwellTimes[a].Name + ' - Dwell Time (min.)',
                key: 'DwellTimes',
                responseKey: 'LocationID',
                responseValue: leads[i].DwellTimes[a].LocationID
              });

      return columns;
    }

    function getExportRow(showCode, columns, input, type) {
      var row = {};

      for (var i = 0; i < columns.length; i++) {
        var props = columns[i].key.split('.');

        (function recurse(props, input) {
          if (props.length) {
            var prop = props[0];

            if (input[prop] && props.length > 1)
              return recurse(props.splice(1), input[prop]);
            else {
              if (columns[i].responseKey && Array.isArray(input[prop])) {
                var answers = [];

                if (columns[i].key === 'DwellTimes' && (!input[prop].length || !input[prop].filter(function (x) { return x.LocationID === columns[i].responseValue; }).length))
                  answers.push(0);

                for (var a = 0; a < input[prop].length; a++)
                  if (input[prop][a][columns[i].responseKey] == columns[i].responseValue) {
                    if (columns[i].key === 'Contact.DemographicAnswers') {
                      for (var b = 0; b < DemographicService.data.demographics[showCode].length; b++) {
                        if (DemographicService.data.demographics[showCode][b].Id == columns[i].responseValue) {
                          if (DemographicService.data.demographics[showCode][b].FieldTypeCode === 'OPN')
                            answers.push(input[prop][a].Response);
                          else
                            for (var c = 0; c < DemographicService.data.demographics[showCode][b].FieldPicks.length; c++)
                              if (DemographicService.data.demographics[showCode][b].FieldPicks[c].PickCode == input[prop][a].Response) {
                                answers.push(DemographicService.data.demographics[showCode][b].FieldPicks[c].Description);
                                break;
                              }
                        }
                      }
                    }
                    else if (columns[i].key === 'DwellTimes')
                      answers.push(input[prop][a].TotalMinutes);
                    else
                      answers.push(input[prop][a].Response);
                  }

                row[columns[i].name] = answers.join(' | ');
              }
              else if (columns[i].key === 'LeadDate')
                row[columns[i].name] = $filter('date')(input[prop], 'short', input.TimeZoneOffset);
              else if (columns[i].key === 'MoreCaptureTimes')
                row[columns[i].name] = (input[prop] || []).map(function (x) {
                  return $filter('date')(x, 'short', input.TimeZoneOffset);
                }).join(' | ');
              else if (columns[i].key === 'Contact.Address.ZipCode' && type && type.toUpperCase() === 'CSV')
                row[columns[i].name] = input[prop] ? ('\'' + input[prop] + '\'') : '';
              else if (['LeadOwnerSpotted', 'NonLeadOwnerSpotted'].indexOf(columns[i].key) > -1)
                row[columns[i].name] = (!!input['LeadOwnerSpotted'] || !!input['NonLeadOwnerSpotted']) ? 'true' : 'false';
              else if (columns[i].key === 'Rating')
                row[columns[i].name] = angular.isDefined(input['Rating']) ? input['Rating'] : '';
              else
                row[columns[i].name] = input[prop];
            }
          }
        })(props, input);
      }

      return row;
    }

    self.getExport = function (showCode, data, type) {
      var r = [];

      for (var i = 0; i < data.length; i++) {
        r.push(getExportRow(showCode, self.data[showCode].columns, data[i], type));
      }

      return r;
    };

    self.getExportCSV = function (showCode, headers, data, type) {
      var exportData = self.getExport(showCode, data, type);
      var csv = '';
      var row = '';

      // headers
      for (var i = 0; i < headers.length; i++) {
        row += JSON.stringify(headers[i]) + ',';
      }
      row = row.slice(0, -1);
      csv += row + '\r\n';

      // data
      for (var i = 0; i < exportData.length; i++) {
        row = '';

        for (var x = 0; x < headers.length; x++) {

          if (exportData[i][headers[x]]) {
            var temp = JSON.stringify(exportData[i][headers[x]]).slice(1, -1) 
            if (temp.includes('"')) {
              row += '"' + temp.replaceAll('\\"', '') + '"' + ',';
            }
            else {
              row += JSON.stringify(exportData[i][headers[x]]) + ',';
            }
          } else {
            row += ',';
          }
        }
        row = row.slice(0, -1);
        csv += row + '\r\n';
      }

      return csv;
    };
  };

  angular.module('exhibitorPortalApp')
    .service('LeadExportService', LeadExportService);
})(angular, window);
