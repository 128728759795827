'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:EmailSurveyCtrl
 * @description
 * # Email Survey Ctrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var EmailSurveyCtrl = function ($state, Survey, CurrentEvent, SurveyService) {
    'ngInject'
		var self = this;

		self.survey = Survey;
		self.email = {
			toAddress: '',
			fromAddress: '',
			subject: '',
			additionalText: ''
		};

		self.send = function () {
			return SurveyService.sendEmail({
					data: angular.extend({ surveyId: Survey.Id, showCode: CurrentEvent.ShowCode }, self.email)
				})
				.then(function(){
					window.alert('Success!');
					$state.go('event.surveys.survey', { id: self.survey.Id });
				});
		};
	};

    angular.module('exhibitorPortalApp')
		.controller('EmailSurveyCtrl', EmailSurveyCtrl);
})(angular, window);
