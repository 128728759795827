'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.DataSales
 * @description
 * # DataSales
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var DataSalesService = function ($q, ApiService) {
    'ngInject'
        var self = this;

        self.data = {};

        self.execute = ApiService.endpoint.post({
            url: 'api/dataSales/portalExecute'
        });

        self.finalize = ApiService.endpoint.post({
            url: 'api/dataSales/portalFinalize'
        });

        self.getConfig = ApiService.endpoint.get({
            url: 'api/dataSales/getConfig',
            success: function (data, params) {
                self.data.config = self.data.config || {};
                self.data.config[params.showCode] = data;
            }
        });

        self.getTemplates = ApiService.endpoint.get({
            url: 'api/dataSales/getTemplates',
            success: function (data, params) {
                self.data.templates = self.data.templates || {};
                self.data.templates[params.showCode] = data;
            }
        });

        self.getTemplate = ApiService.endpoint.get({
            url: 'api/dataSales/getTemplate',
            success: function (data, params) {
                self.data.templateHtml = self.data.templateHtml || {};
                self.data.templateHtml[params.showCode] = self.data.templateHtml[params.showCode] || {};
                self.data.templateHtml[params.showCode][params.templateId] = data;
            }
        });

        self.getEmailCampaigns = ApiService.endpoint.get({
            url: 'api/dataSales/getEmailCampaigns',
            success: function (data, params) {
                self.data.emailCampaigns = self.data.emailCampaigns || {};
                self.data.emailCampaigns[params.showCode] = data;
            }
        });

        self.getPreFilterFields = ApiService.endpoint.get({
            url: 'api/dataSales/getPreFilterFields',
            success: function (data, params) {
                self.data.preFilterFields = self.data.preFilterFields || {};
                self.data.preFilterFields[params.showCode] = data;
            }
        });

        self.getAttendeeListDownloads = ApiService.endpoint.get({
            url: 'api/dataSales/getAttendeeListDownloads',
            success: function (data, params) {
                self.data.attendeeListDownloads = self.data.attendeeListDownloads || {};
                self.data.attendeeListDownloads[params.showCode] = data;
            }
        });

        self.getAttendeeListExport = ApiService.endpoint.post({
            url: 'api/dataSales/getAttendeeListExport'
        });

        self.getStrategy = ApiService.endpoint.get({
            url: 'api/dataSales/getStrategy',
            success: function (data, params) {
                self.data.strategies = self.data.strategies || {};
                self.data.strategies[params.showCode] = self.data.strategies[params.showCode] || {};
                self.data.strategies[params.showCode][params.id] = data;
            }
        });

        self.getFeedbackSummary = ApiService.endpoint.get({
            url: 'api/dataSales/getFeedbackSummary'
        });

        self.saveStrategy = ApiService.endpoint.post({
            url: 'api/dataSales/saveStrategy'
        });

        self.validate = function(showCode, dataSalesCartItem) {
            var d = $q.defer();
            var errors = [];

            if(!dataSalesCartItem.dataSalesTierTypeCode.startsWith('SET') && !dataSalesCartItem.dataSalesListId)
                errors.push('event.shop.dataSales.filterBuilder');

            if(dataSalesCartItem.ProductCode == '203') {
                var fields = [
                    {
                        IsRequired: true,
                        KeyName: 'SubjectLine'
                    },
                    {
                        IsRequired: true,
                        KeyName: 'ScheduledExecutionDate'
                    },
                    {
                        IsRequired: true,
                        KeyName: 'ScheduledExecutionTime'
                    }
                ];            

                self.getTemplates({
                    showCode: showCode
                }).then(function(){
                    var template = self.data.templates[showCode].filter(function(e){
                        return e.TemplateID == dataSalesCartItem.email.TemplateId;
                    });

                    if(template.length){
                        template = template[0];
                        fields = fields.concat(template.BodyFields);

                        for(var i = 0; i < fields.length; i++){
                            if(fields[i].IsRequired && !dataSalesCartItem.email[fields[i].KeyName]){
                                errors.push('event.shop.dataSales.emailBuilder');
                                break;
                            }
                        }
                    }
                    else
                        errors.push('event.shop.dataSales.emailBuilder');

                    if(!dataSalesCartItem.agreeToTerms)
                        errors.push('event.shop.dataSales.terms');

                    d.resolve(errors);
                });
            }
            else if (dataSalesCartItem.productCode == '205'){
                if(Object.keys(dataSalesCartItem.mailHouse).filter(function(key) {
                    Array.isArray(dataSalesCartItem.mailHouse[key]) ? 
                        !dataSalesCartItem.mailHouse[key].length : 
                        !dataSalesCartItem.mailHouse[key] }).length)
                            errors.push('event.shop.dataSales.mailHouse')
                else if(!dataSalesCartItem.agreeToTerms)
                    errors.push('event.shop.dataSales.terms');

                setTimeout(function() { d.resolve(errors); }, 0);
            }
            else {
                if(!dataSalesCartItem.agreeToTerms)
                    errors.push('event.shop.dataSales.terms');
                
                setTimeout(function() { d.resolve(errors); }, 0);
            }

            return d.promise;
        };

        self.sendTestEmail = ApiService.endpoint.post({
            url: 'api/dataSales/sendTestEmail'
        });

    };

    angular.module('exhibitorPortalApp')
      .service('DataSalesService', DataSalesService);
})(angular, window);
