'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:LeadDetailCtrl
 * @description
 * # Lead Detail
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var LeadDetailCtrl = function ($state, $anchorScroll, $location, $filter, $sce, LeadService, LeadExportService, EnvironmentConfig, CurrentEvent, Lead, Demographics, Surveys, ExportHeaders, ActiveUserProfile, HasPurchasedEventBit, LeadCollateral) {
    'ngInject'
    var self = this;

    self.lead = angular.extend({}, Lead, {
      CollateralFormatted: LeadCollateral.map(function (lc) { return lc.CollateralName; }).join(' | ')
    });
    self.demographics = Demographics;
    self.surveys = Surveys;
    self.editMode = false;
    self.exportHeaders = ExportHeaders;
    self.hasPurchasedEventBit = HasPurchasedEventBit;

    self.exportUrl = $sce.trustAsResourceUrl(EnvironmentConfig.SiteRoot + 'export/');
    self.filename = 'lead-' + CurrentEvent.ShowCode + '-' + new Date().toJSON().slice(0, 10);
    self.filedata = '';
    self.filetype = '';

    self.collateral = LeadCollateral;
    self.moreCaptureTimes = Lead.MoreCaptureTimes ? JSON.parse(Lead.MoreCaptureTimes).map(function (x) {
      var d = new Date(0);
      d.setUTCMilliseconds(x.mc);

      return d.toISOString();
    }) : '';

    self.localRating = self.lead.Rating ? self.lead.Rating : 0;

    self.search = {
      collateral: {
        sort: 'SentDate',
        sortDirection: true
      }
    };

    $anchorScroll();

    self.ratingLeave = function () {
      self.localRating = self.lead.Rating ? self.lead.Rating : 0;
    };

    self.setRating = function (rating) {
      self.lead.Rating = rating;
      self.localRating = self.lead.Rating ? self.lead.Rating : 0;
    };

    self.save = function () {
      return LeadService
        .saveLeads({
          params: { showCode: CurrentEvent.ShowCode },
          data: {
            showCode: CurrentEvent.ShowCode,
            leads: [self.lead]
          }
        })
        .then(function () {
          for (var i = 0; i < LeadService.data.leads[CurrentEvent.ShowCode].length; i++)
            if (LeadService.data.leads[CurrentEvent.ShowCode][i].Id == self.lead.Id) {
              self.lead = angular.copy(LeadService.data.leads[CurrentEvent.ShowCode][i]);
              break;
            }
        });
    };

    self.getDemoAnswer = function (demo) {
      var answers = [];
      var response = [];

      for (var i = 0; i < self.lead.Contact.DemographicAnswers.length; i++)
        if (self.lead.Contact.DemographicAnswers[i].ParentId == demo.Id)
          answers.push(self.lead.Contact.DemographicAnswers[i].Response);

      for (var b = 0; b < answers.length; b++)
        if (answers[b] !== undefined) {
          if (demo.FieldTypeCode === 'OPN')
            response.push(answers[b]);
          else
            for (i = 0; i < Demographics.length; i++)
              if (Demographics[i].Id == demo.Id)
                for (var a = 0; a < Demographics[i].FieldPicks.length; a++)
                  if (Demographics[i].FieldPicks[a].PickCode == answers[b])
                    response.push(Demographics[i].FieldPicks[a].Description);
        }

      return response.length ? response.join(', ') : undefined;
    };

    self.getSurveyAnswer = function (surveyQuestion) {
      var answers = [];
      for (var i = 0; i < Lead.SurveyAnswers.length; i++)
        if (Lead.SurveyAnswers[i].ParentId == surveyQuestion.Id)
          answers.push(Lead.SurveyAnswers[i].Response);

      return answers.length ? answers.join(', ') : undefined;
    };

    self.answerCount = function (survey) {
      var count = 0;

      for (var i = 0; i < Surveys.length; i++)
        if (Surveys[i].Id == survey.Id)
          for (var a = 0; a < Surveys[i].SurveyQuestions.length; a++)
            for (var b = 0; b < Lead.SurveyAnswers.length; b++)
              if (Surveys[i].SurveyQuestions[a].Id == Lead.SurveyAnswers[b].ParentId)
                count++;

      return count;
    };

    self.edit = function () {
      self.editMode = !self.editMode;

      if (self.editMode) {
        $location.hash('Edit');
      }
      else {
        $location.hash('');
      }
    };

    self.postLeadsForExport = function (filetype) {
      if (typeof dataLayer !== 'undefined') {
        if (filetype.toLowerCase() === 'csv') {
          dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Lead Detail', 'eventAction': 'Download CSV', 'eventLabel': CurrentEvent.ShowCode });
        } else {
          dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Lead Detail', 'eventAction': 'Download Excel', 'eventLabel': CurrentEvent.ShowCode });
        }
      }

      var data = LeadExportService.getExportCSV(CurrentEvent.ShowCode, self.exportHeaders, [self.lead], filetype);
      self.filedata = btoa(RawDeflate.deflate(encodeURIComponent(data))).replace(/\//g, '-').replace(/\+/g, '.').replace(/=/g, '_');
      self.tiletype = filetype;

      setTimeout(function () { angular.element('#ExportForm').submit(); }, 0);
    };

    self.share = function () {
      //build mailto link
      var subject = 'Lead info from ' + CurrentEvent.TradeShowName;
      var body = '';

      //contact info
      body += 'First Name: ' + self.lead.Contact.FirstName + '\r\n';
      body += 'Last Name: ' + self.lead.Contact.LastName + '\r\n';
      body += 'Title: ' + self.lead.Contact.Title + '\r\n';
      body += 'Company: ' + self.lead.Contact.Company + ' ' + self.lead.Contact.Company2 + '\r\n';

      body += 'Address: ' + self.lead.Contact.Address.Address + ' ' + self.lead.Contact.Address.Address2 + ' ' + self.lead.Contact.Address.Address3 + '\r\n';
      body += 'City: ' + self.lead.Contact.Address.City + '\r\n';
      body += 'State: ' + self.lead.Contact.Address.StateCode + '\r\n';
      body += 'Postal Code: ' + self.lead.Contact.Address.ZipCode + '\r\n';
      body += 'Country: ' + self.lead.Contact.Address.CountryCode + '\r\n';

      body += 'Phone: ' + self.lead.Contact.Phone + (self.lead.Contact.PhoneExtension ? (' x' + self.lead.Contact.PhoneExtension) : '') + '\r\n';
      body += 'Fax: ' + self.lead.Contact.Fax + '\r\n';
      body += 'Email: ' + self.lead.Contact.Email + '\r\n';

      //notes
      body += '\r\nNotes: \r\n' + self.lead.Notes + '\r\n \r\n';

      // other
      body += 'Badge ID: ' + self.lead.Contact.RegistrantId + '\r\n';
      body += 'Capture Date: ' + $filter('date')(self.lead.LeadDate, 'short', self.lead.TimeZoneOffset);

      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Lead Detail', 'eventAction': 'Share', 'eventLabel': CurrentEvent.ShowCode });
      }

      window.location.href = 'mailto:?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
    };

    self.validateNote = function (note) {
      if (note.includes('"'))
        return false;
      else
        return true;
    };

    self.delete = function () {
      if (window.confirm('Are you sure you want to delete this lead?')) {

        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({ 'event': 'GenericEvent', 'eventCategory': 'Lead Detail', 'eventAction': 'DeleteLead', 'eventLabel': CurrentEvent.ShowCode });
        }

        return LeadService
          .deleteLeads({
            params: { showCode: CurrentEvent.ShowCode },
            data: {
              showCode: CurrentEvent.ShowCode,
              leads: [self.lead]
            }
          })
          .then(function () {
            $state.go('event.leads');
          });
      }
    }
  };


  angular.module('exhibitorPortalApp')
    .controller('LeadDetailCtrl', LeadDetailCtrl);
})(angular, window);
