'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  var MainCtrl = function () {
    'ngInject'

    };

    angular.module('exhibitorPortalApp')
	  .controller('MainCtrl', MainCtrl);
})(angular, window);
