'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:SwitchShowCtrl
 * @description
 * # SwitchShowCtrl
 * Controller of the exhibitorPortalApp
 */

(function(angular, window, undefined) {
  var SwitchShowCtrl = function ($state, $scope, $rootScope, $timeout, $window, CurrentEvent, ngCart, MyEvents, LeadService, DemographicService, ShowService, SurveyService, LeadActivityService, ActiveUserProfile, AdditionalInfo) {
    'ngInject'
    var self = this;

    self.modalWidth = angular.element($window).width() > 641 ? '50%' : '90%';
    self.isArchived = CurrentEvent.IsArchived;
		self.currentShow = CurrentEvent;
		self.myEvents = MyEvents;
		self.SWAPOnly = ActiveUserProfile.SWAPOnly;
    self.company = ActiveUserProfile.Company;
    self.isPayOnly = $window.SalesOrderId;
    self.showDetailsModal = false;
    self.additionalInfo = AdditionalInfo;
		//load these resources asynchronously w/out a loading bar
		if(ShowService.data.activations[CurrentEvent.ShowCode])
			$timeout(function() {
				//LeadService.getLeads({ showCode: CurrentEvent.ShowCode } , false, { ignoreLoadingBar: true });
				LeadService.getCountries({ showCode: CurrentEvent.ShowCode } , false, { ignoreLoadingBar: true });
				LeadService.getStates({ showCode: CurrentEvent.ShowCode, countryCode: '' }, false, { ignoreLoadingBar: true });
				SurveyService.getSurveys({ showCode: CurrentEvent.ShowCode }, false, { ignoreLoadingBar: true });
				DemographicService.getDemographics({ showCode: CurrentEvent.ShowCode } , false, { ignoreLoadingBar: true });
      }, 500);

		self.switchEvent = function(showcode) {
			ShowService.hasActivations({
				showCode: showcode
			})
			.then(function() {
				if(ShowService.data.activations[showcode])
					LeadActivityService.getLeadSummary({ showCode: showcode })
					.then(function() {
						ngCart.load(showcode);

						if(LeadActivityService.data.leadSummary[showcode][1].Count)
							$state.go('event.dashboard.landing', { showcode: showcode });
						else
							$state.go('event.shop.products', { showcode: showcode });
					});
				else{
					ngCart.load(showcode);
					$state.go('event.shop.products', { showcode: showcode });
				}
			});
		};
	};

	angular.module('exhibitorPortalApp')
		.controller('SwitchShowCtrl', SwitchShowCtrl);
})(angular, window);
