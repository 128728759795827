'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.filter:searchFilter
 * @description
 * # Search Filter
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function searchFilter($filter) {
    'ngInject'
		return function (input, search) {
			var results = [];
      if (input) {
       
        for (var i = 0; i < input.length; i++) {
          var match = true;
          (function recurse(filter) {
            for (var key in filter) {
              if (Object.prototype.toString.apply(filter[key]) === '[object Object]') {
                match = match && recurse(filter[key]);
              } else {
                match = (function () {
                  var props = key.split('.');

                  return (function recurse(props, input) {
                    if (props.length) {
                      var prop = props[0];
                      if (prop in input)
                        return recurse(props.splice(1), input[prop]);
                      else
                        return false;
                    }
                    else {
                      if (Object.prototype.toString.apply(filter[key]) === '[object Array]') {
                        for (var f = 0; f < filter[key].length; f++) {
                          match = (function () {
                            if (Object.prototype.toString.apply(input) === '[object Array]') {
                              for (var o = 0; o < input.length; o++)
                                if (input[o][filter[key][f].optionKey] === filter[key][f].optionValue || filter[key][f].optionValue === undefined) {
                                  var result = filter[key][f].predicate ?
                                    filter[key][f].predicate(input[o][filter[key][f].responseKey], filter[key][f].responseValue) :
                                    input[o][filter[key][f].responseKey] == filter[key][f].responseValue;

                                  if (!!result)
                                    return result;
                                }
                            }
                            //assume that object is primitive
                            else if (filter[key][f].predicate)
                              return filter[key][f].predicate(input, filter[key][f].responseValue);
                            else
                              throw 'Unhandled filter scenario!';
                          })();

                          if (!match)
                            return false;
                        }

                        return true;
                      }
                      else if (typeof filter[key] === 'string' && typeof input === 'string')
                        return filter[key].toUpperCase() === input.toUpperCase();
                      else
                        return filter[key] == input;
                    }
                  })(props, input[i]);
                })();

                if (!match)
                  return false;
              }
            }
            return true;
          })(search.filters);

          if (match && $filter('filter')([input[i]], search.searchText).length)
            results.push(input[i]);
        }
      }

			return results;
		};
	}

	angular.module('exhibitorPortalApp')
		.filter('searchFilter', searchFilter);
})(angular, window);
