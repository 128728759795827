'use strict';

/**
 * @ngdoc function
 * @name sharedModule.directive:inputField
 * @description
 * # Input Field
 * Directive of the shared Module
 */
(function (angular, window, undefined) {
  function inputField($timeout, $parse) {
    'ngInject'
    return {
      restrict: 'E',
      require: 'ngModel',
      scope: {
        bindModel: '=ngModel',
        bindDisabled: '=?ngDisabled',
        fieldName: '@',
        typeahead: '@',
        typeaheadMinLength: '=?',
        typeaheadItems: '=?',
        isDisabled: '=?',
        description: '@',
        fieldType: '@',
        masked: '=?'
      },
      controller: function () {
      },
      controllerAs: 'inputField',
      bindToController: true,
      templateUrl: (window.location.hostname === 'localhost' ? '/app/' : '') + 'views/shared/inputField.html',
      link: function (scope, element, attrs, ngModel, ngDisabled) {
        var self = scope.inputField;

        if (self.fieldType === 'password') {
          if (!self.masked)
            self.masked = true;
        }

        self.focused = false;

        $timeout(function () {
          element.tabIndex = -1;
          var input = element.find('input');
          if (input && input[0]) {
            input[0].onblur = function () {
              if (self.focused) {
                self.focused = false;
                self.leaving = true;
              }
            };

            input[0].onfocus = function () {
              if (!self.leaving) {
                self.focused = true;
                self.leaving = false;
              }
            };
          }
        }, 0);

        element.bind('focus', function () {
          $timeout(function () {
            var input = element.find('input');
            if (input && input[0]) {
              if (!self.focused && !self.leaving) {
                input[0].focus();
              } else {
                focusNextTabbable(input[0]);
              }
            }
          }, 0);
        });

        function focusNextTabbable(input) {
          var tabs = document.querySelectorAll('button, [href], input, select, textarea');
          var useNext = false;
          for (var i = 0; i < tabs.length; i++) {
            if (useNext && !tabs[i].disabled) {
              tabs[i].focus();
              self.leaving = false;
              return;
            } else if (tabs[i].name === input.name) {
              useNext = true;
            }
          }
        }
      }
    };
  }

  angular.module('sharedModule')
    .directive('inputField', inputField);
})(angular, window);
