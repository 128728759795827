'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalLoginApp.controller:CompanyLoginCtrl
 * @description
 * # CompanyLoginCtrl
 * Controller of the exhibitorPortalLoginApp
 */

(function (angular, window, undefined) {
  var CompanyLoginCtrl = function (EnvironmentConfig, LoginService, $state) {
    'ngInject'
    var self = this;

    self.loginData = LoginService.getCompanyLogin();

    self.helpObj = {
      FullName: '',
      Company: '',
      StreetAddress: '',
      CityStateZip: '',
      Country: '',
      Phone: '',
      Email: '',
      AdditionalInfo: '',
      ShowCode: self.loginData ? self.loginData.ShowCode : '', 
      CompanyId: self.loginData ? self.loginData.CompanyID : '', 
    };

    self.selectContact = function () {
      if (self.selectedContact) {
        var selected = parseInt(self.selectedContact);
        var contact = _.find(self.loginData.Contacts, function (c) { return c.Id === selected; });
        if (self.selectedContact === 'Other') {
          self.getHelp = true;
          self.errors = null;
        } else {
          var loc = (EnvironmentConfig.SiteRoot[EnvironmentConfig.SiteRoot.length - 1] === '/' && self.loginData.RedirectUrl[0] === '/')
            ? EnvironmentConfig.SiteRoot + self.loginData.RedirectUrl.substring(1, self.loginData.RedirectUrl.length - 1)
            : EnvironmentConfig.SiteRoot + self.loginData.RedirectUrl;

          loc = loc.replace('[USERNAME]', contact.UserName).replace('[PWD]', contact.EncodedPassword);

          window.location = loc;
        }
      } else {
        self.errors = ['Please select an option to continue'];
      }
    };

    self.sendGetHelp = function () {
      LoginService.sendGetHelp({
        data: self.helpObj
      }).then(function (data) {
        if (data && data.Success)
          self.getHelpThankYou = true;
        else
          self.errors = data.Errors;
      });
    };

    self.goHome = function () {
      $state.go('login.landing');
    };
  };

  angular.module('exhibitorPortalLoginApp')
    .controller('CompanyLoginCtrl', CompanyLoginCtrl);
})(angular, window);
