'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.filter:startFrom
 * @description
 * # Start From
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function startFrom() {
    'ngInject'
        return function (input, start) {
            return (typeof input == 'undefined' || !input) ? [] : input.slice(start);
        };
    }

    angular.module('exhibitorPortalApp')
		.filter('startFrom', startFrom);
})(angular, window);
