/*  ModalFactory knows the template to use for our modals.  You pass in the following parameters to the showModal() method and it will
    load the templates and activate them in a modal
    options:
        bodyTemplateUrl (or bodyTemplate)
        footerTemplateUrl (or footerTemplate)
        controller
        modalcss
        inputs {
            title: 
        }
    inputs is an arbitrary object containing the parameters you want passed to your controller.  In addtion, title is used in 
    the modaltemplate.html if you want to set this parameter
 */

angular
  .module('exhibitorPortalApp')
  .factory('modalFactory', modalFactory);

/**
 * 
 * @param {ng.IRootScopeService} $rootScope AngularJS RootScope Service
 * @param {ng.ISCEService} $sce AngularJS Strict-Contextual-Escaping Service
 * @param {ng.ITemplateRequestService} $templateRequest AngularJS TemplateRequest Service
 * @param {ng.IQService} $q AngularJS Promise Service
 * @param {any} $modal Pinecone Modal Service
 * @returns {Function} A function that returns an AngularJS Factory
 */
function modalFactory($rootScope, $sce, $templateRequest, $q, $modal/*mm-foundation modal*/) {
  'ngInject';//used by ng-annotate... must be first line in function

  var templateUrl = (window.location.hostname === 'localhost' ? '/app/' : '') + 'views/modalTemplate.html',
    defaultController = 'modalController',
    defaultControllerAs = 'modalCtrl',
    modalStack = [];

  //if we are navigating with modals open then close them
  $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
    if (!event.defaultPrevented) {
      dismissAllModals();
    }
  });

  const service = {
    showModal: showModal,
    dismissAllModals: dismissAllModals,
    displayConfirm: displayConfirm
  };
  return service;

  function showModal(opts) {

    var modalResult = $q.defer(),
      promises = {},
      urls = {
        modal: templateUrl,
        body: opts.bodyTemplateUrl,
        footer: opts.footerTemplateUrl
      };
    angular.forEach(urls, function (value, key) {
      if (value) {
        this[key] = $templateRequest($sce.getTrustedResourceUrl(value));
      }
    }, promises);

    //load all the templates using promises and when complete showModal

    $q.all(promises).then(function (responses) {
      const reControllerAs = new RegExp('\\b' + defaultControllerAs + '\\b', 'g');

      var templates = {};
      angular.forEach(['body', 'footer'], function (value) {
        var template = responses[value] || opts[value + 'Template'];
        if (angular.isDefined(template)) {
          this[value] = opts.controllerAs && opts.controllerAs !== defaultControllerAs
            ? template.replace(reControllerAs, opts.controllerAs) : template;
        }
      }, templates);

      var modalTpl = responses.modal,
        modalInstance = $modal.open({
          template: opts.controllerAs && opts.controllerAs !== defaultControllerAs
            ? modalTpl.replace(reControllerAs, opts.controllerAs) : modalTpl,
          controller: opts.controller || defaultController,
          controllerAs: opts.controllerAs || defaultControllerAs,
          resolve: {
            // these have to be function references or they won't work... WTH?
            templates: function () { return templates; },
            inputs: function () { return opts.inputs; }
          },
          windowClass: opts.modalcss,
          keyboard: angular.isDefined(opts.keyboard) ? opts.keyboard : true,
          backdrop: 'static',//"static" = backdrop visible, but clicking on it doesn't close the modal
          scope: opts.scope
        });
      modalStack.push(modalInstance);

      /*
       * Copied from EventXL BaseShow. Don't need at the moment.
      modalInstance.opened.then(function (opened) {
        if (opts.inputs && opts.inputs.setFocusCtlName) {
          DocumentService.setFocusByName(opts.inputs.setFocusCtlName);
        }
        if (angular.isFunction(opts.onOpen)) {
          opts.onOpen();
        }
      });
      */

      modalInstance.result.then(
        function (closed) {
          popModalStack(modalInstance);
          modalResult.resolve(closed);
        },
        function (dismissed) {
          popModalStack(modalInstance);
          modalResult.reject(dismissed);
        }
      );
      //could not use finally because login does a nav and the ui router event was getting called before finally
      //.finally(function () {
      //    popModalStack(modalInstance);
      //});

    });

    return modalResult.promise;

  }

  function dismissAllModals() {
    if (modalStack.length) {
      //make a copy because calling dismiss will remove the modal from the stack while we are iterating over it
      const temp = modalStack.slice(0);
      for (var i = temp.length - 1; i >= 0; i--) {
        temp[i].dismiss();
      }
    }
  }

  function popModalStack(modal) {
    //normally we could just pop the last element, but it looks like you can close modals out of order
    const index = modalStack.indexOf(modal);
    if (index >= 0) {
      modalStack.splice(index, 1);
    }
  }

  /**
   * 
   * @param {string} msg Message body to display
   * @param {string} title Modal header title to display
   * @param {string} modalCssClass Modal CSS Class to use
   * @returns {PromiseLike} A promise
   */
  function displayConfirm(msg, title, modalCssClass) {
    return showModal({
      bodyTemplate: msg || 'Are you Sure?',
      footerTemplate:
        '<button type="button" class="success" ng-click="modalCtrl.close(true)">Yes</button><button type="button" class="secondary" ng-click="modalCtrl.close(false)">No</button>',
      modalcss: modalCssClass,
      inputs: {
        title: title || 'Confirm'
      }
    }).then((result) => {
      return result.status ? $q.when() : $q.reject();
    });
  }
}
