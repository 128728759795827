'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.Discount
 * @description
 * # Discount
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var DiscountService = function ($q, $rootScope, OrderService) {
    'ngInject'
        return function (item) {
        	var d = $q.defer();

			OrderService.getOrders({ showCode: window.getEventCode() })
				.then(function() {
					if(!item.getDiscount() || item.getDiscount().id <= 0)
						d.resolve(0);

		            var orders = OrderService.data.orders[window.getEventCode()] || [];
		            var existingTransactions = [];

		            orders.forEach(function(o) {
		            	existingTransactions = existingTransactions.concat(o.Transactions.filter(function (t) {
		            		return t.ProductId == item.getId();
		            	}));
		            });

		            var existingDiscount = existingTransactions.reduce(function(accum, t){
		            	if(t.ProductDiscountId === item.getDiscount().id){
			            	if(t.TransTypeCode == 'RET')
			            		return accum - t.Quantity;
			            	else if(t.TransTypeCode == 'PUR')
			            		return accum + t.Quantity;
			            	else
		            			return accum;
		            	}
		            	else
		            		return accum;
		            }, 0);

		            //limit: item.MaxAvailableQty
		            //max: item.MaxDiscountsPerShow
		            //min: item.MinQualifyingQty
		            //price: item.DiscountedUnitPrice
		            var discount = item.getDiscount();
		            var quantity = item.getQuantity();
		            var amtItemAdjustment = discount.price - item.getPrice();

		            if(discount.max > 0 && existingDiscount > 0)
		            	d.resolve(0);
		            else {
			            if(discount.max > 0) {
			            	// THIS IS A "ONE TIME" DISCOUNT... SEE IF THEY MEET THE CRITERIA
							// (no discounts have been applied yet so we don't need to check again)
							// note: this logic will not work for pre-existing quantities so we won't use them for
							// determination of the discount
			            	if(quantity > discount.min){
			            		if((quantity - discount.min) > discount.limit)
			            			d.resolve(amtItemAdjustment * discount.limit);
			            		else
			            			d.resolve(amtItemAdjustment * (quantity - discount.min));
			            	}
			            }
			            else {
			            	// THIS IS A "REOCCURRING" DISCOUNT... SEE IF THEY MEET THE CRITERIA
			            	var totalQualifyQty = discount.min + discount.limit;
			            	var qtyRatio = Math.floor(quantity / totalQualifyQty);

			            	if(discount.limit == 0)
			            		d.resolve((quantity - discount.min) * amtItemAdjustment);
			            	else if(discount.min == 0)
			            		d.resolve(discount.limit * amtItemAdjustment);
			            	else
			            		d.resolve(qtyRatio * discount.limit * amtItemAdjustment);
			            }
		        	}
        	});

			return d.promise;
        };
    };

    angular.module('exhibitorPortalApp')
      .factory('DiscountService', DiscountService);
})(angular, window);
