'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:DataSalesMailHouseCtrl
 * @description
 * # Data Sales Mail House Ctrl
 * Controller of the exhibitorPortalApp
 */

//  After configuring the list build criteria, display page for user to enter mail house information:
// - Configurable text field for instructions to exhibitor user (SWAPMan)
// - Mail House Company, Address, Address2, City, State, Country, Postal Code, Phone, Email
//   -require all fields except Address2
// - Upload collateral (PDF only, 20 MB per file limit, no file limit) Store in DB or on S3.
//   - Need to be able to add and remove files
//   - Need to be able to download uploaded files

(function (angular, window, undefined) {
  var DataSalesMailHouseCtrl = function ($q, $upload, $anchorScroll, $window, $timeout, $state, $stateParams, $scope, EXSConfig, CurrentEvent, DataSalesProduct, DataSalesService, MailHouse, DataSalesConfig, ActiveUserProfile, EnvironmentConfig) {
    'ngInject'
		var self = this;
		$anchorScroll();			
				
		self.dataSalesProduct = DataSalesProduct;				
		self.mailHouse = MailHouse;	
		self.files = MailHouse.DataSalesAttachments;	
		self.config = DataSalesConfig;
		self.exsConfig = EXSConfig;
		self.currentEvent = CurrentEvent;
		self.activeUserProfile = ActiveUserProfile;
		
		self.next = function() {
			var d = $q.defer();

			$timeout(function() {
				if(!self.mailHouseForm.$valid || !self.files.length){
	                self.mailHouseForm.$setSubmitted();
	                d.reject();
				}
	            else{
	            	$state.go('event.shop.dataSales.terms');
	            	d.resolve();
	            }
        	});

        	return d.promise;
		};

		 self.onFileSelect = function($files) {		
			self.upload = $upload.upload({
					url: EnvironmentConfig.SiteRoot + 'api/dataSales/uploadFile', //upload.php script, node.js route, or servlet url
					//method: 'POST' or 'PUT',
					//headers: {'header-key': 'header-value'},
					//withCredentials: true,
					data: { showCode: CurrentEvent.ShowCode },
					file: $files, // or list of files ($files) for html5 only
					//fileName: 'doc.jpg' or ['1.jpg', '2.jpg', ...] // to modify the name of the file(s)
					// customize file formData name ('Content-Disposition'), server side file variable name.
					//fileFormDataName: myFile, //or a list of names for multiple files (html5). Default is 'file'
					// customize how data is added to formData. See #40#issuecomment-28612000 for sample code
					//formDataAppender: function(formData, key, val){}
				})
				// .progress(function(evt) {
				//   console.log('percent: ' + parseInt(100.0 * evt.loaded / evt.total));
				// })
				.success(function(data) {		
					data.forEach(function(url) {
						self.files.push(url);
					})					
				})
				.error(function(data) {
					if(data && data.ExceptionMessage)
						self.errorMessages = [data.ExceptionMessage];
					else
						self.errorMessages = ['There was a problem uploading your file.'];
				});
		};
			
		self.removeFile = function(x) {
			self.files.splice(self.files.indexOf(x), 1);			
		}
	};

    angular.module('exhibitorPortalApp')
		.controller('DataSalesMailHouseCtrl', DataSalesMailHouseCtrl);
})(angular, window);
