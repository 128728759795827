'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:addressForm
 * @description
 * # Address Form
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function addressForm(AddressService, $window) {
  'ngInject'
    return {
      restrict: 'E',
      scope: {
        addresses: '=',
        checkout: '=',
        countries: '=',
        states: '=',
        addressControls: '=',
        currentEvent: '='
      },
      link: function (scope, element, attrs) {
        var self = scope.adrForm;
        
        self.editMode = !self.addresses.length;
        self.editAddress = {};

        self.USA = self.countries.filter(function (x) {
          return x.CountryCode === 'US';
        })[0];

        if (!self.addresses.length)
          self.editAddress = {
            CountryId: self.USA.Id
          };

        if (!self.checkout.address) {
          var preferred = self.addresses.length ? self.addresses.filter(function (x) {
            return x.IsPreferred;
          }) : null;

          self.checkout.address = (preferred && preferred.length) ? preferred[0] :
            ((self.addresses && self.addresses.length) ? self.addresses[0] : null);
        }

        self.edit = function (address) {
          self.editMode = true;
          if (self.addressControls)
            self.addressControls.editMode = true;
          self.modePrefix = 'Edit ';
          self.editAddress = angular.copy(address);
          self.addressForm.$setPristine();
          self.addressForm.$setUntouched();
        };

        self.create = function () {
          self.editMode = true;
          if (self.addressControls)
            self.addressControls.editMode = true;
          self.modePrefix = 'Add ';
          self.editAddress = {
            CountryId: self.USA.Id
          };
          self.addressForm.$setPristine();
          self.addressForm.$setUntouched();
        };

        self.deleteMe = function (address) {
          if (window.confirm('Are you sure you want to delete this address?')) {
            self.editAddress = angular.copy(address);
            self.editAddress.IsActive = false;
            self.save(true);
          }
        };

        self.save = save;
        if (self.addressControls) {
          self.addressControls.save = save;
          self.addressControls.addressForm = self.addressForm;
        }

        function save(force) {
          if (force || self.addressForm.$valid) {
            if (self.editAddress.AddressTypeCode !== 'BILLING')
              self.editAddress.Id = 0;

            angular.extend(self.editAddress,
              {
                AddressTypeCode: 'BILLING',
                CountryName: self.editAddress.CountryId ? self.countries.filter(function (x) {
                  return x.Id === self.editAddress.CountryId;
                })[0].CountryName : undefined
              });

            return AddressService.save({
              data: {
                showCode: self.currentEvent.ShowCode,
                addresses: (function () {
                  if (self.editAddress.IsPreferred) {
                    var oldPreferred = self.addresses.filter(function (x) {
                      return x.Id !== self.editAddress.Id && x.IsPreferred;
                    });

                    if (oldPreferred.length) {
                      var modified = oldPreferred.map(function (x) {
                        x.IsPreferred = false;
                        return x;
                      });

                      modified.push(self.editAddress);

                      return modified;
                    }
                    else
                      return [self.editAddress];
                  }
                  else
                    return [self.editAddress];
                })()
              }
            })
              .then(function () {
                AddressService.getBillingAddresses({}, true)
                  .then(function () {
                    //add
                    if (!self.editAddress.Id) {
                      //scan the existing address array and find the id that is missing to determine
                      //which address is our new one
                      for (var i = 0; i < AddressService.data.billingAddresses.length; i++)
                        if (!self.addresses.filter(function (x) { return x.Id === AddressService.data.billingAddresses[i].Id; }).length) {
                          self.checkout.address = AddressService.data.billingAddresses[i];
                          break;
                        }
                    }
                    //edit
                    else {
                      self.checkout.address = AddressService.data.billingAddresses.filter(function (x) { return x.Id === self.editAddress.Id; })[0];
                    }

                    self.addresses = AddressService.data.billingAddresses;
                    self.editMode = false;
                    if (self.addressControls)
                      self.addressControls.editMode = false;
                  });
              });
          }
        }
      },
      templateUrl: (window.location.hostname === 'localhost' ? '/app/' : '') + 'views/addressForm.html',
      controllerAs: 'adrForm',
      controller: function () {

      },
      bindToController: true
    };
  }

  angular.module('exhibitorPortalApp')
    .directive('addressForm', addressForm);
})(angular, window);
