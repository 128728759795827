'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:Pick A Date
 * @description
 * # Date
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epDate() {
    'ngInject';
		return {
			restrict: 'A',
			scope: {
				options: '=options',
				picker: '=picker'
			},
			link: function (scope, element) {
			    element.pickadate(scope.options);
			    scope.picker = element.pickadate('picker');
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epDate', epDate);
})(angular, window);
