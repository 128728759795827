'use strict';


angular.module('ngCart.directives', [])


    .controller('CartController', ['$scope', 'ngCart', function ($scope, ngCart) {
        $scope.ngCart = ngCart;
    }])

    .directive('addtocart', ['ngCart', function (ngCart) {
        return {
            restrict: 'E',
            controller: 'CartController',
            scope: {
                id: '@',
                name: '@',
                quantity: '@',
                price: '@',
                discount:'=',
                data: '=?',
                max: '@',
                success: '=?'
            },
            transclude: true,
            templateUrl: (window.location.hostname === 'localhost' ? '/app/' : '') + 'views/shared/addtocart.html',
            link: function (scope, element, attrs) {
                scope.attrs = attrs;
                scope.inCart = function () {
                    return ngCart.itemInCart(attrs.id);
                };
                scope.addItem = function(id, name, price, quantity, discount, data, maxTransactionQuantity) {
                    var item = ngCart.addItem(id, name, price, quantity, discount, data, maxTransactionQuantity);

                    if(scope.success) {
                        scope.success(item, element);
                    }
                };
            }
        };
    }])

    .directive('cart', ['$q', '$state', '$rootScope', 'ngCart', 'EnvironmentConfig', function ($q, $state, $rootScope, ngCart, EnvironmentConfig) {
        return {
            restrict: 'E',
            controller: 'CartController',
            scope: {
              errors: '=?',
              showCoupon: '=?',
              showUpsell: '=?',
              upsellSwapProduct: '=?',
            },
            templateUrl: (window.location.hostname === 'localhost' ? '/app/' : '') + 'views/shared/cart.html',
            link: function (scope, element, attrs) {
                scope.readonly = 'readonly' in attrs;
                scope.calculating = false;
                scope.environmentConfig = EnvironmentConfig;

                scope.showCoupon = true;  // TBD: need to figure out how this can be set
                scope.couponCode = ngCart.getCouponCode();

                scope.go = function (route, data) {
                    $state.go(route, angular.extend({ setSubmitted: true }, data));
                };

                function calc(validateCoupon) {
                    scope.calculating = true;

                    $q.all([
                        ngCart.getSubTotal(),
                        ngCart.getCartSummary(validateCoupon),
                    ])
                      .then(function (results) {
                        results.reduce(function (accum, summary) {
                          scope.hasAddress = summary.addressId > 0;
                          scope.couponCode = summary.CouponCode;
                          scope.couponName = summary.CouponName;
                          scope.totalCost = summary.TotalBalance;
                          scope.totalPrice = summary.TotalPrice;
                          scope.totalDiscountOther = summary.TotalDiscountOther;
                          scope.totalDiscountCoupon = summary.TotalDiscountCoupon;
                          scope.totalFees = summary.TotalFees;
                          scope.totalTax = summary.TotalTax;
                        });

                        scope.calculating = false;
                    });

                    //ngCart.getDiscountTotal().then(function (result) {
                    //  scope.discountTotal = result;
                    //});
                }

                $rootScope.$on('ngCart:change', function () {
                    calc(false);
                });

                calc(false);

                scope.addSwapActivations = function () {
                  var p = scope.upsellSwapProduct;

                  var discount = {
                    id: p.ProductDiscountID,
                    limit: p.MaxAvailableQty,
                    max: p.MaxDiscountsPerShow,
                    min: p.MinQualifyingQty,
                    price: p.DiscountedUnitPrice
                  };

                  ngCart.addItem(p.ProductPriceID, p.ProductName, p.UnitPrice, 1, discount, { thumbnail: p.ProductImage, note: p.MoreInfoTypeCode, productCode: p.ProductCode }, 0);
                  scope.showUpsell = false;
                  return false;
                }

                scope.applyCoupon = function (couponCode) {
                  var oldCouponCode = ngCart.getCouponCode();
                  ngCart.setCouponCode(couponCode);
                  scope.couponCode = couponCode;
                  calc(true);
                  if (oldCouponCode != couponCode) {
                      $rootScope.$broadcast('ngCart:change', {});
                  }
                  return false;
                }
            }
        };
    }])

    .directive('summary', ['$rootScope', 'ngCart', function ($rootScope, ngCart) {
        return {
            restrict: 'E',
            controller: 'CartController',
            scope: {},
            transclude: true,
            templateUrl: (window.location.hostname === 'localhost' ? '/app/' : '') + 'views/shared/summary.html',
            link: function (scope) {
                $rootScope.$on('ngCart:change', function () {
                    //ngCart.getCartSummary().then(function(result){
                    //  scope.totalCost = result.TotalBalance;
                    //});
                    //ngCart.getSubTotal().then(function (result) {
                    //  scope.subTotal = result;
                    //});
                });

                //ngCart.getCartSummary().then(function (result) {
                //  scope.totalCost = result.TotalBalance;
                //});
                //ngCart.getSubTotal().then(function (result) {
                //  scope.subTotal = result;
                //});
            }
        };
    }]);

