'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:sortOption
 * @description
 * # Sort Option
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epSortOption() {
    'ngInject'
		return {
			restrict: 'AE',
			require: '^epSort',
			scope: {
				sort: '=epSortOption',
        inverse: '=?epSortOptionInverse',
        clickEvent: '=?epSortOptionClickEvent'
			},
      link: function (scope, element, attrs, ParentSortController) {
			  element.on('click', function () {
          scope.$apply(function () {
					  if(ParentSortController.config.sort === scope.sort)
						  ParentSortController.config.sortDirection = !ParentSortController.config.sortDirection;
					  else {
						  if(ParentSortController.config.sortInverse != scope.inverse)
							  ParentSortController.config.sortDirection = !ParentSortController.config.sortDirection;

						  ParentSortController.config.sortInverse = scope.inverse;
						  ParentSortController.config.sort = scope.sort;
					  }

            if (angular.isFunction(scope.clickEvent)) {
              scope.clickEvent(scope.sort);
            }
				  });
			  });
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epSortOption', epSortOption);
})(angular, window);
