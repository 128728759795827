'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.Lead
 * @description
 * # Lead
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var LeadService = function ($q, $timeout, $filter, ApiService) {
    'ngInject'
    var self = this;

    self.data = {};

    self.getLeads = ApiService.endpoint.get({
      url: 'api/lead/getLeads',
      memoization: false,
      success: function (data, params) {
        self.data.leads = self.data.leads || {};
        self.data.leads[params.showCode] = extendLead(data);
      }
    });

    self.saveLeads = ApiService.endpoint.post({
      url: 'api/lead/saveLeads',
      success: function (data, params) {
        self.data.leads = self.data.leads || {};
        self.data.leads[params.showCode] = extendLead(self.data.leads[params.showCode]) || [];

        for (var i = 0; i < self.data.leads[params.showCode].length; i++)
          for (var a = 0; a < data.length; a++)
            if (self.data.leads[params.showCode][i].Id == data[a].Id)
              self.data.leads[params.showCode][i] = data[a];
      }
    });

    self.deleteLeads = ApiService.endpoint.post({
      url: 'api/lead/DeleteLeads',
      success: function (data, params) {
        self.data.leads = self.data.leads || {};
        self.data.leads[params.showCode] = self.data.leads[params.showCode] || [];

        data.forEach(function (id) {
          self.data.leads[params.showCode] = self.data.leads[params.showCode].filter(function (obj) {
            return obj.Id !== id;
          });
        });
      }
    });

    self.getLeadsCapturedBy = ApiService.endpoint.get({
      url: 'api/lead/getLeadCapturedByList',
      memoization: false,
      success: function (data, params) {
        self.data.leadsCapturedBy = self.data.leadsCapturedBy || {};
        self.data.leadsCapturedBy[params.showCode] = data;
      }
    });

    self.getLeadsEmailInformation = ApiService.endpoint.get({
      url: 'api/lead/getLeadsEmailInformation',
      success: function (data, params) {
        self.data.leadsEmailInformation = self.data.leadsCapturedBy || {};
        self.data.leadsEmailInformation[params.showCode] = data;
      }
    });

    self.getCountries = ApiService.endpoint.get({
      url: 'api/lead/getCountries',
      success: function (data, params) {
        self.data.countries = self.data.countries || {};
        self.data.countries[params.showCode] = data;
      }
    });

    self.getStates = ApiService.endpoint.get({
      url: 'api/lead/getStates',
      success: function (data, params) {
        self.data.states = self.data.states || {};
        self.data.states[params.showCode] = self.data.states[params.showCode] || {};
        self.data.states[params.showCode][params.countryCode] = data;
      }
    });

    self.getAllStates = ApiService.endpoint.get({
      url: 'api/lead/getAllStates',
      success: function (data, params) {
        self.data.allStates = self.data.allStates || {};
        self.data.allStates[params.showCode] = data;
      }
    });

    self.getSwapActivations = ApiService.endpoint.get({
      url: 'api/order/getSwapActivations',
      success: function (data, params) {
        self.data.swapActivations = self.data.swapActivations || {};
        self.data.swapActivations[params.showCode] = data;
      }
    });

    self.getDevKitActivationCode = ApiService.endpoint.get({
      url: 'api/lead/getDevKitActivationCode',
      success: function (data, params) {
        self.data.activationCodes = self.data.activationCodes || {};
        self.data.activationCodes[params.showCode] = data;
      }
    });

    self.hasDevKit = ApiService.endpoint.get({
      url: 'api/lead/getDevKit',
      success: function (data, params) {
        self.data.hasDevKit = self.data.hasDevKit || {};
        self.data.hasDevKit[params.showCode] = data;
      }
    });

    self.activateDevKit = ApiService.endpoint.post({
      url: 'api/lead/activateDevKit'
    });

    self.uploadBarcodes = ApiService.endpoint.post({
      url: 'api/lead/uploadBarcodes'
    });

    self.getLeadDates = function (showcode) {
      var d = $q.defer();

      self.data.leadDates = self.data.leadDates || {};

      if (self.data.leadDates[showcode])
        $timeout(function () {
          d.resolve();
        });
      else {
        var leadDates = {},
          toReturn = [];

        self.getLeads({ showCode: showcode }).then(function () {
          for (var i = 0; i < self.data.leads[showcode].length; i++) {
            var shortDate = $filter('date')(self.data.leads[showcode][i].LeadDate, 'shortDate', self.data.leads[showcode][i].TimeZoneOffset);

            //save the shortDate on the lead object so that we don't have to recalc it when filtering
            self.data.leads[showcode][i].shortLeadDate = shortDate;

            if (!leadDates[shortDate]) {
              leadDates[shortDate] = 1;

              toReturn.push(shortDate);
            }
          }


          self.data.leadDates[showcode] = toReturn;
          d.resolve();
        });
      }

      return d.promise;
    };

    function extendLead(leads) {
      return leads.map(function (l) {
        return angular.extend({}, l, {
          LeadType: 'SCAN'
        });
      })
    }
  };

  angular.module('exhibitorPortalApp')
    .service('LeadService', LeadService);
})(angular, window);
