'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.Survey
 * @description
 * # Survey
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var SurveyService = function (ApiService) {
    'ngInject'
    var self = this;

    self.data = {};

    self.getSurveys = ApiService.endpoint.get({
      url: 'api/survey/getSurveys',
      success: function (data, params) {
        self.data.surveys = self.data.surveys || {};
        if (data) {
          for (var i = 0; i < data.length; i++) {
            for (var j = 0; j < data[i].SurveyQuestions.length; j++) {
              data[i].SurveyQuestions[j].SurveyAnswers = cleanUpNextQuestionId(data[i].SurveyQuestions[j].SurveyAnswers);
            }
          }
        }
        self.data.surveys[params.showCode] = data;
      }
    });

    self.getSurvey = ApiService.endpoint.get({
      url: 'api/survey/getSurvey',
      memoization: false,
      success: function (data, params) {
        var exists = false;
        self.data.surveys = self.data.surveys || {};
        self.data.surveys[params.showCode] = self.data.surveys[params.showCode] || [];

        if (data) {
          for (var j = 0; j < data.SurveyQuestions.length; j++) {
            data.SurveyQuestions[j].SurveyAnswers = cleanUpNextQuestionId(data.SurveyQuestions[j].SurveyAnswers);
          }
        }

        if (data.Id === 0) {
          //if there isn't an unsaved survey template already then add it
          if (!self.data.surveys[params.showCode].filter(function (x) { return !x.Id; }).length)
            self.data.surveys[params.showCode].push(data);
        }
        else {
          for (var i = 0; i < self.data.surveys[params.showCode].length; i++)
            if (self.data.surveys[params.showCode][i].Id == data.Id) {
              self.data.surveys[params.showCode][i] = data;
              exists = true;
              break;
            }

          if (!exists)
            self.data.surveys[params.showCode].push(data);
        }
      }
    });

    self.getHistoricalSurveys = ApiService.endpoint.get({
      url: 'api/survey/getHistoricalSurveys',
      memoization: false,
      success: function (data) {
        self.data.historicalSurveys = self.data.historicalSurveys || {};
        self.data.historicalSurveys = data;
      }
    });

    self.saveSurvey = ApiService.endpoint.post({
      url: 'api/survey/saveSurvey',
      success: function (data, params) {
        var exists = false;
        self.data.surveys = self.data.surveys || {};
        self.data.surveys[params.showCode] = self.data.surveys[params.showCode] || [];

        for (var i = 0; i < self.data.surveys[params.showCode].length; i++)
          if (self.data.surveys[params.showCode][i].Id == data.Id) {
            self.data.surveys[params.showCode][i] = data;
            exists = true;
            break;
          }

        if (!exists)
          for (i = 0; i < self.data.surveys[params.showCode].length; i++)
            if (self.data.surveys[params.showCode][i].Id === 0) {
              self.data.surveys[params.showCode][i] = data;
              break;
            }
      }
    });

    self.getSurveyQuestions = ApiService.endpoint.get({
      url: 'api/survey/getSurveyQuestions',
      memoization: false,
      success: function (data, params) {
        self.data.surveyQuestions = self.data.surveyQuestions || {};
        if (data) {
          for (var i = 0; i < data.length; i++) {
            data[i].SurveyAnswers = cleanUpNextQuestionId(data[i].SurveyAnswers);
          }
        }
        self.data.surveyQuestions[params.showCode] = data;
      }
    });

    self.getSurveyQuestion = ApiService.endpoint.get({
      url: 'api/survey/getSurveyQuestion',
      memoization: false,
      success: function (data, params) {
        for (var i = 0; i < self.data.surveys[params.showCode].length; i++)
          if (self.data.surveys[params.showCode][i].Id == params.surveyId) {
            for (var a = 0; a < self.data.surveys[params.showCode][i].SurveyQuestions.length; a++)
              if (self.data.surveys[params.showCode][i].SurveyQuestions[a].Id == data.Id) {
                if (data) {
                  data.SurveyAnswers = cleanUpNextQuestionId(data.SurveyAnswers);
                }
                self.data.surveys[params.showCode][i].SurveyQuestions[a] = data;
                break;
              }

            break;
          }
      }
    });

    self.saveSurveyQuestion = ApiService.endpoint.post({
      url: 'api/survey/saveSurveyQuestion',
      success: function (data, params) {
        var i, a, exists = false;
        self.data.surveysQuestion = self.data.surveysQuestion || {};
        self.data.surveysQuestion[params.showCode] = self.data.surveysQuestion[params.showCode] || [];

        for (i = 0; i < self.data.surveys[params.showCode].length; i++)
          if (self.data.surveys[params.showCode][i].Id == params.surveyId)
            for (a = 0; a < self.data.surveys[params.showCode][i].SurveyQuestions.length; a++)
              if (self.data.surveys[params.showCode][i].SurveyQuestions[a].Id == data.Id) {
                self.data.surveys[params.showCode][i].SurveyQuestions[a] = data;
                exists = true;
                break;
              }

        if (!exists)
          for (i = 0; i < self.data.surveys[params.showCode].length; i++)
            if (self.data.surveys[params.showCode][i].Id == params.surveyId)
              for (a = 0; a < self.data.surveys[params.showCode][i].SurveyQuestions.length; a++)
                if (self.data.surveys[params.showCode][i].SurveyQuestions[a].Id === 0) {
                  self.data.surveys[params.showCode][i].SurveyQuestions[a] = data;
                  break;
                }
      }
    });

    self.getSurveyAnswers = ApiService.endpoint.get({
      url: 'api/survey/getSurveyAnswers',
      success: function (data, params) {
        self.data.surveyAnswers = self.data.surveyAnswers || {};
        data = cleanUpNextQuestionId(data);
        self.data.surveyAnswers[params.showCode] = data;
      }
    });

    self.addSurveyAnswers = ApiService.endpoint.post({
      url: 'api/survey/addSurveyAnswers',
      success: function (data, params) {
        self.data.surveyAnswers = self.data.surveyAnswers || {};
        self.data.surveyAnswers[params.showCode] = self.data.surveyAnswers[params.showCode] || [];
        self.data.surveyAnswers[params.showCode] = self.data.surveyAnswers[params.showCode].concat(data);
      }
    });

    self.saveSurveyAnswer = ApiService.endpoint.post({
      url: 'api/survey/saveSurveyAnswer',
      success: function (data, params) {
        self.data.surveyAnswers = self.data.surveyAnswers || {};
        self.data.surveyAnswers[params.showCode] = self.data.surveyAnswers[params.showCode] || [];

        for (var i = 0; i < self.data.surveyAnswers[params.showCode].length; i++)
          if (self.data.surveyAnswers[params.showCode][i].Id == data.Id) {
            self.data.surveyAnswers[params.showCode][i] = data;
            break;
          }

        if (i == self.data.surveyAnswers[params.showCode].length)
          self.data.surveyAnswers[params.showCode].push(data);
      }
    });

    self.getPreviewURL = ApiService.endpoint.get({
      url: 'api/survey/getPreviewURL',
      success: function (data, params) {
        self.data.previewURL = self.data.previewURL || {};
        self.data.previewURL[params.showCode] = self.data.previewURL[params.showCode] || {};
        self.data.previewURL[params.showCode][params.surveyId] = self.data.previewURL[params.showCode][params.surveyId] || {};

        try {
          self.data.previewURL[params.showCode][params.surveyId][params.questionId] = JSON.parse(data);
        }
        catch (e) {
          self.data.previewURL[params.showCode][params.surveyId][params.questionId] = data;
        }
      }
    });

    self.sendEmail = ApiService.endpoint.post({
      url: 'api/survey/sendEmail'
    });

    self.copySurvey = ApiService.endpoint.post({
      url: 'api/survey/copySurvey',
      success: function (data, params) {
        self.data.surveys = self.data.surveys || {};
        self.data.surveys[params.destinationShowCode] = self.data.surveys[params.destinationShowCode] || [];
        //self.data.surveys[params.destinationShowCode].push(...data);
        for (var i = 0; i < data.length; i++) {
          self.data.surveys[params.destinationShowCode].push(data[i]);
        }
      }
    });

    self.validateSurveyBranching = function (survey) {
      if (!survey)
        return { valid: false, messages: ['Survey does not exist'] };

      if (survey.SurveyQuestions) {
        for (var i = 0; i < survey.SurveyQuestions.length; i++) {
          var branchValid = { valid: true };
          checkBranchingRecursive(survey.SurveyQuestions[i], survey.SurveyQuestions, null, survey.SurveyQuestions[i].Id, branchValid);
          if (!branchValid.valid)
            return { valid: false, message: 'Question: ' + survey.SurveyQuestions[i].Text + ' has dependencies that would lead back to itself.' };
        }
      }

      return { valid: true, messages: [] };
    };

    self.availableBranchedQuestions = function (firstQuestion, questionsList) {
      var returnQuestions = [];
      for (var i = 0; i < questionsList.length; i++) {
        if (firstQuestion.Id === questionsList[i].Id || !questionsList[i].IsActive) //DTS 22-03-18: pbi-54579 added .isActive test
          continue;

        var branchValid = { valid: true };
        checkBranchingRecursive(questionsList[i], questionsList, null, firstQuestion.Id, branchValid);
        if (branchValid.valid)
          returnQuestions.push(questionsList[i]);
      }
      return returnQuestions;
    };

    function checkBranchingRecursive(question, questionsList, visited, questionId, branchValid) {
      if (!visited)
        visited = [];

      visited.push(question.Id);
      if (_.any(visited, function (v) { return v == questionId; })) {
        branchValid.valid = false;
        return;
      }
      var surveyAnswers = question.SurveyAnswers.filter(function (x) { return x.IsActive; });

      var distinctNextQuestionIds = surveyAnswers
        .map(function (sa) { return sa.NextQuestionId; })
        .filter(function (x, index, self) { return x != null && self.indexOf(x) === index; });
      for (var i = 0; i < distinctNextQuestionIds.length; i++) {
        var nextQuestion = questionsList.filter(function (x) { return x.Id === distinctNextQuestionIds[i]; });
        if (nextQuestion && nextQuestion.length)
          checkBranchingRecursive(nextQuestion[0], questionsList, visited, questionId, branchValid);
      }
    }

    self.hasDownlineRequiredQuestions = function (firstQuestion, questionsList) {
      var hasRequired = { required: false };
      checkRequiredRecursive(firstQuestion, questionsList, hasRequired);
      return hasRequired.required;
    };

    function checkRequiredRecursive(question, questionsList, hasRequired) {
      if (hasRequired.required)
        return;

      var surveyAnswers = question.SurveyAnswers.filter(function (x) { return x.IsActive; });

      var distinctNextQuestionIds = surveyAnswers
        .map(function (sa) { return sa.NextQuestionId; })
        .filter(function (x, index, self) { return x != null && self.indexOf(x) === index; });
      for (var i = 0; i < distinctNextQuestionIds.length; i++) {
        var nextQuestion = questionsList.filter(function (x) { return x.Id == distinctNextQuestionIds[i]; });
        if (nextQuestion && nextQuestion.length) {
          if (nextQuestion[0].Priority == 'REQUIRED') {
            hasRequired.required = true;
          }
          checkRequiredRecursive(nextQuestion[0], questionsList, hasRequired);
        }
      }
    }

    function cleanUpNextQuestionId(answers) {
      if (!answers)
        return answers;

      for (var i = 0; i < answers.length; i++) {
        if (answers[i].BranchAction === 'ENDSURVEY') {
          answers[i].NextQuestionId = 0;
        }
      }

      return answers;
    }

    function sortQuestionsList(questionList) {
      return questionList.sort(function (q1, q2) {
        return q1.DisplayOrder - q2.DisplayOrder;
      });
    }

    self.setNextQuestionIdByNaturalOrder = function (answer, question, questionList) {
      if (answer.BranchAction != 'NATURAL') {
        return;
      }

      var sortedQuestions = sortQuestionsList(questionList);

      var index = 0;
      for (var i = 0; i < sortedQuestions.length; i++) {
        if (sortedQuestions[i].Id == question.Id) {
          index = i + 1;
          break;
        }
      }

      if (index == sortedQuestions.length) {
        answer.NextQuestionId = null; //DTS 22-03-18: This assumes the last entry is "NONE - Remove Branching"
      } else {
        answer.NextQuestionId = sortedQuestions[index].Id; //DTS 22-03-18: This index represents the next question
      }
    };

    self.leadSeenQuestions = function (answers, questionList) {
      if (!answers || answers.length == 0 || !questionList || questionList.length == 0)
        return [];

      var sortedQuestions = sortQuestionsList(questionList);

      var answered = {
        alongTheWay: [sortedQuestions[0]],
        last: sortedQuestions[0]
      };
      getLastSeen(answers, sortedQuestions[0], questionList, answered);

      return answered.alongTheWay;
    };

    function getLastSeen(answers, question, questionList, answered) {
      var keys = Object.keys(answers);

      var questionAnswer = keys.filter(function (k) { return Number(k) === question.Id; });

      var hasAnswer = false;
      if (questionAnswer && questionAnswer.length > 0) {
        var qAnswers = answers[questionAnswer[0]];
        hasAnswer = _.any(qAnswers, function (qa) {
          return qa;
        });
      }

      var hasBranching = _.any(question.SurveyAnswers, function (sa) { return sa.BranchAction != 'NATURAL'; });

      function addQuestionToAnswered(q) {
        if (!_.any(answered.alongTheWay, function (atw) { return atw.Id === q.Id; }))
          answered.alongTheWay.push(q);
        answered.last = q;
      }

      var answerIds = null;
      if (hasAnswer) {
        addQuestionToAnswered(question);

        var answer = answers[question.Id.toString()];
        answerIds = Object.keys(answer).filter(function (a) { return answers[question.Id][a]; }).map(function (a) { return Number(a); });
      } else if (!hasBranching) {
        addQuestionToAnswered(question);
      }

      if ((question.Priority === 'REQUIRED' || hasBranching)
        && !hasAnswer) {
        addQuestionToAnswered(question);
        return;
      }

      var distinctNextQuestionIds = question.SurveyAnswers
        .filter(function (sa) {
          return answerIds === null || _.any(answerIds, function (ai) { return sa.Id === ai; });
        })
        .map(function (sa) { return sa.NextQuestionId; })
        .filter(function (x, index, self) { return x != null && self.indexOf(x) === index; });
      for (var i = 0; i < distinctNextQuestionIds.length; i++) {
        var nextQuestion = questionList.filter(function (x) { return x.Id == distinctNextQuestionIds[i]; });
        if (nextQuestion && nextQuestion.length) {
          getLastSeen(answers, nextQuestion[0], questionList, answered);
        }
      }
    }

    self.getFirstQuestion = function (questionList) {
      var sortedQuestions = sortQuestionsList(questionList);
      return sortedQuestions && sortedQuestions.length ? sortedQuestions[0] : null;
    };

    self.getPreviousQuestion = function (answers, questionList, currentQuestionId) {
      var lastSeenQuestions = self.leadSeenQuestions(answers, questionList);

      if (lastSeenQuestions && lastSeenQuestions.length) {
        var question = null;
        var found = false;
        for (var i = 0; i < lastSeenQuestions.length; i++) {
          if (currentQuestionId == lastSeenQuestions[i].Id) {
            return question;
          } else {
            question = lastSeenQuestions[i];
          }
        }

        return question;
      } else {
        return null;
      }
    };

    function getNextQuestionByAnswerId(answerId, question, questionList) {
      var answers = question.SurveyAnswers.filter(function (sa) { return sa.Id == answerId; });
      var nextQuestionId = answers && answers.length ? answers[0].NextQuestionId : null;

      if (nextQuestionId) {
        var nextQuestion = questionList.filter(function (q) { return q.Id == nextQuestionId; });
        return nextQuestion && nextQuestion.length ? nextQuestion[0] : null;
      } else {
        return null;
      }
    }

    function buildBranchByAnswerRecursive(questionsInBranch, questionList, currentQuestion) {
      if (!currentQuestion)
        return;

      if (!_.any(questionsInBranch.questions, function (qi) { return qi == currentQuestion.Id; }))
        questionsInBranch.questions.push(currentQuestion.Id);

      for (var i = 0; i < currentQuestion.SurveyAnswers.length; i++) {
        var nextQuestion = getNextQuestionByAnswerId(currentQuestion.SurveyAnswers[i].Id, currentQuestion, questionList);
        if (nextQuestion)
          buildBranchByAnswerRecursive(questionsInBranch, questionList, nextQuestion);
      }
    }

    self.getNoLongerValidQuestions = function (prevAnswerId, questionList, currentQuestion, currentAnswerId) {
      var questionsInPrevBranch = {
        questions: [currentQuestion.Id]
      };
      var prevNextQuestion = getNextQuestionByAnswerId(prevAnswerId, currentQuestion, questionList);
      buildBranchByAnswerRecursive(questionsInPrevBranch, questionList, prevNextQuestion);

      var questionsInNewBranch = {
        questions: [currentQuestion.Id]
      };
      var newNextQuestion = getNextQuestionByAnswerId(currentAnswerId, currentQuestion, questionList);
      buildBranchByAnswerRecursive(questionsInNewBranch, questionList, newNextQuestion);

      return questionsInPrevBranch.questions.filter(function (q) {
        return !_.any(questionsInNewBranch.questions, function (nq) { return q == nq; });
      });
    };
  };

  angular.module('exhibitorPortalApp')
    .service('SurveyService', SurveyService);
})(angular, window);
