'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalLoginApp.directive:productDetail
 * @description
 * # Product Detail
 * Directive of the exhibitorPortal Product Detail
 */
(function (angular, window, undefined) {
  function productDetail($window, ProductsService, EnvironmentConfig, $timeout, $rootScope, $sce) {
    'ngInject'
    return {
      restrict: 'E',
      scope: {
        product: '<',
        quantities: '<',
        added: '<',
        prices: '<',
        onAdd: '&',
        setQuantity: '&'
      },
      link: function (scope, element, attrs) {
        var self = scope.pd;

        self.modalOnAdd = (cartItem, el) => {
          if (angular.isDefined(self.onAdd)) {
            self.onAdd()(cartItem, el);
          }
        };

        self.modalSetQuantity = (id, quantity, relative) => {
          if (angular.isDefined(self.setQuantity)) {
            self.setQuantity()(id, quantity, relative);
          }
        };

        self.htmlDesc = self.product && self.product.HTMLDescriptionDetail ? $sce.trustAsHtml(self.product.HTMLDescriptionDetail) : '';

        ProductsService
          .getProductImages({ productID: self.product.Id })
          .then(function () {
            self.productImages = ProductsService.data.productImages[self.product.Id];
          });

      },
      templateUrl: (window.location.hostname === 'localhost' ? '/app/' : '') + 'views/productDetail.html',
      controllerAs: 'pd',
      controller: function () {

      },
      bindToController: true
    };
  }

  angular.module('exhibitorPortalApp')
    .directive('productDetail', productDetail);
})(angular, window);
