'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.Order
 * @description
 * # Order
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var OrderService = function ($log, ApiService) {
    'ngInject'
    var self = this;

    self.data = {};

    self.getOrders = ApiService.endpoint.get({
      url: 'api/order/getOrders',
      success: function (data, params) {
        self.data.orders = self.data.orders || {};
        self.data.orders[params.showCode] = data;
      }
    });

    self.getConfirmationHtml = ApiService.endpoint.get({
      url: 'api/order/getConfirmationHtml',
      success: function (data, params) {
        self.data.confirmations = self.data.confirmations || {};
        self.data.confirmations[params.showCode] = self.data.confirmations[params.showCode] || {};
        self.data.confirmations[params.showCode][params.salesOrderId] = data;
      }
    });

    self.getSwapActivations = ApiService.endpoint.get({
      url: 'api/order/getSwapActivations',
      success: function (data, params) {
        self.data.swapActivations = self.data.swapActivations || {};
        self.data.swapActivations[params.showCode] = data;
      }
    });

    self.processOrder = ApiService.endpoint.post({
      url: 'api/order/processOrder'
    });

    self.processPendingOrder = ApiService.endpoint.post({
      url: 'api/order/processPendingOrder'
    });

    self.completeOrder = ApiService.endpoint.post({
      url: 'api/order/completeOrder',
      success: function (data, params) {
        $log.log(data);
      }
    });

    self.sendExtraConfirmation = ApiService.endpoint.post({
      url: 'api/order/sendExtraConfirmation'
    });

    self.getOrder = ApiService.endpoint.get({
      url: 'api/order/getOrder',
      success: function (data, params) {
        self.data.order = self.data.orders || {};
        self.data.order[params.salesOrderId] = data;
      }
    });

  };

  angular.module('exhibitorPortalApp')
    .service('OrderService', OrderService);
})(angular, window);
