'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.filter:price
 * @description
 * # Price
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function price() {
    'ngInject'
        return function (input) {
            return typeof input === 'string' ? input.replace(/\$0.00/g, 'FREE') : input;
        };
    }

    angular.module('exhibitorPortalApp')
		.filter('price', price);
})(angular, window);
