'use strict';

angular
  .module('swapTemplates', [])
  .run(function ($templateCache) {
    'ngInject';//flag for ng-annotate

    if (process.env.NODE_ENV === 'production') {
      var requireContext = require.context('../../../', true, /^.\/views[//\/]([^//\/]+\/)*[^//\/]+\.html?$/i);
      requireContext.keys().forEach(function (templatePath) {
        $templateCache.put(templatePath.replace(/^(\.\/)/i, ''), requireContext(templatePath));
      });
    }
  });
