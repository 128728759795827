'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:Sort
 * @description
 * # Sort
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epSort() {
    'ngInject'
		return {
			restrict: 'A',
			scope: {
				config: '=epSort'
			},
      controller: function ($scope) {
        'ngInject'
				this.config = $scope.config;
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epSort', epSort);
})(angular, window);
