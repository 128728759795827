'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.Search
 * @description
 * # Search
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
    angular.module('exhibitorPortalApp')
      .constant('CheckoutBase', {        
        showCode: '',
        productIds: [],
        boothId: null,
        addressId: null,
        postBackUrl: '',
        redirectUrl: '',
        strategyId: null,
        PromoCode: '',
        SourceCode: '',
        address: null,
        doesOrderHaveEventBit: false
    });
})(angular, window);
