'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:SurveyQuestionCtrl
 * @description
 * # Survey Question Ctrl
 * Controller of the exhibitorPortalApp
 */

(function(angular, window, undefined) {
  var SurveyQuestionCtrl = function ($scope, $state, $q, $timeout, $filter, CurrentEvent, Survey, SurveyQuestion, Search, SurveyService, SurveyQuestions) {
    'ngInject'
		var self = this;

        SurveyQuestion.SurveyAnswers = $filter('orderBy')(SurveyQuestion.SurveyAnswers, 'DisplayOrder');

        for(var i = 0; i < SurveyQuestion.SurveyAnswers.length; i++)
            SurveyQuestion.SurveyAnswers[i].DisplayOrder = i;

        self.survey = Survey;
        self.baseSurveyQuestion = SurveyQuestion;
		    self.surveyQuestion = angular.copy(SurveyQuestion);
		    self.search = Search.surveyQuestion;
        self.search.edit = false;
        self.answersToAdd = '';
        self.hasOther = false;
      self.otherAnswerText = '';

    self.questions = SurveyQuestions;
    self.availableQuestions = SurveyService.availableBranchedQuestions(self.surveyQuestion, self.questions);
   
    if (!_.any(self.availableQuestions, function (q) { return q.Text === 'END SURVEY' && !q.Id; })) {
        self.availableQuestions.push({
          Id: 0,
          Text: 'END SURVEY',
          EndSurvey: true
        });
    }

    if (!_.any(self.availableQuestions, function (q) { return q.Text === 'NONE - Remove Branching' && !q.Id; })) {
        self.availableQuestions.push({
          Id: -1,
          Text: 'NONE - Remove Branching',
          EndSurvey: false,
          RemoveBranching: true
        });
      }

    self.changeBranchAction = function (answer) {
        if (answer) {
          if (answer.BranchAction === 'NATURAL') {
            answer.BranchAction = 'OVERRIDE';
          } else {
            answer.BranchAction = 'NATURAL';
            SurveyService.setNextQuestionIdByNaturalOrder(answer, self.surveyQuestion, self.questions);
          }
        }
      };

    self.changeNextQuestion = function (answer) {
        if (!answer)
          return;

        var nextQuestion = self.availableQuestions.filter(function (q) { return q.Id == answer.NextQuestionId; })[0];
        if (nextQuestion && nextQuestion.EndSurvey) {
          answer.BranchAction = 'ENDSURVEY';
        } else if (nextQuestion && nextQuestion.RemoveBranching) {
          answer.BranchAction = 'NATURAL';
          SurveyService.setNextQuestionIdByNaturalOrder(answer, self.surveyQuestion, self.questions);
        } else {
          answer.BranchAction = 'OVERRIDE'; //first question selecting third as branch
        }
      };

    self.changeNextQuestionOther = function () {
        var nextQuestion = self.availableQuestions.filter(function (q) { return q.Id == self.otherNextQuestionId; })[0];
        if (nextQuestion && nextQuestion.EndSurvey) {
          self.otherBranchAction = 'ENDSURVEY';
        } else if (nextQuestion && nextQuestion.RemoveBranching) {
          self.otherBranchAction = 'NATURAL';
          var answer = { BranchAction: 'NATURAL', NextQuestionId: null };
          SurveyService.setNextQuestionIdByNaturalOrder(answer, self.surveyQuestion, self.questions);
          self.otherNextQuestionId = answer.NextQuestionId;
        } else {
          self.otherBranchAction = 'OVERRIDE';
        }
      };

      self.changeBranchActionOther = function () {
        if (self.otherBranchAction === 'NATURAL') {
          self.otherBranchAction = 'OVERRIDE';
        } else {
          self.otherBranchAction = 'NATURAL';
          self.otherNextQuestionId = null;
        }
      };

      self.otherNextQuestionId = null;
      self.otherBranchAction = 'NATURAL';

      var other = self.surveyQuestion.SurveyAnswers.filter(function(x) { return x.SurveyAnswerTypeCode === 'OPN' && x.IsActive; });

        if(other.length){
            self.hasOther = true;
          self.otherAnswerText = other[0].Text;
          self.otherNextQuestionId = other[0].NextQuestionId;
          self.otherBranchAction = other[0].BranchAction;
        }

        $scope.$on('$stateChangeStart', function(e) {
            if(!angular.equals(self.surveyQuestion, self.baseSurveyQuestion)) {
                if(!window.confirm('You have unsaved changes that will be lost, are you sure you want to proceed?'))
                    e.preventDefault();
            }
        });

      self.changePriority = function (priority) {
        self.prevPriority = self.surveyQuestion.Priority;
        self.surveyQuestion.Priority = priority;
      };
      
        self.answerFilter = function(answer) {
            return answer.SurveyAnswerTypeCode !== 'OPN';
        };

        self.addAnswer = function() {
            //split on new line and remove empty entries
            var answers = self.answersToAdd.split('\n').filter(function(x) { return x.trim(); });

            for(var i = 0; i < answers.length; i++){
                self.surveyQuestion.SurveyAnswers.push({
                    Id: 0,
                    SurveyQuestionId: self.surveyQuestion.Id,
                    SurveyAnswerTypeCode: 'SEL',
                    Text: answers[i],
                    IsActive: true,
                    DisplayOrder: self.surveyQuestion.SurveyAnswers.length + 1
                });
            };

            self.answersToAdd = '';
        };

        self.hasActiveAnswers = function() {
          if (!self.surveyQuestion.SurveyAnswers)
            return false;

          return self.surveyQuestion.SurveyAnswers.find(answer => answer.SurveyAnswerTypeCode !== 'OPN' && answer.IsActive) ? true : false;
        };

        $scope.$watch(function () {
          return self.surveyQuestion.Text;
        }, function (newval, oldval) {
          if (newval !== oldval) {
            if (!self.surveyQuestion.Id && _.any(self.survey.SurveyQuestions, function (sq) { return sq.Text === newval; }))
              self.form.questionName.$setValidity('nonunique', false);
            else
              self.form.questionName.$setValidity('nonunique', true);
          }
        });

    self.save = function (redirect) {
            if(!self.form.$valid){
                var d = $q.defer();

                $timeout(function() {
                    self.form.$setSubmitted();

                    d.reject();
                });

                return d.promise;
            }

            if(self.answersToAdd.trim())
                self.addAnswer();

            if(other.length){
              if (self.hasOther) {
                other[0].Text = self.otherAnswerText || other[0].Text;
                other[0].NextQuestionId = self.otherNextQuestionId;
                other[0].BranchAction = self.otherBranchAction;
              }
                else
                    other[0].IsActive = false;
            }
            else if(!other.length && self.hasOther){
                var newOther = {
                    Id: 0,
                    SurveyQuestionId: self.surveyQuestion.Id,
                    SurveyAnswerTypeCode: 'OPN',
                    Text: self.otherAnswerText,
                    IsActive: true,
                    DisplayOrder: self.surveyQuestion.SurveyAnswers.length + 1,
                    NextQuestionId: self.otherNextQuestionId,
                    BranchAction: self.otherBranchAction
                };
                other.push(newOther);
                self.surveyQuestion.SurveyAnswers.push(newOther);
            }

            if (!angular.equals(self.surveyQuestion, self.baseSurveyQuestion)) {

                if (!self.surveyQuestion.Id && !_.any(self.survey.SurveyQuestions, function (sq) { return sq.Text === self.surveyQuestion.Text; }))
                    self.survey.SurveyQuestions.push(self.surveyQuestion);
                else
                    for(var i = 0; i < self.survey.SurveyQuestions.length; i++)
                        if(self.survey.SurveyQuestions[i].Id == self.surveyQuestion.Id){
                          self.survey.SurveyQuestions[i] = self.surveyQuestion;
                            break;
                        }
                
                return SurveyService
                    .saveSurvey({
                        params: { showCode: CurrentEvent.ShowCode },
                        data: {
                            showCode: CurrentEvent.ShowCode,
                            survey: self.survey
                        }
                    })
                  .then(function(survey) {
                        for(var i = 0; i < SurveyService.data.surveys[CurrentEvent.ShowCode].length; i++){
                            if(SurveyService.data.surveys[CurrentEvent.ShowCode][i].Id == survey.Id){
                                //edit case
                                if(self.surveyQuestion.Id){
                                    for(var a = 0; a < SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions.length; a++)
                                        if(SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions[a].Id == self.surveyQuestion.Id){
                                            self.baseSurveyQuestion = SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions[a];
                                            self.surveyQuestion = angular.copy(self.baseSurveyQuestion);
                                            break;
                                        }
                                }
                                //add case
                                else {
                                    //determine which question is the new one by comparing old question array to new
                                    for(var a = 0; a < SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions.length; a++){
                                        var existingQuestion = self.survey.SurveyQuestions.filter(function(x) {
                                            return x.Id == SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions[a].Id;
                                        });

                                        if(!existingQuestion.length){
                                            //find the old question object and update it with its new Id and any saved answer objects
                                            var unsavedQuestion = self.survey.SurveyQuestions.filter(function(x) { return !x.Id; });

                                            if(unsavedQuestion.length){
                                                unsavedQuestion[0] = SurveyService.data.surveys[CurrentEvent.ShowCode][i].SurveyQuestions[a];
                                                self.baseSurveyQuestion = unsavedQuestion[0];
                                                self.surveyQuestion = angular.copy(self.baseSurveyQuestion);
                                                break;
                                            }
                                        }
                                    }
                                }

                                break;
                            }
                        }

                        if(redirect !== false)
                            $state.go('event.surveys.survey', { id: self.survey.Id });
                    });
            }
            else {
                var d = $q.defer();

                $timeout(function() {
                    d.resolve();

                    if(redirect !== false)
                        $state.go('event.surveys.survey', { id: self.survey.Id });
                });

                return d.promise;
            }
        };

        self.changeType = function(type) {
            self.surveyQuestion.SurveyQuestionTypeCode = type;
        };

        self.upClick = function(index) {
            if(index === 0)
                self.paginated[index].DisplayOrder = self.paginated[self.paginated.length - 1].DisplayOrder + 1;
            else {
                var dest, destDisplayOrder;

                dest = self.paginated[index - 1];
                destDisplayOrder = dest.DisplayOrder;

                dest.DisplayOrder = self.paginated[index].DisplayOrder;
                self.paginated[index].DisplayOrder = destDisplayOrder;
            }
        };

        self.downClick = function(index) {
            if(index === self.paginated.length - 1){
                self.paginated[index].DisplayOrder = 0;

                for(var i = 0; i < self.paginated.length; i++)
                    if(self.paginated[i] != self.paginated[index])
                        self.paginated[i].DisplayOrder++;
            }
            else {
                var dest, destDisplayOrder;

                dest = self.paginated[index + 1];
                destDisplayOrder = dest.DisplayOrder;

                dest.DisplayOrder = self.paginated[index].DisplayOrder;
                self.paginated[index].DisplayOrder = destDisplayOrder;
            }
        };

        self.edit = function(e) {
            self.search.edit = !self.search.edit;
            e.target.value = self.search.edit;
            angular.element(e.target).triggerHandler('change', true);
        };

        self.activePredicate = function(active, edit) {
            if(edit === 'true')
                return true;
            else
                return active;
        };

        self.preview = function() {
            return self.save(false).then(function() {
                SurveyService.getPreviewURL({
                    showCode: CurrentEvent.ShowCode,
                    surveyId: self.survey.Id,
                    questionId: self.surveyQuestion.Id
                })
                .then(function() {
                    window.open(SurveyService.data.previewURL[CurrentEvent.ShowCode][self.survey.Id][self.surveyQuestion.Id]);
                });
            });
        };
	};

	angular.module('exhibitorPortalApp')
	  .controller('SurveyQuestionCtrl', SurveyQuestionCtrl);
})(angular, window);
