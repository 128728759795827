'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:filter
 * @description
 * # Filter
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
	function epFilter() {
	'ngInject'
		return {
			restrict: 'A',
			scope: {
				activeFilters: '=epFilter',
				filtering: '=?epFilterStatus'
			},
      controller: function ($scope) {
        'ngInject'
				var self = this;
				self.activeFilters = $scope.activeFilters;

        if ($scope.filtering !== undefined) {
          $scope.$watch('activeFilters', function () {
            $scope.filtering = self.getCount($scope.activeFilters);
          }, true);
        }

				self.getCount = function (activeFilters) {
					var count = 0;

					(function recurse(filter){
						for(var key in filter)
							if(Object.prototype.toString.apply(filter[key]) === '[object Object]')
								recurse(filter[key]);
							else {
								if(Object.prototype.toString.apply(filter[key]) === '[object Array]'){
									for(var i = 0; i < filter[key].length; i++)
										if(filter[key][i].defaultValue !== undefined){
											if(filter[key][i].responseValue != filter[key][i].defaultValue)
												count++;
										}
										else
											count++;
								}
								//assume object is primitive
								else
									count++;
							}
					})(activeFilters);

					return count;
				};
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epFilter', epFilter);
})(angular, window);
