'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:promise
 * @description
 * # Promise Button
 * Directive of the exhibitorPortalApp
 */
(function (angular, window, undefined) {
  function epPromiseButton($timeout) {
    'ngInject'
		return {
			restrict: 'A',
			scope: {
				promise: '&epPromiseButton',
				base: '=?epPromiseButtonWatchBase',
				dirty: '=?epPromiseButtonWatchDirty',
				success: '=?epPromiseButtonSuccess',
				error: '=?epPromiseButtonError',
				waitText: '=?epPromiseButtonWaitText',
				wait: '@epPromiseButtonWaitClass'
			},
			link: function (scope, button) {
				button.on('click', function () {
					if(!button.prop('disabled') && !button.attr('disabled')){
						button.prop('disabled', true);

						if(scope.wait !== undefined)
							button.toggleClass(scope.wait);
						else if(button.find('[ep-promise-button-wait-class]').length){
							var waitChild = button.find('[ep-promise-button-wait-class]');
							waitChild.toggleClass(waitChild.attr('ep-promise-button-wait-class'));
						}

						var oldText = button.text();

						if (scope.waitText) {
						    button.text(scope.waitText);
						}

						scope.promise()().then(function() {
							// if(scope.success) {
							// 	var timeout = scope.success.timeout || 2000;
							// 	var text = scope.success.text || 'Success!';
							// 	var cssClass = scope.success.cssClass  || 'success';
							// 	var oldText = button.text();

							// 	button.text(text);
							// 	button.toggleClass(cssClass);

							// 	$timeout(function() {
							// 		button.text(oldText);
							// 		button.toggleClass(cssClass);
							// 	}, timeout);
							// }
							if(scope.success === undefined || scope.success){
								var timeout = (scope.success && scope.success.timeout !== undefined && scope.success.timeout) || 2000;
								var text = (scope.success && scope.success.text !== undefined && scope.success.text) || ' Success!';
								var cssClass = (scope.success && scope.success.cssClass !== undefined && scope.success.cssClass)  || 'success';

								button.text(text);
								button.toggleClass(cssClass);

								$timeout(function() {
									button.text(oldText);
									button.toggleClass(cssClass);
								}, timeout);
							}
						}, function() {
							if(scope.error === undefined || scope.error){
								var timeout = (scope.error && scope.error.timeout !== undefined && scope.error.timeout) || 2000;
								var text = (scope.error && scope.error.text !== undefined && scope.error.text) || ' Error!';
								var cssClass = (scope.error && scope.error.cssClass != undefined && scope.error.cssClass)  || 'alert';

								button.text(text);
								button.toggleClass(cssClass);

								$timeout(function() {
									button.text(oldText);
									button.toggleClass(cssClass);
								}, timeout);
							}
						})
						.finally(function() {
							button.prop('disabled', false);

							if(scope.wait)
								button.toggleClass(scope.wait);
							else if(waitChild) {
								waitChild.toggleClass(waitChild.attr('ep-promise-button-wait-class'));
							}
						});
					}
				});

				if(scope.base !== undefined && scope.dirty !== undefined){
					scope.$watch('base', function () {
						button.prop('disabled', true);
					});

					scope.$watch('dirty', function (newValue) {
						if(!angular.equals(scope.base, newValue))
							button.prop('disabled', false);
						else
							button.prop('disabled', true);
					}, true);
				}
			}
		};
	}

	angular.module('exhibitorPortalApp')
		.directive('epPromiseButton', epPromiseButton);
})(angular, window);
