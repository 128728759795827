'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:CartCtrl
 * @description
 * # CartCtrl
 * Controller of the exhibitorPortalApp
 */

(function(angular, window, undefined) {
  var CartCtrl = function ($rootScope, $scope, $stateParams, $anchorScroll, $state, $q, Cart, AllAvailableProducts, ProductsService, DataSalesService, CurrentEvent, AdditionalInfo, ngCart, Checkout, OrderService, CartTotal, EnvironmentConfig, UserEmail, ECR, Errors, Addresses, Countries, States, AddressService) {
    'ngInject'
        var self = this;
        var products = [];
        var p;

        self.isRecommendationEvent = window.RecommendationEvents.toString().split(',').includes(CurrentEvent.ShowCode);
        self.environmentConfig = EnvironmentConfig;
        self.cartTotal = CartTotal;
        self.errors = Errors;
        self.showCoupon = true;
        self.addresses = Addresses;
        self.countries = Countries;
        self.states = States;
        self.currentEvent = CurrentEvent;
        self.addressControl = {};

        $stateParams.productsHash = $stateParams.productsHash.split(',');

        $anchorScroll();		

        if (self.environmentConfig.QuickShop) {
          self.checkout = Checkout;

          self.processQuickOrder = function () {
            if (self.addressControl.editMode && !self.addresses.length) {
              if (!self.addressControl.addressForm.$valid)
                return $q.when(self.addressControl.addressForm.$setSubmitted());
              else
                return self.addressControl.save().then(function () {
                  $scope.shop.processOrder();
                });
            }
            else if (self.addressControl.editMode)
              return self.addressControl.save().then(function () {
                $scope.shop.processOrder();
              });
            else if (!self.addressControl.editMode && !self.checkout.address) {
              window.alert('Please provide a billing address before proceeding.');
              return $q.when(undefined);
            }
            else if (self.checkout.address.AddressTypeCode != 'BILLING') {
              self.editAddress = self.checkout.address;

              return self.addressControl.save(true).then(function () {
                $scope.shop.processOrder();
              });
            }
            else
              return $scope.shop.processOrder();
          };
        }

        var swapActivationUpsellProduct = AllAvailableProducts.filter(function (x) { return x.ProductCode == '312'; }); 

        if (swapActivationUpsellProduct.length) {
        	swapActivationUpsellProduct = swapActivationUpsellProduct[0];

        	if (!Cart.getItems().filter(function (x) { return x.getData().productCode == '312'; }).length) {
        		self.showUpsell = true;
        		self.swapActivationUpsellProduct = swapActivationUpsellProduct;
        	}
        }
 

        self.extraConfirmationRecipient = ECR.get() === undefined || ECR.get() == null ? UserEmail : ECR.get();
        $scope.$watch('cart.extraConfirmationRecipient', function (val) {
            ECR.set(val);
        }, true);

        for (var i = 0; i < $stateParams.productsHash.length; i++)
            if (p = $stateParams.productsHash[i].trim())
                products.push(p);

        if (products.length) {
            var productCode;
            var productQuantity;

            Cart.empty();

            for (i = 0; i < products.length; i++) {
                p = products[i].split(':');

                productCode = p[0];
                productQuantity = p[1];

                p = AllAvailableProducts.filter(function (x) { return x.ProductCode == productCode; });

                if (p.length === 1) {
                    p = p[0];

                    var discount = {
                        id: p.ProductDiscountID,
                        limit: p.MaxAvailableQty,
                        max: p.MaxDiscountsPerShow,
                        min: p.MinQualifyingQty,
                        price: p.DiscountedUnitPrice
                    };

                    Cart.addItem(
                        p.ProductPriceID,
                        p.ProductName,
                        p.PackageTotalPrice,
                        productQuantity,
                        discount,
                        {
                            thumbnail: p.ProductImage,
                            note: p.MoreInfoTypeCode == 'CUSTOMPRINT' ? '' : undefined,
                            productCode: p.ProductCode,
                            promoCode: p.ProductCode == '1010' ? 'ENGAGED' : '' //use the ENGAGED promoCode if this is in an upsell
                        });
                }
            }
        }

        self.next = function() {
            var dataSalesValidation = [];

            return $q.all((function() {
                var promises = [];

                promises.push(ProductsService.validateCart({
                    data: {
                      showCode: CurrentEvent.ShowCode,
                      addressId: Checkout.address ? Checkout.address.Id : null,
                      couponCode: Cart.getCouponCode(),
                      cart: (function() {							
                        return Cart.getItems().map(function(x) {                            
                            return {
                                productPriceId: x.getId(),
                                quantity: x.getQuantity(),
                                note: x.getData().note,
                                dataSalesListId: x.getData().dataSalesListId,
                                dataSalesTargetShowCode: x.getData().targetShowCode,                                
                                promoCode: x.getData().promoCode
                            };
                        });
                    })() 
                    }
                }));

                var cartItems = Cart.getItems();
                for (var i = 0; i < cartItems.length; i++) {
                  if (!!cartItems[i].getData().dataSalesTierTypeCode) {
                    dataSalesValidation.push(cartItems[i].getData().timestamp);
                    promises.push(DataSalesService.validate(CurrentEvent.ShowCode, cartItems[i].getData()));
                  }
                }

                return promises;
            })())
            .then(function(results) {
                var hasErrors = false;

                // PDJ, need to start at 1, those are where DSE errors come from
                // Do not see where results[0] is being tested
                for (var i = 1; i < results.length; i++) {
                	if (results[i].length) {
                		self.errors.dataSales[dataSalesValidation[i - 1]] = results[i];
                		hasErrors = hasErrors || results[i].length;
                	}
                }

                if(hasErrors)
                    $anchorScroll();
                else
                    $state.go('event.shop.address');
            });
        };

        self.persistExtraConfirmationRecipient = function (val) {
            ECR.set(val);
        };

        $rootScope.$on('ngCart:change', function () {
            var dataSalesValidation = [];
            var errors = {};

            return $q.all((function() {
              var promises = [];

              // PDJ: This same code is in app.js, changes here needed there
              var cartItems = Cart.getItems();
              for (var i = 0; i < cartItems.length; i++) {
                  if (!!cartItems[i].getData().dataSalesTierTypeCode) {
                  dataSalesValidation.push(cartItems[i].getData().timestamp);
                  promises.push(DataSalesService.validate(CurrentEvent.ShowCode, cartItems[i].getData()));
                }
              }

              return promises;
            })())
              .then(function (results) {
                // PDJ: This same code is in app.js, changes here needed there
                for (var i = 0; i < results.length; i++) {
                  if (results[i].length) {
                    errors.dataSales = errors.dataSales || {};
                    errors.dataSales[dataSalesValidation[i]] = results[i];
                  }
                }

                self.errors = errors;
            });
        });
    };

	angular.module('exhibitorPortalApp')
	  .controller('CartCtrl', CartCtrl);
})(angular, window);
