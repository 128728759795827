'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:CheckoutCtrl
 * @description
 * # CheckoutCtrl
 * Controller of the exhibitorPortalApp
 */

(function(angular, window, undefined) {
  var CheckoutCtrl = function ($anchorScroll, $rootScope, CartTotal, CurrentEvent, EnvironmentConfig, Cart, Checkout, AllAvailableProducts, modalFactory) {
    'ngInject'
    var self = this;

    self.isRecommendationEvent = window.RecommendationEvents.toString().split(',').includes(CurrentEvent.ShowCode);

    $rootScope.showActionWidget = true;

    $anchorScroll();

    const cart = Cart.getCart();
    self.doesOrderHaveEventBit = false;
    cart.items.forEach(item => {
      const product = AllAvailableProducts.find(p => p.ProductPriceID == item._id);
      if (product.ProductCode == 1510) {
        self.doesOrderHaveEventBit = true;
      } else if (product.PackageItemIDList) {
        const productIDList = product.PackageItemIDList.split(',').map(x => parseInt(x));
        const products = AllAvailableProducts.filter(p => _.some(productIDList, id => id == p.Id));
        self.doesOrderHaveEventBit = self.doesOrderHaveEventBit || _.some(products, p => p.ProductCode == 1510);
      };
    })
    Checkout.doesOrderHaveEventBit = self.doesOrderHaveEventBit;

		self.cartTotal = CartTotal;
    self.environmentConfig = EnvironmentConfig;

	};

	angular.module('exhibitorPortalApp')
	  .controller('CheckoutCtrl', CheckoutCtrl);
})(angular, window);
