'use strict';

/**
 * @ngdoc service
 * @name exhibitorPortalApp.Resource
 * @description
 * # Resource
 * Service in the exhibitorPortalApp.
 */

(function (angular, window, undefined) {
  var ResourceService = function (ApiService) {
    'ngInject'
        var self = this;

        self.data = {};

        self.getResources = ApiService.endpoint.get({
            url: 'api/resource/getResources',
            success: function (data, params) {
              self.data.resources = self.data.resources || {};
              self.data.resources[params.subFolder] = data;
            }
        });

        self.getExhibitorResources = ApiService.endpoint.get({
          url: 'api/resource/getExhibitorResources',
          success: function (data, params) {
            self.data.resources = self.data.resources || {};
            self.data.resources[params.showCode] = data;
          }
        });
    };

    angular.module('exhibitorPortalApp')
      .service('ResourceService', ResourceService);
})(angular, window);
